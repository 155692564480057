import React, { Component, useState } from "react";
import "./Playbook.scss";
import Btn from "../common/Button";
import Store from "../../store";
import { ToastsStore } from "react-toasts";
import utils from "../../api/common/utils";
import Modal from "../common/Modal";
import ModalLang from "../common/Modal";
import ProfileApi from "../../api/profile/Profile";
import Playbook from "../../api/playbook/Playbook";
import Auth from "../../api/auth/Auth";
import { Spinner } from "reactstrap";
import {
  Col,
  Row,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  FormGroup,
  Label
} from "reactstrap";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import HeadingContainer from "../layout/HeadingContainer";
import FormRule from "./FormRule";
import FormNewPlaybook from "./FormNewPlaybook";

export default class MyPlaybook extends Component {
  state = {
    loading: true,
    toggleAction: false,
    isRequire: false,
    isIgnore: false,
    addingNewPlayBook: false,
    status: ".active",
    currentPage: 1,
    firstPage: 1,
    lastPage: Infinity,
    totalEntries: 0,
    fromEntries: 0,
    toEntries: 0,
    data: [],
    currentPlaybook: {}
  };

  isUserAuthority = utils.isUserAuthority();
  componentDidMount() {
    this.loadPlaybookList();
    console.log(utils.isUserAuthority());
  }

  loadPlaybookList = (page = 1) => {
    this.setState({ loading: true });
    Playbook.get_playbook_details(page, "active")
      .then(body => {
        this.setState({
          data: body.data,
          toEntries: body.to,
          loading: false,
          fromEntries: body.from,
          currentPage: body.current_page,
          lastPage: body.total != 0 ? Math.ceil(body.total / body.per_page) : 1,
          totalEntries: body.total
        });
      })
      .catch(e => {
        this.setState({
          loading: false
        });
      });
  };
  navigateToPlaybook = playbook => {
    let path = "/playbook";
    if (playbook.id) path += `/${playbook.id}`;
    else path += "/new-playbook";
    this.props.history.push(path, {
      playbook: playbook || null
    });
  };
  handleRequire() {
    this.setState({ is_required: !this.state.variable_list.is_required });
  }

  handleIgnore() {
    this.setState({ is_ignore: !this.state.variable_list.is_ignore });
  }

  handleOpenPlaybook = playbook => {
    this.navigateToPlaybook(playbook);
  };
  handleInactivePlaybook = (playbook, index) => {
    Playbook.inactive_playbook_rule(playbook.id)
      .then(body => {
        this.loadPlaybookList(this.state.currentPage);
      })
      .catch(e => {
        console.log(e);
        ToastsStore.error(
          e.error.message ? e.error.message : "Cannot Inactive Playbook"
        );
      });
  };

  handleEditPlaybook = playbook => {
    this.setState({ currentPlaybook: playbook }, () => {
      this.editPlaybook.toggle();
    });
  };

  handletoggleAction() {
    this.setState({ toggleAction: !this.state.toggleAction });
  }
  handleNextPage = () => {
    if (this.state.currentPage == this.state.lastPage || this.state.loading)
      return;
    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.loadPlaybookList(this.state.currentPage);
    });
  };
  handleGotoPage = diff => {
    if (this.state.loading) return;
    this.setState({ currentPage: this.state.currentPage + diff }, () => {
      this.loadPlaybookList(this.state.currentPage);
    });
  };
  handlePreviousPage = () => {
    if (this.state.currentPage == 1 || this.state.loading) return;
    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.loadPlaybookList(this.state.currentPage);
    });
  };

  onUpdatePlaybookRule = (data, index) => {
    console.log(data);
    let id = data.id;
    delete data.id;
    Playbook.edit_playbook_rule(id, data)
      .then(body => {
        ToastsStore.success(body.message);
        let newdata = this.state.data.concat();
        newdata[index] = data;
        this.setState({ data: newdata });
        this.editPlaybook.toggle();
      })
      .catch(e => {
        ToastsStore.error(e.error.message);
      });
  };

  onAddPlaybookRule = data => {
    console.log(data);
    Playbook.add_new_playbook(data)
      .then(body => {
        ToastsStore.success(body.message);
        this.loadPlaybookList(1);
        this.addPlaybook.toggle();
      })
      .catch(e => {
        ToastsStore.error(e.error.message);
      });
  };

  renderNewPlaybookRuleModal() {
    return (
      <Modal ref={c => (this.addPlaybook = c)} cname="model-sm model-pb">
        <ModalHeader
          className="modal-header"
          toggle={() => this.addPlaybook.toggle()}
        >
          Add New Playbook
        </ModalHeader>
        <ModalBody>
          <FormNewPlaybook
            type={"new"}
            onAdd={data => this.onAddPlaybookRule(data)}
          />
        </ModalBody>
      </Modal>
    );
  }

  renderEditPlaybookRuleModal() {
    return (
      <Modal ref={c => (this.editPlaybook = c)} cname="model-sm model-pb">
        <ModalHeader
          className="modal-header"
          toggle={() => this.editPlaybook.toggle()}
        >
          Edit Playbook Rule
        </ModalHeader>
        <ModalBody>
          <FormNewPlaybook
            type={"edit"}
            playbook={this.state.currentPlaybook}
            index={this.state.currentPlaybookIndex}
            onAdd={(data, index) => this.onUpdatePlaybookRule(data, index)}
          />
        </ModalBody>
      </Modal>
    );
  }

  renderplaybook() {
    return (
      <div className="renderplaybook">
        {this.state.loading ? (
          <div
            style={{
              display: "inline-block",
              alignSelf: "center",
              margin: "auto"
            }}
          >
            <Spinner style={{ color: "#473080" }} />
          </div>
        ) : this.state.data.length == 0 ? (
          <div
            style={{
              display: "inline-block",
              alignSelf: "center",
              margin: "auto"
            }}
          >
            No Records
          </div>
        ) : (
          <table>
            <tr>
              <th>Playbook Name</th>
              <th>Contract Type</th>
              <th>Owner</th>
              <th>Created on</th>
              <th>Last Modified</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {this.state.data.map((d, i) => {
              return (
                <PlaybookItem
                  canEdit={this.isUserAuthority}
                  playbook={d}
                  index={i}
                  handleOpenPlaybook={this.handleOpenPlaybook}
                  handleInactivePlaybook={this.handleInactivePlaybook}
                  handleEditPlaybook={this.handleEditPlaybook}
                />
              );
            })}
          </table>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="playbook-heading">
        <div className="p-heading">
          <h1>Organization's Playbook</h1>
          <div className="add-new" onClick={() => this.addPlaybook.toggle()}>
            <span>+ </span> Add New Playbook
          </div>
        </div>
        <div className="_page-container">
          <div className="playbook-content-section">
            {this.renderplaybook()}
          </div>

          <div className="Playbook-last-section">
            <div className="showing">{`Showing ${this.state.fromEntries ||
              "0"} to ${this.state.toEntries || "0"} of ${this.state
              .totalEntries || "0"} Entries`}</div>
            <div className="page-numbers">
              {
                <div
                  className={`prev ${
                    this.state.currentPage == 1 ? "gray" : ""
                  }`}
                  onClick={e => this.handlePreviousPage()}
                >
                  Previous Page
                </div>
              }
              {this.state.currentPage > 2 && (
                <div className="p2" onClick={e => this.handleGotoPage(-2)}>
                  {this.state.currentPage - 2}
                </div>
              )}
              {this.state.currentPage > 1 && (
                <div className="p2" onClick={e => this.handleGotoPage(-1)}>
                  {this.state.currentPage - 1}
                </div>
              )}
              <div className="p1">{this.state.currentPage}</div>
              {this.state.lastPage - this.state.currentPage >= 1 && (
                <div className="p2" onClick={e => this.handleGotoPage(1)}>
                  {this.state.currentPage + 1}
                </div>
              )}
              {this.state.lastPage - this.state.currentPage >= 2 && (
                <div className="p2" onClick={e => this.handleGotoPage(2)}>
                  {this.state.currentPage + 2}
                </div>
              )}
              {
                <div
                  className={`next ${
                    this.state.currentPage == this.state.lastPage ? "gray" : ""
                  }`}
                  onClick={e => this.handleNextPage()}
                >
                  Next Page
                </div>
              }
            </div>
          </div>
          {this.renderNewPlaybookRuleModal()}
          {this.renderEditPlaybookRuleModal()}
        </div>
      </div>
    );
  }
}

function PlaybookItem(props) {
  let d = props.playbook;
  let canEdit = props.canEdit;
  const [action, setAction] = useState(false);
  return (
    <tr>
      <td className="cursor" onClick={e => props.handleOpenPlaybook(d)}>
        {d.playbook_name}
      </td>
      <td className="inrow">
        {d.contract_types[0] && (
          <div className="type-btn">{d.contract_types[0]}</div>
        )}
        {d.contract_types[1] && (
          <div className="type-btn">{d.contract_types[1]}</div>
        )}
      </td>
      <td>
        <img src={require("../../assets/images/avatar.png")} alt="" />{" "}
        {d.owner_name}
      </td>
      <td>{d.created_at}</td>
      <td>{d.updated_at}</td>
      <td>{d.status || "Active"}</td>
      <td tabIndex="0">
        <div className="action">
          <Dropdown isOpen={action} toggle={() => setAction(!action)}>
            <DropdownToggle
              className={`${action ? "dotbackground" : null} dots`}
            >
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="actions_box">
              <DropdownItem onClick={e => props.handleOpenPlaybook(d)}>
                <div className="scale cursor">
                  <img src={require("../../assets/images/scale.png")} alt="" />
                  <p>View</p>
                </div>
              </DropdownItem>

              <DropdownItem disabled={!canEdit}>
                {" "}
                <div
                  className="scale cursor"
                  onClick={e => props.handleEditPlaybook(d, props.index)}
                >
                  <img src={require("../../assets/images/edit.png")} alt="" />
                  <p>Edit</p>
                </div>
              </DropdownItem>

              <DropdownItem disabled={!canEdit}>
                {" "}
                <div
                  className="scale cursor"
                  onClick={e => {
                    setAction(false);
                    props.handleInactivePlaybook(d, props.index);
                  }}
                >
                  <img
                    src={require("../../assets/images/disabled.png")}
                    alt=""
                  />
                  <p>Inactive</p>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
}
