/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import NotificationsIcon from "mdi-react/NotificationsIcon";
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import Badge from "@material-ui/core/Badge";
import Notifications from "../../api/notification/Notification";
import Store from "../../store";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import Auth from "../../api/auth/Auth";
import utils from "../../api/common/utils";
const BASE_URL = Store.BASE_URL;

const notifications = [
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava.png`,
    name: "Cristopher Changer",
    message: " has started a new project",
    date: "09:02"
  },
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
    name: "Sveta Narry",
    message: " has closed a project",
    date: "09:00"
  },
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava3.png`,
    name: "Lory McQueen",
    message: " has started a new project as a Project Managert",
    date: "08:43"
  },
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
    name: "Cristopher Changer",
    message: " has closed a project",
    date: "08:43"
  }
];

export default class TopbarNotification extends Component {
  state = {
    collapse: false,
    showNotificationDot: false,
    notifications: [{ message: "" }],
    allNotifictaion: [],
    noNotification:false
  };

  socket = null;

  componentDidUpdate() {
    this.recreateSocketConnection();
  }

  componentDidMount() {
    // alert("hi");
    this.fetchNotifications();

    this.createSocketConnection();
    window.addEventListener("online", () => this.recreateSocketConnection());

    utils.get_token().then(token => {
      fetch(`${BASE_URL}/v1/notifications`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
          // "Access-Control-Allow-Origin":  "https://chroma-mock/api/v1/notifications"
        }
      })
        .then(res => {
          res.json().then(data => {
            this.setState({ allNotifictaion: data.body.notifications.data });
            console.log("respnse noti ka===>", data.body.notifications.data);
          });
        })
        .catch(err => {
          console.log(err.message);
          // console.log(token)
        });
    });
  }

  fetchNotifications = () => {
    // this.setState({
    //   notifications: JSON.parse(localStorage.getItem("notifications") || "[]")
    // });
    // new copmmnt
    // Notifications.getUserNotifications()
    //   .then(res => {
    //     Store.user = res;
    //     this.setState({ loading: false });
    //     if (Store.profileRef) Store.profileRef.setState({ loading: false });
    //   })
    //   .catch(e => {
    //     ToastsStore.error("Failed to Load Profile Details!");
    //     this.setState({ loading: false });
    //     if (Store.profileRef) Store.profileRef.setState({ loading: false });
    //     console.log(e);
    //   });
    // Store.topBarRef = this;
  };

  recreateSocketConnection = () => {
    if (
      !(
        this.socket &&
        (this.socket.readyState == 1 || this.socket.readyState == 0)
      )
    ) {
      this.createSocketConnection();
    }
  };

  createSocketConnection = () => {
    let user = Auth.get_current_user();

    this.socket = new WebSocket(
      `wss://caoell8j90.execute-api.us-east-2.amazonaws.com/dev?user_id=${user.user_id}&channel=channel-general`
    );

    this.socket.onopen = () => {
      console.log("Notification connection established");
      this.startSocketHeartbeat();
    };

    this.socket.error = () => {
      console.log("Notification connection not established");
      this.recreateSocketConnection();
    };

    this.socket.onmessage = event => {
      console.log("Socket message data recieved: " + event.data);
      try {
        let data = JSON.parse(event.data);
        switch (data.action) {
          case "NOTIFICATION":
            this.onNewNotificationReceived(data.data);
            break;
          case "PING":
            this.sendPongToServer(data);
            break;
          case "PONG":
            console.log("recieved PONG");
            break;
          default:
            this.onNewNotificationReceived(data); // console.log("Unknown Action");
        }
      } catch (e) {
        console.log("Wrong JSON");
        return false;
      }
    };
  };

  playNotioficationSound() {
    var audio = new Audio(require("../../assets/sounds/notification.mp3"));
    audio.play();
  }

  sendPongToServer = () => {
    this.socket.send(JSON.stringify({ action: "PONG" }));
  };

  startSocketHeartbeat = () => {
    setInterval(() => {
      this.socket.send(JSON.stringify({ action: "PING" }));
    }, 60000);
  };

  timeNow = () => {
    var d = new Date(),
      h = (d.getHours() < 10 ? "0" : "") + d.getHours(),
      m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return h + ":" + m;
  };

  onNewNotificationReceived = notification => {
    notification.date = this.timeNow();
    Store.notifications.unshift(notification);
    let notifications = this.state.notifications.concat();
    notifications.unshift(notification);
    this.playNotioficationSound();
    this.setState(
      { notifications: notifications, showNotificationDot: true }
      // () => {
      //   localStorage.setItem(
      //     "notifications",
      //     JSON.stringify(this.state.notifications)
      //   );
      // }
    );
  };

  componentWillUnmount = () => {
    if (this.socket && this.socket.readyState == 1) {
      this.socket.close();
    }
  };

  redirectToURL = url => {
    if (url) window.location.href = url;
  };

  toggle = () => {
    this.setState(prevState => ({
      collapse: !prevState.collapse,
      showNotificationDot: false
    }));
  };

  render() {
    let newNotification = 0;
    console.log(this.state);
    const { collapse } = this.state;
    return (
      <div className="topbar__collapse">
        <button className="topbar__btn" type="button" onClick={this.toggle}>
          <Badge
            variant="dot"
            overlap="circle"
            color={"#473080"}
            invisible={!this.state.showNotificationDot}
          >
            <BellOutlineIcon />
          </Badge>
        </button>
        {collapse && (
          <button
            className="topbar__back"
            type="button"
            onClick={this.toggle}
          />
        )}

        <Collapse isOpen={collapse} className="topbar__collapse-content">
          <div
            id="scrollbar"
            style={
              this.state.allNotifictaion.length >= 5
                ? { height: "363.70px", overflowY: "auto" }
                : { overflowY: "hidden" }
            }
          >
            <div>
              <div className="topbar__collapse-title-wrap">
                {this.state.notifications.length == 1 ? (
                  <p className="topbar__collapse-title">
                    You have 0 new notification
                  </p>
                ) : (
                  <p className="topbar__collapse-title">
                    You have {this.state.notifications.length - 1} new
                    notification
                  </p>
                )}
                <p className="topbar__collapse-title">
                  <u>Mark all Read </u>
                </p>
                {/* <button className="topbar__collapse-button" type="button">
              Mark all as read
            </button> */}
              </div>

              <div className="topbar__collapse-bottom-border" />

              {!this.state.notifications.length
                ? void 0
                : this.state.notifications
                    .slice(0, 5)
                    .map((notification, index) => (
                      <>
                        {notification.message != "" ? (
                          <div
                            className={`topbar__collapse-item ${
                              notification.redirect ? "link" : ""
                            }`}
                            key={index}
                            onClick={e => {
                              this.redirectToURL(notification.redirect);
                            }}
                          >
                            {
                              // this.stat
                            }
                            <p className="topbar__collapse-message">
                              {notification.message.length > 100
                                ? notification.message.slice(0, 97) + "..."
                                : notification.message}
                            </p>
                            {/* <p className="topbar__collapse-date">{notification.date}</p> */}
                            {/* <p>hello {console.log(notification)} </p> */}
                          </div>
                        ) : (
                          void 0
                        )}
                        {/* <div className="topbar__collapse-bottom-border" /> */}
                      </>
                    ))}
              {/* Arslan code is starting from here */}
              {!this.state.allNotifictaion
                ? ()=>{ this.setState({ noNotification:true })}
                : this.state.allNotifictaion.map((notification, index) => (
                    <>
                      <div
                        className={`topbar__collapse-item ${
                          notification.redirect ? "link" : ""
                        }`}
                        key={index}
                        onClick={e => {
                          this.redirectToURL(notification.data.redirect);
                        }}
                      >
                        {
                          // this.stat
                        }
                        {/* <p>All Notifications {index+1} </p> */}
                        <p className="topbar__collapse-message">
                          {notification.data.message}
                          {/* {notification.message.length > 100
                        ? notification.message.slice(0, 97) + "..."
                        : notification.message} */}
                        </p>
                        {/* <p className="topbar__collapse-date">{notification.date}</p> */}
                        {/* <p> {console.log("from lower==>", notification)} </p> */}
                      </div>
                      <div className="topbar__collapse-bottom-border" />
                    </>
                  ))}
              {/* Arslan code is starting from here */}
              { this.state.allNotifictaion.length > 0 || (this.state.notifications.length>1) ? null : (
                
                /* <Link
              className="topbar__collapse-link"
              to="/dashboard_default"
              onClick={this.toggle}
            >
              See all notifications
            </Link> */
                <div className="topbar__collapse-no-item">
                  <span>Loading.....</span> 
                </div>
              )}
            </div>

            { this.state.allNotifictaion.length > 0 || (this.state.notifications.length>1) ?  (

            <div
              className="view-all-notification"
              
            >
              <p className="topbar__collapse-title">View all notifications</p>
            </div>): void 0}
          </div>
        </Collapse>

        {/* <Collapse isOpen={collapse} className="topbar__collapse-content">
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Notifications</p>
            <button className="topbar__collapse-button" type="button">
              Mark all as read
            </button>
          </div>
          {notifications.map((notification, index) => (
            <div className="topbar__collapse-item" key={index}>
              <p className="topbar__collapse-message">{notification.message}</p>
              <p className="topbar__collapse-date">{notification.date}</p>
            </div>
          ))}
          <Link
            className="topbar__collapse-link"
            to="/dashboard_default"
            onClick={this.toggle}
          >
            See all notifications
          </Link>
        </Collapse> */}
      </div>
    );
  }
}
