import React, { Component, useState } from "react";
import EditorToolbar from "../common/editor/EditorToolbar";

import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import MyEditor from "../common/editor/MyEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Contract from "../../api/contract/Contract";
import CommentItem from "../common/contract/CommentItem";
import Btn from "../common/Button";
import Badge from "@material-ui/core/Badge";
import Notification from "rc-notification";
import Store from "../../store";
import { ToastsStore } from "react-toasts";
import { Prompt, Redirect } from "react-router";
import FileDocumentOutline from "mdi-react/FileDocumentOutlineIcon";
import PlaylistEdit from "mdi-react/PlaylistEditIcon";
import WarningOutlineIcon from "mdi-react/WarningOutlineIcon";
import PlaylistCheckIcon from "mdi-react/PlaylistCheckIcon";
import ContentSaveOutline from "mdi-react/ContentSaveOutlineIcon";
import CloseIcon from "mdi-react/CloseIcon";
import CommentIcon from "mdi-react/CommentTextOutlineIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import CommentPlusIcon from "mdi-react/CommentPlusIcon";
import PlusIcon from "mdi-react/PlusIcon";
import SVG from "react-inlinesvg";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import MoreVertIcon from "mdi-react/MoreVertIcon";
import SendIcon from "mdi-react/SendIcon";
import ChevronLeft from "mdi-react/ChevronLeftIcon";
import debounce from "lodash.debounce";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
// import "./editor.scss";

import Tooltipx from "@material-ui/core/Tooltip";

import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import TextArea from "../common/TextArea";
import DropdownInput from "../common/Dropdown";
import Tabs from "../common/Tabs";
import ClauseItem from "../common/contract/ClauseItem";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { ContentBlock, convertFromRaw, genKey, convertToRaw } from "draft-js";
import Clause from "../../api/clause/Clause";
import throttle from "lodash.throttle";
import $ from "jquery";
import {
  getCommentDecorator,
  isSelectionACommentEntity,
  addLineBreakBetweenComments,
  generateSpanId,
  getSelectionEntitySpanId,
  removeCommentBySpanId,
  selectionHasEntity,
  addCommentIntelligence,
  addCommentNew,
  removeHighlights,
  renderHighlightsInEditor,
  setDecorator,
  getHighlightColorClass
} from "../common/editor/utils";
import { handleNewLine } from "draftjs-utils";
import Comment from "../../api/comment/Comment";
import ToolTip from "../common/ToolTip";
import SimpleToolTip from "../common/ToolTip";
import Fab from "../common/Fab";

import AssignModal from "../common/contract/AssignModal";
import ApproveModal from "../common/contract/ApproveModal";
import { AnalyzeCard } from "../common/contract/AnalyzeCard";
import { AnalyzeNotification } from "../common/contract/AnalyzeNotification";

import TextTruncate from "react-text-truncate";
import ReactMeter from "../common/ReactMeter";

import Index from "../common/contract/Index";
import { set } from "immutable";
import { Switch } from "@material-ui/core";
import { switchStatement } from "@babel/types";

let notificationRU = null;
const toolbarPlugin = createToolbarPlugin();

export default class MyContract extends Component {
  ERB_Summary = "summary";
  ERB_Clause = "clause";
  ERB_Details = "details";
  ERB_Playbook = "playbook";
  ERB_SEC = "sec";
  clauseCurrentPage = 1;
  lastCommentBlock = null;
  timeDiff = null;
  init_comment = {};

  comments_wrapper_height = 0;

  empty_content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { key: "637gr" }
      }
    ]
  };

  dummy = [
    {
      type: "payment terms",
      sub_type: ["exclusive"],
      paragraphs: [
        {
          paragraph_id: "637gr",
          paragraph:
            "In consideration of the promises\nand covenants contained herein the Parties agree to the following:"
        }
      ]
    },
    {
      type: "license",
      sub_type: [
        "exclusive",
        "irrevocable",
        "royalty_free",
        "unlimited",
        "worldwide"
      ],
      paragraphs: [
        {
          paragraph_id: "8dms6",
          paragraph:
            "“Agent”\nmeans the software that will enable Customer’s data to be transmitted to and\nretrieved from Supplier’s servers for the Service(s) described in the applicable\nSchedule(s)."
        }
      ]
    }
  ];

  state = {
    action: this.props.location.pathname.includes("modelling")
      ? "modelling"
      : "insight",
    contentState: this.empty_content,
    editorState: EditorState.createWithContent(
      convertFromRaw(this.empty_content)
    ),
    title: "Untitled Document",
    savedState: EditorState.createWithContent(
      convertFromRaw(this.empty_content)
    ),
    savedTitle: "",
    saving: false,
    forwarding: false,
    index: [],
    showIndex: false,
    hideIndex: true,
    contract_id: null,
    titleEditingMode: false,
    redirect: false,
    showRightBar: false,
    hideRightBar: true,
    erbSelectedItem: null,
    erbLoading: true,
    editSummaryMode: false,
    summary: { alerts: [] },
    clauses: [],
    erbClauseLoading: false,
    clauseLastPage: Infinity,
    erbClauseSearchKeyword: null,
    erbClauseSearchNoResults: false,
    clauseCurrentPage: 1,
    dropdownOpen: false,
    showComments: false,
    comments: {},
    savedComments: {},
    focusSpanId: "",
    focusSpanId2: "",
    hideBubble: false,
    slimulateAlerts: [],
    analyzing: false,
    showAnalyzeBar: false,
    showAnalyzeFab: false,
    showAssignModal: false,
    showApproveModal: false,
    loadingAssignModal: false,
    unassigned_users: [],
    assigned_users: [],
    is_editable: 1,
    is_owner: 1,
    is_approved: 0,
    is_remodeled: 1,
    is_remodeled: 1,
    is_cik_available: 0,
    is_saved: true,
    contractLoading: false,
    showRulingsModal: false,
    rulings: [],
    playbookIntelligence: null,
    sub_extractors_values: null,
    extractors: [],
    risk_score: 0.0,
    secFiling: null,
    toggleAction: false
  };

  index_paddings = Array(7).fill(12);

  componentDidUpdate(prevProps, prevState) {
    this.updateIndex(prevState);
    let x = this;
    $.each($(".comment-box"), function(i, item) {
      setTimeout(function() {
        let factor =
          $("._my-editor-wrapper").outerHeight() -
          $(".editor-main-container").outerHeight();
        let key = $(item).attr("data");
        let editorBlock = $(`.inline-comment-span[data-span-id="${key}"]`);
        if (!editorBlock.offset()) return;
        let top = x.getOffsetSpan(editorBlock[0]);
        //top= top -factor;
        let originalTop = top;
        if (
          !$(item)
            .prev()
            .offset()
        ) {
          $(item).css("top", top);
          $(item).attr("fake-top", top);
          return;
        }

        let prev = $(item).prev();
        let prevBottom = parseInt(prev.attr("fake-top")) + prev.outerHeight();
        top = top > prevBottom + 20 ? top : prevBottom + 20;

        $(item).css("top", top);
        $(item).attr("fake-top", top);
      }, 0 + i * 0);
    });

    $(".comment-box")
      .get()
      .forEach(function(item, index) {});
  }

  componentDidMount() {
    document.addEventListener("click", () => {});
    Notification.newInstance(
      { style: { position: "absolute", right: 10, bottom: 10, zIndex: 5000 } },
      n => (notificationRU = n)
    );

    if (this.props.location.pathname.split("/").pop() !== "new-contract") {
      let contractId = this.props.location.pathname.split("/").pop();
      console.log("cid", contractId);
      this.onOpenContract(contractId);
    } else {
      if (this.props.location.pathname.split("/").pop() == "new-contract") {
        Contract.upload_contract(
          this.state.title,
          convertToRaw(this.state.editorState.getCurrentContent())
        )
          .then(res => {
            this.setState(
              {
                // savedState: Object.assign({}, this.state.editorState),
                savedTitle: this.state.title,
                saving: false,
                contract_id: res.contract_id,
                redirect: "new-url"
              },
              () => {
                this.fetctComments();
                this.fetchAssignedUsers();
              }
            );
          })
          .catch(e => {
            ToastsStore.error("An error occurred while saving contract!");
            console.log(e);
            this.setState({ saving: false });
          });
      } else {
        this.fetctComments();
        this.fetchAssignedUsers();
      }

      // window.onbeforeunload = () => {
      //   if (this.state.is_editable) {
      //     if (
      //       !this.isCommentsSaved() ||
      //       !this.isSaved() ||
      //       !this.isTitleSaved()
      //     )
      //       return "You have unsaved changes, are you sure you want to leave?";
      //     return undefined;
      //   }
      //   return undefined;
      // };
    }
  }

  onOpenContract = id => {
    this.setState({ contractLoading: true });
    Contract.get_contract(id)
      .then(contract => {
        console.log(contract);
        let content =
          contract.content.content !== undefined
            ? contract.content.content
            : contract.content;
        //first replace all 'null' values with ''
        content = JSON.stringify(content, null, 4);
        content = content.replace(/"text": null/g, '"text": ""');
        //then if 'atomic' blocks contain empty text they should be changed to ' '
        console.log(content);
        content = JSON.parse(content);
        content.blocks = content.blocks.map(block => {
          if (block.type === "atomic" && block.text === "") block.text = " ";
          return block;
        });
        //then convert entityMap array into object
        content.entityMap = { ...content.entityMap };
        this.setState({ contractLoading: false }, () =>
          this.setState(
            {
              editorState: setDecorator(
                EditorState.createWithContent(
                  convertFromRaw(content || this.empty_content)
                )
              ),
              savedState: setDecorator(
                EditorState.createWithContent(
                  convertFromRaw(content || this.empty_content)
                )
              ),
              contentState: content || this.empty_content,
              title: contract.contract_name,
              savedTitle: contract.contract_name,
              contract_id: id,
              is_owner: contract.is_owner,
              is_editable: contract.is_editable,
              is_approved: contract.is_approved,
              is_remodeled: contract.is_remodeled,
              is_cik_available: contract.is_cik_available,
              risk_score: parseFloat(contract.risk_score)
            },
            () => {
              this.setState({ savedState: this.state.editorState });
              this.fetctComments();
              this.fetchAssignedUsers();
            }
          )
        );
      })
      .catch(e => {
        this.setState({ contractLoading: false });

        ToastsStore.error(
          "Error opening contract... " +
            (e.hasOwnProperty("error") ? e.error.message : "")
        );
        console.log(e);
      });
  };

  fetchAssignedUsers = () => {
    Contract.get_assign_users(this.state.contract_id)
      .then(data => {
        this.setState({
          unassigned_users: data.content.unassigned_users,
          assigned_users: data.content.assigned_users
            ? data.content.assigned_users
            : []
        });
        console.log("x", data.content.unassigned_users);
      })
      .catch(e => {
        console.error(e);
        ToastsStore.error(e.error.message);
      });
  };

  fetctComments = () => {
    Comment.get_comments_for_contract(this.state.contract_id)
      .then(data => {
        this.initComments(data.content);
        console.log(data);
      })
      .catch(e => {
        console.error(e);
        ToastsStore.error(e.error.message);
      });
  };

  initComments = commentsData => {
    let comments = {};
    console.log(commentsData);
    commentsData.forEach(comment => {
      console.log(comment);
      comments[comment.block_key] = {};
      comments[comment.block_key].index = -1;
      comments[comment.block_key].data = [];
      comments[comment.block_key].data.push({
        id: comment.id,
        comment_by: comment.comment_by,
        block_key: comment.block_key,
        comment: comment.comment,
        created_at: comment.created_at,
        updated_at: comment.updated_at
      });
      comments[comment.block_key].data = comments[
        comment.block_key
      ].data.concat(comment.replies);
    });
    let com = this.sortCommentIndexes(comments);
    let scom = Object.assign({}, com);
    console.log("INIT COMMENT!");

    this.setState({ comments: com, savedComments: scom }, () => {
      this.showPlaybook();
    });
  };

  componentWillUnmount() {
    notificationRU.destroy();
    window.onbeforeunload = undefined;
  }

  updateIndex = debounce(prevState => {
    if (!this.state.hideIndex) {
      if (
        JSON.stringify(prevState.contentState) !==
        JSON.stringify(this.state.contentState)
      ) {
        this.setState({ index: this.generateIndex() });
      }
    }
  }, 300);

  onContentStateChange = contentState => {
    console.log("Content State Changed", contentState);

    this.setState({ contentState });
  };

  isSaved() {
    return this.state.is_saved;
  }

  isCommentsSaved = () => {
    return (
      JSON.stringify(this.state.comments) ===
      JSON.stringify(this.state.savedComments)
    );
  };

  isTitleSaved() {
    return this.state.savedTitle === this.state.title;
  }

  isApproved() {
    return this.state.is_approved;
  }

  isContractEmpty() {
    const content = this.state.contentState.blocks;
    if (content.length === 0) return true;
    if (content.length === 1) {
      if (
        content[0].text.length === 0 &&
        content[0].inlineStyleRanges.length === 0 &&
        content[0].entityRanges.length === 0 &&
        Object.entries(content[0].data).length === 0
      )
        return true;
    }
    return false;
  }

  getAlertsProirity = metadata => {
    if (!metadata) return 10;
    switch (metadata.stage) {
      case "playbook":
        return 0;
      case "modeling":
        if (metadata.type == "risk_analyzer_engine") return 1;
        else return 2;
      case "pulse":
        if (metadata.type == "sanction") return 4;
        else return 5;
      case "playbook":
        return 0;
    }
  };

  sortAlerts = alerts => {
    let getAlertsProirity = this.getAlertsProirity;
    alerts.sort((a, b) => {
      return getAlertsProirity(a.metadata) - getAlertsProirity(b.metadata);
    });
    return alerts;
  };

  onAnalyze = () => {
    this.setState({ analyzing: true });
    if (this.isContractEmpty())
      return ToastsStore.warning("Can't analyze empty contract!");
    if (this.state.contract_id && !this.state.is_editable) {
      Contract.simulate_contract(this.state.contract_id)
        .then(body => {
          this.setState(
            {
              slimulateAlerts: this.sortAlerts(body.alerts),
              analyzing: false,
              risk_score: body.risk_score
            },
            () => {
              this.showAnalyzeFab();
              this.renderHighlights(body.alerts);
              this.showAlertsNotification(body.alerts.slice(0, 4));
            }
          );
          ToastsStore.success("Contract analyzed successfully!");
        })
        .catch(e => {
          this.setState({ analyzing: false });
          ToastsStore.error("An error occurred while analyzing contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    } else if (this.state.contract_id) {
      Contract.update_contract(
        this.state.title,
        this.state.contract_id,
        this.clearHighlights(
          convertToRaw(this.state.editorState.getCurrentContent())
        )
      )
        .then(res => {
          this.setState({
            saving: false,
            is_saved: true,
            savedTitle: this.state.title
          });
          Contract.simulate_contract(this.state.contract_id)
            .then(body => {
              this.setState(
                {
                  slimulateAlerts: body.alerts,
                  analyzing: false,
                  risk_score: body.risk_score
                },
                () => {
                  this.showAnalyzeFab();
                  this.renderHighlights(body.alerts);
                  this.showAlertsNotification(body.alerts.slice(0, 4));
                }
              );
              ToastsStore.success("Contract analyzed successfully!");
            })
            .catch(e => {
              this.setState({ analyzing: false });
              ToastsStore.error("An error occurred while analyzing contract!");
              console.log(e);
              this.setState({ saving: false });
            });
        })
        .catch(e => {
          this.setState({ analyzing: false });
          ToastsStore.error("An error occurred while saving contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    } else {
      this.setState({ analyzing: false });
      ToastsStore.error("Cannot Analyze an unsaved contract!");
    }
  };

  onApprove = () => {
    this.setState({ showApproveModal: true });
  };

  renderHighlights = slimulateAlerts => {
    let a = slimulateAlerts.filter(alert => {
      if (
        alert.metadata.stage == "playbook" &&
        alert.metadata.type == "variable"
      )
        return alert.data.ruleBreak;
      return true;
    });

    this.setState({
      editorState: renderHighlightsInEditor(this.state.editorState, a)
    });
  };

  clearHighlights = contentState => {
    let cleanContentState = removeHighlights(contentState);
    let cleanEditorState = setDecorator(
      EditorState.createWithContent(convertFromRaw(contentState))
    );
    this.setState({
      editorState: cleanEditorState,
      savedState: cleanEditorState
    });
    return cleanContentState;
  };

  onSave = () => {
    if (this.isContractEmpty())
      return ToastsStore.warning("Can't save empty contract!");
    else if (this.state.title === "")
      return ToastsStore.warning("Title must not be empty!");
    this.setState({ saving: true });
    if (this.state.contract_id) {
      //if contract has been uploaded atleas once

      let cleanContentState = this.clearHighlights(
        convertToRaw(this.state.editorState.getCurrentContent())
      );

      Contract.update_contract(
        this.state.title,
        this.state.contract_id,
        this.isSaved() ? null : cleanContentState
      )
        .then(res => {
          this.setState(
            {
              savedTitle: this.state.title,
              savedComments: Object.assign({}, this.state.comments)
            },
            () => {
              this.setState({
                saving: false,
                is_saved: true,
                slimulateAlerts: []
              });
            }
          );
        })
        .catch(e => {
          ToastsStore.error("An error occurred while saving contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    } else {
      Contract.upload_contract(
        this.state.title,
        convertToRaw(this.state.editorState.getCurrentContent())
      )
        .then(res => {
          this.isSaved = () => true;
          this.isTitleSaved = () => true;
          this.setState(
            {
              savedTitle: this.state.title,
              savedComments: Object.assign({}, this.state.comments),
              saving: false,
              contract_id: res.contract_id
            },
            () => {
              this.props.history.push(
                `/insights/contract/${this.state.contract_id}`
              );
            }
          );
        })
        .catch(e => {
          ToastsStore.error("An error occurred while saving contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    }
  };

  onForward = () => {
    if (!this.isSaved() || this.state.forwarding)
      return ToastsStore.warning("Please save the contract first!");
    else if (!this.isTitleSaved())
      return ToastsStore.warning("Please save the title first!");

    if (this.state.action == "modelling") {
      if (!this.isApproved())
        return ToastsStore.warning("Please approve the contract first!");

      this.setState({ forwarding: true });
      Contract.fwd_to_next_module(this.state.contract_id, "PUL")
        .then(res => {
          setTimeout(() => {
            ToastsStore.success(res.message);
            this.props.history.push("/pulse/contracts");
          }, 200);
        })
        .catch(e => {
          this.setState({ forwarding: false });
          ToastsStore.error(
            "An Error occurred:" + e.error ? e.error.message : " Unknown"
          );
          console.log(e);
        });
    } else {
      this.setState({ forwarding: true });
      Contract.fwd_to_next_module(this.state.contract_id, "MOD")
        .then(res => {
          setTimeout(() => {
            ToastsStore.success(res.message);
            this.props.history.push("/modelling/contracts");
          }, 200);
        })
        .catch(e => {
          this.setState({ forwarding: false });
          ToastsStore.error(
            "An Error occurred:" + e.error ? e.error.message : " Unknown"
          );
          console.log(e);
        });
    }
  };

  onTest = () => {
    this.setState({
      editorState: addCommentIntelligence(
        this.state.editorState,
        this.state.editorState.getSelection(),
        "data"
      )
    });
    console.log(
      JSON.stringify(this.state.editorState.getCurrentContent(), null, 4)
    );
  };

  onTest2 = () => {
    this.showNotification();
    // this.setState({ editorState: removeComment(this.state.editorState) });
    // console.log(JSON.stringify(this.state.contentState, null, 4));
  };

  getOffset = el => {
    const elemRect = el.getBoundingClientRect();
    const bodyRect = this.editor_wrapper.getBoundingClientRect();
    return elemRect.top - bodyRect.top;
  };

  getOffsetSpan = el => {
    return this.getOffset(el) + 70;
  };

  moveToIndex = key => {
    let i = 0;
    let queryKey = "";
    while ("1234567890".includes(key[i])) {
      queryKey = queryKey + "\\3" + key[i] + " ";
      ++i;
    }
    queryKey += key.slice(i);
    const elem = this.editor_wrapper.querySelectorAll(
      `[data-offset-key=${queryKey}-0-0]`
    )[0];
    if (elem)
      this.scroll_component.scrollTo({
        top: this.getOffset(elem) - 110,
        left: 0,
        behavior: "smooth"
      });
  };

  moveToSpanId(spanId) {
    if (spanId == "") return;
    const elem = this.editor_wrapper.querySelectorAll(
      `.inline-comment-span[data-span-id="${spanId}"]`
    )[0];
    if (elem)
      this.scroll_component.scrollTo({
        top: this.getOffset(elem) - 110,
        left: 0,
        behavior: "smooth"
      });
  }

  serializeLevels(n, levelArr) {
    if (!levelArr[n]) {
      for (let i = n + 1; i < levelArr.length; ++i) {
        this.index_paddings[i] += 12;
      }
      levelArr[n] = true;
    }
    return n;
  }

  getPaddingAndType(type, levelArr, _currLevel) {
    let currLevel;
    switch (type) {
      case "header-one":
        currLevel = 0;
        break;
      case "header-two":
        currLevel = 1;
        break;
      case "header-three":
        currLevel = 2;
        break;
      case "header-four":
        currLevel = 3;
        break;
      case "header-five":
        currLevel = 4;
        break;
      case "header-six":
        currLevel = 5;
        break;
      default:
        return {
          type: "normal",
          levelIndex: _currLevel + 1,
          currLevel: _currLevel
        };
    }
    return {
      type: "bold",
      levelIndex: this.serializeLevels(currLevel, levelArr),
      currLevel
    };
  }

  generateIndex() {
    this.index_paddings = Array(7).fill(12);
    this.index_numberings = Array(7).fill(0);

    let indexJson = [];
    let levelArr = Array(7).fill(false);
    let currLevel = -1;
    let blockMap = this.state.editorState
      .getCurrentContent()
      .get("blockMap")
      .toJSON();

    let blocks = Object.keys(blockMap).map(function(key) {
      return blockMap[key];
    });

    for (let block of blocks) {
      if (/\S/.test(block.text)) {
        //if text contains atleast one non-whitespace char
        const info = this.getPaddingAndType(block.type, levelArr, currLevel);
        currLevel = info.currLevel;
        indexJson.push({
          depth: block.depth,
          text: block.text.substr(0, 40),
          key: block.key,
          weight: info.type,
          levelIndex: info.levelIndex,
          number: this.getIndexNumber(
            this.index_paddings[info.levelIndex] / 12 - 1,
            this.index_numberings
          )
        });
      }
    }
    return indexJson;
  }

  getIndexNumber(depthLevel, index_numberings) {
    let index = "";
    index_numberings[depthLevel]++;
    index_numberings[depthLevel + 1] = 0;
    for (let i = 0; i <= depthLevel; ++i) {
      if (index_numberings[i] === 0) index_numberings[i] = 1;
      index = index + index_numberings[i] + ".";
    }
    return index;
  }

  toolbarOptions = {
    // options: [
    //   "blockType",
    //   "fontFamily",
    //   "fontSize",
    //   "inline",
    //   "textAlign",
    //   "list",
    //   "colorPicker",
    //   "link",
    //   "image",
    //   "history"
    // ],
    // inline: {
    //   options: ["bold", "italic", "underline"],
    //   bold: {
    //     className: "t-inline-icon t-common"
    //   },
    //   italic: { className: "t-inline-icon t-common" },
    //   underline: { className: "t-inline-icon t-common" },
    //   className: "t-inline"
    // },
    // blockType: {
    //   inDropdown: true,
    //   options: [
    //     "Normal",
    //     "H1",
    //     "H2",
    //     "H3",
    //     "H4",
    //     "H5",
    //     "H6",
    //     "Blockquote",
    //     "Code"
    //   ],
    //   className: "t-blockType t-common"
    // },
    // fontSize: {
    //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    //   className: "t-fontSize t-common"
    // },
    // fontFamily: {
    //   options: [
    //     "Arial",
    //     "Georgia",
    //     "Impact",
    //     "Tahoma",
    //     "Times New Roman",
    //     "Verdana"
    //   ],
    //   className: "t-fontFamily t-common"
    // },
    // list: {
    //   inDropdown: true,
    //   options: ["unordered", "ordered", "indent", "outdent"],
    //   className: "t-list t-common"
    // },
    // textAlign: {
    //   inDropdown: true,
    //   options: ["left", "center", "right", "justify"],
    //   className: "t-textAlign t-common"
    // },
    // colorPicker: {
    //   colors: [
    //     "rgb(97,189,109)",
    //     "rgb(26,188,156)",
    //     "rgb(84,172,210)",
    //     "rgb(44,130,201)",
    //     "rgb(147,101,184)",
    //     "rgb(71,85,119)",
    //     "rgb(204,204,204)",
    //     "rgb(65,168,95)",
    //     "rgb(0,168,133)",
    //     "rgb(61,142,185)",
    //     "rgb(41,105,176)",
    //     "rgb(85,57,130)",
    //     "rgb(40,50,78)",
    //     "rgb(0,0,0)",
    //     "rgb(247,218,100)",
    //     "rgb(251,160,38)",
    //     "rgb(235,107,86)",
    //     "rgb(226,80,65)",
    //     "rgb(163,143,132)",
    //     "rgb(239,239,239)",
    //     "rgb(255,255,255)",
    //     "rgb(250,197,28)",
    //     "rgb(243,121,52)",
    //     "rgb(209,72,65)",
    //     "rgb(184,49,47)",
    //     "rgb(124,112,107)",
    //     "rgb(209,213,216)"
    //   ],
    //   className: "t-colorPicker t-common"
    // },
    link: {
      inDropdown: true,
      options: ["link", "unlink"],
      showOpenOptionOnHover: true,
      defaultTargetOption: "_self",
      className: "t-link t-common"
    },
    image: {
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      previewImage: false,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "auto"
      },
      className: "t-image t-common"
    },
    history: {
      options: []
    }
  };

  focusComment = (spanId, oldfocusSpanId = "") => {
    $(".comments-wrapper div").removeClass("focused");
    console.log("focusing comment", spanId);
    let editorBlock = $(`.inline-comment-span[data-span-id="${spanId}"]`);
    console.log("meez check ed null", editorBlock);
    if (!editorBlock.offset()) return;

    console.log("meez check ed ok top", editorBlock.offset().top);
    console.log("focusing comment 2", spanId);
    let editorBlockTop = this.getOffsetSpan(editorBlock[0]);

    spanId = spanId ? spanId : "a";
    let commentblock = $(".comment-box." + spanId);
    if (!commentblock.offset()) return;

    let diff = editorBlockTop - parseInt(commentblock.css("top"));
    console.log("focus diff", diff);

    $(".comments-wrapper").css("top", diff);
    commentblock.addClass("focused");
    commentblock.css("top", "50");
  };

  renderAddCommentBubble() {
    console.log(this.state.hideBubble);

    return (
      <div
        style={{ top: 0, opacity: this.state.hideBubble ? 0 : 1 }}
        onClick={this.addNewComment}
        className="add-comment-bubble"
      >
        <CommentPlusIcon />
      </div>
    );
  }

  renderRedirect() {
    if (this.state.redirect === "bad-url")
      return <Redirect to={{ pathname: "/bad-url" }} />;
    else if (this.state.redirect === "new-url")
      return (
        <Redirect
          to={{
            pathname: `/insights/contract/${this.state.contract_id}`,
            state: {
              rawContent: this.state.contentState,
              title: this.state.title,
              contract_id: this.state.contract_id,
              is_owner: this.state.is_owner,
              is_editable: this.state.is_editable
            }
          }}
        />
      );
    else return null;
  }

  renderAnalyzingLoader() {
    if (this.state.analyzing)
      return (
        <div className="analyzing-loader-wrapper">
          <div className="analyzing-loader">
            <div>
              <img
                className="1"
                src={require("../../assets/editor-images/1.png")}
              />
              <img
                className="second"
                src={require("../../assets/editor-images/2.png")}
              />
            </div>
            <span>Analyzing...</span>
          </div>
        </div>
      );
    else return null;
  }

  renderFab() {
    if (this.state.showAnalyzeFab)
      return (
        <div
          className={`analyzing-fab-wrapper ${
            this.state.showIndex
              ? "analyzing-fab-wrapper-index"
              : this.state.showComments
              ? "analyzing-fab-wrapper-comment"
              : this.state.showRightBar
              ? "analyzing-fab-wrapper-erb"
              : ""
          }`}
        >
          <Badge
            badgeContent={
              this.state.slimulateAlerts
                ? this.state.slimulateAlerts.filter(
                    i => i.metadata.type != "risk_analyzer_engine"
                  ).length
                : 0
            }
            overlap="circle"
            color="secondary"
            onClick={e => this.showAnalyzeBar()}
          >
            <Fab color="#473080" aria-label="edit">
              <img src={require("../../assets/icons/analyze_icon.svg")} />
            </Fab>
          </Badge>
        </div>
      );
    else return null;
  }

  toggle = () => {
    this.setState({ showAssignModal: false });
  };

  toggleApproveModal = () => {
    this.setState({ showApproveModal: false });
  };

  toggleRulingsModal = () => {
    this.setState({ showRulingsModal: false });
  };

  handleAssignUser = id => {
    this.setState({ loadingAssignModal: true });
    Contract.assign_users(this.state.contract_id, { user_ids: [id] })
      .then(res => {
        this.fetchAssignedUsers();
        this.setState({ loadingAssignModal: false });
      })
      .catch(e => {
        this.setState({ loadingAssignModal: false });
        console.error(e);
      });
  };
  handleAddSecondParty = email => {
    this.setState({ loadingAssignModal: true });

    Contract.forward_to_counter_party(this.state.contract_id, { email: email })
      .then(res => {
        console.log(res);
        this.setState({ loadingAssignModal: false, showAssignModal: false });
        ToastsStore.success("Forwarded to Counter Party");
        window.location.reload();
      })
      .catch(e => {
        this.setState({ loadingAssignModal: false });
        console.error(e);
        ToastsStore.success(
          e.error ? e.error.message : "Error occured while forwarding.."
        );
      });
  };

  handleApproveContract = id => {
    this.setState({ loadingAssignModal: true });

    Contract.approve_contract(this.state.contract_id, { signature_id: id })
      .then(res => {
        console.log(res);
        ToastsStore.success("Approved.");
        this.toggleApproveModal();
        this.setState({ is_approved: 1 });
      })
      .catch(e => {
        console.error(e);
        ToastsStore.success(
          e.error ? e.error.message : "Error occured while approving.."
        );
      });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleAnalyzeCardAction = (action, data) => {
    switch (action) {
      case "view-rulings": {
        this.setState({
          rulings: data,
          showRulingsModal: true
        });
      }
    }
  };

  handleReturnToOwner = () => {
    if (this.isSaved() && this.isTitleSaved()) {
      this.setState({ loadingAssignModal: true });

      Contract.return_to_owner(this.state.contract_id)
        .then(res => {
          ToastsStore.success("Returned to Owner");
          this.goBack();
        })
        .catch(e => {
          this.setState({ loadingAssignModal: false });
          console.error(e);
        });
    } else {
      ToastsStore.warning("Please save contract before returning.");
    }
  };

  handleForwardUser = id => {
    this.setState({ loadingAssignModal: true });
    Contract.forward_to_user(this.state.contract_id, { shared_to: id })
      .then(res => {
        this.setState({
          loadingAssignModal: false,
          is_editable: 0,
          showAssignModal: false
        });
        ToastsStore.success("Contract Shared. You cannot edit it now.");
        //this.goBack();
      })
      .catch(e => {
        this.setState({ loadingAssignModal: false });
        console.error(e);
      });
  };

  renderAssignModal() {
    return (
      <AssignModal
        onAddSecondParty={this.handleAddSecondParty}
        onAssignUser={this.handleAssignUser}
        onForwardUser={this.handleForwardUser}
        assignedUsers={this.state.assigned_users}
        unassignedUsers={this.state.unassigned_users}
        loading={this.state.loadingAssignModal}
        showAssignModal={this.state.showAssignModal}
        toggle={() => this.toggle()}
      />
    );
  }

  renderApproveModal() {
    return (
      <ApproveModal
        onApproveContract={this.handleApproveContract}
        show={this.state.showApproveModal}
        toggle={() => this.toggleApproveModal()}
      />
    );
  }

  renderRulingsModal() {
    return (
      <Modal
        isOpen={this.state.showRulingsModal}
        toggle={() => this.toggleRulingsModal()}
        centered
        className={"rulings-modal"}
      >
        <ModalHeader toggle={() => this.toggleRulingsModal()}>
          Rulings
        </ModalHeader>
        <ModalBody>
          {this.state.rulings.map(ruling => {
            return <p>{ruling}</p>;
          })}
        </ModalBody>
      </Modal>
    );
  }
  renderAnalyzeBar = () => {
    if (this.state.showAnalyzeBar)
      return (
        <div
          className="editor-analyze-right-bar"
          /* onScroll={this.attachERBScrollListener} */
        >
          <div className={"details-section"}>
            <div
              className="analyze-section-title"
              /* onScroll={this.attachERBScrollListener} */
            >
              <img src={require("../../assets/icons/analyze_icon_light.svg")} />
              <h2>Intelligence Assistance</h2>

              <span className="analyze-section-close-container"><CloseIcon onClick={() => this.hideAnalyzeBar()} /></span>
            </div>
            <div className="analyze-section-list">
              {this.state.slimulateAlerts
                .filter(i => i.metadata.type != "risk_analyzer_engine")
                .map(alert => {
                  return (
                    <AnalyzeCard
                      onClick={e => {
                        if (alert.blockId != null)
                          this.moveToIndex(alert.blockId);
                      }}
                      handleAction={(action, data) =>
                        this.handleAnalyzeCardAction(action, data)
                      }
                      alert={alert}
                      type={"risk"}
                      title={"Pulse Risk"}
                      secTitle={alert.alert_message}
                      message={
                        alert.data
                          ? alert.data.title + ": " + alert.data.description
                          : ""
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      );
    else return null;
  };

  renderSave() {
    if (this.state.saving)
      return (
        <div className="custom-icon-CTA save-cta-container">
          <ContentSaveOutline size="1.1vw" color="#666" />
          <p>Saving...</p>
        </div>
      );
    if (this.isCommentsSaved() && this.isSaved() && this.isTitleSaved())
      return (
        <div className="custom-icon-CTA save-cta-container">
          <p>Already Saved</p>
        </div>
      );
    else
      return (
        <div
          className="custom-icon-CTA save-cta-container"
          onClick={this.onSave}
        >
          <ContentSaveOutline size="1.1vw" color="#666" />
          <p>Save Document</p>
        </div>
      );
  }

  toogleComments = () => {
    this.setState({ showComments: !this.state.showComments });
  };

  renderComments() {
    return (
      <div
        className="custom-icon-CTA comments-cta-container"
        onClick={() => {
          this.hideERB();
          this.hideIndex();
          this.toogleComments();
        }}
      >
        <CommentIcon size="1.1vw" color="#666" />
        <p>{!this.state.showComments ? "Show Comments" : "Hide Comments"}</p>
      </div>
    );
  }

  renderFwd2Modelling() {
    return (
      <div
        style={{ color: "#473080", fontWeight: "bolder" }}
        className="custom-icon-CTA fwd-cta-container"
        onClick={this.onForward}
      >
        <SendIcon size="1.1vw" color="#473080" />
        <p>
          {this.state.forwarding
            ? "Forwarding..."
            : this.state.action == "modelling"
            ? "Forward to Pulse"
            : "Forward to Modelling"}
        </p>
      </div>
    );
  }

  renderLoader() {
    return (
      <div className="editor_loader_wrapper">
        <Spinner />
      </div>
    );
  }
  renderTitle() {
    return (
      <div className="_my-editor-title">
        {this.state.titleEditingMode ? (
          <input
            ref={c => (this.title_input = c)}
            onBlur={e => this.setState({ titleEditingMode: false })}
            type="text"
            value={this.state.title}
            onChange={e => this.setState({ title: e.target.value })}
            onKeyPress={e => {
              if (e.key === "Enter") this.title_input.blur();
            }}
          />
        ) : (
          <div className="flex-parent">
            <h2
              onClick={e => {
                this.state.is_editable
                  ? this.setState({ titleEditingMode: true }, () =>
                      this.title_input.focus()
                    )
                  : this.setState({});
              }}
            >
              {this.state.title}
            </h2>
          </div>
        )}

        {this.state.titleEditingMode ? null : (
          <FileDocumentOutline color="#4d4d4d" size="1.5vw" />
        )}

        {this.state.is_approved ? (
          <div className="doc-tag">{"Approved"}</div>
        ) : null}
        {!this.state.is_editable ? (
          <span class="doc-tag">Read Only</span>
        ) : null}
        {this.state.is_owner ? null : this.state.is_editable ? (
          <span class="doc-tag">Forwarded</span>
        ) : (
          <span class="doc-tag">Assigned</span>
        )}

        {this.renderCTAs()}
      </div>
    );
  }

  // activateStyleButton = (currentBlockType, currentInlineStyleType) => {
  //   console.log("CONTENT BLOCK SF", currentBlockType);
  //   console.log("CONTENT TYPE SF", currentInlineStyleType.has("BOLD"));
  //   if (currentInlineStyleType.has("BOLD")) {
  //     console.log("BOLD");
  //     this.setState({
  //       selectInlinebtn: "BOLD"
  //     });
  //   }
  //   if (currentInlineStyleType.has("ITALIC")) {
  //     console.log("ITALIC");
  //     this.setState({
  //       selectInlinebtn: "ITALIC"
  //     });
  //   }
  //   if (currentInlineStyleType.has("UNDERLINE")) {
  //     console.log("UNDERLINE");
  //     this.setState({
  //       selectInlinebtn: "UNDERLINE"
  //     });
  //   } else if (currentInlineStyleType.has("CODE")) {
  //     console.log("CODE");
  //     this.setState({
  //       selectInlinebtn: "CODE"
  //     });
  //   } else {
  //     console.log("NULL");
  //     this.setState({
  //       selectInlinebtn: null
  //     });
  //   }
  //   // switch (currentInlineStyleType) {
  //   //   case currentInlineStyleType.has("BOLD"):
  //   //     console.log("BOLD");
  //   //     this.setState({ selectedbtn: "BOLD" });
  //   //     break;
  //   //   default:
  //   //     this.setState({ selectedbtn: null });
  //   // }
  //   switch (currentBlockType) {
  //     case "unordered-list-item":
  //       console.log("HELLO");
  //       this.setState({ selectedbtn: currentBlockType });
  //       break;
  //     case "ordered-list-item":
  //       console.log("HELLO");
  //       this.setState({ selectedbtn: currentBlockType });
  //       break;
  //     case "align-right":
  //       this.setState({ selectedbtn: currentBlockType });
  //       break;
  //     case "align-left":
  //       this.setState({ selectedbtn: currentBlockType });
  //       break;
  //     case "align-justify":
  //       this.setState({ selectedbtn: currentBlockType });
  //       break;
  //     case "header-one":
  //       this.setState({ defaultBlockType: "H1" });
  //       break;
  //     case "header-two":
  //       console.log("HEADER T2");
  //       this.setState({ defaultBlockType: "H2" });
  //       break;
  //     case "header-three":
  //       this.setState({ defaultBlockType: "H3" });
  //       break;
  //     case "header-four":
  //       this.setState({ defaultBlockType: "H4" });
  //       break;
  //     case "header-five":
  //       this.setState({ defaultBlockType: "H5" });
  //       break;
  //     case "header-six":
  //       this.setState({ defaultBlockType: "H6" });
  //       break;
  //     case "blockquote":
  //       this.setState({ defaultBlockType: "Blockquote" });
  //       break;
  //     case "code-block":
  //       this.setState({ defaultBlockType: "Code" });
  //       break;
  //     default:
  //       this.setState({ selectedbtn: null, defaultBlockType: "Normal" });
  //   }
  // };

  checkandUnSaveContract = editorState => {
    if (this.isSaved()) {
      if (
        !this.state.savedState
          .getCurrentContent()
          .equals(editorState.getCurrentContent())
      ) {
        this.setState({ is_saved: false });
      }
    }
  };

  onEditorStateChange = editorState => {
    this.checkandUnSaveContract(editorState);

    let selectedSpanId = getSelectionEntitySpanId(editorState);
    // const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    // const currentInlineStyleType = editorState.getCurrentInlineStyle();
    // this.activateStyleButton(currentBlockType, currentInlineStyleType);
    // console.log("CONTENT SF", currentBlockType);
    let noFocus = (this.state.focusSpanId2 = selectedSpanId);
    let block = $(
      `[data-offset-key=${editorState.getSelection().focusKey}-0-0]`
    );
    if (block.offset())
      $(`.add-comment-bubble`).css("top", this.getOffsetSpan(block[0]));
    console.log("meez check nofocus");
    this.setState(
      {
        editorState: editorState,
        focusSpanId2: selectedSpanId,
        hideBubble:
          editorState.getSelection().isCollapsed() ||
          selectionHasEntity(editorState, "COMMENT")
        //||
        // this.state.comments.hasOwnProperty(
        //   editorState.getSelection().focusSpanId
        // )
      },
      () => {
        if (!noFocus) {
          $(".comments-wrapper div")
            .not("." + selectedSpanId ? selectedSpanId : "a")
            .removeClass("focused");

          setTimeout(() => {
            this.setState({ focusSpanId: selectedSpanId });
            this.focusComment(selectedSpanId);
          }, 200);
        } else {
          setTimeout(() => {
            this.setState({ focusSpanId: selectedSpanId });
            this.focusComment(selectedSpanId);
          }, 200);
        }
      }
    );

    console.log("focus key", editorState.getSelection().focusSpanId);
    let startKey = editorState.getSelection().getStartKey();
    console.log("key", startKey);

    // console.log(
    //   "text",
    //   editorState.getBlockForKey(startKey) &&
    //     editorState.getBlockForKey(startKey).getText()
    // );

    console.log("ES Changed");
    console.log("TIME2", Date.now() - this.timeDiff);
  };
  onDrop = e => {
    console.log("dropped");
    //this.setState({ editorState: this.addEmptyBlock(this.state.editorState) });
    // e.preventDefault();
    // console.log("");
    // console.log(
    //   "key",
    //   this.state.editorState.getCurrentContent().getSelectionAfter()
    // );
    // console.log("Droped", e);
  };

  sortCommentIndexes = comments => {
    Object.keys(comments).map((spanId, index) => {
      console.log("sort  index", spanId);
      if (
        $(`.inline-comment-span[data-span-id="${spanId}"]`).index(
          ".inline-comment-span"
        ) == undefined
      )
        comments[spanId].index = -1;
      else
        comments[spanId].index = $(
          `.inline-comment-span[data-span-id="${spanId}"]`
        ).index(".inline-comment-span");

      console.log("sort  index 2", spanId);
    });
    return comments;
  };

  addNewComment = e => {
    let comments = this.state.comments;
    let key = this.state.focusSpanId;
    let spanId = comments.hasOwnProperty(key) ? key : generateSpanId();
    let results = addCommentNew(this.state.editorState, spanId);
    this.setState(
      {
        editorState: results
      },
      () => {
        console.log();
        comments[spanId]
          ? comments[spanId].data.push()
          : (comments[spanId] = {
              index: 0,
              data: []
            });

        this.setState(
          {
            comments: this.sortCommentIndexes(comments),
            focusSpanId2: spanId,
            focusSpanId: spanId
          },
          () => {
            setTimeout(() => {
              this.focusComment(spanId);
            }, 500);
          }
        );
        //this.editor.focus();
      }
    );
  };

  addBlock = (direction, editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selection.getEndKey());

    const blockMap = contentState.getBlockMap();
    // Split the blocks
    const blocksBefore = blockMap.toSeq().takeUntil(function(v) {
      return v === currentBlock;
    });
    const blocksAfter = blockMap
      .toSeq()
      .skipUntil(function(v) {
        return v === currentBlock;
      })
      .rest();
    const newBlockKey = genKey();
    let newBlocks =
      direction === "before"
        ? [
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: "unstyled",
                text: ""
              })
            ],
            [currentBlock.getKey(), currentBlock]
          ]
        : [
            [currentBlock.getKey(), currentBlock],
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: "unstyled",
                text: ""
              })
            ]
          ];
    const newBlockMap = blocksBefore
      .concat(newBlocks, blocksAfter)
      .toOrderedMap();
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection
    });
    return EditorState.push(editorState, newContentState, "insert-fragment");
  };

  handleReturn = (event, editorState) => {
    if (isSelectionACommentEntity(editorState)) {
      this.setState({
        editorState: addLineBreakBetweenComments(editorState, event)
      });
      return "handled";
    }

    let selectionState = editorState.getSelection();
    let isCollapsed = selectionState.isCollapsed();
    if (event.shiftKey && isCollapsed) {
      this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
      return "handled";
    }

    let contentState = editorState.getCurrentContent();
    if (!(isCollapsed && selectionState.focusOffset == 0)) return "not-handled";
    let currentBlock = contentState.getBlockForKey(
      selectionState.getAnchorKey()
    );
    if (currentBlock.getLength() == 0) return "not-handled";

    // submit message
    console.log(this.state.editorState.getCurrentContent().getPlainText());

    this.setState({ editorState: this.addBlock("before", editorState) });

    console.log(contentState.getBlockForKey(selectionState.getAnchorKey()));
    console.log(selectionState.getAnchorKey());
    console.log(selectionState.focusOffset);

    return "handled";
  };

  onStateChangeByToolbar = editorState => {
    this.setState(
      {
        editorState
      },
      () => {
        this.checkandUnSaveContract(editorState);
      }
    );
  };

  renderEditor() {
    return (
      <div ref={c => (this.editor_wrapper = c)} onDrop={this.onDrop}>
        {/* <div className={`_my-editor-wrapper`}>
          <div className={" _my-editor-toolbar"}>
            <EditorToolbar
              editorState={this.state.editorState}
              onChangeEditor={this.onEditorChange}
         
            />
           
            {this.renderCutomToolBar()}
          </div>
          <div
            className={`_my-editor-editor${
              !this.state.showIndex ? "" : " _my-editor-shrinked"
            }${!this.state.showComments ? "" : " my-editor-editor-comments"}`}
          > */}
        {/* <div className="toolbar">{buttons}</div> */}
        <MyEditor
          handleBeforeInput={(text, editorState, timeStamp) => {
            this.timeDiff = Date.now();
            // console.log("TIME",Date.now())
            return "not-handled";
          }}
          readOnly={!(this.state.is_editable && this.state.is_editable == 1)}
          customDecorators={[getCommentDecorator()]}
          editorRef={element => {
            this.editor = element;
          }}
          /* plugins={[toolbarPlugin.staticToolbarPlugin]} */
          editorState={this.state.editorState}
          handleReturn={this.handleReturn}
          wrapperClassName="_my-editor-wrapper"
          editorClassName={`_my-editor-editor${
            !this.state.showIndex ? "" : " _my-editor-shrinked"
          }${this.state.showComments||this.state.showAnalyzeBar ? " my-editor-editor-comments" : ""}`}
          onEditorStateChange={this.onEditorStateChange}
          onChange={this.onEditorStateChange}
          onChangeByToolbar={this.onStateChangeByToolbar}
          toolbarClassName="_my-editor-toolbar"
          onContentStateChange={this.onContentStateChange}
          toolbar={this.toolbarOptions}
          toolbarCustomComponent={this.renderCutomToolBar()}
        />
        {/* </div> */}
        {/* </div> */}
        {this.state.showComments && this.renderAllComments()}
        {this.state.showComments && this.renderAddCommentBubble()}
      </div>
    );
  }

  renderRightBar() {
    return (
      <div className="editor-right-bar">
        {this.state.is_editable ? (
          <div
            className={`editor-right-bar-item ${
              this.state.erbSelectedItem == this.ERB_Clause ? "active" : ""
            }`}
          >
            <div onClick={() => this.handleERBClick(this.ERB_Clause)}>
              <SVG src={require("../../assets/icons/clause-library.svg")} />
              <p>Clause Library</p>
            </div>
          </div>
        ) : null}
        <div
          className={`editor-right-bar-item ${
            this.state.erbSelectedItem == this.ERB_Summary ? "active" : ""
          }`}
        >
          <div onClick={() => this.handleERBClick(this.ERB_Summary)}>
            <SVG src={require("../../assets/icons/snapshot-summary.svg")} />
            <p>Snapshot Summary</p>
          </div>
        </div>
        <div
          className={`editor-right-bar-item ${
            this.state.erbSelectedItem == this.ERB_Details ? "active" : ""
          }`}
        >
          <div onClick={() => this.handleERBClick(this.ERB_Details)}>
            <SVG src={require("../../assets/icons/details.svg")} />
            <p>Details</p>
          </div>
        </div>
        <div
          className={`editor-right-bar-item ${
            this.state.erbSelectedItem == this.ERB_Playbook ? "active" : ""
          }`}
        >
          <div onClick={() => this.handleERBClick(this.ERB_Playbook)}>
            <SVG src={require("../../assets/icons/playbook.svg")} />
            <p>Playbook</p>
          </div>
        </div>
        <div
          className={`editor-right-bar-item ${
            this.state.erbSelectedItem == this.ERB_SEC ? "active" : ""
          }`}
        >
          <div
            className={this.state.is_cik_available ? "" : "disabled"}
            onClick={() => {
              this.state.is_cik_available
                ? this.handleERBClick(this.ERB_SEC)
                : ToastsStore.warning("Cik is unavailable.");
            }}
          >
            {this.state.is_cik_available ? (
              <span>
                <SVG src={require("../../assets/icons/sec.svg")} />
                <p>SEC Filing</p>
              </span>
            ) : (
              <Tooltipx title="Cik Unavailable">
                <span>
                  <SVG src={require("../../assets/icons/sec.svg")} />
                  <p>SEC Filing</p>
                </span>
              </Tooltipx>
            )}
          </div>
        </div>
      </div>
    );
  }

  handleERBClick = selectedItem => {
    this.hideComments();
    this.hideAnalyzeFab();
    if (selectedItem == this.state.erbSelectedItem) {
      this.hideERB();
    } else {
      if (this.state.showIndex) this.hideIndex();

      if (selectedItem == this.ERB_Summary) {
        this.onGetSummary();
      }
      if (selectedItem == this.ERB_Clause) {
        this.setState(
          { erbLoading: true, clauseCurrentPage: 1, clauses: [] },
          this.onGetClauseList
        );
      }
      if (selectedItem == this.ERB_Details) {
        this.setState({ erbLoading: false });
      } else if (selectedItem == this.ERB_Playbook) {
        this.setState({ erbLoading: false });
      } else if (selectedItem == this.ERB_SEC) {
        this.onGetSecFiling();
      }

      this.setState({
        showRightBar: true,
        erbSelectedItem: selectedItem,
        hideRightBar: false
      });
    }
  };

  hideIndex = () => {
    this.setState({ showIndex: false }, () =>
      setTimeout(() => this.setState({ hideIndex: true, index: [] }), 50)
    );
  };

  hideERB = () => {
    this.setState(
      {
        erbSelectedItem: null,
        showRightBar: false,
        hideRightBar: true
      },
      () => {
        if (this.state.slimulateAlerts && this.state.slimulateAlerts.length > 0)
          this.showAnalyzeFab();
      }
    );
  };

  hideAnalyzeBar = () => {
    this.setState({
      showAnalyzeBar: false
    });
  };

  hideAnalyzeFab = () => {
    this.setState({
      showAnalyzeFab: false
    });
  };

  showAnalyzeFab = () => {
    this.setState({
      showAnalyzeFab: true
    });
  };

  showAnalyzeBar = () => {
    this.hideERB();
    this.hideIndex();
    this.setState({
      showAnalyzeBar: true
    });
  };

  hideComments = () => {
    this.setState({
      showComments: false
    });
  };

  showPlaybook = () => {
    this.hideComments();
    this.hideIndex();
    this.setState({
      erbLoading: false,
      showRightBar: true,
      erbSelectedItem: this.ERB_Playbook,
      hideRightBar: false
    });
  };
  showComments = () => {
    this.hideERB();
    this.hideIndex();
    this.setState({
      showComments: true
    });
  };

  // addEmptyBlock = editorState => {
  //   const newBlock = new ContentBlock({
  //     key: genKey(),
  //     type: "unstyled",
  //     text: "NEW"
  //   });

  //   const contentState = editorState.getCurrentContent();
  //   const newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock);

  //   return EditorState.push(
  //     editorState,
  //     ContentState.createFromBlockArray(newBlockMap.toArray())
  //       .set("selectionBefore", contentState.getSelectionBefore())
  //       .set("selectionAfter", contentState.getSelectionAfter())
  //   );
  // };

  onUpdateSummary = () => {
    let summary = Object.assign({}, this.state.summary);
    if (summary.hasOwnProperty("alerts")) delete summary["alerts"];
    delete summary["id"];
    summary.contract_title = summary.title;
    summary.contract_value = summary.value;
    summary.contract_sector = summary.sector;
    summary.contract_entity = summary.entity;
    summary.contract_type = summary.type;

    Contract.update_summary(this.state.contract_id, summary)
      .then(res => {
        let summary = res.content;

        this.setState({
          summary: summary,
          erbLoading: false,
          is_cik_available: summary.is_cik_available
        });
        // this.onGetSummary(false);
        //this.onOpenContract(false);
        //ToastsStore.success(res.message);
      })
      .catch(e => {
        ToastsStore.error(
          "An error occurred while saving summary! " + e.error.message
        );
        console.log(e);
        this.setState({ saving: false });
      });
  };

  handleEditSummaryButton = () => {
    if (this.state.editSummaryMode) this.onUpdateSummary();
    this.setState({ editSummaryMode: !this.state.editSummaryMode });
  };

  onGetMoreClauseList = throttle(
    () => {
      this.onGetClauseList();
    },
    800,
    {
      trailing: false
    }
  );

  onGetClauseList = () => {
    if (
      this.state.erbClauseLoading ||
      this.state.clauseCurrentPage > this.state.clauseLastPage
    )
      return;
    let page = this.state.clauseCurrentPage;
    let keyword = this.state.erbClauseSearchKeyword;
    console.log(page, keyword);
    this.setState({ erbClauseLoading: true, erbClauseSearchNoResults: false });

    keyword && keyword.length > 0
      ? Clause.get_clause_list_by_keyword(keyword, page)
          .then(res => {
            this.setState({
              clauses: this.state.clauses.concat(res.data),
              erbLoading: false,
              erbClauseLoading: false,
              clauseCurrentPage: page + 1,
              clauseLastPage: res.last_page
            });
            console.log(res);
          })
          .catch(e => {
            this.setState({ erbLoading: false, erbClauseLoading: false });
            console.log(e);
            e.error.message == "No Clause Record Found"
              ? this.setState({ erbClauseSearchNoResults: true })
              : ToastsStore.error(e.error.message);
          })
      : Clause.get_clause_list(page)
          .then(res => {
            this.setState({
              clauses: this.state.clauses.concat(res.data),
              erbLoading: false,
              erbClauseLoading: false,
              clauseCurrentPage: page + 1,
              clauseLastPage: res.last_page
            });
            console.log(res);
          })
          .catch(e => {
            this.setState({ erbLoading: false, erbClauseLoading: false });
            console.log(e);
            e.error.message == "No Clause Record Found"
              ? this.setState({ erbClauseSearchNoResults: true })
              : ToastsStore.error(e.error.message);
          });
  };

  onGetSummary = (showLoading = true) => {
    if (showLoading) this.setState({ erbLoading: true });

    Contract.get_summary(this.state.contract_id)
      .then(res => {
        let summary = res.content;

        this.setState({ summary: summary, erbLoading: false });
        console.log(res);
      })
      .catch(e => {
        this.setState({ erbLoading: false });
        console.log(e);
        ToastsStore.error(e.error.message);
      });
  };

  onGetExtractors = () => {
    this.setState({ erbLoading: true });

    Contract.get_playbook_extractors(this.state.contract_id)
      .then(res => {
        this.setState(
          {
            extractors:
              this.generateExtractorsForUI(res.extractors) || this.dummy,
            erbLoading: false
          },
          () => {}
        );
      })
      .catch(e => {
        this.setState({ erbLoading: false });
        console.error(e);
        ToastsStore.error(e.error.message);
      });
  };

  generateExtractorsForUI(sourceExtractors) {
    return sourceExtractors.concat().map(sourceExtractor => {
      let extractor = {};
      extractor.type = sourceExtractor.type;
      extractor.sub_type = sourceExtractor.sub_type.map(sub_type => {
        let subType = {};
        subType.name = sub_type;
        subType.paragraphs = sourceExtractor.paragraphs.map(paragraph => {
          let paragraphn = Object.assign({}, paragraph);
          // paragraph.checked = false;
          paragraphn.paragraph_sub_type = sub_type;
          return paragraphn;
        });
        return subType;
      });
      return extractor;
    });
  }

  onGetSecFiling = () => {
    this.setState({ erbLoading: true });

    Contract.get_sec_filing(this.state.contract_id)
      .then(res => {
        this.setState({
          secFiling: res,
          erbLoading: false
        });
      })
      .catch(e => {
        this.setState({ erbLoading: false });
        console.error(e);
        ToastsStore.error(e.error.message);
      });
  };

  onSubmitExtractors = () => {
    let stateExtractor = this.state.extractors.concat();
    let stateExtractor2 = this.state.extractors.concat();
    let paragraphsForvalidation = stateExtractor
      .map(i => {
        return i.sub_type.map(i => i.paragraphs).flat();
      })
      .flat();

    let paragraphs = stateExtractor2.map(i => {
      let a = Object.assign({}, i);
      a.sub_extractors = a.sub_type
        .map(i => i.paragraphs.filter(i => i.checked))
        .flat();
      delete a.sub_type;
      return a;
    });

    console.log("pr:", paragraphs);

    let validated = paragraphsForvalidation.every(i =>
      i.hasOwnProperty("checked")
    );
    // if (validated) {
    this.setState({ erbLoading: true });

    Contract.forward_with_sub_extractors(this.state.contract_id, {
      extractors: paragraphs
    })
      .then(res => {
        this.setState({
          sub_extractors_values: res.extractors || this.dummy,
          erbLoading: false
        });
        console.log(res);
      })
      .catch(e => {
        this.setState({ erbLoading: false });
        console.log(e);
        ToastsStore.error(e.error.message);
      });
    // }
    //  else {
    //   ToastsStore.error("Please select one clause for all Sub types.");
    // }

    console.log("para", paragraphs);
    // Contract.get_playbook_extractors(this.state.contract_id)
    //   .then(res => {
    //     this.setState({ extractors: this.dummy, erbLoading: false });
    //     console.log(res);
    //   })
    //   .catch(e => {
    //     this.setState({ erbLoading: false });
    //     console.log(e);
    //     ToastsStore.error(e.error.message);
    //   });
  };

  onSubmitSubExtractors = () => {
    let extractors = this.state.sub_extractors_values.map(
      sub_extractor_value_groups => {
        sub_extractor_value_groups.sub_extractor_values = sub_extractor_value_groups.sub_extractor_values.map(
          sub_extractor_values => {
            console.log("sb:", sub_extractor_values);
            if (!sub_extractor_values.hasOwnProperty("confirm")) {
              sub_extractor_values.confirm = true;
            }
            // delete sub_extractor_values.paragraph;
            return sub_extractor_values;
          }
        );
        return sub_extractor_value_groups;
      }
    );
    this.setState({ erbLoading: true });

    Contract.confirm_sub_extractors(this.state.contract_id, {
      extractors: extractors
    })
      .then(res => {
        this.setState({
          playbookIntelligence: res.extractors,
          extractors: null,
          sub_extractors_values: null,
          erbLoading: false
        });
        console.log(res);
      })
      .catch(e => {
        this.setState({ erbLoading: false });
        console.log(e);
        ToastsStore.error(e.error.message);
      });
  };

  renderExtendedRightBar() {
    if (!this.state.hideRightBar) {
      return (
        <div
          className="editor-extended-right-bar"
          onScroll={this.attachERBScrollListener}
        >
          {this.state.erbSelectedItem == this.ERB_Summary &&
            this.renderERBSummary()}
          {this.state.erbSelectedItem == this.ERB_Clause &&
            this.renderERBClause()}
          {this.state.erbSelectedItem == this.ERB_Details &&
            this.renderERBDetails()}
          {this.state.erbSelectedItem == this.ERB_Playbook &&
            this.renderERBPlaybook()}
          {this.state.erbSelectedItem == this.ERB_SEC &&
            this.renderERBSecFiling()}
        </div>
      );
    } else {
      return null;
    }
  }

  attachERBScrollListener = e => {
    console.log("ok");
    if (this.state.erbSelectedItem == this.ERB_Clause) {
      const obj = e.target;
      const height = obj.scrollHeight - obj.offsetHeight;
      const scrollPos = obj.scrollTop;
      const factor = 0.7;
      if (scrollPos >= factor * height) {
        if (!this.state.erbClauseLoading) {
          this.onGetMoreClauseList();
        }
      }
    }
  };

  renderERBSummary() {
    if (this.state.erbLoading)
      return (
        <div
          style={{
            display: "inline-block",
            alignSelf: "center",
            margin: "auto"
          }}
        >
          <Spinner style={{ color: "#473080" }} />
        </div>
      );
    return (
      <div
        className={"summary-wrapper"}
        style={{
          display: "flex",

          flexDirection: "column"
        }}
      >
        <div className="section-title-container">
          <h3>Contract Summary</h3>
          {this.state.is_editable ? (
            this.state.editSummaryMode ? (
              <Btn.primary
                className="btn-summary"
                onClick={e => this.handleEditSummaryButton()}
              >
                Save
              </Btn.primary>
            ) : (
              <Btn.primary
                className="btn-summary"
                onClick={e => this.handleEditSummaryButton()}
              >
                Edit
              </Btn.primary>
            )
          ) : null}
        </div>
        {this.renderSummaryItem(
          true,
          "Title of Contract",
          this.state.summary.title,
          e => {
            let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                title: value
              }
            }));
          },
          "textarea"
        )}

        {this.renderSummaryItem(
          true,
          "Value",
          this.state.summary.value,
          e => {
            let value = e.target.value;
            {
              /* if (isNaN(value)) return; */
            }
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                value: value
              }
            }));
          },
          "input",
          this.state.summary.type == "PA" ? "(AED)" : "(USD)",
          this.state.summary.alerts.value
        )}

        {this.renderSummaryItem(
          true,
          "Counter Party Name",
          this.state.summary.second_party_name,
          e => {
            let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                second_party_name: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.second_party_name
        )}

        {this.renderSummaryItem(
          true,
          "Counter Party Country Name",
          this.state.summary.country_name,
          e => {
            let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                country_name: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.country_name
        )}

        {this.state.summary.other_parties_to_contract &&
          this.renderSummaryItem(
            true,
            "Contract Tags",
            this.state.summary.other_parties_to_contract.length > 0
              ? this.state.summary.other_parties_to_contract.reduce(
                  (string, item) => string + ", " + item
                )
              : "",
            tags => {
              this.setState(prevState => ({
                summary: {
                  ...prevState.summary,
                  other_parties_to_contract: tags
                }
              }));
            },
            "tags",
            null,
            this.state.summary.alerts.other_parties_to_contract
          )}

        {this.renderSummaryItem(
          false,
          "Version",
          this.state.summary.version,
          e => {
            let value = e.target.value;
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                version: value
              }
            }));
          },
          "input",
          null,
          this.state.summary.alerts.version
        )}

        {this.renderSummaryItem(
          true,
          "Type",
          this.state.summary.type,
          value => {
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                type: value
              }
            }));
          },
          "dropdown",
          ["MSA", "PA"],
          this.state.summary.alerts.type
        )}

        {this.renderSummaryItem(
          true,
          "Sector",
          this.state.summary.sector,
          value =>
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                sector: value
              }
            })),
          "dropdown",
          ["Public", "Private"],
          this.state.summary.alerts.sector
        )}

        {this.renderSummaryItem(
          true,
          "Entity",
          this.state.summary.entity == "NG" ? "Non Government" : "Government",
          value =>
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                entity: value == "Non Government" ? "NG" : "G"
              }
            })),
          "dropdown",
          ["Government", "Non Government"],
          this.state.summary.alerts.entity
        )}

        {this.renderSummaryItem(
          true,
          "PO Start Date",
          this.state.summary.start_date,
          e => {
            let value = e.target.value;
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                start_date: value
                  .split("-")
                  .reverse()
                  .join("/")
              }
            }));
          },
          "date",
          [],
          this.state.summary.alerts.start_date
        )}

        {this.renderSummaryItem(
          true,
          "PO End Date",
          this.state.summary.end_date,
          e => {
            let value = e.target.value;
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                end_date: value
                  .split("-")
                  .reverse()
                  .join("/")
              }
            }));
          },
          "date",
          []
        )}

        {this.renderSummaryItem(
          false,
          "Risk",
          this.state.summary.risk + "%",
          e => {
            let value = e.target.value;
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                risk: value
              }
            }));
          },
          "input",
          [],
          this.state.summary.alerts.risk
        )}

        {this.renderSummaryItem(
          true,
          "Contract Purpose",
          this.state.summary.contract_purpose,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                contract_purpose: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.contract_purpose
        )}

        {this.renderSummaryItem(
          true,
          "Which Template",
          this.state.summary.which_template,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                which_template: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.which_template
        )}

        {this.renderSummaryItem(
          true,
          "Key Business Risk",
          this.state.summary.key_business_risk,
          e => {
            // let value = e.target.value.replace(/\n|\r/g, "");
            let value = e.target.value;
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                key_business_risk: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.key_business_risk
        )}

        {this.renderSummaryItem(
          true,
          "Key Buisness Opportunities",
          this.state.summary.key_business_opportunities,
          e => {
            // let value = e.target.value.replace(/\n|\r/g, "");
            let value = e.target.value;
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                key_business_opportunities: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.key_business_opportunities
        )}

        {this.renderSummaryItem(
          true,
          "Non-Standard Terms",
          this.state.summary.nonstandard_terms,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                nonstandard_terms: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.nonstandard_terms
        )}

        {this.renderSummaryItem(
          true,
          "Termination Options",
          this.state.summary.termination_options,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                termination_options: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.termination_options
        )}

        {this.renderSummaryItem(
          true,
          "IP Protected",
          this.state.summary.ip_protected,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                ip_protected: value
              }
            }));
          },
          "input",
          null,
          this.state.summary.alerts.ip_protected
        )}

        {this.renderSummaryItem(
          true,
          "Misc Problems",
          this.state.summary.misc_problems,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                misc_problems: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.misc_problems
        )}

        {this.renderSummaryItem(
          true,
          "Related Documents",
          this.state.summary.related_documents,
          e => {
            let value = e.target.value;
            // let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                related_documents: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.related_documents
        )}

        {/* {this.renderSummaryItem(
          true,
          "Counter Party Country Name",
          this.state.summary.country_name,
          e => {
            let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                country_name: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.country_name
        )} */}

        {/* {this.renderSummaryItem(
          true,
          "Counter Party Name",
          this.state.summary.second_party_name,
          e => {
            let value = e.target.value.replace(/\n|\r/g, "");
            this.setState(prevState => ({
              summary: {
                ...prevState.summary,
                second_party_name: value
              }
            }));
          },
          "textarea",
          null,
          this.state.summary.alerts.second_party_name
        )} */}

        {/* {this.state.summary.other_parties_to_contract &&
          this.renderSummaryItem(
            true,
            "Other Parties to Contract",
            this.state.summary.other_parties_to_contract.length > 0
              ? this.state.summary.other_parties_to_contract.reduce(
                  (string, item) => string + ", " + item
                )
              : "",
            tags => {
              this.setState(prevState => ({
                summary: {
                  ...prevState.summary,
                  other_parties_to_contract: tags
                }
              }));
            },
            "tags",
            null,
            this.state.summary.alerts.other_parties_to_contract
          )} */}
      </div>
    );
  }

  renderSummaryInput(type, value, onChangeHandler, items) {
    switch (type) {
      case "textarea":
        return (
          <TextArea type="text" value={value} onChange={onChangeHandler} />
        );
      case "input":
        return <input type="text" value={value} onChange={onChangeHandler} />;
      case "dropdown":
        return (
          <DropdownInput
            items={items}
            value={value}
            onChange={onChangeHandler}
          />
        );
      case "date":
        return (
          <input
            type="date"
            required={true}
            value={value
              .split("/")
              .reverse()
              .join("-")}
            onChange={onChangeHandler}
          />
        );
      case "tags":
        return (
          <ReactTagInput
            placeholder={"Type and enter to add"}
            tags={value.length > 0 ? value.split(", ") : []}
            onChange={onChangeHandler}
          />
        );
      default:
        return <span>ops</span>;
    }
  }

  renderSummaryItem(
    editable,
    title,
    value,
    onChangeHandler,
    type,
    items = null,
    warningMessage
  ) {
    return (
      <p>
        <div className={"summary-item-label"}>
          <strong>{title}</strong>

          {warningMessage && (
            <ToolTip title={warningMessage} placement="left">
              <div>
                <WarningOutlineIcon color={"red"}></WarningOutlineIcon>
              </div>
            </ToolTip>
          )}
        </div>
        {this.state.editSummaryMode && editable
          ? this.renderSummaryInput(type, value, onChangeHandler, items)
          : value
          ? value + (title == "Value" ? items : "")
          : "N/A"}
      </p>
    );
  }

  renderSECFilingTable(
    editable,
    title,
    value,
    value2,
    title1,
    value3,
    value4,
    title2,
    value5,
    value6,
    title3,
    value7,
    value8,
    title4,
    value9,
    value10,
    title5,
    value11,
    value12,
    onChangeHandler,
    type,
    items = null,
    warningMessage
  ) {
    return (
      <div>
        <div className={"summary-item-label"}>
          {/* <strong>{title}</strong> */}
        </div>

        <table className="sec-filing-table">
          <tr>
            <th>Description</th>
            <th>2018</th>
            <th>2019</th>
          </tr>
          <tr>
            <td>
              <strong>{title}</strong>
            </td>
            <td>{value}</td>
            <td>{value2}</td>
          </tr>
          <tr>
            <td>
              <strong>{title1}</strong>
            </td>
            <td>{value3}</td>
            <td>{value4}</td>
          </tr>
          <tr>
            <td>
              <strong>{title2}</strong>
            </td>
            <td>{value5}</td>
            <td>{value6}</td>
          </tr>
          <tr>
            <td>
              <strong>{title3}</strong>
            </td>
            <td>{value7}</td>
            <td>{value8}</td>
          </tr>
          {title4 ? (
            <tr>
              <td>
                <strong>{title4}</strong>
              </td>
              <td>{value9}</td>
              <td>{value10}</td>
            </tr>
          ) : null}
          {title5 ? (
            <tr>
              <td>
                <strong>{title5}</strong>
              </td>
              <td>{value11}</td>
              <td>{value12}</td>
            </tr>
          ) : null}
        </table>
      </div>
    );
  }

  renderEnitityDropdown() {
    return (
      <DropdownInput
        items={["Non Government", "Government"]}
        value={
          this.state.summary.entity == "NG" ? "Non Government" : "Government"
        }
        onChange={value =>
          this.setState(prevState => ({
            summary: {
              ...prevState.summary,
              entity: value == "Non Government" ? "NG" : "G"
            }
          }))
        }
      />
    );
  }

  onErbClauseSearch = e => {
    console.log(e.target.value);
    this.setState(
      {
        clauses: [],
        clauseCurrentPage: 1,
        clauseLastPage: Infinity,
        erbClauseSearchKeyword: e.target.value
      },
      this.onGetClauseList
    );
  };

  renderSectorDropdown() {
    return (
      <DropdownInput
        items={["Public", "Private"]}
        value={this.state.summary.sector}
        onChange={value =>
          this.setState(prevState => ({
            summary: {
              ...prevState.summary,
              sector: value
            }
          }))
        }
      />
    );
  }
  renderERBClause() {
    if (this.state.erbLoading)
      return (
        <div
          style={{
            display: "inline-block",
            alignSelf: "center",
            margin: "auto"
          }}
        >
          <Spinner style={{ color: "#473080" }} />
        </div>
      );
    return (
      <div className={"clause-wrapper"}>
        <div className="section-title-container">
          <h3>Clause Library</h3>
        </div>
        <div className={"search-filter-wrapper"} style={{ display: "flex" }}>
          <input
            value={this.state.erbClauseSearchKeyword}
            onChange={this.onErbClauseSearch}
            placeholder={"Search"}
          ></input>
          <img src={require("../../assets/icons/equalizer.svg")} />
        </div>
        <Tabs items={["Fallback", "All"]} onChange={null} />
        {this.state.clauses.map(clause => {
          return (
            <ClauseItem title={clause.clause_section} text={clause.clause} />
          );
        })}
        {this.state.erbClauseLoading ? (
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              margin: "auto"
            }}
          >
            <Spinner style={{ color: "#473080", margin: "16px auto" }} />
          </div>
        ) : null}
        {this.state.erbClauseSearchNoResults ? (
          <div
            style={{
              display: "flow-root",
              alignSelf: "center",
              textAlign: "center",
              margin: "auto"
            }}
          >
            No Results
          </div>
        ) : null}
      </div>
    );
  }
  renderERBDetails() {
    if (this.state.erbLoading)
      return (
        <div
          style={{
            display: "inline-block",
            alignSelf: "center",
            margin: "auto"
          }}
        >
          <Spinner style={{ color: "#473080" }} />
        </div>
      );
    return (
      <div
        className={"details-wrapper"}
        style={{
          display: "flex",

          flexDirection: "column"
        }}
      >
        <div className="section-title-container">
          <h3>Details</h3>
        </div>
        <p>
          ID
          <br />
          XXXXX
        </p>
        <p>
          Version
          <br />
          XXXXX
        </p>
        <p>
          Round
          <br />
          XXXXX
        </p>
        <p>
          Last Modified
          <br />
          XXXX
        </p>
        <p>
          Duration
          <br />
          XXXX
        </p>
        <span className={"details-break"}></span>
        <div className={"details-section"}>
          <div className={"details-section-title"}>
            <h3>Authority</h3>
            <PlusIcon />
          </div>
          <div className={"details-item"}>
            <img src={"/static/media/avatar.28908e67.png"} />
            <span>hahaha</span>
          </div>
          <div className={"details-item"}>
            <img src={"/static/media/avatar.28908e67.png"} />
            <span>hahaha</span>
          </div>
        </div>
        <span className={"details-break"}></span>
        <div className={"details-section"}>
          <div className={"details-section-title "}>
            <h3>Counter Party</h3>
            <Btn.primary
              className="btn-add-counter-party"
              onClick={e => this.handleEditSummaryButton()}
            >
              Save
            </Btn.primary>
          </div>
          <div className={"details-item"}>
            <img src={"/static/media/avatar.28908e67.png"} />
            <span>General Motors</span>
          </div>
        </div>
      </div>
    );
  }

  handleSubExtractorValueChange = (i, j, value) => {
    let sub_extractors_values = this.state.sub_extractors_values.concat();
    sub_extractors_values[i].sub_extractor_values[j].subExtractor.value = value;
    sub_extractors_values[i].sub_extractor_values[j].confirm = false;
    this.setState({ sub_extractors_values: sub_extractors_values });
  };

  getStringBetweenRoundBraclets = txt => {
    let init = txt.indexOf("(");
    let fin = txt.indexOf(")");
    return txt.substring(init + 1, fin);
  };

  handleSubExtractorNumericValueChange = (i, j, value) => {
    let newValue = value.map(i => {
      return {
        value: i.split("(")[0],
        unit: this.getStringBetweenRoundBraclets(i)
      };
    });

    let sub_extractors_values = this.state.sub_extractors_values.concat();
    sub_extractors_values[i].sub_extractor_values[
      j
    ].subExtractor.value = newValue;
    sub_extractors_values[i].sub_extractor_values[j].confirm = false;
    this.setState({ sub_extractors_values: sub_extractors_values });
  };

  removeExtractorSubType = (extractorIndex, subExtractorIndex) => {
    let extractors = this.state.extractors.concat();
    let paras = extractors[extractorIndex].sub_type[
      subExtractorIndex
    ].paragraphs.concat();

    extractors[extractorIndex].sub_type[
      subExtractorIndex
    ].paragraphs = extractors[extractorIndex].sub_type[
      subExtractorIndex
    ].paragraphs.map(para => {
      para.checked = false;
      return para;
    });

    this.setState({ extractors: extractors });
  };

  updateExtractorSubType = (
    extractorIndex,
    subExtractorIndex,
    paraIndex,
    value
  ) => {
    console.log(
      "chk: ",
      value,
      " extractorIndex:",
      extractorIndex,
      "subExtractorIndex:",
      subExtractorIndex,
      "paraIndex:",
      paraIndex
    );
    let extractors = this.state.extractors.concat();
    let paras = extractors[extractorIndex].sub_type[
      subExtractorIndex
    ].paragraphs.concat();

    extractors[extractorIndex].sub_type[
      subExtractorIndex
    ].paragraphs = extractors[extractorIndex].sub_type[
      subExtractorIndex
    ].paragraphs.map(para => {
      para.checked = false;
      return para;
    });

    extractors[extractorIndex].sub_type[subExtractorIndex].paragraphs[
      paraIndex
    ].checked = true;
    this.setState({ extractors: extractors });
  };

  renderERBPlaybook() {
    if (this.state.erbLoading)
      return (
        <div
          style={{
            display: "inline-block",
            alignSelf: "center",
            margin: "auto"
          }}
        >
          <Spinner style={{ color: "#473080" }} />
        </div>
      );

    return (
      <div className={"playbook-wrapper"}>
        <Btn.primary onClick={() => this.onGetExtractors()}>
          Extract
        </Btn.primary>
        <div className="extractor-wrapper">
          {this.state.sub_extractors_values
            ? this.state.sub_extractors_values.map((extractor, i) => {
                return (
                  <div class="extractor-group">
                    <span className={"extractor-heading"}>
                      {extractor.type.split("_").join(" ")}
                    </span>
                    {extractor.sub_extractor_values.map(
                      (extractors_values, j) => {
                        return (
                          <React.Fragment>
                            <span>
                              {extractors_values.paragraph.substr(0, 40) +
                                "..."}
                              <strong
                                onClick={e =>
                                  this.moveToIndex(
                                    extractors_values.paragraph_id
                                  )
                                }
                              >
                                view
                              </strong>
                            </span>

                            <span className={"extractor-type"}>
                              Sub Type:{" "}
                              {extractors_values.subExtractor.paragraph_sub_type
                                .split("_")
                                .join(" ")}
                            </span>

                            {extractors_values.subExtractor.valueType ==
                            "Boolean" ? (
                              <DropdownInput
                                value={
                                  extractors_values.subExtractor.value
                                    ? "True"
                                    : "False"
                                }
                                items={["True", "False"]}
                                onChange={value => {
                                  this.handleSubExtractorValueChange(
                                    i,
                                    j,
                                    value
                                  );
                                }}
                              ></DropdownInput>
                            ) : extractors_values.subExtractor.valueType ==
                              "String" ? (
                              <input
                                type="text"
                                value={extractors_values.subExtractor.value}
                                onChange={e => {
                                  this.handleSubExtractorValueChange(
                                    i,
                                    j,
                                    e.target.value
                                  );
                                }}
                              />
                            ) : extractors_values.subExtractor.valueType !=
                              "numeric" ? (
                              <ReactTagInput
                                placeholder={"Type and enter to add"}
                                tags={extractors_values.subExtractor.value}
                                onChange={value => {
                                  this.handleSubExtractorValueChange(
                                    i,
                                    j,
                                    value
                                  );
                                }}
                              />
                            ) : (
                              <>
                                <ReactTagInput
                                  placeholder={
                                    "Type 'Value(Unit)' enter to add"
                                  }
                                  tags={extractors_values.subExtractor.value.map(
                                    i => i.value + "(" + i.unit + ")"
                                  )}
                                  onChange={value => {
                                    this.handleSubExtractorNumericValueChange(
                                      i,
                                      j,
                                      value
                                    );
                                  }}
                                />
                              </>
                            )}
                            <div className="separator"></div>
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                );
              })
            : this.state.extractors
            ? this.state.extractors.map((extractor, i) => {
                return (
                  <div className={"extractor-group"}>
                    <span className={"extractor-heading"}>
                      {extractor.type.split("_").join(" ")}
                    </span>

                    {extractor.sub_type.map((subType, j) => {
                      return (
                        <div className="extractor-subtype-group">
                          <span className={"extractor-subtype-heading"}>
                            Please select clause for{" "}
                            {subType.name.split("_").join(" ")}
                          </span>

                          {subType.paragraphs.map((paragraph, k) => {
                            return (
                              <div className="extractor-group-item">
                                <input
                                  className={"radio-button"}
                                  type="radio"
                                  /* checked={paragraph.checked} */
                                  name={extractor.type + "_" + subType.name}
                                  onChange={e =>
                                    this.updateExtractorSubType(
                                      i,
                                      j,
                                      k,
                                      e.target.checked
                                    )
                                  }
                                ></input>
                                <span>
                                  {paragraph.paragraph.substr(0, 55) +
                                    (paragraph.paragraph.length > 55
                                      ? "..."
                                      : "")}

                                  <strong
                                    onClick={e =>
                                      this.moveToIndex(paragraph.paragraph_id)
                                    }
                                  >
                                    view
                                  </strong>
                                </span>
                              </div>
                            );
                          })}

                          <div className="extractor-group-item">
                            <input
                              className={"radio-button"}
                              type="radio"
                              /* checked={paragraph.checked} */
                              name={extractor.type + "_" + subType.name}
                              onChange={e => this.removeExtractorSubType(i, j)}
                            ></input>
                            <span>Non of the above</span>
                          </div>
                        </div>
                      );
                    })}

                    {/* {extractor.paragraphs.map((paragraph, j) => {
                      return (
                        <div className="extractor-group-item">
                          <span>
                            {paragraph.paragraph.substr(0, 55) +
                              (paragraph.paragraph.length > 55 ? "..." : "")}

                            <strong
                              onClick={e =>
                                this.moveToIndex(paragraph.paragraph_id)
                              }
                            >
                              view
                            </strong>
                          </span>

                          <Dropdown
                            value={
                              extractor.sub_type[0].split("_").join(" ") ||
                              "Select Sub-Type"
                            }
                            items={extractor.sub_type.map(i =>
                              i.split("_").join(" ")
                            )}
                            onChange={value => {
                              this.updateExtractorSubType(
                                i,
                                j,
                                value
                                  .split(" ")
                                  .join("_")
                                  .toLowerCase()
                              );
                            }}
                          ></Dropdown>
                        </div>
                      );
                    })} */}
                  </div>
                );
              })
            : this.state.playbookIntelligence
            ? this.state.playbookIntelligence.map(alert => {
                return (
                  <AnalyzeCard
                    onClick={e => {
                      if (alert.blockId != null)
                        this.moveToIndex(alert.blockId);
                    }}
                    handleAction={(action, data) =>
                      this.handleAnalyzeCardAction(action, data)
                    }
                    alert={alert}
                    type={"risk"}
                    title={"Pulse Risk"}
                    secTitle={alert.alert_message}
                    message={
                      alert.data
                        ? alert.data.title + ": " + alert.data.description
                        : ""
                    }
                  />
                );
              })
            : null}
        </div>

        {this.state.extractors && this.state.extractors.length > 0 && (
          <Btn.primary
            className="btn-submit"
            onClick={() => {
              this.state.sub_extractors_values
                ? this.onSubmitSubExtractors()
                : this.onSubmitExtractors();
            }}
          >
            Submit
          </Btn.primary>
        )}
      </div>
    );
  }

  renderERBSecFiling() {
    if (this.state.erbLoading)
      return (
        <div
          style={{
            display: "inline-block",
            alignSelf: "center",
            margin: "auto"
          }}
        >
          <Spinner style={{ color: "#473080" }} />
        </div>
      );
    return (
      <div
        className={"summary-wrapper sec-filing-wrapper"}
        style={{
          display: "flex",

          flexDirection: "column"
        }}
      >
        <div className="section-title-container">
          <h3>SEC Filing</h3>
        </div>
        <h4>Counterparty SEC Filing Details</h4>

        {this.renderSummaryItem(
          false,
          "Company Name",
          this.state.secFiling.counterparty_sec_filing_info.companyname
        )}

        {this.renderSummaryItem(
          false,
          "SIC Description",
          this.state.secFiling.counterparty_sec_filing_info.sicdescription
        )}

        {this.renderSummaryItem(
          false,
          "USD Conversion Rate",
          this.state.secFiling.counterparty_sec_filing_info.usdconversionrate
        )}

        {this.renderSummaryItem(
          false,
          "Form Type",
          this.state.secFiling.counterparty_sec_filing_info.formtype
        )}
        {this.renderSummaryItem(
          false,
          "Currency",
          this.state.secFiling.counterparty_sec_filing_info.currencycode
        )}
        {this.renderSummaryItem(
          false,
          "Audited",
          this.state.secFiling.counterparty_sec_filing_info.audited
            ? "Yes"
            : "No"
        )}
        <h4>Statement Of Cash Flow</h4>
        <div /*className={"margin-left"} */>
          {/*<h5>2018 | 2019</h5> */}
          {this.renderSECFilingTable(
            false,
            <span>
              Cash
              <br />
              From
              <br />
              Financing
              <br />
              Activities
            </span>,
            this.state.secFiling.statement_of_cash_flow["2018"]
              .cashfromfinancingactivities,
            this.state.secFiling.statement_of_cash_flow["2019"]
              .cashfromfinancingactivities,
            <span>
              Cash From
              <br />
              Investing
              <br />
              Activities
            </span>,
            this.state.secFiling.statement_of_cash_flow["2018"]
              .cashfrominvestingactivities,
            this.state.secFiling.statement_of_cash_flow["2019"]
              .cashfrominvestingactivities,
            <span>
              Cash
              <br />
              From
              <br />
              Operating
              <br />
              Activities
            </span>,
            this.state.secFiling.statement_of_cash_flow["2018"]
              .cashfromoperatingactivities,
            this.state.secFiling.statement_of_cash_flow["2019"]
              .cashfromoperatingactivities,
            "Net Change in Cash",
            this.state.secFiling.statement_of_cash_flow["2018"].netchangeincash,
            this.state.secFiling.statement_of_cash_flow["2019"].netchangeincash
          )}
                    
          {/* {this.renderSummaryItem(
            false,
            "Cash From Investing Activities",
            this.state.secFiling.statement_of_cash_flow["2018"]
              .cashfrominvestingactivities +
              " | " +
              this.state.secFiling.statement_of_cash_flow["2019"]
                .cashfrominvestingactivities
          )}
                    
          {this.renderSummaryItem(
            false,
            "Cash From Operating Activities",
            this.state.secFiling.statement_of_cash_flow["2018"]
              .cashfromoperatingactivities +
              " | " +
              this.state.secFiling.statement_of_cash_flow["2019"]
                .cashfromoperatingactivities
          )}
                    
          {this.renderSummaryItem(
            false,
            "Net Change in Cash",
            this.state.secFiling.statement_of_cash_flow["2018"]
              .netchangeincash +
              " | " +
              this.state.secFiling.statement_of_cash_flow["2019"]
                .netchangeincash
          )} */}
                    
          {/* <h5>2019</h5>
          {this.renderSummaryItem(
            false,
            "Cash From Financing Activities",
            this.state.secFiling.statement_of_cash_flow["2019"]
              .cashfromfinancingactivities
          )}

          {this.renderSummaryItem(
            false,
            "Cash From Investing Activities",
            this.state.secFiling.statement_of_cash_flow["2019"]
              .cashfrominvestingactivities
          )}
          {this.renderSummaryItem(
            false,
            "Cash From Operating Activities",
            this.state.secFiling.statement_of_cash_flow["2019"]
              .cashfromoperatingactivities
          )}
          {this.renderSummaryItem(
            false,
            "Net Change in Cash",
            this.state.secFiling.statement_of_cash_flow["2019"].netchangeincash
          )} */}
        </div>
        <h4>Consolidated Statement of Operations</h4>
        <div /*className={"margin-left"} */>
          {/*           <h5>2018 | 2019</h5> */}
          {this.renderSECFilingTable(
            false,
            "Ebit",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .ebit,
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .ebit,
            <span>
              Cost Of
              <br />
               Revenue
            </span>,
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .costofrevenue,
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .costofrevenue,
            <span>
              Gross
              <br />
              Profit
            </span>,
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .grossprofit,
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .grossprofit,
            <span>
              Income
              <br />
              Before
              <br />
              Taxes
            </span>,
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .incomebeforetaxes,
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .incomebeforetaxes,
            <span>
              Total
              <br />
              Revenue
            </span>,
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .totalrevenue,
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .totalrevenue,
            <span>
              Net
              <br />
              Income
            </span>,
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .netincome,
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .netincome
          )}
                    
          {/* {this.renderSummaryItem(
            false,
            "Cost Of Revenue",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .costofrevenue +
              " | " +
              this.state.secFiling.consolidated_statement_of_operations["2019"]
                .costofrevenue
          )}
                    
          {this.renderSummaryItem(
            false,
            "Gross Profit",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .grossprofit +
              " | " +
              this.state.secFiling.consolidated_statement_of_operations["2019"]
                .grossprofit
          )}
                    
          {this.renderSummaryItem(
            false,
            "Income Before Taxes",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .incomebeforetaxes +
              " | " +
              this.state.secFiling.consolidated_statement_of_operations["2019"]
                .incomebeforetaxes
          )}
                    
          {this.renderSummaryItem(
            false,
            "Total Revenue",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .totalrevenue +
              " | " +
              this.state.secFiling.consolidated_statement_of_operations["2019"]
                .totalrevenue
          )}
                    
          {this.renderSummaryItem(
            false,
            "Net Income",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .netincome +
              " | " +
              this.state.secFiling.consolidated_statement_of_operations["2019"]
                .netincome
          )} */}
                    
          {/* <h5>2019</h5>
          {this.renderSummaryItem(
            false,
            "Ebit",
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .ebit
          )}

          {this.renderSummaryItem(
            false,
            "Cost Of Revenue",
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .costofrevenue
          )}
          {this.renderSummaryItem(
            false,
            "gross Profit",
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .grossprofit
          )}
          {this.renderSummaryItem(
            false,
            "Income Before Taxes",
            this.state.secFiling.consolidated_statement_of_operations["2018"]
              .incomebeforetaxes
          )}
          {this.renderSummaryItem(
            false,
            "Total Revenue",
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .totalrevenue
          )}

          {this.renderSummaryItem(
            false,
            "Net Income",
            this.state.secFiling.consolidated_statement_of_operations["2019"]
              .netincome
          )} */}
        </div>
        <h4>Statement of Assest and Liabilities</h4>
        <div /*className={"margin-left"} */>
          {/*           <h5>2018 | 2019</h5> */}
          {this.renderSECFilingTable(
            false,
            <span>
              Total
              <br />
              Assets
            </span>,
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalassets,
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalassets,
            <span>
              Total
              <br />
               Current
              <br />
               Assets
            </span>,
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalcurrentassets,
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalcurrentassets,
            <span>
              Total
              <br />
               Current 
              <br />
              Liabilities
            </span>,
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalcurrentliabilities,
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalcurrentliabilities,
            <span>
              Total
              <br />
              Liabilities
            </span>,
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalliabilities,
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalliabilities,
            <span>
              Total Long
              <br />
              Term Debt
            </span>,
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totallongtermdebt,
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totallongtermdebt
          )}
          {/* {this.renderSummaryItem(
            false,
            "Total Current Assets",
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalcurrentassets +
              " | " +
              this.state.secFiling.statement_of_assets_and_liabilities["2019"]
                .totalcurrentassets
          )}
                    
          {this.renderSummaryItem(
            false,
            "Total Current Liabilities",
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalcurrentliabilities +
              " | " +
              this.state.secFiling.statement_of_assets_and_liabilities["2019"]
                .totalcurrentliabilities
          )}
                    
          {this.renderSummaryItem(
            false,
            "Total Liabilities",
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totalliabilities +
              " | " +
              this.state.secFiling.statement_of_assets_and_liabilities["2019"]
                .totalliabilities
          )}
                    
          {this.renderSummaryItem(
            false,
            "Total Long Term Debt",
            this.state.secFiling.statement_of_assets_and_liabilities["2018"]
              .totallongtermdebt +
              " | " +
              this.state.secFiling.statement_of_assets_and_liabilities["2019"]
                .totallongtermdebt
          )} */}
           
          {/* <h5>2019</h5>

          {this.renderSummaryItem(
            false,
            "Total Assets",
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalassets
          )}

          {this.renderSummaryItem(
            false,
            "Total Current Assets",
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalcurrentassets
          )}
          {this.renderSummaryItem(
            false,
            "Total Current Liabilities",
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalcurrentliabilities
          )}
          {this.renderSummaryItem(
            false,
            "Total Liabilities",
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totalliabilities
          )}
          {this.renderSummaryItem(
            false,
            "Total Long Term Debt",
            this.state.secFiling.statement_of_assets_and_liabilities["2019"]
              .totallongtermdebt
          )} */}
        </div>
      </div>
    );
  }

  getAbsPosition = function(el) {
    var el2 = el;
    var curtop = 0;
    var curleft = 0;
    if (document.getElementById || document.all) {
      do {
        curleft += el.offsetLeft - el.scrollLeft;
        curtop += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
        el2 = el2.parentNode;
        while (el2 != el) {
          curleft -= el2.scrollLeft;
          curtop -= el2.scrollTop;
          el2 = el2.parentNode;
        }
      } while (el.offsetParent);
    } else if (document.layers) {
      curtop += el.y;
      curleft += el.x;
    }
    return [curtop, curleft];
  };

  lastKey = "";
  renderComment = (comments, spanId, index) => {
    //console.log("COMMENT RENDERED ="+index);

    let editorBlock = $(`.inline-comment-span[data-span-id="${spanId}"]`);
    console.log("meez check rc eb", editorBlock);

    if (!editorBlock.offset()) return;

    // let newblock = $(`[data-offset-key="${comment.key}"][data-block="true"]`);
    // if (!newblock.offset()) return;

    // window.block = newblock;

    // console.log("last", $(`.comment-box ${this.lastKey}`).outerHeight());

    // let lastCommentBlock = $(".comment-box").last();
    // let lastBlockHeight = lastCommentBlock.outerHeight()
    //   ? lastCommentBlock.outerHeight()
    //   : 144;
    // console.log("last bottom", this.lastBottom);
    // console.log("lastBlockHeight", lastBlockHeight);
    // console.log("top", newblock.offset().top + 174);
    // console.log("bottom", newblock.offset().top + 174 + lastBlockHeight);
    // let offset =
    //   newblock.offset().top + 174 > this.lastBottom
    //     ? newblock.offset().top + 174
    //     : this.lastBottom;
    // this.lastBottom = offset + lastBlockHeight + 20;
    // this.lastKey = "." + comment.key;
    // console.log(offset);

    let top = 0;
    let prev = $(".comment-box " + this.lastKey);
    if (prev.offset()) {
      let prevBottom = this.getOffsetSpan(prev[0]);
      prev.outerHeight();
      top = top > prevBottom + 20 ? top : prevBottom + 20;
    }
    // console.log()

    this.lastKey = "." + spanId;
    let oldfocusSpanId = this.state.focusSpanId;
    return (
      <div
        key={spanId}
        style={{ top: top, zIndex: this.state.focusSpanId2 == spanId ? 2 : 1 }}
        fake-top={top}
        onClick={() => this.handleCommentBoxClick(spanId, oldfocusSpanId)}
        className={`comment-box ${spanId}`}
        data={spanId}
      >
        {console.log(spanId, this.state.focusSpanId)}
        <CommentItem
          blockKey={spanId}
          focussed={this.state.focusSpanId2 == spanId}
          comments={comments}
          onStartEditing={this.handleStartEditing}
          onStartDelete={this.handleStartDelete}
          onDelete={this.handleCommentDelete}
          onCreateNewComment={this.handleCreateNewComment}
          onAddNewReply={this.handleNewReply}
        />
      </div>
    );
  };

  handleCommentBoxClick = (key, oldfocusSpanId) => {
    if (key == this.state.focusSpanId) return;

    this.setState({ focusSpanId2: key, hideBubble: true });

    setTimeout(() => {
      this.focusComment(key);
      this.moveToSpanId(key);
      this.setState({ focusSpanId: key });
    }, 200);
  };

  handleCommentDelete = spanId => {
    let comments = this.state.comments;
    delete comments[spanId];

    console.log("deleted", spanId);
    this.setState({
      editorState: removeCommentBySpanId(this.state.editorState, spanId),
      comments: comments
    });
  };

  handleStartEditing = (spanId, index, id) => {
    alert(spanId + "-" + index + "-" + id);
  };
  handleStartDelete = (spanId, index, id) => {
    alert(spanId + "-" + index + "-" + id);
  };

  handleCreateNewComment = (key, value) => {
    let comments = this.state.comments;
    let commentDetails = {
      comment: value,
      contract_id: this.state.contract_id,
      block_key: key
    };
    Comment.add_new_comment(commentDetails)
      .then(body => {
        comments[key].data.push(body);
        this.setState({ comments: comments }, () => {
          this.onSave();
        });
      })
      .catch(e => {
        ToastsStore.error(
          e.error ? e.error.message : "Error in creating comment."
        );
      });
  };

  handleNewReply = (key, value) => {
    let comments = this.state.comments;
    let commentDetails = {
      comment: value,
      contract_id: this.state.contract_id,
      block_key: key,
      parent_id: comments[key].data[0].id
    };
    Comment.add_new_comment(commentDetails)
      .then(body => {
        comments[key].data.push(body);
        this.setState({ comments: comments });
      })
      .catch(e => {
        ToastsStore.error(e.error.message);
      });
  };

  lastBottom = 0;

  getSortedComments = () => {
    let comments = this.state.comments;
    let sortedKeys = Object.keys(comments).sort(function(a, b) {
      return comments[a].index > comments[b].index; // Organize the category array
    });
    return sortedKeys;
  };

  renderAllComments = () => {
    return (
      <div className={"comments-wrapper"}>
        {this.getSortedComments().map((key, index) => {
          console.log(
            "meez comment render",
            key,
            this.state.comments[key].data
          );
          return this.renderComment(this.state.comments[key].data, key, index);
        })}
        {/* {this.state.comments.map((comment,index) => {
          return this.renderComment(comment,index);
        })} */}
      </div>
    );
  };

  toggleAction = () =>
    this.setState({ toggleAction: !this.state.toggleAction });

  renderCTAs() {
    return (
      <div className="editor-header-cta-container">
        {this.state.is_editable ? (
          <Dropdown
            className={"action-cta"}
            direction="down"
            isOpen={this.state.toggleAction}
            toggle={this.toggleAction}
          >
            <DropdownToggle tag="div" data-toggle="dropdown">
              <div className="first _btn">
                {/* <DotsVerticalIcon /> */}
                {/* <img src={require("../../assets/icons/share.svg")} /> */}
                Actions
              </div>
            </DropdownToggle>
            <DropdownMenu className="actions_box">
              {this.state.is_editable ? (
                <DropdownItem
                  className=" "
                  onClick={() => {
                    this.state.is_owner
                      ? this.setState({ showAssignModal: true })
                      : this.handleReturnToOwner();
                  }}
                >
                  <img src={require("../../assets/icons/share.svg")} />
                  {this.state.is_owner ? "Assign" : "Return"}
                </DropdownItem>
              ) : null}

              {this.state.is_owner && this.state.is_editable ? (
                <DropdownItem onClick={this.onForward}>
                  <SendIcon size="1.1vw" color="#473080" />

                  {this.state.forwarding
                    ? "Forwarding..."
                    : this.state.action == "modelling"
                    ? "Forward to Pulse"
                    : "Forward to Modelling"}
                </DropdownItem>
              ) : null}

              {this.state.is_editable &&
              Store.user.profile_details.designation == "CEO" &&
              !this.state.is_approved ? (
                <DropdownItem
                  className="purple"
                  onClick={() => this.onApprove()}
                >
                  <img src={require("../../assets/icons/approved.svg")} />
                  {"Approve"}
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </Dropdown>
        ) : null}

        <div className="second _btn" onClick={() => this.onAnalyze()}>
          <img src={require("../../assets/icons/chroma_logo_white.svg")} />
          {this.state.action != "modelling" ? "Analyze" : "Simulate"}
        </div>
        {this.renderRiskMeter()}
      </div>
    );
  }

  renderRiskMeter() {
    return (
      <div className="editor-risk-container">
        <ReactMeter
          className="risk-meter"
          label={"Risk"}
          value={this.state.risk_score}
        />
      </div>
    );
  }

  renderIndex() {
    if (!this.state.hideIndex)
      return (
        <div
          className={`editor-index-wrapper${
            !this.state.showIndex ? " editor-index-wrapper-collapsed" : ""
          }`}
        >
          <div
            class="index-title"
            onClick={() =>
              this.setState({ showIndex: false }, () =>
                setTimeout(
                  () => this.setState({ hideIndex: true, index: [] }),
                  50
                )
              )
            }
          >
            Index <ChevronLeft size="1.4vw" color="#4d4d4d" />
          </div>
          <Index
            index={this.state.index}
            moveToIndex={this.moveToIndex}
            blocks={this.state.contentState.blocks}
            index_paddings={this.index_paddings}
            alerts={this.state.slimulateAlerts}
          />
        </div>
      );
    return (
      <div
        onClick={() => {
          this.hideERB();
          this.hideComments();
          let index = this.generateIndex();
          this.setState({
            showIndex: true,
            index: index,
            hideIndex: false
          });
        }}
        className="editor-index-CTA"
      >
        <FileDocumentOutline size="1.4vw" color="#4d4d4d" />
      </div>
    );
  }

  renderTestCTA(show = true) {
    if (show)
      return (
        <React.Fragment>
          <button
            onClick={this.onTest}
            style={{
              zIndex: "300",
              position: "fixed",
              top: "70px",
              right: "101px"
            }}
          >
            Test
          </button>
          <button
            onClick={this.onTest2}
            style={{
              zIndex: "300",
              position: "fixed",
              top: "70px",
              right: "151px"
            }}
          >
            Test2
          </button>
        </React.Fragment>
      );
    return null;
  }

  renderCutomToolBar() {
    return (
      <div className="custom-toolbar custom-toolbar-ins-mod">
        {this.state.is_editable ? this.renderSave() : null}
        {this.renderComments()}

        {/* {this.state.is_owner && this.state.is_editable
          ? this.renderFwd2Modelling()
          : null} */}

        {/* {this.renderRiskMeter()} */}
      </div>
    );
  }

  renderHeaderBg() {
    return <div className="editor-header-bg"></div>;
  }

  showNotification = (title, message, type, alert) => {
    notificationRU.notice({
      content: (
        <AnalyzeNotification
          type={type}
          title={title}
          message={message}
          alert={alert}
        />
      ),
      duration: 2,
      closable: true,
      style: { top: 0, left: "calc(100vw - 100%)", transition: "all 1s ease" },
      className: `right-up`
    });
  };

  showAlertsNotification = alerts => {
    const sn = this.showNotification;
    alerts.forEach(function(alert, index) {
      setTimeout(function() {
        sn(
          alert.metadata.category == "pusle_risk" ? "Pulse Risk" : "Pulse Risk",
          alert.alert_message,
          alert.metadata.category,
          alert
        );
      }, 700 * index);
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.is_editable == 1 && (
          <Prompt
            when={
              !(this.isCommentsSaved() && this.isSaved() && this.isTitleSaved())
            }
            message="You have unsaved changes, are you sure you want to leave?"
          />
        )}
        {this.state.contractLoading && this.renderLoader()}
        <div className="_my-editor-container">
          <div className="avoid-scroll-wrapper">
            <div
              ref={c => (this.scroll_component = c)}
              className="editor-main-container"
            >
              {/* {this.renderHeaderBg()} */}
              {this.renderTitle()}
              {this.renderEditor()}
              {this.renderFab()}
            </div>
            {this.renderIndex()}
            {this.renderTestCTA(false)}
            {this.renderAnalyzingLoader()}
          </div>
          {this.renderExtendedRightBar()}
          {this.renderRightBar()}
          {this.renderRedirect()}
          {this.renderAnalyzeBar()}
          {this.renderAssignModal()}
          {this.renderApproveModal()}
          {this.renderRulingsModal()}
        </div>
      </React.Fragment>
    );
  }
}
