import React, { Component, useState } from "react";

import { Link, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import WorkSpaceSideBar from "../workSpaceSideBar/workSpaceSideBar";

class SideBarComponent extends Component {
  menuItems = [
    {
      img: require("../../assets/icons/sidebar1.svg"),
      path: "/mission-control"
    },
    {
      img: require("../../assets/icons/sidebar2.svg"),
      // path: "/insights/workspace",
      options: [
        {
          text: "My Workspace",
          link: "/insights/workspace"
        },
        {
          text: "PlayBook",
          link: "/playbook"
        }
      ]
    },
    {
      img: require("../../assets/icons/sidebar3.svg"),
      path: "/modelling/contracts"
      //path: "/playbook"
    },
    {
      img: require("../../assets/icons/sidebar4.svg"),
      path: "/pulse/dashboard"
    }
  ];

  getSelectedFromPath(path) {
    switch (path) {
      case "/mission-control":
        return 0;
      case "/insights/workspace":
        return 1;
      case "/modelling/contracts":
        // case "/playbook":
        return 2;
      case "/pulse/dashboard":
        return 3;
      default:
        return -1;
    }
  }

  state = {
    selected: 0
  };

  componentDidMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = location => {
    this.setState({
      selected: this.getSelectedFromPath(location.pathname)
    });
  };

  render() {
    return (
      <div className="sidebar-container">
        {this.menuItems.map((menuItem, index) => {
          return (
            <div key={index.toString()} className="sidebar-icon-container">
              {!menuItem.path ? (
                <div>
                  {/* <img
                    src={menuItem.img}
                    className={
                      this.state.selected === index ? "selected-menu-item" : ""
                    }
                  /> */}
                  <MenuItems options={menuItem.options}>
                    <img
                      src={menuItem.img}
                      className={
                        this.state.selected === index
                          ? "selected-menu-item"
                          : ""
                      }
                    />
                  </MenuItems>
                </div>
              ) : (
                <Link to={menuItem.path}>
                  <img
                    src={menuItem.img}
                    className={
                      this.state.selected === index
                        ? "selected-menu-item"
                        : "mm"
                    }
                  />
                </Link>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

const SideBar = withRouter(props => <SideBarComponent {...props} />);

const MenuItems = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="action">
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="div"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          {props.children}
        </DropdownToggle>
        <DropdownMenu className="actions_box">
          {props.options.map((optionItem, index) => (
            <Link to={optionItem.link} key={index}>
              <DropdownItem>{optionItem.text}</DropdownItem>
            </Link>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SideBar;
