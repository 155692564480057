import React, { Component } from "react";

export default class LogoBox extends Component {
  navigateToHome() {
    window.location.href = "../mission-control";
  }

  render() {
    return (
      <div className="logo-box">
        <img
          onClick={() => this.navigateToHome()}
          className="logo-image"
          src={require("../../assets/icons/logo.png")}
        />
        <div className="logo-seperator"></div>
      </div>
    );
  }
}
