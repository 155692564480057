import React, { Component } from "react";
import "./common.scss";

export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setState({ value: this.props.value });
  }

  render() {
    const items = this.props.items || [];
    return (
      <ul className={"tabs-container"}>
        {items.map((item, i) => (
          <li
            className={i == this.state.active ? "active" : ""}
            onClick={e => {
              this.select(e, i);
            }}
          >
            {item}
            <span></span>
          </li>
        ))}
      </ul>
    );
  }

  select = (event, i) => {
    let value = event.target.innerText;
    console.log(value);
    this.setState(
      {
        active: i
      },
      () => {
        this.props.onChange && this.props.onChange(value, i);
      }
    );
  };
}
