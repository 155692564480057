import React, { Component } from 'react';
import GIFCONTRACT from '../../assets/gif/Chroma3DContracts.gif'

class GifMissionControl extends Component {
    handleCloseBtn=()=>(
        this.props.history.push('/mission-control')
    )
  render() {
    //   alert("HI")
    return (
      <div className='gif-container'>
          <img className='gif-container' src={GIFCONTRACT} alt='Loading...'/>
          <div className='close-btn-div' onClick={this.handleCloseBtn}></div>
      </div>
    );
  }
}

export default GifMissionControl;
