import React, { memo, Component } from "react";
import { FixedSizeList as List } from "react-window";
import memoize from "memoize-one";
import AutoSizer from "react-virtualized-auto-sizer";

import { getHighlightColorClass } from "../editor/utils";

class Index extends Component {
  render() {
    return (
      <div style={{ height: "100%" }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemData={createItemData(
                this.props.index,
                this.props.moveToIndex,
                this.props.index_paddings,
                this.props.alerts
              )}
              itemCount={this.props.index.length}
              itemSize={40}
              className="editor-index-container"
              width={width}
            >
              {IndexRow}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  }
}

const createItemData = memoize(
  (items, moveToIndex, index_paddings, alerts) => ({
    items,
    moveToIndex,
    index_paddings,
    alerts
  })
);

const IndexRow = memo(({ data, index, style }) => {
  // let array = this.state.slimulateAlerts.filter(
  //     i => i.blockId == index.key
  //   );
  //   let indexClass = "";
  //   if (array.length > 0) {
  //     indexClass =
  //       array[0].metadata.type == "sanction" ? "index_dot_red" : "";
  //   }

  const { items, moveToIndex, index_paddings, alerts } = data;

  const item = items[index];

  if (!alerts) {
    alert("khali hai");
    alerts = [];
  }
  let filteredAlerts = alerts.filter(alert => {
    if (alert.metadata.stage == "playbook" && alert.metadata.type == "variable")
      return alert.data.ruleBreak && alert.blockId == item.key;
    return alert.blockId == item.key;
  });

  let indexClass = "";

  if (filteredAlerts.length > 0) {
    indexClass = getHighlightColorClass(
      filteredAlerts[filteredAlerts.length - 1],
      "color"
    );
  }

  return (
    <p
      style={{
        fontWeight: item.weight,
        paddingLeft: index_paddings[item.levelIndex],
        display: "flex",
        top: style.top,
        position: style.position,
        width: "100%"
      }}
      onClick={e => moveToIndex(item.key)}
    >
      <span
        className={`index_text ${indexClass}`}
      >{`${item.number} ${item.text}`}</span>
      {/* {indexClass != "" ? (
        <span className={`index_dot ${indexClass}`}></span>
      ) : null} */}
    </p>
  );
});

export default Index;
