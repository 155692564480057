import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RichUtils } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Contract from "../../api/contract/Contract";

import { ToastsStore } from "react-toasts";
import { Prompt, Redirect } from "react-router";
import FileDocumentOutline from "mdi-react/FileDocumentOutlineIcon";

import ContentSaveOutline from "mdi-react/ContentSaveOutlineIcon";
import ChevronLeft from "mdi-react/ChevronLeftIcon";
import debounce from "lodash.debounce";

import { Spinner } from "reactstrap";

import {
  isSelectionACommentEntity,
  addLineBreakBetweenComments
} from "../common/editor/utils";

import "@pathofdev/react-tag-input/build/index.css";
import { ContentBlock, convertFromRaw, genKey, convertToRaw } from "draft-js";

export default class SecondPartyContract extends Component {
  ERB_Summary = "summary";
  ERB_Clause = "clause";
  ERB_Details = "details";
  clauseCurrentPage = 1;

  empty_content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { key: "637gr" }
      }
    ]
  };

  state = {
    contentState: this.empty_content,
    editorState: EditorState.createWithContent(
      convertFromRaw(this.empty_content)
    ),
    title: "Untitled Document",
    savedState: {},
    savedTitle: "",
    saving: false,
    forwarding: false,
    index: [],
    showIndex: false,
    contract_id: null,
    titleEditingMode: false,
    redirect: false,
    showRightBar: false,
    hideRightBar: true,
    erbSelectedItem: null,
    erbLoading: true,
    editSummaryMode: false,
    summary: {},
    clauses: [],
    erbClauseLoading: false,
    clauseLastPage: Infinity,
    erbClauseSearchKeyword: null,
    erbClauseSearchNoResults: false,
    clauseCurrentPage: 1,
    dropdownOpen: false,

    returned: false,
    error: null,
    contractLoading: false,
    showRulingsModal: false,
    rulings: []
  };

  index_paddings = Array(7).fill(12);

  componentDidUpdate(prevProps, prevState) {
    this.updateIndex(prevState);
  }

  componentDidMount() {
    if (this.props.location.pathname.split("/").pop() !== "new-contract") {
      let contractId = this.props.location.pathname.split("/").pop();
      console.log("cid", contractId);
      this.onOpenContract(contractId);
    } else {
    }
  }

  onOpenContract = id => {
    this.setState({ contractLoading: true });
    Contract.get_counter_party_contract(id)
      .then(contract => {
        console.log(contract);
        let content =
          contract.content.content !== undefined
            ? contract.content.content
            : contract.content;
        //first replace all 'null' values with ''
        content = JSON.stringify(content, null, 4);
        content = content.replace(/null/g, '""');
        //then if 'atomic' blocks contain empty text they should be changed to ' '
        content = JSON.parse(content);
        content.blocks = content.blocks.map(block => {
          if (block.type === "atomic" && block.text === "") block.text = " ";
          return block;
        });
        //then convert entityMap array into object
        content.entityMap = { ...content.entityMap };
        this.setState({ contractLoading: false }, () =>
          this.setState(
            {
              editorState: EditorState.createWithContent(
                convertFromRaw(content || this.empty_content)
              ),
              savedState: EditorState.createWithContent(
                convertFromRaw(content || this.empty_content)
              ),
              title: contract.contract_name,
              savedTitle: contract.contract_name,
              contract_id: id,
              is_owner: contract.is_owner,
              is_editable: contract.is_editable
            },
            () => {}
          )
        );
      })
      .catch(e => {
        this.setState({
          contractLoading: false,
          returned: true,
          error: e.error ? e.error.message : null
        });

        ToastsStore.error(
          e.error ? e.error.message : "Error opening contract!"
        );

        console.log(e);
      });
  };

  componentWillUnmount() {
    window.onbeforeunload = undefined;
  }

  updateIndex = debounce(prevState => {
    if (this.state.showIndex) {
      if (
        JSON.stringify(prevState.contentState) !==
        JSON.stringify(this.state.contentState)
      ) {
        this.setState({ index: this.generateIndex() });
      }
    }
  }, 300);

  onContentStateChange = contentState => {
    console.log("Content State Changed", contentState);

    this.setState({ contentState });
  };

  isSaved() {
    return (
      JSON.stringify(this.state.contentState) ===
      JSON.stringify(this.state.savedState)
    );
  }

  isTitleSaved() {
    return this.state.savedTitle === this.state.title;
  }

  isContractEmpty() {
    const content = this.state.contentState.blocks;
    if (content.length === 0) return true;
    if (content.length === 1) {
      if (
        content[0].text.length === 0 &&
        content[0].inlineStyleRanges.length === 0 &&
        content[0].entityRanges.length === 0 &&
        Object.entries(content[0].data).length === 0
      )
        return true;
    }
    return false;
  }

  onSave = () => {
    if (this.isContractEmpty())
      return ToastsStore.warning("Can't save empty contract!");
    else if (this.state.title === "")
      return ToastsStore.warning("Title must not be empty!");
    this.setState({ saving: true });
    if (this.state.contract_id) {
      //if contract has been uploaded atleas once
      let cleanState = convertToRaw(this.state.editorState.getCurrentContent());
      Contract.update_counter_party_contract(
        this.state.title,
        this.state.contract_id,
        this.isSaved() ? null : cleanState
      )
        .then(res => {
          this.setState(
            {
              savedState: Object.assign({}, cleanState),
              savedTitle: this.state.title
            },
            () => {
              this.setState({ saving: false });
            }
          );
        })
        .catch(e => {
          ToastsStore.error("An error occurred while saving contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    } else {
      return ToastsStore.warning("Invalid Token Id!");
    }
  };

  onReturnToOwner = () => {
    if (!this.isSaved() || this.state.forwarding)
      return ToastsStore.warning("Please save the contract first!");
    else if (!this.isTitleSaved())
      return ToastsStore.warning("Please save the title first!");
    this.setState({ forwarding: true });
    Contract.return_to_owner(this.state.contract_id)
      .then(res => {
        setTimeout(() => {
          ToastsStore.success(res.message);
          this.props.history.push("/modelling/contracts");
        }, 200);
      })
      .catch(e => {
        this.setState({ forwarding: false });
        ToastsStore.error("An error occurred!");
        console.log(e);
      });
  };

  getOffset = el => {
    const elemRect = el.getBoundingClientRect();
    const bodyRect = this.editor_wrapper.getBoundingClientRect();
    return elemRect.top - bodyRect.top;
  };

  getOffsetSpan = el => {
    return this.getOffset(el) + 70;
  };

  moveToIndex(key) {
    let i = 0;
    let queryKey = "";
    while ("1234567890".includes(key[i])) {
      queryKey = queryKey + "\\3" + key[i] + " ";
      ++i;
    }
    queryKey += key.slice(i);
    const elem = this.editor_wrapper.querySelectorAll(
      `[data-offset-key=${queryKey}-0-0]`
    )[0];
    this.scroll_component.scrollTo({
      top: this.getOffset(elem) - 110,
      left: 0,
      behavior: "smooth"
    });
  }

  serializeLevels(n, levelArr) {
    if (!levelArr[n]) {
      for (let i = n + 1; i < levelArr.length; ++i) {
        this.index_paddings[i] += 12;
      }
      levelArr[n] = true;
    }
    return n;
  }

  getPaddingAndType(type, levelArr, _currLevel) {
    let currLevel;
    switch (type) {
      case "header-one":
        currLevel = 0;
        break;
      case "header-two":
        currLevel = 1;
        break;
      case "header-three":
        currLevel = 2;
        break;
      case "header-four":
        currLevel = 3;
        break;
      case "header-five":
        currLevel = 4;
        break;
      case "header-six":
        currLevel = 5;
        break;
      default:
        return {
          type: "normal",
          levelIndex: _currLevel + 1,
          currLevel: _currLevel
        };
    }
    return {
      type: "bold",
      levelIndex: this.serializeLevels(currLevel, levelArr),
      currLevel
    };
  }

  generateIndex() {
    this.index_paddings = Array(7).fill(12);
    let indexJson = [];
    let levelArr = Array(7).fill(false);
    let currLevel = -1;
    for (let block of this.state.contentState.blocks) {
      if (/\S/.test(block.text)) {
        //if text contains atleast one non-whitespace char
        const info = this.getPaddingAndType(block.type, levelArr, currLevel);
        currLevel = info.currLevel;
        indexJson.push({
          depth: block.depth,
          text: block.text.substr(0, 40),
          key: block.key,
          weight: info.type,
          levelIndex: info.levelIndex
        });
      }
    }
    return indexJson;
  }

  getIndexNumber(depthLevel, index_numberings) {
    let index = "";
    index_numberings[depthLevel]++;
    index_numberings[depthLevel + 1] = 0;
    for (let i = 0; i <= depthLevel; ++i) {
      if (index_numberings[i] === 0) index_numberings[i] = 1;
      index = index + index_numberings[i] + ".";
    }
    return index;
  }

  toolbarOptions = {
    options: [
      "blockType",
      "fontFamily",
      "fontSize",
      "inline",
      "textAlign",
      "list",
      "colorPicker",
      "link",
      "image",
      "history"
    ],
    inline: {
      options: ["bold", "italic", "underline"],
      bold: {
        className: "t-inline-icon t-common"
      },
      italic: { className: "t-inline-icon t-common" },
      underline: { className: "t-inline-icon t-common" },
      className: "t-inline"
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code"
      ],
      className: "t-blockType t-common"
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: "t-fontSize t-common"
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana"
      ],
      className: "t-fontFamily t-common"
    },
    list: {
      inDropdown: true,
      options: ["unordered", "ordered", "indent", "outdent"],
      className: "t-list t-common"
    },
    textAlign: {
      inDropdown: true,
      options: ["left", "center", "right", "justify"],
      className: "t-textAlign t-common"
    },
    colorPicker: {
      colors: [
        "rgb(97,189,109)",
        "rgb(26,188,156)",
        "rgb(84,172,210)",
        "rgb(44,130,201)",
        "rgb(147,101,184)",
        "rgb(71,85,119)",
        "rgb(204,204,204)",
        "rgb(65,168,95)",
        "rgb(0,168,133)",
        "rgb(61,142,185)",
        "rgb(41,105,176)",
        "rgb(85,57,130)",
        "rgb(40,50,78)",
        "rgb(0,0,0)",
        "rgb(247,218,100)",
        "rgb(251,160,38)",
        "rgb(235,107,86)",
        "rgb(226,80,65)",
        "rgb(163,143,132)",
        "rgb(239,239,239)",
        "rgb(255,255,255)",
        "rgb(250,197,28)",
        "rgb(243,121,52)",
        "rgb(209,72,65)",
        "rgb(184,49,47)",
        "rgb(124,112,107)",
        "rgb(209,213,216)"
      ],
      className: "t-colorPicker t-common"
    },
    link: {
      inDropdown: true,
      options: ["link", "unlink"],
      showOpenOptionOnHover: true,
      defaultTargetOption: "_self",
      className: "t-link t-common"
    },
    image: {
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      previewImage: false,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "auto"
      },
      className: "t-image t-common"
    },
    history: {
      options: []
    }
  };

  renderRedirect() {
    if (this.state.redirect === "bad-url")
      return <Redirect to={{ pathname: "/bad-url" }} />;
    else if (this.state.redirect === "new-url")
      return (
        <Redirect
          to={{
            pathname: `/insights/contract/${this.state.contract_id}`,
            state: {
              rawContent: this.state.contentState,
              title: this.state.title,
              contract_id: this.state.contract_id,
              is_owner: this.state.is_owner,
              is_editable: this.state.is_editable
            }
          }}
        />
      );
    else return null;
  }

  goBack = () => {
    this.props.history.goBack();
  };

  handleReturnToOwner = () => {
    if (this.isSaved() && this.isTitleSaved()) {
      Contract.counter_party_return_to_owner(this.state.contract_id)
        .then(res => {
          ToastsStore.success("Returned to owner");
          this.setState({ returned: true });
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      ToastsStore.warning("Please save contract before returning to owner.");
    }
  };

  renderSave() {
    if (this.state.saving)
      return (
        <div className="custom-icon-CTA save-cta-container">
          <ContentSaveOutline size="1.1vw" color="#666" />
          <p>Saving...</p>
        </div>
      );
    if (this.isSaved() && this.isTitleSaved())
      return (
        <div className="custom-icon-CTA save-cta-container">
          <p>Already Saved</p>
        </div>
      );
    else
      return (
        <div
          className="custom-icon-CTA save-cta-container"
          onClick={this.onSave}
        >
          <ContentSaveOutline size="1.1vw" color="#666" />
          <p>Save Document</p>
        </div>
      );
  }

  renderLoader() {
    return (
      <div className="editor_loader_wrapper">
        <Spinner />
      </div>
    );
  }

  renderTitle() {
    return (
      <div className="_my-editor-title">
        {this.state.titleEditingMode ? (
          <input
            ref={c => (this.title_input = c)}
            onBlur={e => this.setState({ titleEditingMode: false })}
            type="text"
            value={this.state.title}
            onChange={e => this.setState({ title: e.target.value })}
            onKeyPress={e => {
              if (e.key === "Enter") this.title_input.blur();
            }}
          />
        ) : (
          <h2
            onClick={e => {
              this.state.is_editable
                ? this.setState({ titleEditingMode: true }, () =>
                    this.title_input.focus()
                  )
                : this.setState({});
            }}
          >
            {this.state.title}
          </h2>
        )}

        {this.state.titleEditingMode ? null : (
          <FileDocumentOutline color="#4d4d4d" size="1.5vw" />
        )}
      </div>
    );
  }
  onEditorStateChange = editorState => {
    this.setState({
      editorState: editorState
    });
  };
  onDrop = e => {
    console.log("dropped");
    //this.setState({ editorState: this.addEmptyBlock(this.state.editorState) });
    // e.preventDefault();
    // console.log("");
    // console.log(
    //   "key",
    //   this.state.editorState.getCurrentContent().getSelectionAfter()
    // );
    // console.log("Droped", e);
  };

  addBlock = (direction, editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selection.getEndKey());

    const blockMap = contentState.getBlockMap();
    // Split the blocks
    const blocksBefore = blockMap.toSeq().takeUntil(function(v) {
      return v === currentBlock;
    });
    const blocksAfter = blockMap
      .toSeq()
      .skipUntil(function(v) {
        return v === currentBlock;
      })
      .rest();
    const newBlockKey = genKey();
    let newBlocks =
      direction === "before"
        ? [
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: "unstyled",
                text: ""
              })
            ],
            [currentBlock.getKey(), currentBlock]
          ]
        : [
            [currentBlock.getKey(), currentBlock],
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: "unstyled",
                text: ""
              })
            ]
          ];
    const newBlockMap = blocksBefore
      .concat(newBlocks, blocksAfter)
      .toOrderedMap();
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection
    });
    return EditorState.push(editorState, newContentState, "insert-fragment");
  };

  handleReturn = (event, editorState) => {
    if (isSelectionACommentEntity(editorState)) {
      this.setState({
        editorState: addLineBreakBetweenComments(editorState, event)
      });
      return "handled";
    }

    let selectionState = editorState.getSelection();
    let isCollapsed = selectionState.isCollapsed();
    if (event.shiftKey && isCollapsed) {
      this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
      return "handled";
    }

    let contentState = editorState.getCurrentContent();
    if (!(isCollapsed && selectionState.focusOffset == 0)) return "not-handled";
    let currentBlock = contentState.getBlockForKey(
      selectionState.getAnchorKey()
    );
    if (currentBlock.getLength() == 0) return "not-handled";

    // submit message
    console.log(this.state.editorState.getCurrentContent().getPlainText());

    this.setState({ editorState: this.addBlock("before", editorState) });

    console.log(contentState.getBlockForKey(selectionState.getAnchorKey()));
    console.log(selectionState.getAnchorKey());
    console.log(selectionState.focusOffset);

    return "handled";
  };

  renderEditor() {
    const { contentState, editorState } = this.state;

    return (
      <div ref={c => (this.editor_wrapper = c)} onDrop={this.onDrop}>
        <Editor
          hashtag={true}
          editorRef={element => {
            this.editor = element;
          }}
          editorState={this.state.editorState}
          handleReturn={this.handleReturn}
          wrapperClassName="_my-editor-wrapper"
          editorClassName={`_my-editor-editor${
            !this.state.showIndex ? "" : " _my-editor-shrinked"
          }`}
          handlePastedText={() => false}
          onEditorStateChange={this.onEditorStateChange}
          toolbarClassName="_my-editor-toolbar"
          onContentStateChange={this.onContentStateChange}
          toolbar={this.toolbarOptions}
          toolbarCustomButtons={[this.renderCutomToolBar()]}
        />
      </div>
    );
  }

  renderRightBar() {
    return (
      <div className="editor-right-bar">
        {this.state.is_editable ? (
          <div onClick={() => this.handleERBClick(this.ERB_Clause)}>
            <img src={require("../../assets/icons/clause-library.svg")} />
            <p>Clause Library</p>
          </div>
        ) : null}
        <div onClick={() => this.handleERBClick(this.ERB_Summary)}>
          <img src={require("../../assets/icons/snapshot-summary.svg")} />
          <p>Snapshot Summary</p>
        </div>
        <div onClick={() => this.handleERBClick(this.ERB_Details)}>
          <img src={require("../../assets/icons/details.svg")} />
          <p>Details</p>
        </div>
      </div>
    );
  }

  hideIndex = () => {
    this.setState({ showIndex: false }, () =>
      setTimeout(() => this.setState({ showIndex: false, index: [] }), 50)
    );
  };

  getAbsPosition = function(el) {
    var el2 = el;
    var curtop = 0;
    var curleft = 0;
    if (document.getElementById || document.all) {
      do {
        curleft += el.offsetLeft - el.scrollLeft;
        curtop += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
        el2 = el2.parentNode;
        while (el2 != el) {
          curleft -= el2.scrollLeft;
          curtop -= el2.scrollTop;
          el2 = el2.parentNode;
        }
      } while (el.offsetParent);
    } else if (document.layers) {
      curtop += el.y;
      curleft += el.x;
    }
    return [curtop, curleft];
  };

  lastKey = "";

  lastBottom = 0;

  renderCTAs() {
    return (
      <div className="editor-CTA-container">
        <div
          className="first"
          onClick={() => {
            this.handleReturnToOwner();
          }}
        >
          <img src={require("../../assets/icons/share.svg")} />
          {"Return"}
        </div>
      </div>
    );
  }

  renderIndex() {
    let index_numberings = Array(7).fill(0);

    if (this.state.showIndex)
      return (
        <div
          className={`editor-index-wrapper${
            !this.state.showIndex ? " editor-index-wrapper-collapsed" : ""
          }`}
        >
          <div
            onClick={() =>
              this.setState({ showIndex: false }, () =>
                setTimeout(
                  () => this.setState({ showIndex: false, index: [] }),
                  50
                )
              )
            }
          >
            Index <ChevronLeft size="1.4vw" color="#4d4d4d" />
          </div>
          <div>
            {this.state.index.map(index => {
              return (
                <p
                  style={{
                    fontWeight: index.weight,
                    paddingLeft: this.index_paddings[index.levelIndex],
                    display: "flex"
                  }}
                  key={index.key}
                  onClick={e => this.moveToIndex(index.key)}
                >
                  <span className={`index_text `}>
                    {`${this.getIndexNumber(
                      this.index_paddings[index.levelIndex] / 12 - 1,
                      index_numberings
                    )} ${index.text}`}
                  </span>
                </p>
              );
            })}
          </div>
        </div>
      );
    return (
      <div
        onClick={() => {
          this.setState({
            showIndex: true,
            index: this.generateIndex()
          });
        }}
        className="editor-index-CTA"
      >
        <FileDocumentOutline size="1.4vw" color="#4d4d4d" />
      </div>
    );
  }

  renderTestCTA(show = true) {
    if (show)
      return (
        <React.Fragment>
          <button
            onClick={this.onTest}
            style={{
              zIndex: "300",
              position: "fixed",
              top: "70px",
              right: "101px"
            }}
          >
            Test
          </button>
          <button
            onClick={this.onTest2}
            style={{
              zIndex: "300",
              position: "fixed",
              top: "70px",
              right: "151px"
            }}
          >
            Test2
          </button>
        </React.Fragment>
      );
    return null;
  }

  renderCutomToolBar() {
    return (
      <div className="custom-toolbar">
        {" "}
        {this.renderSave()} {this.renderCTAs()}
      </div>
    );
  }

  renderHeaderBg() {
    return <div className="editor-header-bg"></div>;
  }

  renderCloseWindow() {
    return this.state.error ? (
      <span>{this.state.error}</span>
    ) : (
      <span>
        Contract Returned Back..
        <br />
        You can close this window.
      </span>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div class={"public-editor-container"}>
          {!this.state.returned ? (
            <div class="page-container">
              {this.state.is_editable == 1 && (
                <Prompt
                  when={!(this.isSaved() && this.isTitleSaved())}
                  message="You have unsaved changes, are you sure you want to leave?"
                />
              )}
              {this.state.contractLoading && this.renderLoader()}
              <div className="_my-editor-container">
                <div className="avoid-scroll-wrapper">
                  <div
                    ref={c => (this.scroll_component = c)}
                    className="editor-main-container"
                  >
                    {this.renderTitle()}
                    {this.renderEditor()}
                  </div>
                  {this.renderIndex()}
                </div>

                {this.renderRedirect()}
              </div>
            </div>
          ) : (
            <div class="close-window">{this.renderCloseWindow()}</div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
