import React, { Component } from "react";

import {
  Col,
  Row,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  FormGroup,
  Label
} from "reactstrap";

export default class FormNewPlaybook extends Component {
  state = this.props.playbook || { playbook_name: "", contract_types: [] };

  componentDidMount() {
    console.log(this.state.Playbook);
  }
  handlePlaybookName = val => {
    this.setState({ playbook_name: val });
  };
  handleCheckMSA = e => {
    if (e.target.checked) {
      if (this.state.contract_types.includes("PA")) {
        this.setState({ contract_types: ["MSA", "PA"] });
      } else {
        this.setState({ contract_types: ["MSA"] });
      }
    } else {
      if (this.state.contract_types.includes("PA")) {
        this.setState({ contract_types: ["PA"] });
      } else {
        this.setState({ contract_types: [] });
      }
    }
  };
  handleAddPlaybook = e => {
    e.preventDefault();
    let data = {
      playbook_name: this.state.playbook_name,
      contract_types: this.state.contract_types
    };
    if (this.props.type != "new") data.id = this.state.id;
    this.props.onAdd(data, this.props.index);
  };
  handleCheckPA = e => {
    if (e.target.checked) {
      if (this.state.contract_types.includes("MSA")) {
        this.setState({ contract_types: ["MSA", "PA"] });
      } else {
        this.setState({ contract_types: ["PA"] });
      }
    } else {
      if (this.state.contract_types.includes("MSA")) {
        this.setState({ contract_types: ["MSA"] });
      } else {
        this.setState({ contract_types: [] });
      }
    }
  };
  render() {
    return (
      <form className="Playbook-form" onSubmit={e => this.handleAddPlaybook(e)}>
        <div className="row container">
          <div className="form__form-group col-lg-12">
            <span className="form__form-group-label">Play Book Name</span>
            <div className="form__form-group-field">
              <Input
                name="first_name"
                type="text"
                placeholder="IT Department Playbook"
                value={this.state.playbook_name}
                defaultValue={""}
                onChange={e => this.handlePlaybookName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-12 check">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="value"
                  value="MSA"
                  checked={this.state.contract_types.includes("MSA")}
                  onChange={e => this.handleCheckMSA(e)}
                />{" "}
                Master Service Agreement (MSA)
              </Label>
            </FormGroup>
          </div>
          <div className="col-lg-12 check">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  value="PA"
                  name="value"
                  checked={this.state.contract_types.includes("PA")}
                  onChange={e => this.handleCheckPA(e)}
                />{" "}
                Purchase Agreement (PA)
              </Label>
            </FormGroup>
          </div>
          <div className="add">
            <Button className="add-btn">
              {this.props.type == "new" ? "Add" : "Update"}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
