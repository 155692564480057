import React, { Component } from "react";
import SVG from "react-inlinesvg";

class ReactMeter extends Component {
  state = {
    value: this.props.value || 0
  };

  componentWillReceiveProps(props) {
    this.setState({ value: props.value || 0 });
  }

  calculateNeedleTranmsform() {
    let value = this.state.value;
    let start = -145;
    let end = 290;
    let final = start + end * (value / 100);
    return `rotate(${final}deg)`;
  }

  render() {
    return (
      <div className={`react-meter ${this.props.className}`}>
        <div className="inner-dial">
          <SVG
            className="circle"
            src={require("../../assets/vectors/meter-circle.svg")}
          />
          <SVG
            className="needle"
            style={{ transform: this.calculateNeedleTranmsform() }}
            src={require("../../assets/vectors/needle.svg")}
          />
        </div>
        <div className="meter-labels">
          <span className="label-0">0</span>
          <span className="label-25">25</span>
          <span className="label-50">50</span>
          <span className="label-75">75</span>
          <span className="label-100">100</span>
          <span className="label-main">
            {this.props.label + ": " + this.state.value + "%"}
          </span>
        </div>
      </div>
    );
  }
}

export default ReactMeter;
