import React, { Component } from "react";
import IconAtom from "../../assets/editor-images/AtomIcon.png";
import IconAtom2 from "../../assets/editor-images/PurpleCircle.png";
import IconAtom3 from "../../assets/editor-images/RiskCircle.svg";
import "../../scss/component/pulse_dashboard.scss";
import ContractGraph from "../../assets/images/chromaGraph.svg";
import MissionControl_Icon from "../../assets/icons/sidebar1.svg";
import PlayBook_Icon from "../../assets/icons/sidebar2.svg";
import Modeling_Icon from "../../assets/icons/sidebar3.svg";
import Pulse_Icon from "../../assets/icons/sidebar4.svg";
import SVG from "react-inlinesvg";
import throttle from "lodash.throttle";
import { ToastsStore } from "react-toasts";
import Contract from "../../api/contract/Contract";

export default class PulseDashboard extends Component {
  state = {
    data: [],
    loading: false,
    page: 1
  };
  get_value(n) {
    n = parseInt(n);
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }
  get_random_color() {
    return "hsl(" + 360 * Math.random() + "," + 75 + "%," + 40 + "%)";
  }

  load_contracts = () => {
    if (this.state.page <= 0 || this.state.loading) return;
    this.setState({ loading: true });
    Contract.get_contracts_list("MOD", this.state.page)
      .then(res => {
        const data = this.state.data;
        res.data.forEach(item => {
          const contract = item;
          contract.duration = this.get_duration(
            contract.start_at,
            contract.end_at
          );
          /*if (contract.title.length > 20)
                contract.title = contract.title.substr(0, 20) + "...";*/
          contract.value = this.get_value(contract.value);
          contract.risk = Number(contract.risk);
          contract.entity =
            contract.entity === "NG" ? "Non-Govt." : "Government";
          contract.bg_clr = this.get_random_color();
          data.push(contract);
        });
        this.setState({
          data: this.state.data,
          loading: false,
          page: res.data.length === res.per_page ? res.current_page + 1 : -1
        });
      })
      .catch(e => {
        ToastsStore.error("Failed to Load Contracts List!");
        this.setState({ loading: false });
        console.log(e);
      });
  };

  load_more = throttle(this.load_contracts, 800, {
    trailing: false
  });

  get_duration(start, end) {
    let p1 = start.split("/");
    start = new Date(Number(p1[2]), Number(p1[1]) - 1, Number(p1[0]));
    let p2 = end.split("/");
    end = new Date(Number(p2[2]), Number(p2[1]) - 1, Number(p2[0]));

    const millis = end.getTime() - start.getTime();
    const years = millis / 31536000000;
    return `${Math.round(years)} Years`;
  }
  componentDidMount() {
    this.load_contracts();
    this.page_wrapper.onscroll = e => {
      const obj = e.target;
      const height = obj.scrollHeight - obj.offsetHeight;
      const scrollPos = obj.scrollTop;
      const factor = 0.7;
      if (scrollPos >= factor * height) {
        this.load_more();
      }
    };
  }
  renderChromaStatus() {
    return (
      <div className="pulse-chromaStatusContainer">
        <div className="chromaStatsHeading">
          <p>Chroma Stats</p>
        </div>
        <div className="pulse-chromaVisualContainer">
          <div className="anim-container">
            <img className="icon-atom" src={IconAtom}></img>
            <img
              className="lightOpCircle"
              src={require("../../assets/editor-images/6.svg")}
            ></img>
            <img className="purple-circle" src={IconAtom2}></img>
            <img
              className="boxCircle"
              src={require("../../assets/editor-images/boxCircle.svg")}
            ></img>
            <img
              className="LineCircle"
              src={require("../../assets/editor-images/LineCircle.svg")}
            ></img>
            <img
              className="innerCircle"
              src={require("../../assets/editor-images/3.svg")}
            ></img>
            <img
              className="forthCircle"
              src={require("../../assets/editor-images/4.svg")}
            ></img>
            <img
              className="centerCircle"
              src={require("../../assets/editor-images/5.svg")}
            ></img>
          </div>
          <div className="riskCircle1">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">75%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Business Operations</p>
            </div>
          </div>
          <div className="dotCircle">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          {/* <div className="RiskCircleContainer"> */}
          <div className="riskCircle2">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">5%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Regulatory&nbsp;& Compliance</p>
            </div>
            {/* </div> */}
          </div>
          <div className="dotCircle1">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle3">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">75%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Asset Protection</p>
            </div>
          </div>
          <div className="dotCircle2">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle4">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">5%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Financial&nbsp;& Payments</p>
            </div>
          </div>
          <div className="dotCircle3">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle5">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">75%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Reputation</p>
            </div>
          </div>
          <div className="dotCircle4">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle6">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">5%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Other</p>
            </div>
          </div>
          <div className="dotCircle5">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="rightCross1"></div>
          <div className="rightStraight1"></div>
          <div className="rightCross2"></div>
          <div className="rightStraight2"></div>
          <div className="rightCross3"></div>
          <div className="rightStraight3"></div>
          <div className="leftCross1"></div>
          <div className="leftStraight1"></div>
          <div className="leftCross2"></div>
          <div className="leftStraight2"></div>
          <div className="leftCross3"></div>
          <div className="leftStraight3"></div>
        </div>
      </div>
    );
  }

  renderContractList() {
    return (
      <div className="contract-list-wrapper" ref={c => (this.page_wrapper = c)}>
        <div className="contract-list-heading">
          <p>Contracts List</p>
        </div>
        {this.state.data.map(item => (
          <div className="contract">
            <div className="contract-right-side">
              <div className="contract-heading">
                <p>{item.title}</p>
              </div>
              <div className="contract-details">
                <div className="right-details">
                  <div>
                    <span>Contract ID</span>
                    <span>{item.id}</span>
                  </div>
                  <div>
                    <span>Contract Value</span>
                    <span>$ {item.value}</span>
                  </div>
                  <div>
                    <span>Contract Type</span>
                    <span>{item.type}</span>
                  </div>
                </div>
                <div className="line-between"></div>
                <div className="left-details">
                  <div>
                    <span>Version</span>
                    <span>{item.version}</span>
                  </div>
                  <div>
                    <span>Entity</span>
                    <span>{item.entity}</span>
                  </div>
                  <div>
                    <span>Sector</span>
                    <span>{item.sector}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="contract-left-side">
              <img src={ContractGraph} />
            </div>
          </div>
        ))}
        {/* <div className="contract">
          <div className="contract-right-side">
            <div className="contract-heading">
              <p>Walmart Office Supply Agreement</p>
            </div>
            <div className="contract-details">
              <div className="right-details">
                <div>
                  <span>Contract ID</span>
                  <span>56501</span>
                </div>
                <div>
                  <span>Contract Value</span>
                  <span>$ 1.5M</span>
                </div>
                <div>
                  <span>Contract Type</span>
                  <span>NDA</span>
                </div>
              </div>
              <div className="line-between"></div>
              <div className="left-details">
                <div>
                  <span>Version</span>
                  <span>1.11.12</span>
                </div>
                <div>
                  <span>Entity</span>
                  <span>Government</span>
                </div>
                <div>
                  <span>Sector</span>
                  <span>Private</span>
                </div>
              </div>
            </div>
          </div>
          <div className="contract-left-side">
            <img src={ContractGraph} />
          </div>
        </div> */}
      </div>
    );
  }

  renderButtonBlock() {
    return (
      <div className="button-container">
        <div className="btn-div">
          <SVG src={MissionControl_Icon} />
          {/* <img src={MissionControl_Icon} /> */}
          <p>Mission Control</p>
        </div>
        <div className="btn-div">
          <SVG src={PlayBook_Icon} />
          <p>Playbook</p>
        </div>
        <div className="btn-div">
          <SVG src={Modeling_Icon} />
          <p>Modeling</p>
        </div>
        <div className="btn-div">
          <SVG src={Pulse_Icon} />
          <p>Work Folder</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="_page-container">
        <div className="main-heading">
          <h1>Pulse Dashboard</h1>
        </div>
        <div className="pulse-container">
          <div className="sub-pulse-container">
            {this.renderChromaStatus()}
            {this.renderButtonBlock()}
          </div>
          {this.renderContractList()}
        </div>
      </div>
    );
  }
}
