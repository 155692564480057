import React, { Component } from "react";
import MyContract from "../editor/MyContract";

class ModellingContract extends Component {
  componentDidMount() {}
  render() {
    return <MyContract {...this.props} />;
  }
}

export default ModellingContract;
