import React, { Component } from "react";
import MyContract from "../editor/MyContract";

class InsightContract extends Component {
  componentDidMount() {}
  render() {
    return <MyContract {...this.props} />;
  }
}

export default InsightContract;
