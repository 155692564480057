import React, { Component } from "react";
import VerticalLine from "../VerticalLine";
import HorizontalLine from "../HorizontalLine";

import { width } from "@material-ui/system";

export default class ContractHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: this.props.contract
    };
  }

  diff_years(dt1) {
    let p1 = dt1.split("/");
    dt1 = new Date(Number(p1[2]), Number(p1[1]) - 1, Number(p1[0]));
    let dt2 = new Date();

    var diff = dt2.getTime() - dt1.getTime();
    diff /= 3.154e10;
    return Math.round(diff * 10) / 10;
  }
  getNameInitials(name) {
    const arr = name.split(" ");
    return arr[0][0] + (arr.length > 1 ? arr[1][0] : "");
  }

  render() {
    const data = this.state.contract;
    return (
      <div className="contract-header-container">
        <div className="company-section">
          <div className="logo-box">
            <div style={{ backgroundColor: data.bg_clr }}>
              {this.getNameInitials(data.counter_party_name)}
            </div>
            <span className="footer-name normal-text">
              {data.counter_party_name}
            </span>
          </div>
        </div>

        <div className="valuation-section">
          <div className="normal-text">Contract Valuation</div>
          <div className="emphasized-text">{"$" + data.value}</div>
          <HorizontalLine className="horizontal-line" />
          <div className="normal-text">Type</div>
          <div className="emphasized-text">{data.type}</div>
        </div>
        <VerticalLine className="vertical-line" />

        <div className="version-section">
          <div>
            <span className="bold-text">Version</span>
            <span className="normal-text">{data.version}</span>
          </div>
          <div>
            <span className="bold-text">Entity</span>
            <span className="normal-text">{data.entity}</span>
          </div>
          <div>
            <span className="bold-text">Sector</span>
            <span className="normal-text">{data.sector}</span>
          </div>
        </div>
        <VerticalLine className="vertical-line" />

        <div className="duration-section">
          <div className="normal-text">Duration</div>
          <div className="emphasized-text">{data.duration}</div>
        </div>
        <VerticalLine className="vertical-line" />

        <div className="date-section">
          <div className="date-container">
            <div>
              <span className="bold-text">Start Date</span>
              <span className="normal-text">{data.start_at}</span>
            </div>
            <div>
              <span className="bold-text">End Date</span>
              <span className="normal-text">{data.end_at}</span>
            </div>
          </div>
          {/* <div className="risk-meter">
            <div className="risk-score">{`${data.risk}%`}</div>
            <ReactSpeedometer
              needleHeightRatio={0.45}
              maxSegmentLabels={0}
              ringWidth={16}
              fluidWidth={true}
              valueTextFontSize={"0px"}
              needleColor={"#666"}
              segments={1000}
              value={data.risk * 10}
              endColor={"#eb1515"}
              startColor={"#3fe821"}
            />
            <div>Risk Score</div>
          </div> */}
        </div>
        <div onClick={this.props.onClick} className="contract-time">
          <div className="progress_text">
            {this.diff_years(data.start_at)} / {data.duration}
          </div>
          <span className="progress_bar">
            <span
              className="progress_bar_filled"
              style={{
                width:
                  (this.diff_years(data.start_at) / parseInt(data.duration)) *
                    100 +
                  "%"
              }}
            ></span>
          </span>
        </div>
        {/* <div className="_arrow-down">
          <ChevronDown color="#fff" size="20px" />
        </div> */}
      </div>
    );
  }
}
