import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import Store from "../../../store";
import { ToastsStore } from "react-toasts";

class AssignModal extends Component {
  state = {
    error: null
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    console.log(prevProps);
  }

  assignUser = user => {
    this.props.onAssignUser(user.id);
  };

  forwardUser = user => {
    this.props.onForwardUser(user.id);
  };

  addSecondParty = input => {
    if (input.checkValidity()) this.props.onAddSecondParty(input.value);
    else {
      ToastsStore.error("Invalid email address");
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showAssignModal}
        /* / toggle={this.props.toggle} */
        className={"assign-modal "}
        centered
      >
        {this.props.loading && (
          <div className="loader-wrapper">
            <div
              style={{
                display: "inline-block",
                alignSelf: "center",
                margin: "auto"
              }}
            >
              <Spinner style={{ color: "#473080" }} />
            </div>
          </div>
        )}

        <ModalHeader toggle={this.props.toggle}>Sharing Settings</ModalHeader>
        <ModalBody>
          <div className="assign-user-container">
            <span className="label">Unassigned Users</span>

            {this.props.unassignedUsers.map(user => {
              return (
                <div className="assign-user-item">
                  <img src={Store.userAvatar} />
                  <span>{user.first_name + " " + user.last_name}</span>
                  <span class="button" onClick={() => this.assignUser(user)}>
                    Assign
                  </span>
                </div>
              );
            })}

            {this.props.assignedUsers &&
              this.props.unassignedUsers.length == 0 && (
                <div className="assign-user-no-item">
                  <span>No user available to assign</span>
                </div>
              )}
          </div>
          <div className="assign-user-container">
            <span className="label">Assigned Users</span>

            {this.props.assignedUsers.map(user => {
              return (
                <div className="assign-user-item">
                  <img src={Store.userAvatar} />
                  <span>{user.first_name + " " + user.last_name}</span>
                  <span class="button fw" onClick={e => this.forwardUser(user)}>
                    Forward
                  </span>
                </div>
              );
            })}

            {this.props.assignedUsers && this.props.assignedUsers.length == 0 && (
              <div className="assign-user-no-item">
                <span>No user assigned yet.</span>
              </div>
            )}
          </div>
          <div className="assign-user-container">
            <span className="label">Counter Party Users</span>

            <div className="assign-user-item">
              <span>
                <label>Email:</label>
                <input
                  required
                  type={"email"}
                  ref={e => (this.secondparty = e)}
                ></input>
              </span>
              <span
                class="button fw"
                onClick={e => this.addSecondParty(this.secondparty)}
              >
                Add
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default AssignModal;
