import React, { PureComponent } from "react";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import TextTruncate from "react-text-truncate";

export const AnalyzeNotification = props => {
  const { color, title, alert } = props;
  let stage = alert.metadata.stage;
  let type = alert.metadata.type;
  let subType = alert.metadata.sub_type;
  let message =
    type == "newsfeed_article"
      ? alert.data
        ? alert.data.title + ": " + alert.data.description
        : ""
      : type == "variable"
      ? `${alert.alert_message}. Rule Break: ${
          alert.data.ruleBreak ? "True" : "False"
        }`
      : type == "risk_analyzer_engine"
      ? `Buyer Intensity: ${alert.data.buyerIntenstiy}\nSeller Intensity: ${alert.data.sellerIntenstiy}`
      : type == "court_ruling"
      ? alert.data.rulings.length > 0
        ? alert.data.rulings[0]
        : ""
      : alert.alert_message;

  return (
    <div className={`notification`}>
      <div
        className={`notification__border ${
          stage == "pulse"
            ? "bg_orange"
            : stage == "modeling"
            ? "bg_red"
            : stage == "playbook"
            ? "bg_red"
            : "bg_green"
        }`}
      ></div>
      <div className={`notification__content`}>
        <h5
          className={`notification__title ${
            stage == "pulse"
              ? "orange"
              : stage == "modeling"
              ? "red"
              : stage == "playbook"
              ? "red"
              : "green"
          }`}
        >
          {" "}
          {stage} {type.replace("_", " ")}
        </h5>
        <p className="notification__message">{message}</p>
      </div>
    </div>
  );
};
