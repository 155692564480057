import React, { Component } from "react";
import VerticalLine from "../VerticalLine";
import HorizontalLine from "../HorizontalLine";
import ReactSpeedometer from "react-d3-speedometer";
import ChevronDown from "mdi-react/ChevronDownIcon";
import Cached from "mdi-react/CachedIcon";

import ReactMeter from "../ReactMeter";
import ContractImage from '../../../assets/images/ContractImage.png';
// import "./contractsListItem.scss";

export default class ContractsListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: this.props.contract,
      isFliped: false,
      toggleImage:false
    };
  }

  getNameInitials(name) {
    const arr = name.split(" ");
    return arr[0][0] + (arr.length > 1 ? arr[1][0] : "");
  }

  toogleFlip = () => {
    this.setState({
      isFliped: !this.state.isFliped
    });
  };
  toggleImage=()=>{
    this.setState({
      toggleImage: !this.state.toggleImage,
      isFliped: !this.state.isFliped
    });
  };

  render() {
    const data = this.state.contract;

    if(this.state.toggleImage){
      return(
        <div className="contract-image-container">
          <img src={ContractImage} className='contract-image' style={{width:'82%'}}/>
          <div onClick={this.props.onClick} className="contract-title">
          <p class="_title-content">{data.title}</p>
          <VerticalLine className="vertical-line-title" />
          <div id="_id">{"ID " + data.id}</div>
        </div>
        <div className="_arrow-down centered" onClick={this.toggleImage}>
          <ChevronDown color="#fff" size="20px" />
        </div>
        <div
          className="_arrow-rotate centered"
          onClick={e => this.toogleFlip()}
        >
          <Cached size="20px" />
        </div>
        </div>
      )
    }else{
    return (
      
      <div className="contract-item-container">
        {/* {this.state.toggleImage ? 
        (
        <div className='contract-image'>
          <img src={ContractImage} className='contract-image'/>
        </div>)
        :
        // null}
        (<div> */}
        {!this.state.isFliped ? (
          <div className="contract-details">
            <div className="company-section">
              <div className="logo-box">
                <div style={{ backgroundColor: data.bg_clr }}>
                  {this.getNameInitials(data.counter_party_name)}
                </div>
              </div>
              <div className="footer-name normal-text">
                <span>{data.counter_party_name}</span>
              </div>
            </div>

            <div className="valuation-section">
              <div className="normal-text">Contract Valuation</div>
              <div className="emphasized-text">{"$" + data.value}</div>
              <HorizontalLine className="horizontal-line" />
              <div className="normal-text">Type</div>
              <div className="emphasized-text">{data.type}</div>
            </div>
            <VerticalLine className="vertical-line" />

            <div className="version-section">
              <div>
                <span className="bold-text">Version</span>
                <span className="normal-text">{data.version}</span>
              </div>
              <div>
                <span className="bold-text">Entity</span>
                <span className="normal-text">{data.entity}</span>
              </div>
              <div>
                <span className="bold-text">Sector</span>
                <span className="normal-text">{data.sector}</span>
              </div>
            </div>
            <VerticalLine className="vertical-line" />

            <div className="duration-section">
              <div className="normal-text">Duration</div>
              <div className="emphasized-text">{data.duration}</div>
            </div>
            <VerticalLine className="vertical-line" />

            <div className="date-section">
              <div className="date-container">
                <div>
                  <span className="bold-text">Start Date</span>
                  <span className="normal-text">{data.start_at}</span>
                </div>
                <div>
                  <span className="bold-text">End Date</span>
                  <span className="normal-text">{data.end_at}</span>
                </div>
              </div>
              <div className="risk-meter">
                {/* <div className="risk-score">{`${data.risk}%`}</div> */}

                <ReactMeter
                  className={""}
                  label="Risk"
                  value={data.risk_score}
                />
                {/* <ReactSpeedometer
              needleHeightRatio={0.45}
              maxSegmentLabels={0}
              ringWidth={16}
              fluidWidth={true}
              valueTextFontSize={"0px"}
              needleColor={"#666"}
              segments={1000}
              value={data.risk * 10}
              endColor={"#eb1515"}
              startColor={"#3fe821"}
            /> */}
                <div className={"risk-score"}>Risk Score</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="contract-summary">
            <div className={"section"}>
              <h1>Contract Purpose</h1>
              <p>hi</p>
              <h1>Which Template</h1>
              <p>hi</p>
              <h1>Key Business</h1>
              <p>hi</p>
            </div>
            <VerticalLine className="vertical-line" />
            <div className={"section"}>
              <h1>Key Business Opportunities</h1>
              <p>hi</p>
              <h1>Non-Standard Term</h1>
              <p>hi</p>
              <h1>Termination Options</h1>
              <p>hi</p>
            </div>
            <VerticalLine className="vertical-line" />
            <div className={"section"}>
              <h1>IP Protected</h1>
              <p>hi</p>
              <h1>Misc Problem</h1>
              <p>hi</p>
              <h1>Related Documents</h1>
              <p>hi</p>
            </div>
            <VerticalLine className="vertical-line" />
            <div className={"section"}>
              <h1>Counter Party Country Name</h1>
              <p>hi</p>
              <h1>Counter Party Name</h1>
              <p>hi</p>
              <h1>Other Parties to Contract</h1>
              <p>hi</p>
            </div>
          </div>
        )}
        {/* </div>)} */}

        <div onClick={this.props.onClick} className="contract-title">
          <p class="_title-content">{data.title}</p>
          <VerticalLine className="vertical-line-title" />
          <div id="_id">{"ID " + data.id}</div>
        </div>
        <div className="_arrow-down centered" onClick={this.toggleImage}>
          <ChevronDown color="#fff" size="20px" />
        </div>
        <div
          className="_arrow-rotate centered"
          onClick={e => this.toogleFlip()}
        >
          <Cached size="20px" />
        </div>
      </div>
    );
  }
}
}
