import React from "react";
import ToolTip from "../ToolTip";
export const EntityComponent = ({
  offsetkey,
  children,
  entityKey,
  contentState
}) => {
  console.log("meez", entityKey);
  let data = contentState.getEntity(entityKey).getData();
  let alert = data.alert;
  let stage = alert && alert.metadata.stage;
  let type = alert && alert.metadata.type;
  let subType = alert && alert.metadata.sub_type;
  let message =
    type == "risk_analyzer_engine"
      ? `Intensity: ${alert.data.intensity}`
      : type == "legislation"
      ? `Risk: ${alert.data.risk}, Score: ${alert.data.score},\n\r${alert.alert_message}`
      : stage == "playbook" && type == "variable"
      ? `${alert.alert_message.split("_").join(" ")}.\n Rule Break: ${
          alert.data.ruleBreak ? "True" : "False"
        }`
      : alert
      ? alert.alert_message
      : "<NO ALERT MSG>";

  let secTitle =
    type == "risk_analyzer_engine"
      ? alert && alert.data.clause
      : alert && alert.alert_message;

  let colorClass = ` ${
    stage == "pulse" && type == "sanction" && subType == "risk"
      ? "bg-red"
      : stage == "modeling" && type == "risk_analyzer_engine"
      ? alert.data.intensity <= 10
        ? "bg-dark-green"
        : alert.data.intensity <= 25
        ? "bg-green"
        : alert.data.intensity <= 49
        ? "bg-yellow"
        : alert.data.intensity <= 69
        ? "bg-orange"
        : alert.data.intensity <= 89
        ? "bg-red"
        : "bg-dark-red"
      : stage == "pulse" && type == "legislation"
      ? alert.data.risk <= 10
        ? "bg-dark-green"
        : alert.data.risk <= 25
        ? "bg-green"
        : alert.data.risk <= 49
        ? "bg-yellow"
        : alert.data.risk <= 69
        ? "bg-orange"
        : alert.data.risk <= 89
        ? "bg-red"
        : "bg-dark-red"
      : stage == "playbook" && type == "variable"
      ? "bg-dark-red"
      : "bg-gray"
  }`;

  let commentSpan = (
    <span
      className={`${data.spanId ? "inline-comment-span" : ""} ${
        data.alert && data.highlight ? "with-highlight" : "without-highlight"
      }`}
      data-span-id={data.spanId}
      data-alert-id={data.alertId}
      data-offset-key={offsetkey}
      data-entity-key={entityKey}
    >
      {children}
    </span>
  );

  let toRender =
    data.alert && data.highlight ? (
      <ToolTip
        alert={data.alert}
        title={message}
        bdColorClass={colorClass.replace("bg", "bd")}
      >
        <span className={`highlight-span ${colorClass}`}>{commentSpan}</span>
      </ToolTip>
    ) : (
      commentSpan
    );

  return toRender;
};
