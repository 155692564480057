import React, { Component } from "react";

import LogoBox from "./LogoBox";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import WorkSpaceSideBar from "../workSpaceSideBar/workSpaceSideBar";

export default class Layout extends Component {
  state = {
    expanded: false
  };

  toggleSidebar = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <div className="main-container">
        <div
          className={`sideBar${this.state.expanded ? " sideBar-expanded" : ""}`}
        >
          <div className="logo-box-container">
            <LogoBox toggleSidebar={this.toggleSidebar} />
          </div>
          <SideBar toggleSidebar={this.toggleSidebar} />
        </div>
        <div className="right-layout">
          <TopBar />
          <div className="page-container">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
