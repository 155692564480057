import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import "./scss/app.scss";
import ScrollToTop from "./components/router/ScrollToTop";
import Router from "./components/router/Router";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import Store from "./store";

export default class App extends Component {
  componentDidMount() {
    Store.Toast = ToastsStore;
  }

  componentWillUnmount() {
    Store.Toast = null;
  }

  render() {
    return (
      <BrowserRouter>
        <ToastsContainer
          position={ToastsContainerPosition.TOP_CENTER}
          store={ToastsStore}
        />
        <ScrollToTop>
          <Router />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}
