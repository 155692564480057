import React from "react";
import { withStyles } from "@material-ui/core/styles";
import myModule from "@material-ui/core/Fab";
import { green, purple } from "@material-ui/core/colors";

const ColorButton = withStyles(theme => ({
  root: {
    color: "#473080",
    backgroundColor: "#473080",
    "&:hover": {
      backgroundColor: "#473080"
    }
  }
}))(myModule);

export default function Fab(props) {
  return <ColorButton {...props}></ColorButton>;
}
