import React, { Component } from "react";

import SignatureCanvas from "react-signature-canvas";
import Btn from "../Button";
import User from "../../../api/user/User";

import { ToastsStore } from "react-toasts";
import { Spinner } from "reactstrap";
class Signature extends Component {
  state = {
    signature: null
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    User.get_user_signature()
      .then(res => {
        console.log(res);
        this.setState({ signature: res, loading: 0 });
      })
      .catch(e => {
        console.log(e);
        this.setState({ loading: false });
        if (
          e.error
            ? e.error.message == "No Signature found for this user!"
            : false
        ) {
        } else {
          ToastsStore.error(
            e.error ? e.error.message : "Error fetching Signature."
          );
        }
      });
  };

  approveContract = id => {
    this.props.onApproveContract(id);
  };

  uploadSignature = data => {
    User.upload_user_signature({
      signature_base64: data
    })
      .then(res => {
        console.log(res);
        this.approveContract(res.signature_id);
      })
      .catch(e => {
        console.log(e);
        ToastsStore.error(
          e.error ? e.error.message : "Error Uploading Signature."
        );
      });
  };

  onClearSign = () => {
    this.sigCanvas.clear();
  };

  onApprove = () => {
    if (!this.state.signature && this.sigCanvas.isEmpty()) {
      ToastsStore.error("Please draw the sign first.");
    } else if (this.state.signature) {
      this.approveContract(this.state.signature.id);
    } else {
      this.uploadSignature(
        this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
      );
    }
  };

  render() {
    return (
      <div class={"signature-container"}>
        {this.state.loading ? (
          <div className="editor_loader_wrapper">
            <Spinner style={{ color: "#473080" }} />
          </div>
        ) : (
          <React.Fragment>
            {this.state.signature ? (
              <div className={"signature-image"}>
                <img
                  src={
                    this.state.signature
                      ? this.state.signature.signature_image
                      : ""
                  }
                />
              </div>
            ) : (
              <React.Fragment>
                <span className={"signature-line"}></span>
                <span>Create your signature</span>
                <SignatureCanvas
                  ref={ref => {
                    this.sigCanvas = ref;
                  }}
                  minWidth={1.5}
                  maxWidth={1.5}
                  canvasProps={{
                    className: "signature-canvas"
                  }}
                />
              </React.Fragment>
            )}

            <div className={"btn-container"}>
              {!this.state.signature ? (
                <Btn.primary
                  className={"_clear btn-ghost"}
                  onClick={() => this.onClearSign()}
                >
                  Clear
                </Btn.primary>
              ) : null}
              <Btn.primary onClick={() => this.onApprove()}>
                Approve
              </Btn.primary>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Signature;
