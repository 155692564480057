import Store from "../../store";
import utils from "../common/utils";
import error from "../common/Error";

const BASE_URL = Store.BASE_URL;

const get_clause_list = page => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/clauses?page=${page}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                console.log("clause sy", page);
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_clause_list_by_keyword = (keyword, page) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(
          `${BASE_URL}/v1/clauses/search/?keyword=${keyword}&page=${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        )
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const Clause = {
  get_clause_list,
  get_clause_list_by_keyword
};

export default Clause;
