import { config } from "react-transition-group";

const styleMap = {
  px8: {
    fontSize: "8px"
  },
  px9: {
    fontSize: "9px"
  },
  px10: {
    fontSize: "10px"
  },
  px11: {
    fontSize: "11px"
  },
  px12: {
    fontSize: "12px"
  },
  px14: {
    fontSize: "14px"
  },
  px16: {
    fontSize: "16px"
  },
  px18: {
    fontSize: "18px"
  },
  px24: {
    fontSize: "24px"
  },
  px30: {
    fontSize: "30px"
  },
  px36: {
    fontSize: "36px"
  },
  px48: {
    fontSize: "48px"
  },
  px60: {
    fontSize: "60px"
  },
  px72: {
    fontSize: "72px"
  },
  px96: {
    fontSize: "96px"
  },
  TimesNewRoman: {
    fontFamily: "Times New Roman"
  },
  Georgia: {
    fontFamily: "Goergia"
  },
  Impact: {
    fontFamily: "Impact"
  },
  Tahoma: {
    fontFamily: "Tahoma"
  },
  Verdana: {
    fontFamily: "Verdana"
  },
  Arial: {
    fontFamily: "Arial"
  },
  color1: {
    color: "rgb(97,189,109)"
  },
  color2: {
    color: "rgb(26,188,156)"
  },
  color3: {
    color: "rgb(84,172,210)"
  },
  color4: {
    color: "rgb(44,130,201)"
  },
  color5: {
    color: "rgb(147,101,184)"
  },
  color6: {
    color: "rgb(71,85,119)"
  },
  color7: {
    color: "rgb(204,204,204)"
  },
  color8: {
    color: "rgb(65,168,95)"
  },
  color9: {
    color: "rgb(0,168,133)"
  },
  color10: {
    color: "rgb(61,142,185)"
  },
  color11: {
    color: "rgb(41,105,176)"
  },
  color12: {
    color: "rgb(85,57,130)"
  },
  color13: {
    color: "rgb(40,50,78)"
  },
  color14: {
    color: "rgb(0,0,0)"
  },
  color15: {
    color: "rgb(247,218,100)"
  },
  color16: {
    color: "rgb(251,160,38)"
  },
  color17: {
    color: "rgb(235,107,86)"
  },
  color18: {
    color: "rgb(226,80,65)"
  },
  color19: {
    color: "rgb(163,143,132)"
  },
  color20: {
    color: "rgb(239,239,239)"
  },
  color21: {
    color: "rgb(255,255,255)"
  },
  color22: {
    color: "rgb(250,197,28)"
  },
  color23: {
    color: "rgb(243,121,52)"
  },
  color24: {
    color: "rgb(209,72,65)"
  },
  color25: {
    color: "rgb(184,49,47)"
  },
  color26: {
    color: "rgb(124,112,107)"
  },
  color27: {
    color: "rgb(209,213,216)"
  },
  hcolor1: {
    backgroundColor: "rgb(97,189,109)"
  },
  hcolor2: {
    backgroundColor: "rgb(26,188,156)"
  },
  hcolor3: {
    backgroundColor: "rgb(84,172,210)"
  },
  hcolor4: {
    backgroundColor: "rgb(44,130,201)"
  },
  hcolor5: {
    backgroundColor: "rgb(147,101,184)"
  },
  hcolor6: {
    backgroundColor: "rgb(71,85,119)"
  },
  hcolor7: {
    backgroundColor: "rgb(204,204,204)"
  },
  hcolor8: {
    backgroundColor: "rgb(65,168,95)"
  },
  hcolor9: {
    backgroundColor: "rgb(0,168,133)"
  },
  hcolor10: {
    backgroundColor: "rgb(61,142,185)"
  },
  hcolor11: {
    backgroundColor: "rgb(41,105,176)"
  },
  hcolor12: {
    backgroundColor: "rgb(85,57,130)"
  },
  hcolor13: {
    backgroundColor: "rgb(40,50,78)"
  },
  hcolor14: {
    backgroundColor: "rgb(0,0,0)"
  },
  hcolor15: {
    backgroundColor: "rgb(247,218,100)"
  },
  hcolor16: {
    backgroundColor: "rgb(251,160,38)"
  },
  hcolor17: {
    backgroundColor: "rgb(235,107,86)"
  },
  hcolor18: {
    backgroundColor: "rgb(226,80,65)"
  },
  hcolor19: {
    backgroundColor: "rgb(163,143,132)"
  },
  hcolor20: {
    backgroundColor: "rgb(239,239,239)"
  },
  hcolor21: {
    backgroundColor: "rgb(255,255,255)"
  },
  hcolor22: {
    backgroundColor: "rgb(250,197,28)"
  },
  hcolor23: {
    backgroundColor: "rgb(243,121,52)"
  },
  hcolor24: {
    backgroundColor: "rgb(209,72,65)"
  },
  hcolor25: {
    backgroundColor: "rgb(184,49,47)"
  },
  hcolor26: {
    backgroundColor: "rgb(124,112,107)"
  },
  hcolor27: {
    backgroundColor: "rgb(209,213,216)"
  },
  leftAlign: {
    textAlign: "left !important"
  }
};

const myBlockStyleFn = contentBlock => {
  const data = contentBlock.getData();

  const type = data.has("text-align") ? data.get("text-align") : "left";

  console.log("TYPE", type);

  switch (type) {
    case "right":
      return "align-right";
    case "left":
      return "align-left";
    case "justify":
      return "align-justify";
    default:
      return;
  }
};

export { myBlockStyleFn, styleMap };
