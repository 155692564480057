import React, { Component } from "react";
import {
  Dropdown as Dropdown2,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import ChevronDownIcon from "mdi-react/ChevronDownIcon";

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      value: this.props.value
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setState({ value: this.props.value });
  }

  render() {
    const items = this.props.items || [];
    return (
      <Dropdown2 isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle>
          {this.state.value} <ChevronDownIcon />
        </DropdownToggle>
        <DropdownMenu>
          {items.map(item => (
            <div>
              <DropdownItem onClick={this.select}>{item}</DropdownItem>
              <span></span>
            </div>
          ))}
        </DropdownMenu>
      </Dropdown2>
    );
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  select = event => {
    let value = event.target.innerText;
    console.log(value);
    this.setState(
      {
        dropdownOpen: !this.state.dropdownOpen,
        value: value
      },
      () => {
        this.props.onChange(value);
      }
    );
  };
}
