import React, { Component, useState } from "react";
import "./Playbook.scss";
import Btn from "../common/Button";
import Store from "../../store";
import { ToastsStore } from "react-toasts";
import utils from "../../api/common/utils";
import Modal from "../common/Modal";
import ModalLang from "../common/Modal";
import ProfileApi from "../../api/profile/Profile";
import Playbook from "../../api/playbook/Playbook";
import Auth from "../../api/auth/Auth";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import {
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  Col,
  Row,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  FormGroup,
  Label
} from "reactstrap";
import HeadingContainer from "../layout/HeadingContainer";
import FormRule from "./FormRule";
import FormNewPlaybook from "./FormNewPlaybook";

export default class MyPlaybookDetail extends Component {
  state = {
    currentVariable: {},
    loading: true,
    toggleAction: false,
    toggleState: false,
    isRequire: false,
    isIgnore: false,
    addingNewPlayBook: false,
    status: ".active",
    playbookId: parseInt(this.props.location.pathname.split("/").pop()),
    playbook: this.props.location.state
      ? this.props.location.state.playbook
      : {},
    currentPage: 1,
    firstPage: 1,
    lastPage: 1,
    totalEntries: 0,
    fromEntries: 0,
    toEntries: 0,
    variable_list: []
  };

  componentDidMount() {
    this.loadVariableList();
  }

  loadVariableList = (page = 1) => {
    this.setState({ loading: true });
    if (this.state.playbookId) {
      Playbook.get_variable_list_for_playbook(
        this.state.playbookId,
        page,
        "active"
      )
        .then(body => {
          // body = body[1];
          this.setState({
            variable_list: body.data,
            toEntries: body.to,
            fromEntries: body.from,
            currentPage: body.current_page,
            lastPage:
              body.total != 0 ? Math.ceil(body.total / body.per_page) : 1,
            totalEntries: body.total,
            loading: false
          });
        })
        .catch(e => {
          this.setState({ loading: false });
          ToastsStore.error(e.error ? e.error.message : "Error Occured");
        });
    } else {
      this.setState({ loading: false });
      ToastsStore.error("playbook not found");
    }
    if (!this.state.playbook) {
    }
  };

  handleRequire = () => {
    this.setState({ is_required: !this.state.variable_list.is_required });
  };

  handleIgnore = () => {
    this.setState({ is_ignore: !this.state.variable_list.is_ignore });
  };

  handleInactivePlaybookVariable = (variable, index) => {
    Playbook.inactive_specific_variable(variable.id)
      .then(body => {
        this.loadVariableList(this.state.currentPage);
      })
      .catch(e => {
        console.log(e);
        ToastsStore.error(
          e.error.message ? e.error.message : "Cannot Inactive Playbook"
        );
      });
  };

  handleEditPlaybookVariable = variable => {
    this.setState({ currentVariable: variable }, () => {
      this.editRule.toggle();
    });
  };

  handleNextPage = () => {
    if (this.state.currentPage == this.state.lastPage || this.state.loading)
      return;
    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.loadVariableList(this.state.currentPage);
    });
  };
  handleGotoPage = diff => {
    if (this.state.loading) return;
    this.setState({ currentPage: this.state.currentPage + diff }, () => {
      this.loadVariableList(this.state.currentPage);
    });
  };
  handlePreviousPage = () => {
    if (this.state.currentPage == 1 || this.state.loading) return;
    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.loadVariableList(this.state.currentPage);
    });
  };

  onAddPlaybookVariable = data => {
    console.log(data);
    let dataNew = Object.assign({}, data);
    dataNew.sub_variables = dataNew.sub_variables.map(i => {
      delete i.key;
      if (i.variable_type == "text") {
        delete i.variable_value.range;
        delete i.variable_value.option;
      } else if (i.variable_type == "value") {
        delete i.variable_value.text;
        delete i.variable_value.option;
      } else if (i.variable_type == "selection") {
        delete i.variable_value.text;
        delete i.variable_value.range;
      }
      return i;
    });
    delete data.id;
    if (this.state.addingNewPlayBook) {
      return;
    }
    Playbook.add_new_variable_for_playbook(this.state.playbookId, dataNew)
      .then(body => {
        ToastsStore.success("Sucess");
        this.addRule.toggle();
        this.loadVariableList(this.state.lastPage);
      })
      .catch(e => {
        if (e.error && e.error.message) ToastsStore.error(e.error.message);
      });
  };

  onUpdatePlaybookVariable = (data, index) => {
    console.log(data);
    let id = data.id;
    let dataNew = Object.assign({}, data);
    delete dataNew.id;
    dataNew.sub_variables = dataNew.sub_variables.map(i => {
      delete i.key;
      if (i.variable_type == "text") {
        delete i.variable_value.range;
        delete i.variable_value.option;
      } else if (i.variable_type == "value") {
        delete i.variable_value.text;
        delete i.variable_value.option;
      } else if (i.variable_type == "selection") {
        delete i.variable_value.text;
        delete i.variable_value.range;
      }
      return i;
    });

    Playbook.edit_specific_variable(id, dataNew)
      .then(body => {
        ToastsStore.success(body.message);
        this.loadVariableList(this.state.currentPage);
        this.editRule.toggle();
      })
      .catch(e => {
        ToastsStore.error(e.error.message);
      });
  };

  renderNewRule() {
    return (
      <Modal cname="model-rule" ref={c => (this.addRule = c)}>
        <ModalHeader
          scrollable={true}
          className="modal-header"
          toggle={() => this.addRule.toggle()}
          centered
        >
          Add New Rule
        </ModalHeader>
        <ModalBody>
          <FormRule
            type="new"
            onAdd={data => this.onAddPlaybookVariable(data)}
          />
        </ModalBody>
      </Modal>
    );
  }

  renderEditRule() {
    return (
      <Modal ref={c => (this.editRule = c)} cname="model-rule">
        <ModalHeader
          scrollable={true}
          className="modal-header"
          toggle={() => this.editRule.toggle()}
          centered
        >
          Add New Rule
        </ModalHeader>
        <ModalBody>
          <FormRule
            type="edit"
            variable={this.state.currentVariable}
            onAdd={data => this.onUpdatePlaybookVariable(data)}
          />
        </ModalBody>
      </Modal>
    );
  }

  renderPlaybookVariables() {
    return (
      <div className="renderplaybook">
        {this.state.loading ? (
          <div
            style={{
              display: "inline-block",
              alignSelf: "center",
              margin: "auto"
            }}
          >
            <Spinner style={{ color: "#473080" }} />
          </div>
        ) : this.state.variable_list.length == 0 ? (
          <div
            style={{
              display: "inline-block",
              alignSelf: "center",
              margin: "auto"
            }}
          >
            No Records
          </div>
        ) : (
          <table>
            <tr>
              <th>Provision</th>
              <th>Concept</th>
              <th className="green">Require</th>
              <th className="red">Unacceptable</th>
              <th className="blue">Specific</th>
              <th>Ignore</th>
              <th>Apply to</th>
              <th>Action</th>
            </tr>
            {this.state.variable_list.map((data, i) => {
              return (
                <PlaybookVariableItem
                  index={i}
                  canEdit={utils.isUserAuthority()}
                  playbookVariable={data}
                  handleIgnore={this.handleIgnore}
                  handleRequire={this.handleRequire}
                  handleEditPlaybookVariable={this.handleEditPlaybookVariable}
                  handleInactivePlaybookVariable={
                    this.handleInactivePlaybookVariable
                  }
                />
              );
            })}
          </table>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="playbook-heading">
        <div className="p-heading">
          <h1>
            {this.state.playbook
              ? this.state.playbook.playbook_name + `'s Variables`
              : "x"}
          </h1>
          <div className="add-new" onClick={() => this.addRule.toggle()}>
            <span>+ </span> Add New Variable
          </div>
        </div>
        <div className="_page-container">
          <div className="playbook-content-section">
            {this.renderPlaybookVariables()}
          </div>

          <div className="Playbook-last-section">
            <div className="showing">{`Showing ${this.state.fromEntries ||
              "0"} to ${this.state.toEntries || "0"} of ${this.state
              .totalEntries || "0"} Entries`}</div>
            <div className="page-numbers">
              {
                <div
                  className={`prev ${
                    this.state.currentPage == 1 ? "gray" : ""
                  }`}
                  onClick={e => this.handlePreviousPage()}
                >
                  Previous Page
                </div>
              }
              {this.state.currentPage > 2 && (
                <div className="p2" onClick={e => this.handleGotoPage(-2)}>
                  {this.state.currentPage - 2}
                </div>
              )}
              {this.state.currentPage > 1 && (
                <div className="p2" onClick={e => this.handleGotoPage(-1)}>
                  {this.state.currentPage - 1}
                </div>
              )}
              <div className="p1">{this.state.currentPage}</div>
              {this.state.lastPage - this.state.currentPage >= 1 && (
                <div className="p2" onClick={e => this.handleGotoPage(1)}>
                  {this.state.currentPage + 1}
                </div>
              )}
              {this.state.lastPage - this.state.currentPage >= 2 && (
                <div className="p2" onClick={e => this.handleGotoPage(2)}>
                  {this.state.currentPage + 2}
                </div>
              )}
              {
                <div
                  className={`next ${
                    this.state.currentPage == this.state.lastPage ? "gray" : ""
                  }`}
                  onClick={e => this.handleNextPage()}
                >
                  Next Page
                </div>
              }
            </div>
          </div>

          {this.renderNewRule()}
          {this.renderEditRule()}
        </div>
      </div>
    );
  }
}

function PlaybookVariableItem(props) {
  let data = props.playbookVariable;
  let canEdit = props.canEdit;
  const [action, setAction] = useState(false);
  return (
    <tr>
      <td>{data.name}</td>
      <td className="concept left">{data.clause_concept}</td>
      <td className="reqwrapper">
        <div
          className={`${!data.is_required ? "grey" : null} req`}
          onClick={props.handleRequire}
        >
          <div className="reqin"></div>
        </div>
      </td>
      <td className="col-max-300 left">
        <ul>
          {data.unacceptable_provisions &&
            data.unacceptable_provisions.map((item, i) => <li>{`${item}`}</li>)}
        </ul>
      </td>
      <td className="col-max-300 left">
        {" "}
        <ul>
          {data.sub_variables &&
            data.sub_variables.map((item, i) => (
              <li>{`${item.name}: ${
                item.variable_type == "text"
                  ? item.variable_value.text
                  : item.variable_type == "selection"
                  ? item.variable_value.option
                  : item.variable_value.range &&
                    item.variable_value.range.value +
                      " " +
                      item.variable_value.range.unit
              }`}</li>
            ))}
        </ul>
      </td>
      <td className="reqwrapper">
        <div
          className={`${!data.is_ignore ? "grey" : null} req`}
          onClick={props.handleIgnore}
        >
          <div className="reqin"></div>
        </div>
      </td>
      <td className="">
        <div className="apply_to">
          {data.apply_to[0] && (
            <div className="type-btn">{data.apply_to[0]}</div>
          )}
          {data.apply_to[1] && (
            <div className="type-btn">{data.apply_to[1]}</div>
          )}
        </div>
      </td>
      <td tabIndex="0" onBlur={e => setAction(true)}>
        <div className="action">
          <Dropdown isOpen={action} toggle={() => setAction(!action)}>
            <DropdownToggle
              className={`${action ? "dotbackground" : null} dots`}
            >
              <DotsHorizontalIcon />
            </DropdownToggle>
            <DropdownMenu className="actions_box">
              <DropdownItem disabled={!canEdit}>
                {" "}
                <div
                  className="scale cursor"
                  onClick={e =>
                    props.handleEditPlaybookVariable(data, props.index)
                  }
                >
                  <img src={require("../../assets/images/edit.png")} alt="" />
                  <p>Edit</p>
                </div>
              </DropdownItem>
              <DropdownItem disabled={!canEdit}>
                {" "}
                <div
                  className="scale cursor"
                  onClick={e => {
                    setAction(false);
                    props.handleInactivePlaybookVariable(data, props.index);
                  }}
                >
                  <img
                    src={require("../../assets/images/disabled.png")}
                    alt=""
                  />
                  <p>Inactive</p>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
}
