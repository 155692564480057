import Store from "../../store";
import utils from "../common/utils";
import error from "../common/Error";

const BASE_URL = Store.BASE_URL;

const get_contracts_list = (
  module_status = "INS",
  page = 1,
  shared = false
) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(
          `${BASE_URL}/v1/contracts${
            shared ? "/assigned" : ""
          }?module_status=${module_status}&page=${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        )
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const upload_contract = (title, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            contract_title: title,
            content: data
          })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const upload_counter_party_contract = (title, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            contract_title: title,
            content: data
          })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const update_contract = (title, id, data = null) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            content: data,
            contract_title: title
          })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const update_counter_party_contract = (title, id, data = null) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/counter_party/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            content: data,
            contract_title: title
          })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const simulate_contract = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/simulate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_contract = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_counter_party_contract = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/counter_party/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const fwd_to_next_module = (id, module_name = "MOD") => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/forward/module`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            module_name: module_name
          })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_summary = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/summary`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const update_summary = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/summary`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_assign_users = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/assign`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const assign_users = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/assign`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const forward_to_user = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/forward`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const forward_to_counter_party = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/tocounterparty`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const return_to_owner = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/returnforchange`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const counter_party_return_to_owner = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/counter_party/${id}/return`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const remodel_contract = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/remodeling`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const approve_contract = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/approved`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_playbook_extractors = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/extractors`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const forward_with_sub_extractors = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/sub-extractors`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const confirm_sub_extractors = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${id}/sub-extractors/confirm`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_sec_filing = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/secfiling/contract/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const Contract = {
  get_contracts_list,
  upload_contract,
  upload_counter_party_contract,
  update_contract,
  update_counter_party_contract,
  simulate_contract,
  get_contract,
  get_counter_party_contract,
  fwd_to_next_module,
  get_summary,
  update_summary,
  get_assign_users,
  assign_users,
  forward_to_user,
  forward_to_counter_party,
  return_to_owner,
  counter_party_return_to_owner,
  remodel_contract,
  approve_contract,
  get_playbook_extractors,
  forward_with_sub_extractors,
  confirm_sub_extractors,
  get_sec_filing
};

export default Contract;
