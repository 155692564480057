import React, { Component } from "react";

import Store from "../../store";
import { ToastsStore } from "react-toasts";
import ProfileApi from "../../api/profile/Profile";
import { Link, withRouter } from "react-router-dom";
import TopbarNotification from "./TopbarNotification";
export default class TopBar extends Component {
  state = {
    searchText: "",
    loading: true
  };

  update_user = () => {
    this.setState({ loading: false });
  };
  componentWillUnmount() {
    Store.topBarRef = null;
  }

  componentDidMount() {
    ProfileApi.get_profile_details()
      .then(res => {
        Store.user = res;
        this.setState({ loading: false });
        if (Store.profileRef) Store.profileRef.setState({ loading: false });
      })
      .catch(e => {
        ToastsStore.error("Failed to Load Profile Details!");
        this.setState({ loading: false });
        if (Store.profileRef) Store.profileRef.setState({ loading: false });
        console.log(e);
      });
    Store.topBarRef = this;

    window.addEventListener("offline", () => {
      ToastsStore.warning(
        "You are Offline. Please check your Internet Connection"
      );
    });

    window.addEventListener("online", () => {
      ToastsStore.success("You are Online.");
    });
  }

  render() {
    return (
      <div className="topbar-container">
        <div class="topbar__right">
          <form>
            <input
              value={this.state.searchText}
              className="searchBox"
              placeholder="Search..."
              type="text"
              onChange={e => this.setState({ searchText: e.target.value })}
            />
          </form>

          <TopbarNotification />
          {/* <img
            className="notification-icon"
            src={require("../../assets/icons/notification.svg")}
          /> */}
          <div className="topbar-vertical-line"></div>
          <Link className={"profile-link"} to={"/profile"}>
            <img className="topbar-avatar" src={Store.userAvatar} />
            <div className="topbar-userName">
              {this.state.loading
                ? "Loading..."
                : `${Store.user.profile_details.first_name} ${Store.user.profile_details.last_name}`}
            </div>
          </Link>
          <img
            className="arrow-down"
            src={require("../../assets/icons/arrow-down.svg")}
          />
        </div>
      </div>
    );
  }
}
