import React, { Component } from "react";
import HeadingContainer from "../layout/HeadingContainer";
// import "../common/contract/contractsList.scss";
import ContractsListItem from "../common/contract/ContractsListItem";
import Contract from "../../api/contract/Contract";
import Header from "../common/Header";
import throttle from "lodash.throttle";
import { ToastsStore } from "react-toasts";
import { Spinner } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

export default class ModellingContracts extends Component {
  FilterAllContracts = 0;
  FilterSharedContracts = 1;
  FilterRemodelledContracts = 2;
  FilterMyContracts = 3;

  state = {
    data: [],
    loading: false,
    page: 1,

    isFilterOpen: false,
    selectedFilter: 0
  };

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen
    });
  };

  getFilterLabel(value) {
    switch (value) {
      case 0:
        return "All Contracts";
      case 1:
        return "Shared with me";
      case 2:
        return "Remodelled Contracts";
      case 3:
        return "My Contracts";
    }
  }

  changeFilter = value => {
    this.setState({ selectedFilter: value, page: 1, data: [] }, () => {
      this.load_contracts();
    });
  };

  get_value(n) {
    n = parseInt(n);
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }

  get_random_color() {
    return "hsl(" + 360 * Math.random() + "," + 75 + "%," + 40 + "%)";
  }

  get_duration(start, end) {
    let p1 = start.split("/");
    start = new Date(Number(p1[2]), Number(p1[1]) - 1, Number(p1[0]));
    let p2 = end.split("/");
    end = new Date(Number(p2[2]), Number(p2[1]) - 1, Number(p2[0]));

    const millis = end.getTime() - start.getTime();
    const years = millis / 31536000000;
    return `${Math.round(years)} Years`;
  }

  load_contracts = () => {
    if (this.state.page <= 0 || this.state.loading) return;
    this.setState({ loading: true });

    Contract.get_contracts_list(
      "MOD",
      this.state.page,
      this.state.selectedFilter == this.FilterSharedContracts
    )
      .then(res => {
        const data = this.state.data;
        res.data.forEach(item => {
          const contract = item;
          contract.duration = this.get_duration(
            contract.start_at,
            contract.end_at
          );
          /*if (contract.title.length > 20)
            contract.title = contract.title.substr(0, 20) + "...";*/
          contract.value = this.get_value(contract.value);
          contract.risk = Number(contract.risk);
          contract.entity =
            contract.entity === "NG" ? "Non-Govt." : "Government";
          contract.bg_clr = this.get_random_color();
          data.push(contract);
        });
        this.setState({
          data: this.state.data,
          loading: false,
          page: res.data.length === res.per_page ? res.current_page + 1 : -1
        });
      })
      .catch(e => {
        ToastsStore.error("Failed to Load Contracts List!");
        this.setState({ loading: false });
        console.log(e);
      });
  };

  load_more = throttle(this.load_contracts, 800, {
    trailing: false
  });

  componentDidMount() {
    this.load_contracts();
    this.page_wrapper.onscroll = e => {
      const obj = e.target;
      const height = obj.scrollHeight - obj.offsetHeight;
      const scrollPos = obj.scrollTop;
      const factor = 0.7;
      if (scrollPos >= factor * height) {
        this.load_more();
      }
    };
  }

  navigateToEditor = contractId => {
    let path = "/modelling/contract";
    if (contractId) path += `/${contractId}`;
    else path += "/new-contract";
    this.props.history.push(path);
  };

  renderfilter() {
    return (
      <Dropdown
        className={"contract-list-filter"}
        isOpen={this.state.isFilterOpen}
        toggle={this.toggleFilter}
      >
        <DropdownToggle caret>
          <span
            class="
        label-wrapper"
          >
            <img src={require("../../assets/icons/filter.svg")} />{" "}
            <span>{this.getFilterLabel(this.state.selectedFilter)}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={e => this.changeFilter(this.FilterAllContracts)}
          >
            All Contracts
          </DropdownItem>
          <DropdownItem
            onClick={e => this.changeFilter(this.FilterSharedContracts)}
          >
            Shared with Me
          </DropdownItem>
          {/* <DropdownItem
            onClick={e => this.changeFilter(this.FilterRemodelledContracts)}
          >
            Remodelled Contracts
          </DropdownItem>
          <DropdownItem
            onClick={e => this.changeFilter(this.FilterMyContracts)}
          >
            My Contracts
          </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    return (
      <div
        className="_contracts-container _page-container"
        ref={c => (this.page_wrapper = c)}
      >
        <div className={"contract-list-header"}>
          <Header
            heading="Modelling Contracts"
            headingStyle={{
              fontWeight: "normal",
              marginLeft: `${(28 * 100) / 1920}vw`,
              marginTop: `${(28 * 100) / 1920}vw`,
              marginBottom: `${(69 * 100) / 1920}vw`
            }}
          />
          <div class={"contract-list-filter-wrapper"}>
            {this.renderfilter()}
          </div>
        </div>
        {this.state.data.map(contract => (
          <ContractsListItem
            onClick={e => this.navigateToEditor(contract.id)}
            key={contract.id}
            contract={contract}
          />
        ))}
        {this.state.loading ? (
          <div style={{ display: "inline-block", alignSelf: "center" }}>
            <Spinner style={{ color: "#473080" }} />
          </div>
        ) : null}
      </div>
    );
  }
}
