import React, { PureComponent } from "react";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import TextTruncate from "react-text-truncate";

export const AnalyzeCard = props => {
  const { alert, handleAction } = props;
  let stage = alert.metadata.stage;
  let type = alert.metadata.type;
  let subType = alert.metadata.sub_type;
  let message =
    type == "newsfeed_article"
      ? alert.data
        ? alert.data.title + ": " + alert.data.description
        : ""
      : type == "variable"
      ? `Rule Break: ${alert.data.ruleBreak ? "True" : "False"}`
      : type == "risk_analyzer_engine"
      ? `Buyer Intensity: ${alert.data.buyerIntenstiy}\nSeller Intensity: ${alert.data.sellerIntenstiy}`
      : type == "court_ruling"
      ? alert.data.rulings.length > 0
        ? alert.data.rulings[0]
        : ""
      : "";
  let secTitle =
    type == "risk_analyzer_engine"
      ? alert.data.clause
      : alert.alert_message.split("_").join(" ");

  let showTruncated =
    type == "court_ruling" || type == "newsfeed_article" ? true : false;


    let colorClass = ` ${
      stage == "pulse" && type == "sanction" && subType == "risk"
        ? "bg-red"
        : stage == "modeling" && type == "risk_analyzer_engine"
        ? alert.data.intensity <= 10
          ? "bg-dark-green"
          : alert.data.intensity <= 25
          ? "bg-green"
          : alert.data.intensity <= 49
          ? "bg-yellow"
          : alert.data.intensity <= 69
          ? "bg-orange"
          : alert.data.intensity <= 89
          ? "bg-red"
          : "bg-dark-red"
        : stage == "pulse" && type == "legislation"
        ? alert.data.risk <= 10
          ? "bg-dark-green"
          : alert.data.risk <= 25
          ? "bg-green"
          : alert.data.risk <= 49
          ? "bg-yellow"
          : alert.data.risk <= 69
          ? "bg-orange"
          : alert.data.risk <= 89
          ? "bg-red"
          : "bg-dark-red"
        : stage == "playbook" && type == "variable"
        ? "bg-dark-red":
        subType == "risk"
        ? "bg-red"
        : subType == "mixed" || subType == "neutral"
        ? "bg-coffee"
        : subType == "opportunity"
        ? "bg-purple"
        : "bg-gray"
    }`;

  return (
    <div onClick={props.onClick} className={`notification-card`}>
      <div
        className={`notification-card__border ${colorClass}`}
      ></div>
      <div className={`notification-card__content`}>
        <h5
          className={`notification-card__title ${
            stage == "pulse"
              ? "orange"
              : stage == "modeling"
              ? "red"
              : stage == "playbook"
              ? "red"
              : "green"
          }`}
        >
          {stage} {type.replace("_", " ").replace("_", " ")}
          <span
            className={`notification-card__type ${colorClass.replace("bg-","text-")}`}
          >
            {alert.hasOwnProperty("risk")||alert.data&&alert.data.hasOwnProperty("risk")?((alert.data&&alert.data.risk)||(alert.hasOwnProperty("risk")&&alert.risk==null?"100":false)||"0")+"%":subType.split("_").join(" ")}
          </span>
        </h5>
        <h3 className="notification-card__title">{secTitle}</h3>
        <p className="notification-card__message">
          {showTruncated ? (
            <span>
              {`${message.substring(0, 50)} ${
                message.length > 50 ? "..." : null
              }`}
              {alert && alert.data && alert.data.rulings ? (
                <a
                  onClick={e =>
                    handleAction("view-rulings", alert.data.rulings)
                  }
                >
                  Read
                </a>
              ) : null}
            </span>
          ) : (
            /* <TextTruncate
              line={2}
              element="span"
              truncateText="…"
              text={message}
              textTruncateChild={
                alert && alert.data && alert.data.rulings ? (
                  <a
                    onClick={e =>
                      handleAction("view-rulings", alert.data.rulings)
                    }
                  >
                    Read
                  </a>
                ) : null
              }
            /> */
            message
          )}
        </p>

        <div class="notification-card__footer">
            {type==="newsfeed_article"?alert.data.categories.map(item=>(<div className='tag-container'><div className='category-tag' style={{backgroundColor:'lightgrey',marginRight:'5px',marginBottom:'5px',padding:'5px'}}>{item.category}</div></div>)):null}
         {((alert.data&&alert.data.rulings)|| type == "newsfeed_article")?null:<span class="footer__text">View Impacted Clauses</span>}
        </div>
      </div>
    </div>
  );
};
