import React, { Component } from "react";
// import "./clauseitem.scss";
class ClauseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      viewFull: false
    };
  }
  render() {
    return (
      <div className={this.state.dragging ? "dragging-clause-container " : ""}>
        <div
          className={"my-card " + (this.state.dragging ? "dragging" : "")}
          draggable
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
        >
          <div className={"header"}>
            {this.props.title}
            {/* <span className={"right-item"}>17%</span> */}
          </div>
          <div className={"body"}>
            {this.state.viewFull
              ? this.props.text + " "
              : this.props.text.substring(0, 84) + "... "}
            {this.state.viewFull ? (
              <span onClick={this.toogleViewFull}>view less</span>
            ) : (
              <span onClick={this.toogleViewFull}>view more</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  toogleViewFull = () => {
    this.setState({ viewFull: !this.state.viewFull });
  };

  handleDragStart = ev => {
    this.setState({
      dragging: true,
      viewFull: false
    });
    // ev.target.style.visibility = "hidden";
    // ev.dataTransfer.setData("text/html, this.props.text + "<br>");
    // ev.dataTransfer.setData("text/html", this.props.text + "<br>");
    ev.dataTransfer.setData(
      "text/html",
      `
    ${this.props.text}`
    );
    ev.dataTransfer.setData(
      "text/plain",
      `${this.props.text}`
      //"<strong>" + this.props.text + "</strong>"
    );
  };

  handleDragEnd = ev => {
    this.setState({
      dragging: false
    });
  };
}

export default ClauseItem;
