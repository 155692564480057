import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#473080",
          fontSize: "48px",
          fontWeight: "bolder",
          textAlign: "center"
        }}
      >
        404 Not Found 😒
      </div>
    );
  }
}

// if(branch) {console.log('branch created)}
