import React, { Component } from "react";
import "./Playbook.scss";
import Btn from "../common/Button";
import Store from "../../store";
import { ToastsStore } from "react-toasts";
import utils from "../../api/common/utils";
import Modal from "../common/Modal";
import ModalLang from "../common/Modal";
import ProfileApi from "../../api/profile/Profile";
import Auth from "../../api/auth/Auth";
import {
  Col,
  Row,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  FormGroup,
  Label
} from "reactstrap";
import HeadingContainer from "../layout/HeadingContainer";

let seed = 0;
const now = Date.now();

function getUuid() {
  return `xxx${now}_${seed++}`;
}
const title = () => {
  return (
    <div className="title-cont">
      <div className="title">
        <p>Title</p>{" "}
        <div>
          <p>x</p>
        </div>
      </div>
      <input type="text" />
    </div>
  );
};

function createSV(variable) {
  if (variable) {
    return {
      name: variable.name ? variable.name : "",
      variable_type: variable.variable_type || "text",
      variable_value: {
        text: variable.variable_value.text || "",
        option: variable.variable_value.option || "yes",
        range: variable.variable_value.range || { value: 0, unit: "" }
      },
      key: getUuid()
    };
  }
  return {
    name: "",
    variable_type: "text",
    variable_value: {
      text: "",
      option: "yes",
      range: { value: 0, unit: "" }
    },
    key: getUuid()
  };
}

function createUA(value = "") {
  return {
    data: value,
    key: getUuid()
  };
}

function fixVariables(variable) {
  if (variable) {
    let unacceptable_provisions = variable.unacceptable_provisions;
    unacceptable_provisions = unacceptable_provisions.map(d => createUA(d));
    variable.unacceptable_provisions = unacceptable_provisions;

    let sub_variables = variable.sub_variables;
    sub_variables = sub_variables.map(d => createSV(d));
    variable.sub_variables = sub_variables;
    variable.name = variable.name || variable.name;

    return variable;
  } else {
    return null;
  }
}

function fixSubVariables(subVariables) {
  if (subVariables) {
    subVariables = subVariables.map(d => {
      d.name = d.name;
      return d;
    });
    return subVariables;
  } else {
    return null;
  }
}

export default class FormRule extends Component {
  state = this.props.variable
    ? fixVariables(Object.assign({}, this.props.variable))
    : {
        name: "",
        clause_concept: "",
        is_required: false,
        is_ignore: false,
        apply_to: [],
        unacceptable_provisions: [createUA()],
        sub_variables: [createSV()]
      };

  handleRequireRule() {
    this.setState({ is_required: !this.state.is_required });
  }
  handleIgnoreRule() {
    this.setState({ is_ignore: !this.state.is_ignore });
  }

  handleAddRule = i => {
    this.setState({
      unacceptable_provisions: [
        ...this.state.unacceptable_provisions,
        createUA()
      ]
    });
  };

  handleInputUnacceptable = (val, i) => {
    let newtitle = this.state.unacceptable_provisions.concat();
    newtitle[i].data = val;
    this.setState({ unacceptable_provisions: newtitle });
  };
  handleChangeSpecificInput = (val, i) => {
    let newSubVariable = this.state.sub_variables.concat();
    newSubVariable[i].name = val;
    this.setState({ sub_variables: newSubVariable });
  };
  handleChangeMintext = (val, i) => {
    let newspecific = this.state.sub_variables.concat();
    newspecific[i].variable_value.range.value = val;
    this.setState({ sub_variables: newspecific });
  };
  handleChangeMaxtext = (val, i) => {
    let newspecific = this.state.sub_variables.concat();
    newspecific[i].variable_value.range.unit = val;
    this.setState({ sub_variables: newspecific });
  };
  handleSelectOptions = (val, i) => {
    let newspecific = this.state.sub_variables.concat();
    newspecific[i].variable_value.option = val;
    this.setState({ sub_variables: newspecific });
  };
  handle_variable_name = (val, i) => {
    this.setState({ name: val });
  };
  handle_clause_concept = (val, i) => {
    this.setState({ clause_concept: val });
  };
  handlecheckedMSA = e => {
    if (e.target.checked) {
      if (this.state.apply_to.includes("PA")) {
        this.setState({ apply_to: ["MSA", "PA"] });
      } else {
        this.setState({ apply_to: ["MSA"] });
      }
    } else {
      if (this.state.apply_to.includes("PA")) {
        this.setState({ apply_to: ["PA"] });
      } else {
        this.setState({ apply_to: [] });
      }
    }
  };
  handlecheckedPA = e => {
    if (e.target.checked) {
      if (this.state.apply_to.includes("MSA")) {
        this.setState({ apply_to: ["MSA", "PA"] });
      } else {
        this.setState({ apply_to: ["PA"] });
      }
    } else {
      if (this.state.apply_to.includes("MSA")) {
        this.setState({ apply_to: ["MSA"] });
      } else {
        this.setState({ apply_to: [] });
      }
    }
  };
  handleChangeText = (val, i, key) => {
    console.log("changed", val, i, key);
    let newtitle = this.state.sub_variables.concat();
    newtitle[i].variable_value.text = val;
    this.setState({ specific: newtitle });
  };
  handleOptionChange = (v, i) => {
    let newtitle = this.state.sub_variables.concat();

    newtitle[i].variable_type = v;
    this.setState({ sub_variables: newtitle });
  };
  handleAddSpecific = e => {
    this.setState({
      sub_variables: [...this.state.sub_variables, createSV()]
    });
  };
  handleAddPlaybookRule = e => {
    e.preventDefault();
    let data = {
      name: this.state.name,
      clause_concept: this.state.clause_concept,
      is_required: this.state.is_required ? 1 : 0,
      is_ignore: this.state.is_ignore ? 1 : 0,
      apply_to: this.state.apply_to,
      unacceptable_provisions: this.state.unacceptable_provisions.map(
        v => v.data
      ),

      sub_variables: this.state.sub_variables
    };
    if (this.state.id) data.id = this.state.id;
    console.log(this.props.data);
    this.props.onAdd(data);
  };
  removeUnacceptable = index => {
    let newUnacceptable = this.state.unacceptable_provisions.concat();
    newUnacceptable.splice(index, 1);
    this.setState({ unacceptable_provisions: newUnacceptable });
  };

  removeSpecific = index => {
    let newSpecific = this.state.sub_variables.concat();
    newSpecific.splice(index, 1);
    this.setState({ sub_variables: newSpecific });
  };
  cleanSubVariable = sub_variables => {
    sub_variables.forEach((sub_variable, index) => {
      console.log("for e", sub_variable, index);
      let x;
      if (sub_variable.variable_type == "text") {
        x = sub_variables[index].variable_value.option
          ? (sub_variables[index].variable_value.option = null)
          : null;
        x = sub_variables[index].variable_value.range
          ? (sub_variables[index].variable_value.range.value = null)
          : null;
      } else if (sub_variable.variable_type == "selection") {
        x = sub_variables[index].variable_value.text
          ? (sub_variables[index].variable_value.text = null)
          : null;

        x = sub_variables[index].variable_value.range
          ? (sub_variables[index].variable_value.range.value = null)
          : null;
      } else if (sub_variable.variable_type == "value") {
        x = sub_variables[index].variable_value.option
          ? (sub_variables[index].variable_value.option = null)
          : null;
        x = sub_variables[index].variable_value.option
          ? (sub_variables[index].variable_value.option = null)
          : null;
        x = sub_variables[index].variable_value.option
          ? (sub_variables[index].variable_value.option = null)
          : null;
        x = sub_variables[index].variable_value.text
          ? (sub_variables[index].variable_value.text = null)
          : null;
      }
    });
    if (this.props.type == "edit")
      sub_variables = fixSubVariables(sub_variables);
    return sub_variables;
  };
  render() {
    return (
      <form
        className="Playbook-form"
        onSubmit={e => this.handleAddPlaybookRule(e)}
      >
        <div className="row container">
          <div className="form__form-group form_rule col-lg-6">
            <span className="">Provision</span>
            <div className="provision">
              <input
                value={this.state.name}
                type="text"
                required={true}
                onChange={e => this.handle_variable_name(e.target.value)}
              />
            </div>
            <div className="clause">Clause/Concept</div>
            <div className="clause-concept">
              <textarea
                required={true}
                value={this.state.clause_concept}
                onChange={e => this.handle_clause_concept(e.target.value)}
              ></textarea>
            </div>
            <div className="require">
              <p>Require</p>{" "}
              <div
                className={`${!this.state.is_required ? "grey" : null} req`}
                onClick={this.handleRequireRule.bind(this)}
              >
                <div className="reqin"></div>
              </div>{" "}
            </div>
            <div className="require ignore">
              <p>Ignore</p>{" "}
              <div
                className={`${!this.state.is_ignore ? "grey" : null} req`}
                onClick={this.handleIgnoreRule.bind(this)}
              >
                <div className="reqin"></div>
              </div>{" "}
            </div>
            <div className="apply">
              <p>Apply to</p>
              <div className="check">
                <div>
                  <input
                    type="checkbox"
                    value="MSA"
                    required={!this.state.apply_to.includes("PA")}
                    checked={this.state.apply_to.includes("MSA")}
                    onChange={e => this.handlecheckedMSA(e)}
                  />
                  MSA
                </div>
                <div>
                  <input
                    type="checkbox"
                    value="PA"
                    required={!this.state.apply_to.includes("MSA")}
                    checked={this.state.apply_to.includes("PA")}
                    onChange={e => this.handlecheckedPA(e)}
                  />
                  PA
                </div>
              </div>
            </div>
            <div className="un">
              <p>Unacceptable</p>
            </div>
            <div className="unacceptable">
              {this.state.unacceptable_provisions.map((d, i) => {
                return (
                  <div className="title-cont" key={d.key}>
                    <div className="title">
                      <p>Description</p>{" "}
                      {this.state.unacceptable_provisions.length > 1 && (
                        <div onClick={e => this.removeUnacceptable(i)}>
                          <p>x</p>
                        </div>
                      )}
                    </div>
                    <input
                      type="text"
                      required={true}
                      value={d.data}
                      onChange={e =>
                        this.handleInputUnacceptable(e.target.value, i)
                      }
                    />
                  </div>
                );
              })}

              <title />
              <div className="add-more-btn2" onClick={this.handleAddRule}>
                <div className="add-more-btn-circle">+</div>

                <p>Add More</p>
                <div className="hori-cont">
                  <div className="hori"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="form__form-group col-lg-6">
            <span className="">Specific</span>
            <div className="specific">
              {this.state.sub_variables.map((d, i) => {
                return (
                  <div className="specific1 value" key={d.key}>
                    <div className="title">
                      <p>Title</p>
                      {this.state.sub_variables.length > 1 && (
                        <p onClick={e => this.removeSpecific(i)}>x</p>
                      )}
                    </div>
                    <div className="text">
                      <input
                        type="text"
                        required={true}
                        value={d.name}
                        onChange={e =>
                          this.handleChangeSpecificInput(e.target.value, i)
                        }
                      />
                    </div>
                    <div className="radio">
                      <div>
                        <input
                          type="radio"
                          name={"value" + i}
                          value="text"
                          required={true}
                          checked={d.variable_type === "text"}
                          onChange={e =>
                            this.handleOptionChange(e.target.value, i)
                          }
                        />
                        <p> Text</p>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          name={"value" + i}
                          value="selection"
                          required={true}
                          checked={d.variable_type === "selection"}
                          onChange={e =>
                            this.handleOptionChange(e.target.value, i)
                          }
                        />
                        <p>Selection</p>
                      </div>
                      <div>
                        {" "}
                        <input
                          type="radio"
                          name={"value" + i}
                          value="value"
                          required={true}
                          checked={d.variable_type === "value"}
                          onChange={e =>
                            this.handleOptionChange(e.target.value, i)
                          }
                        />
                        <p> Value</p>
                      </div>
                    </div>
                    {d.variable_type === "value" ? (
                      <div className="Range x" value>
                        <p>Range</p>
                        <div>
                          <input
                            type="number"
                            value={d.variable_value.range.value}
                            required={true}
                            onChange={e =>
                              this.handleChangeMintext(e.target.value, i)
                            }
                          />
                          <input
                            type="text"
                            required={true}
                            value={d.variable_value.range.unit}
                            onChange={e =>
                              this.handleChangeMaxtext(e.target.value, i)
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    {d.variable_type === "selection" ? (
                      <div className="Range valueselect">
                        <p>Select an Option</p>
                        <div>
                          <select
                            name=""
                            id=""
                            value={d.variable_value.option}
                            onChange={e =>
                              this.handleSelectOptions(e.target.value, i)
                            }
                          >
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {d.variable_type === "text" ? (
                      <div className="Range valuewrite">
                        <p>Write your text</p>
                        <div>
                          <input
                            type="text"
                            required={true}
                            value={d.variable_value.text}
                            onChange={e =>
                              this.handleChangeText(e.target.value, i, d.key)
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <div className="add-more-btn" onClick={this.handleAddSpecific}>
                <div className="add-more-btn-circle">+</div>

                <p>Add More</p>
                <div className="hori-cont">
                  <div className="hori"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="add rule-btn">
            <Button className="add-btn">
              {this.props.type == "new" ? "Add" : "Update"}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
