import React, { Component } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, Modifier, RichUtils } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import Contract from "../../api/contract/Contract";
// import Btn from "../common/Button";
// import Badge from "@material-ui/core/Badge";
// import Notification from "rc-notification";
import MyPulseContract from "../editor/MyPulseContract";
// import { ToastsStore } from "react-toasts";
// import { Prompt, Redirect } from "react-router";
// import FileDocumentOutline from "mdi-react/FileDocumentOutlineIcon";
// import PlaylistEdit from "mdi-react/PlaylistEditIcon";
// import WarningOutlineIcon from "mdi-react/WarningOutlineIcon";
// import PlaylistCheckIcon from "mdi-react/PlaylistCheckIcon";
// import ContentSaveOutline from "mdi-react/ContentSaveOutlineIcon";
// import CloseIcon from "mdi-react/CloseIcon";
// import CommentIcon from "mdi-react/CommentTextOutlineIcon";
// import CommentPlusIcon from "mdi-react/CommentPlusIcon";
// import PlusIcon from "mdi-react/PlusIcon";

// import SendIcon from "mdi-react/SendIcon";
// import ChevronLeft from "mdi-react/ChevronLeftIcon";
// import debounce from "lodash.debounce";
// import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
// import TextArea from "../common/TextArea";
// import Dropdown from "../common/Dropdown";
// import Tabs from "../common/Tabs";

// import ReactTagInput from "@pathofdev/react-tag-input";
// import "@pathofdev/react-tag-input/build/index.css";
// import {
//   ContentState,
//   ContentBlock,
//   convertFromRaw,
//   genKey,
//   convertToRaw
// } from "draft-js";
// import Clause from "../../api/clause/Clause";
// import throttle from "lodash.throttle";
// import $ from "jquery";
// import {
//   addComment,
//   getCommentDecorator,
//   removeComment,
//   isSelectionACommentEntity,
//   addLineBreakBetweenComments,
//   generateSpanId,
//   getSelectionEntitySpanId,
//   removeCommentBySpanId,
//   selectionHasEntity,
//   addCommentIntelligence,
//   addCommentNew,
//   removeHighlights,
//   renderHighlightsInEditor
// } from "../common/editor/utils";
// import { handleNewLine } from "draftjs-utils";
// import Comment from "../../api/comment/Comment";
// import ToolTip from "../common/ToolTip";
// import Fab from "../common/Fab";
// import { AnalyzeCard } from "../common/contract/AnalyzeCard";
// import { AnalyzeNotification } from "../common/contract/AnalyzeNotification";
// import ContractHeader from "../common/contract/ContractHeader";

// let notificationRU = null;

export default class PulseContract extends Component {
  // ERB_Summary = "summary";
  // ERB_Clause = "clause";
  // ERB_Details = "details";
  // clauseCurrentPage = 1;
  // lastCommentBlock = null;

  // init_comment = {};

  // comments_wrapper_height = 0;

  // empty_content = {
  //   entityMap: {},
  //   blocks: [
  //     {
  //       key: "637gr",
  //       text: "",
  //       type: "unstyled",
  //       depth: 0,
  //       inlineStyleRanges: [],
  //       entityRanges: [],
  //       data: { key: "637gr" }
  //     }
  //   ]
  // };
  // state =
  //   this.props.location.state !== undefined
  //     ? {
  //         contentState:
  //           this.props.location.state.rawContent || this.empty_content,
  //         editorState: EditorState.createWithContent(
  //           convertFromRaw(
  //             this.props.location.state.rawContent || this.empty_content
  //           )
  //         ),
  //         contract: this.props.location.state.contract,
  //         title: this.props.location.state.contract.title,
  //         savedState: this.props.location.state.contract_id
  //           ? this.props.location.state.rawContent
  //           : {},
  //         savedTitle: this.props.location.state.contract_id,
  //         action: this.props.location.state.action,
  //         saving: false,
  //         forwarding: false,
  //         index: [],
  //         showIndex: false,
  //         hideIndex: true,
  //         contract_id: this.props.location.state.contract_id,
  //         titleEditingMode: false,
  //         redirect: false,
  //         showRightBar: false,
  //         hideRightBar: true,
  //         erbSelectedItem: null,
  //         erbLoading: true,
  //         editSummaryMode: false,
  //         summary: "",
  //         clauses: [],
  //         clauseLastPage: Infinity,
  //         clauseCurrentPage: 1,
  //         erbClauseLoading: false,
  //         erbClauseSearchKeyword: null,
  //         erbClauseSearchNoResults: false,
  //         dropdownOpen: false,
  //         showComments: false,
  //         comments: {},
  //         savedComments: {},
  //         focusSpanId: "",
  //         focusSpanId2: "",
  //         hideBubble: false,
  //         slimulateAlerts: [],
  //         analyzing: false,
  //         showAnalyzeBar: false,
  //         showAnalyzeFab: false,
  //         showRulingsModal: false,
  //         rulings: []
  //       }
  //     : {
  //         contentState: this.empty_content,
  //         editorState: EditorState.createWithContent(
  //           convertFromRaw(this.empty_content)
  //         ),
  //         title: "Untitled Document",
  //         savedState: {},
  //         savedTitle: "",
  //         saving: false,
  //         forwarding: false,
  //         index: [],
  //         showIndex: false,
  //         hideIndex: true,
  //         contract_id: null,
  //         titleEditingMode: false,
  //         redirect: false,
  //         showRightBar: false,
  //         hideRightBar: true,
  //         erbSelectedItem: null,
  //         erbLoading: true,
  //         editSummaryMode: false,
  //         summary: {},
  //         clauses: [],
  //         erbClauseLoading: false,
  //         clauseLastPage: Infinity,
  //         erbClauseSearchKeyword: null,
  //         erbClauseSearchNoResults: false,
  //         clauseCurrentPage: 1,
  //         dropdownOpen: false,
  //         showComments: false,
  //         comments: {},
  //         savedComments: {},
  //         focusSpanId: "",
  //         focusSpanId2: "",
  //         hideBubble: false,
  //         slimulateAlerts: [],
  //         analyzing: false,
  //         showAnalyzeBar: false,
  //         showAnalyzeFab: false,
  //         showRulingsModal: false,
  //         rulings: []
  //       };
  // index_paddings = Array(7).fill(12);

  // componentDidUpdate(prevProps, prevState) {
  //   this.updateIndex(prevState);
  //   let x = this;
  //   $.each($(".comment-box"), function(i, item) {
  //     setTimeout(function() {
  //       let factor =
  //         $("._my-editor-wrapper").outerHeight() -
  //         $(".editor-main-container").outerHeight();
  //       let key = $(item).attr("data");
  //       let editorBlock = $(`.inline-comment-span[data-span-id="${key}"]`);
  //       if (!editorBlock.offset()) return;
  //       let top = x.getOffsetSpan(editorBlock[0]);
  //       //top= top -factor;
  //       let originalTop = top;
  //       if (
  //         !$(item)
  //           .prev()
  //           .offset()
  //       ) {
  //         $(item).css("top", top);
  //         $(item).attr("fake-top", top);
  //         return;
  //       }

  //       let prev = $(item).prev();
  //       let prevBottom = parseInt(prev.attr("fake-top")) + prev.outerHeight();
  //       top = top > prevBottom + 20 ? top : prevBottom + 20;

  //       $(item).css("top", top);
  //       $(item).attr("fake-top", top);
  //     }, 0 + i * 0);
  //   });

  //   $(".comment-box")
  //     .get()
  //     .forEach(function(item, index) {});
  // }

  // componentDidMount() {
  //   Notification.newInstance(
  //     { style: { position: "absolute", right: 10, bottom: 10, zIndex: 5000 } },
  //     n => (notificationRU = n)
  //   );
  //   if (
  //     this.props.location.state === undefined &&
  //     this.props.location.pathname.split("/").pop() !== "new-contract"
  //   ) {
  //     this.isSaved = () => true;
  //     this.isTitleSaved = () => true;
  //     this.setState({ redirect: "bad-url" });
  //   } else {
  //     if (this.props.location.pathname.split("/").pop() == "new-contract") {
  //       Contract.upload_contract(
  //         this.state.title,
  //         convertToRaw(this.state.editorState.getCurrentContent())
  //       )
  //         .then(res => {
  //           this.setState({
  //             savedState: Object.assign({}, this.state.contentState),
  //             savedTitle: this.state.title,
  //             saving: false,
  //             contract_id: res.contract_id,
  //             redirect: "new-url"
  //           });
  //         })
  //         .catch(e => {
  //           ToastsStore.error("An error occurred while saving contract!");
  //           console.log(e);
  //           this.setState({ saving: false });
  //         });
  //     } else {
  //     }
  //   }
  // }

  // fetctComments = () => {
  //   return;
  //   Comment.get_comments_for_contract(this.state.contract_id)
  //     .then(data => {
  //       this.initComments(data.content);
  //       console.log(data);
  //     })
  //     .catch(e => {
  //       console.error(e);
  //       ToastsStore.error(e.error.message);
  //     });
  // };

  // initComments = commentsData => {
  //   let comments = {};
  //   console.log(commentsData);
  //   commentsData.forEach(comment => {
  //     console.log(comment);
  //     comments[comment.block_key] = {};
  //     comments[comment.block_key].index = -1;
  //     comments[comment.block_key].data = [];
  //     comments[comment.block_key].data.push({
  //       id: comment.id,
  //       comment_by: comment.comment_by,
  //       block_key: comment.block_key,
  //       comment: comment.comment,
  //       created_at: comment.created_at,
  //       updated_at: comment.updated_at
  //     });
  //     comments[comment.block_key].data = comments[
  //       comment.block_key
  //     ].data.concat(comment.replies);
  //   });
  //   let com = this.sortCommentIndexes(comments);
  //   let scom = Object.assign({}, com);
  //   console.log("INIT COMMENT!");

  //   this.setState({ comments: com, savedComments: scom }, () => {
  //     if (commentsData.length > 0) this.showComments();
  //   });
  // };

  // componentWillUnmount() {
  //   notificationRU.destroy();
  //   window.onbeforeunload = undefined;
  // }

  // updateIndex = debounce(prevState => {
  //   if (!this.state.hideIndex) {
  //     if (
  //       JSON.stringify(prevState.contentState) !==
  //       JSON.stringify(this.state.contentState)
  //     ) {
  //       this.setState({ index: this.generateIndex() });
  //     }
  //   }
  // }, 300);

  // onContentStateChange = contentState => {
  //   console.log("Content State Changed", contentState);

  //   this.setState({ contentState });
  // };

  // isSaved() {
  //   return (
  //     JSON.stringify(this.state.contentState) ===
  //     JSON.stringify(this.state.savedState)
  //   );
  // }

  // isCommentsSaved = () => {
  //   return (
  //     JSON.stringify(this.state.comments) ===
  //     JSON.stringify(this.state.savedComments)
  //   );
  // };

  // isTitleSaved() {
  //   return this.state.savedTitle === this.state.title;
  // }

  // isContractEmpty() {
  //   const content = this.state.contentState.blocks;
  //   if (content.length === 0) return true;
  //   if (content.length === 1) {
  //     if (
  //       content[0].text.length === 0 &&
  //       content[0].inlineStyleRanges.length === 0 &&
  //       content[0].entityRanges.length === 0 &&
  //       Object.entries(content[0].data).length === 0
  //     )
  //       return true;
  //   }
  //   return false;
  // }

  // toggleRulingsModal = () => {
  //   this.setState({ showRulingsModal: false });
  // };

  // handleAnalyzeCardAction = (action, data) => {
  //   switch (action) {
  //     case "view-rulings": {
  //       this.setState({
  //         rulings: data,
  //         showRulingsModal: true
  //       });
  //     }
  //   }
  // };
  // onAnalyze = () => {
  //   this.setState({ analyzing: true });
  //   if (this.isContractEmpty())
  //     return ToastsStore.warning("Can't analyze empty contract!");
  //   if (this.state.contract_id) {
  //     Contract.simulate_contract(this.state.contract_id)
  //       .then(body => {
  //         this.setState(
  //           {
  //             slimulateAlerts: body.alerts,
  //             analyzing: false
  //           },
  //           () => {
  //             this.showAnalyzeFab();
  //             this.renderHighlights(body.alerts);
  //             this.showAlertsNotification(body.alerts.splice(0, 4));
  //           }
  //         );
  //         ToastsStore.success("Contract analyzed successfully!");
  //       })
  //       .catch(e => {
  //         this.setState({ analyzing: false });
  //         ToastsStore.error(
  //           "An error occurred while analyzing contract!" + e.error
  //             ? e.error.message
  //             : ""
  //         );
  //         console.log(e);
  //         this.setState({ saving: false });
  //       });
  //   } else {
  //     this.setState({ analyzing: false });
  //     ToastsStore.error("Cannot Analyze an unsaved contract!");
  //   }
  // };

  // onRemodel = () => {
  //   if (this.isContractEmpty())
  //     return ToastsStore.warning("Can't analyze empty contract!");

  //   Contract.remodel_contract(this.state.contract_id)
  //     .then(res => {
  //       ToastsStore.success("Sent for Remodelling..");
  //       this.goBack();
  //     })
  //     .catch(e => {
  //       ToastsStore.error(
  //         "An error occurred while remodeling contract! " + e.error
  //           ? e.error.message
  //           : ""
  //       );
  //       console.log(e);
  //       this.setState({ saving: false });
  //     });
  // };

  // goBack = () => {
  //   this.props.history.goBack();
  // };

  // renderHighlights = slimulateAlerts => {
  //   this.setState({
  //     editorState: renderHighlightsInEditor(
  //       this.state.editorState,
  //       slimulateAlerts
  //     )
  //   });
  // };

  // clearHighlights = contentState => {
  //   let cleanContentState = removeHighlights(contentState);
  //   this.setState({
  //     editorState: EditorState.createWithContent(convertFromRaw(contentState))
  //   });
  //   return cleanContentState;
  // };

  // onSave = () => {
  //   if (this.isContractEmpty())
  //     return ToastsStore.warning("Can't save empty contract!");
  //   else if (this.state.title === "")
  //     return ToastsStore.warning("Title must not be empty!");
  //   this.setState({ saving: true });
  //   if (this.state.contract_id) {
  //     //if contract has been uploaded atleas once
  //     Contract.update_contract(
  //       this.state.title,
  //       this.state.contract_id,
  //       this.isSaved()
  //         ? null
  //         : this.clearHighlights(
  //             convertToRaw(this.state.editorState.getCurrentContent())
  //           )
  //     )
  //       .then(res => {
  //         this.setState({
  //           saving: false,
  //           savedState: Object.assign(
  //             {},
  //             convertToRaw(this.state.editorState.getCurrentContent())
  //           ),
  //           savedTitle: this.state.title,
  //           savedComments: Object.assign({}, this.state.comments)
  //         });
  //       })
  //       .catch(e => {
  //         ToastsStore.error("An error occurred while saving contract!");
  //         console.log(e);
  //         this.setState({ saving: false });
  //       });
  //   } else {
  //     Contract.upload_contract(
  //       this.state.title,
  //       convertToRaw(this.state.editorState.getCurrentContent())
  //     )
  //       .then(res => {
  //         this.isSaved = () => true;
  //         this.isTitleSaved = () => true;
  //         this.setState({
  //           savedState: Object.assign(
  //             {},
  //             convertToRaw(this.state.editorState.getCurrentContent())
  //           ),
  //           savedTitle: this.state.title,
  //           savedComments: Object.assign({}, this.state.comments),
  //           saving: false,
  //           contract_id: res.contract_id,
  //           redirect: "new-url"
  //         });
  //       })
  //       .catch(e => {
  //         ToastsStore.error("An error occurred while saving contract!");
  //         console.log(e);
  //         this.setState({ saving: false });
  //       });
  //   }
  // };

  // onForward = () => {
  //   if (!this.isSaved() || this.state.forwarding)
  //     return ToastsStore.warning("Please save the contract first!");
  //   else if (!this.isTitleSaved())
  //     return ToastsStore.warning("Please save the title first!");
  //   this.setState({ forwarding: true });
  //   Contract.fwd_to_next_module(this.state.contract_id, "PUL")
  //     .then(res => {
  //       setTimeout(() => {
  //         ToastsStore.success(res.message);
  //         this.props.history.push("/modelling/contracts");
  //       }, 200);
  //     })
  //     .catch(e => {
  //       this.setState({ forwarding: false });
  //       ToastsStore.error("An error occurred!");
  //       console.log(e);
  //     });
  // };

  // onTest = () => {
  //   this.setState({
  //     editorState: addCommentIntelligence(
  //       this.state.editorState,
  //       this.state.editorState.getSelection(),
  //       "data"
  //     )
  //   });
  //   console.log(
  //     JSON.stringify(this.state.editorState.getCurrentContent(), null, 4)
  //   );
  // };

  // onTest2 = () => {
  //   this.showNotification();
  //   // this.setState({ editorState: removeComment(this.state.editorState) });
  //   // console.log(JSON.stringify(this.state.contentState, null, 4));
  // };

  // getOffset = el => {
  //   const elemRect = el.getBoundingClientRect();
  //   const bodyRect = this.editor_wrapper.getBoundingClientRect();
  //   return elemRect.top - bodyRect.top;
  // };

  // getOffsetSpan = el => {
  //   return this.getOffset(el) + 70;
  // };

  // moveToIndex(key) {
  //   let i = 0;
  //   let queryKey = "";
  //   while ("1234567890".includes(key[i])) {
  //     queryKey = queryKey + "\\3" + key[i] + " ";
  //     ++i;
  //   }
  //   queryKey += key.slice(i);
  //   const elem = this.editor_wrapper.querySelectorAll(
  //     `[data-offset-key=${queryKey}-0-0]`
  //   )[0];
  //   this.scroll_component.scrollTo({
  //     top: this.getOffset(elem) - 110,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }

  // moveToSpanId(spanId) {
  //   if (spanId == "") return;
  //   const elem = this.editor_wrapper.querySelectorAll(
  //     `.inline-comment-span[data-span-id="${spanId}"]`
  //   )[0];
  //   this.scroll_component.scrollTo({
  //     top: this.getOffset(elem) - 110,
  //     left: 0,
  //     behavior: "smooth"
  //   });
  // }

  // serializeLevels(n, levelArr) {
  //   if (!levelArr[n]) {
  //     for (let i = n + 1; i < levelArr.length; ++i) {
  //       this.index_paddings[i] += 12;
  //     }
  //     levelArr[n] = true;
  //   }
  //   return n;
  // }

  // getPaddingAndType(type, levelArr, _currLevel) {
  //   let currLevel;
  //   switch (type) {
  //     case "header-one":
  //       currLevel = 0;
  //       break;
  //     case "header-two":
  //       currLevel = 1;
  //       break;
  //     case "header-three":
  //       currLevel = 2;
  //       break;
  //     case "header-four":
  //       currLevel = 3;
  //       break;
  //     case "header-five":
  //       currLevel = 4;
  //       break;
  //     case "header-six":
  //       currLevel = 5;
  //       break;
  //     default:
  //       return {
  //         type: "normal",
  //         levelIndex: _currLevel + 1,
  //         currLevel: _currLevel
  //       };
  //   }
  //   return {
  //     type: "bold",
  //     levelIndex: this.serializeLevels(currLevel, levelArr),
  //     currLevel
  //   };
  // }

  // generateIndex() {
  //   this.index_paddings = Array(7).fill(12);
  //   let indexJson = [];
  //   let levelArr = Array(7).fill(false);
  //   let currLevel = -1;
  //   for (let block of this.state.contentState.blocks) {
  //     if (/\S/.test(block.text)) {
  //       //if text contains atleast one non-whitespace char
  //       const info = this.getPaddingAndType(block.type, levelArr, currLevel);
  //       currLevel = info.currLevel;
  //       indexJson.push({
  //         depth: block.depth,
  //         text: block.text.substr(0, 40),
  //         key: block.key,
  //         weight: info.type,
  //         levelIndex: info.levelIndex
  //       });
  //     }
  //   }
  //   return indexJson;
  // }

  // getIndexNumber(depthLevel, index_numberings) {
  //   let index = "";
  //   index_numberings[depthLevel]++;
  //   index_numberings[depthLevel + 1] = 0;
  //   for (let i = 0; i <= depthLevel; ++i) {
  //     if (index_numberings[i] === 0) index_numberings[i] = 1;
  //     index = index + index_numberings[i] + ".";
  //   }
  //   return index;
  // }

  // toolbarOptions = {
  //   options: [
  //     "blockType",
  //     "fontFamily",
  //     "fontSize",
  //     "inline",
  //     "textAlign",
  //     "list",
  //     "colorPicker",
  //     "link",
  //     "image",
  //     "history"
  //   ],
  //   inline: {
  //     options: ["bold", "italic", "underline"],
  //     bold: {
  //       className: "t-inline-icon t-common"
  //     },
  //     italic: { className: "t-inline-icon t-common" },
  //     underline: { className: "t-inline-icon t-common" },
  //     className: "t-inline"
  //   },
  //   blockType: {
  //     inDropdown: true,
  //     options: [
  //       "Normal",
  //       "H1",
  //       "H2",
  //       "H3",
  //       "H4",
  //       "H5",
  //       "H6",
  //       "Blockquote",
  //       "Code"
  //     ],
  //     className: "t-blockType t-common"
  //   },
  //   fontSize: {
  //     options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  //     className: "t-fontSize t-common"
  //   },
  //   fontFamily: {
  //     options: [
  //       "Arial",
  //       "Georgia",
  //       "Impact",
  //       "Tahoma",
  //       "Times New Roman",
  //       "Verdana"
  //     ],
  //     className: "t-fontFamily t-common"
  //   },
  //   list: {
  //     inDropdown: true,
  //     options: ["unordered", "ordered", "indent", "outdent"],
  //     className: "t-list t-common"
  //   },
  //   textAlign: {
  //     inDropdown: true,
  //     options: ["left", "center", "right", "justify"],
  //     className: "t-textAlign t-common"
  //   },
  //   colorPicker: {
  //     colors: [
  //       "rgb(97,189,109)",
  //       "rgb(26,188,156)",
  //       "rgb(84,172,210)",
  //       "rgb(44,130,201)",
  //       "rgb(147,101,184)",
  //       "rgb(71,85,119)",
  //       "rgb(204,204,204)",
  //       "rgb(65,168,95)",
  //       "rgb(0,168,133)",
  //       "rgb(61,142,185)",
  //       "rgb(41,105,176)",
  //       "rgb(85,57,130)",
  //       "rgb(40,50,78)",
  //       "rgb(0,0,0)",
  //       "rgb(247,218,100)",
  //       "rgb(251,160,38)",
  //       "rgb(235,107,86)",
  //       "rgb(226,80,65)",
  //       "rgb(163,143,132)",
  //       "rgb(239,239,239)",
  //       "rgb(255,255,255)",
  //       "rgb(250,197,28)",
  //       "rgb(243,121,52)",
  //       "rgb(209,72,65)",
  //       "rgb(184,49,47)",
  //       "rgb(124,112,107)",
  //       "rgb(209,213,216)"
  //     ],
  //     className: "t-colorPicker t-common"
  //   },
  //   link: {
  //     inDropdown: true,
  //     options: ["link", "unlink"],
  //     showOpenOptionOnHover: true,
  //     defaultTargetOption: "_self",
  //     className: "t-link t-common"
  //   },
  //   image: {
  //     urlEnabled: true,
  //     uploadEnabled: true,
  //     alignmentEnabled: true,
  //     previewImage: false,
  //     inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
  //     alt: { present: false, mandatory: false },
  //     defaultSize: {
  //       height: "auto",
  //       width: "auto"
  //     },
  //     className: "t-image t-common"
  //   },
  //   history: {
  //     options: []
  //   }
  // };

  // focusComment = (spanId, oldfocusSpanId = "") => {
  //   $(".comments-wrapper div").removeClass("focused");
  //   console.log("focusing comment", spanId);
  //   let editorBlock = $(`.inline-comment-span[data-span-id="${spanId}"]`);
  //   console.log("meez check ed null", editorBlock);
  //   if (!editorBlock.offset()) return;

  //   console.log("meez check ed ok top", editorBlock.offset().top);
  //   console.log("focusing comment 2", spanId);
  //   let editorBlockTop = this.getOffsetSpan(editorBlock[0]);

  //   spanId = spanId ? spanId : "a";
  //   let commentblock = $(".comment-box." + spanId);
  //   if (!commentblock.offset()) return;

  //   let diff = editorBlockTop - parseInt(commentblock.css("top"));
  //   console.log("focus diff", diff);

  //   $(".comments-wrapper").css("top", diff);
  //   commentblock.addClass("focused");
  //   commentblock.css("top", "50");
  // };

  // renderAddCommentBubble() {
  //   console.log(this.state.hideBubble);

  //   return (
  //     <div
  //       style={{ top: 0, opacity: this.state.hideBubble ? 0 : 1 }}
  //       onClick={this.addNewComment}
  //       className="add-comment-bubble"
  //     >
  //       <CommentPlusIcon />
  //     </div>
  //   );
  // }

  // renderRedirect() {
  //   if (this.state.redirect === "bad-url")
  //     return <Redirect to={{ pathname: "/bad-url" }} />;
  //   else if (this.state.redirect === "new-url")
  //     return (
  //       <Redirect
  //         to={{
  //           pathname: `/insights/contract/${this.state.contract_id}`,
  //           state: {
  //             rawContent: this.state.contentState,
  //             title: this.state.title,
  //             contract_id: this.state.contract_id
  //           }
  //         }}
  //       />
  //     );
  //   else return null;
  // }

  // renderAnalyzingLoader() {
  //   if (this.state.analyzing)
  //     return (
  //       <div className="analyzing-loader-wrapper">
  //         <div className="analyzing-loader">
  //           <div>
  //             <img
  //               className="1"
  //               src={require("../../assets/editor-images/1.png")}
  //             />
  //             <img
  //               className="second"
  //               src={require("../../assets/editor-images/2.png")}
  //             />
  //           </div>
  //           <span>Analyzing...</span>
  //         </div>
  //       </div>
  //     );
  //   else return null;
  // }

  // renderFab() {
  //   if (this.state.showAnalyzeFab)
  //     return (
  //       <div
  //         className={`analyzing-fab-wrapper ${
  //           this.state.showIndex
  //             ? "analyzing-fab-wrapper-index"
  //             : this.state.showComments
  //             ? "analyzing-fab-wrapper-comment"
  //             : this.state.showRightBar
  //             ? "analyzing-fab-wrapper-erb"
  //             : ""
  //         }`}
  //       >
  //         <Badge
  //           badgeContent={
  //             this.state.slimulateAlerts ? this.state.slimulateAlerts.length : 0
  //           }
  //           overlap="circle"
  //           color="secondary"
  //           onClick={e => this.showAnalyzeBar()}
  //         >
  //           <Fab color="#473080" aria-label="edit">
  //             <img src={require("../../assets/icons/analyze_icon.svg")} />
  //           </Fab>
  //         </Badge>
  //       </div>
  //     );
  //   else return null;
  // }

  // renderAnalyzeBar() {
  //   if (this.state.showAnalyzeBar)
  //     return (
  //       <div
  //         className="editor-analyze-right-bar"
  //         /* onScroll={this.attachERBScrollListener} */
  //       >
  //         <div className={"details-section"}>
  //           <div
  //             className="analyze-section-title"
  //             /* onScroll={this.attachERBScrollListener} */
  //           >
  //             <img src={require("../../assets/icons/analyze_icon_light.svg")} />
  //             <h2>Intelligence Assistance</h2>

  //             <CloseIcon onClick={() => this.hideAnalyzeBar()} />
  //           </div>
  //           <div className="analyze-section-list">
  //             {this.state.slimulateAlerts.map(alert => {
  //               return (
  //                 <AnalyzeCard
  //                   onClick={e => {
  //                     if (alert.blockId != null)
  //                       this.moveToIndex(alert.blockId);
  //                   }}
  //                   handleAction={(action, data) =>
  //                     this.handleAnalyzeCardAction(action, data)
  //                   }
  //                   alert={alert}
  //                   type={"risk"}
  //                   title={"Pulse Risk"}
  //                   secTitle={alert.alert_message}
  //                   message={
  //                     alert.data
  //                       ? alert.data.title + ": " + alert.data.description
  //                       : ""
  //                   }
  //                 />
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   else return null;
  // }

  // renderSave() {
  //   if (this.state.saving)
  //     return (
  //       <div className="custom-icon-CTA save-cta-container">
  //         <ContentSaveOutline size="1.1vw" color="#666" />
  //         <p>Saving...</p>
  //       </div>
  //     );
  //   if (this.isCommentsSaved() && this.isSaved() && this.isTitleSaved())
  //     return (
  //       <div className="custom-icon-CTA save-cta-container">
  //         <p>Already Saved</p>
  //       </div>
  //     );
  //   else
  //     return (
  //       <div
  //         className="custom-icon-CTA save-cta-container"
  //         onClick={this.onSave}
  //       >
  //         <ContentSaveOutline size="1.1vw" color="#666" />
  //         <p>Save Document</p>
  //       </div>
  //     );
  // }

  // toogleComments = () => {
  //   this.setState({ showComments: !this.state.showComments });
  // };

  // renderComments() {
  //   return (
  //     <div
  //       className="custom-icon-CTA comments-cta-container"
  //       onClick={() => {
  //         this.hideERB();
  //         this.hideIndex();
  //         this.toogleComments();
  //       }}
  //     >
  //       <CommentIcon size="1.1vw" color="#666" />
  //       <p>{!this.state.showComments ? "Show Comments" : "Hide Comments"}</p>
  //     </div>
  //   );
  // }

  // renderFwd2Modelling() {
  //   return (
  //     <div
  //       style={{ color: "#473080", fontWeight: "bolder" }}
  //       className="custom-icon-CTA fwd-cta-container"
  //       onClick={this.onForward}
  //     >
  //       <SendIcon size="1.1vw" color="#473080" />
  //       <p>
  //         {this.state.forwarding ? "Forwarding..." : "Forward to Modelling"}
  //       </p>
  //     </div>
  //   );
  // }

  // renderHeader() {
  //   return (
  //     <div className="_my-editor-header">
  //       <h2>{this.state.title}</h2>
  //       <div className={"_my-editor-header-details"}>
  //         <ContractHeader contract={this.state.contract} />
  //         {!this.state.showRightBar && this.renderCTAs()}
  //       </div>
  //     </div>
  //   );
  // }

  // renderTitle() {
  //   return (
  //     <div className="_my-editor-title">
  //       {this.state.titleEditingMode ? (
  //         <input
  //           ref={c => (this.title_input = c)}
  //           onBlur={e => this.setState({ titleEditingMode: false })}
  //           type="text"
  //           value={this.state.title}
  //           onChange={e => this.setState({ title: e.target.value })}
  //           onKeyPress={e => {
  //             if (e.key === "Enter") this.title_input.blur();
  //           }}
  //         />
  //       ) : (
  //         <h2
  //           onClick={e => {
  //             this.setState({ titleEditingMode: true }, () =>
  //               this.title_input.focus()
  //             );
  //           }}
  //         >
  //           {this.state.title}
  //         </h2>
  //       )}
  //       {this.state.titleEditingMode ? null : (
  //         <FileDocumentOutline color="#4d4d4d" size="1.5vw" />
  //       )}
  //     </div>
  //   );
  // }

  // renderRulingsModal() {
  //   return (
  //     <Modal
  //       isOpen={this.state.showRulingsModal}
  //       toggle={() => this.toggleRulingsModal()}
  //       centered
  //       className={"rulings-modal"}
  //     >
  //       <ModalHeader toggle={() => this.toggleRulingsModal()}>
  //         Rulings
  //       </ModalHeader>
  //       <ModalBody>
  //         {this.state.rulings.map(ruling => {
  //           return <p>{ruling}</p>;
  //         })}
  //       </ModalBody>
  //     </Modal>
  //   );
  // }

  // onEditorStateChange = editorState => {
  //   let selectedSpanId = getSelectionEntitySpanId(editorState);
  //   let noFocus = (this.state.focusSpanId2 = selectedSpanId);
  //   let block = $(
  //     `[data-offset-key=${editorState.getSelection().focusKey}-0-0]`
  //   );
  //   if (block.offset())
  //     $(`.add-comment-bubble`).css("top", this.getOffsetSpan(block[0]));
  //   console.log("meez check nofocus");
  //   this.setState(
  //     {
  //       editorState: editorState,
  //       focusSpanId2: selectedSpanId,
  //       hideBubble:
  //         editorState.getSelection().isCollapsed() ||
  //         selectionHasEntity(editorState, "COMMENT")
  //       //||
  //       // this.state.comments.hasOwnProperty(
  //       //   editorState.getSelection().focusSpanId
  //       // )
  //     },
  //     () => {
  //       if (!noFocus) {
  //         $(".comments-wrapper div")
  //           .not("." + selectedSpanId ? selectedSpanId : "a")
  //           .removeClass("focused");

  //         setTimeout(() => {
  //           this.setState({ focusSpanId: selectedSpanId });
  //           this.focusComment(selectedSpanId);
  //         }, 200);
  //       } else {
  //         setTimeout(() => {
  //           this.setState({ focusSpanId: selectedSpanId });
  //           this.focusComment(selectedSpanId);
  //         }, 200);
  //       }
  //     }
  //   );

  //   console.log("focus key", editorState.getSelection().focusSpanId);
  //   let startKey = editorState.getSelection().getStartKey();
  //   console.log("key", startKey);

  //   // console.log(
  //   //   "text",
  //   //   editorState.getBlockForKey(startKey) &&
  //   //     editorState.getBlockForKey(startKey).getText()
  //   // );

  //   console.log("ES Changed");
  // };
  // onDrop = e => {
  //   console.log("dropped");
  //   //this.setState({ editorState: this.addEmptyBlock(this.state.editorState) });
  //   // e.preventDefault();
  //   // console.log("");
  //   // console.log(
  //   //   "key",
  //   //   this.state.editorState.getCurrentContent().getSelectionAfter()
  //   // );
  //   // console.log("Droped", e);
  // };

  // sortCommentIndexes = comments => {
  //   Object.keys(comments).map((spanId, index) => {
  //     console.log("sort  index", spanId);
  //     if (
  //       $(`.inline-comment-span[data-span-id="${spanId}"]`).index(
  //         ".inline-comment-span"
  //       ) == undefined
  //     )
  //       comments[spanId].index = -1;
  //     else
  //       comments[spanId].index = $(
  //         `.inline-comment-span[data-span-id="${spanId}"]`
  //       ).index(".inline-comment-span");

  //     console.log("sort  index 2", spanId);
  //   });
  //   return comments;
  // };

  // addNewComment = e => {
  //   let comments = this.state.comments;
  //   let key = this.state.focusSpanId;
  //   let spanId = comments.hasOwnProperty(key) ? key : generateSpanId();
  //   let results = addCommentNew(this.state.editorState, spanId);
  //   this.setState(
  //     {
  //       editorState: results
  //     },
  //     () => {
  //       console.log();
  //       comments[spanId]
  //         ? comments[spanId].data.push()
  //         : (comments[spanId] = {
  //             index: 0,
  //             data: []
  //           });

  //       this.setState(
  //         {
  //           comments: this.sortCommentIndexes(comments),
  //           focusSpanId2: spanId,
  //           focusSpanId: spanId
  //         },
  //         () => {
  //           setTimeout(() => {
  //             this.focusComment(spanId);
  //           }, 500);
  //         }
  //       );
  //       //this.editor.focus();
  //     }
  //   );
  // };

  // addBlock = (direction, editorState) => {
  //   const selection = editorState.getSelection();
  //   const contentState = editorState.getCurrentContent();
  //   const currentBlock = contentState.getBlockForKey(selection.getEndKey());

  //   const blockMap = contentState.getBlockMap();
  //   // Split the blocks
  //   const blocksBefore = blockMap.toSeq().takeUntil(function(v) {
  //     return v === currentBlock;
  //   });
  //   const blocksAfter = blockMap
  //     .toSeq()
  //     .skipUntil(function(v) {
  //       return v === currentBlock;
  //     })
  //     .rest();
  //   const newBlockKey = genKey();
  //   let newBlocks =
  //     direction === "before"
  //       ? [
  //           [
  //             newBlockKey,
  //             new ContentBlock({
  //               key: newBlockKey,
  //               type: "unstyled",
  //               text: ""
  //             })
  //           ],
  //           [currentBlock.getKey(), currentBlock]
  //         ]
  //       : [
  //           [currentBlock.getKey(), currentBlock],
  //           [
  //             newBlockKey,
  //             new ContentBlock({
  //               key: newBlockKey,
  //               type: "unstyled",
  //               text: ""
  //             })
  //           ]
  //         ];
  //   const newBlockMap = blocksBefore
  //     .concat(newBlocks, blocksAfter)
  //     .toOrderedMap();
  //   const newContentState = contentState.merge({
  //     blockMap: newBlockMap,
  //     selectionBefore: selection,
  //     selectionAfter: selection
  //   });
  //   return EditorState.push(editorState, newContentState, "insert-fragment");
  // };

  // handleReturn = (event, editorState) => {
  //   if (isSelectionACommentEntity(editorState)) {
  //     this.setState({
  //       editorState: addLineBreakBetweenComments(editorState, event)
  //     });
  //     return "handled";
  //   }

  //   let selectionState = editorState.getSelection();
  //   let isCollapsed = selectionState.isCollapsed();
  //   if (event.shiftKey && isCollapsed) {
  //     this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
  //     return "handled";
  //   }

  //   let contentState = editorState.getCurrentContent();
  //   if (!(isCollapsed && selectionState.focusOffset == 0)) return "not-handled";
  //   let currentBlock = contentState.getBlockForKey(
  //     selectionState.getAnchorKey()
  //   );
  //   if (currentBlock.getLength() == 0) return "not-handled";

  //   // submit message
  //   console.log(this.state.editorState.getCurrentContent().getPlainText());

  //   this.setState({ editorState: this.addBlock("before", editorState) });

  //   console.log(contentState.getBlockForKey(selectionState.getAnchorKey()));
  //   console.log(selectionState.getAnchorKey());
  //   console.log(selectionState.focusOffset);

  //   return "handled";
  // };

  // renderEditor() {
  //   const { contentState, editorState } = this.state;

  //   return (
  //     <div ref={c => (this.editor_wrapper = c)} onDrop={this.onDrop}>
  //       <Editor
  //         hashtag={true}
  //         customDecorators={[getCommentDecorator()]}
  //         editorRef={element => {
  //           this.editor = element;
  //         }}
  //         readOnly
  //         editorState={this.state.editorState}
  //         handleReturn={this.handleReturn}
  //         wrapperClassName="_my-editor-wrapper"
  //         editorClassName={`_my-editor-editor${
  //           !this.state.showIndex ? "" : " _my-editor-shrinked"
  //         }${!this.state.showComments ? "" : " my-editor-editor-comments"}`}
  //         onEditorStateChange={this.onEditorStateChange}
  //         toolbarClassName="_my-editor-toolbar"
  //         onContentStateChange={this.onContentStateChange}
  //         toolbarHidden
  //         //toolbarCustomButtons={[this.renderCutomToolBar()]}
  //       />
  //       {this.state.showComments && this.renderAllComments()}
  //       {this.state.showComments && this.renderAddCommentBubble()}
  //     </div>
  //   );
  // }

  // renderRightBar() {
  //   return (
  //     <div className="editor-right-bar">
  //       <div
  //         className={`editor-right-bar-item ${
  //           this.state.erbSelectedItem == this.ERB_Clause ? "active" : ""
  //         }`}
  //       >
  //         <div onClick={() => this.handleERBClick(this.ERB_Clause)}>
  //           {/* <img src={require("../../assets/icons/clause-library.svg")} />
  //         <p>Clause Library</p> */}
  //         </div>
  //       </div>

  //       <div
  //         className={`editor-right-bar-item ${
  //           this.state.erbSelectedItem == this.ERB_Summary ? "active" : ""
  //         }`}
  //       >
  //         <div onClick={() => this.handleERBClick(this.ERB_Summary)}>
  //           <img src={require("../../assets/icons/snapshot-summary.svg")} />
  //           <p>Snapshot Summary</p>
  //         </div>
  //       </div>
  //       <div
  //         className={`editor-right-bar-item ${
  //           this.state.erbSelectedItem == this.ERB_Details ? "active" : ""
  //         }`}
  //       >
  //         <div onClick={() => this.handleERBClick(this.ERB_Details)}>
  //           <img src={require("../../assets/icons/details.svg")} />
  //           <p>Details</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // handleERBClick = selectedItem => {
  //   this.hideComments();
  //   this.hideAnalyzeFab();
  //   if (selectedItem == this.state.erbSelectedItem) {
  //     this.hideERB();
  //   } else {
  //     if (this.state.showIndex) this.hideIndex();

  //     if (selectedItem == this.ERB_Summary) {
  //       this.onGetSummary();
  //     }
  //     if (selectedItem == this.ERB_Clause) {
  //       this.setState(
  //         { erbLoading: true, clauseCurrentPage: 1, clauses: [] },
  //         this.onGetClauseList
  //       );
  //     }
  //     if (selectedItem == this.ERB_Details) {
  //       this.setState({ erbLoading: false });
  //     }

  //     this.setState({
  //       showRightBar: true,
  //       erbSelectedItem: selectedItem,
  //       hideRightBar: false
  //     });
  //   }
  // };

  // hideIndex = () => {
  //   this.setState({ showIndex: false }, () =>
  //     setTimeout(() => this.setState({ hideIndex: true, index: [] }), 50)
  //   );
  // };

  // hideERB = () => {
  //   this.setState(
  //     {
  //       erbSelectedItem: null,
  //       showRightBar: false,
  //       hideRightBar: true
  //     },
  //     () => {
  //       if (this.state.slimulateAlerts && this.state.slimulateAlerts.length > 0)
  //         this.showAnalyzeFab();
  //     }
  //   );
  // };

  // hideAnalyzeBar = () => {
  //   this.setState({
  //     showAnalyzeBar: false
  //   });
  // };

  // hideAnalyzeFab = () => {
  //   this.setState({
  //     showAnalyzeFab: false
  //   });
  // };

  // showAnalyzeFab = () => {
  //   this.setState({
  //     showAnalyzeFab: true
  //   });
  // };

  // showAnalyzeBar = () => {
  //   this.hideERB();
  //   this.hideIndex();
  //   this.setState({
  //     showAnalyzeBar: true
  //   });
  // };

  // hideComments = () => {
  //   this.setState({
  //     showComments: false
  //   });
  // };

  // showComments = () => {
  //   this.hideERB();
  //   this.hideIndex();
  //   this.setState({
  //     showComments: true
  //   });
  // };

  // // addEmptyBlock = editorState => {
  // //   const newBlock = new ContentBlock({
  // //     key: genKey(),
  // //     type: "unstyled",
  // //     text: "NEW"
  // //   });

  // //   const contentState = editorState.getCurrentContent();
  // //   const newBlockMap = contentState.getBlockMap().set(newBlock.key, newBlock);

  // //   return EditorState.push(
  // //     editorState,
  // //     ContentState.createFromBlockArray(newBlockMap.toArray())
  // //       .set("selectionBefore", contentState.getSelectionBefore())
  // //       .set("selectionAfter", contentState.getSelectionAfter())
  // //   );
  // // };

  // onUpdateSummary = () => {
  //   let summary = Object.assign({}, this.state.summary);
  //   if (summary.hasOwnProperty("alerts")) delete summary["alerts"];
  //   delete summary["id"];
  //   Contract.update_summary(this.state.contract_id, summary)
  //     .then(res => {
  //       this.onGetSummary(false);
  //       //ToastsStore.success(res.message);
  //     })
  //     .catch(e => {
  //       ToastsStore.error(
  //         "An error occurred while saving summary! " + e.error.message
  //       );
  //       console.log(e);
  //       this.setState({ saving: false });
  //     });
  // };

  // handleEditSummaryButton = () => {
  //   if (this.state.editSummaryMode) this.onUpdateSummary();
  //   this.setState({ editSummaryMode: !this.state.editSummaryMode });
  // };

  // onGetMoreClauseList = throttle(
  //   () => {
  //     this.onGetClauseList();
  //   },
  //   800,
  //   {
  //     trailing: false
  //   }
  // );

  // onGetClauseList = () => {
  //   if (
  //     this.state.erbClauseLoading ||
  //     this.state.clauseCurrentPage > this.state.clauseLastPage
  //   )
  //     return;
  //   let page = this.state.clauseCurrentPage;
  //   let keyword = this.state.erbClauseSearchKeyword;
  //   console.log(page, keyword);
  //   this.setState({ erbClauseLoading: true, erbClauseSearchNoResults: false });

  //   keyword && keyword.length > 0
  //     ? Clause.get_clause_list_by_keyword(keyword, page)
  //         .then(res => {
  //           this.setState({
  //             clauses: this.state.clauses.concat(res.data),
  //             erbLoading: false,
  //             erbClauseLoading: false,
  //             clauseCurrentPage: page + 1,
  //             clauseLastPage: res.last_page
  //           });
  //           console.log(res);
  //         })
  //         .catch(e => {
  //           this.setState({ erbLoading: false, erbClauseLoading: false });
  //           console.log(e);
  //           e.error.message == "No Clause Record Found"
  //             ? this.setState({ erbClauseSearchNoResults: true })
  //             : ToastsStore.error(e.error.message);
  //         })
  //     : Clause.get_clause_list(page)
  //         .then(res => {
  //           this.setState({
  //             clauses: this.state.clauses.concat(res.data),
  //             erbLoading: false,
  //             erbClauseLoading: false,
  //             clauseCurrentPage: page + 1,
  //             clauseLastPage: res.last_page
  //           });
  //           console.log(res);
  //         })
  //         .catch(e => {
  //           this.setState({ erbLoading: false, erbClauseLoading: false });
  //           console.log(e);
  //           e.error.message == "No Clause Record Found"
  //             ? this.setState({ erbClauseSearchNoResults: true })
  //             : ToastsStore.error(e.error.message);
  //         });
  // };

  // onGetSummary = (showLoading = true) => {
  //   if (showLoading) this.setState({ erbLoading: true });

  //   Contract.get_summary(this.state.contract_id)
  //     .then(res => {
  //       let summary = res.content;

  //       this.setState({ summary: summary, erbLoading: false });
  //       console.log(res);
  //     })
  //     .catch(e => {
  //       this.setState({ erbLoading: false });
  //       console.log(e);
  //       ToastsStore.error(e.error.message);
  //     });
  // };

  // renderExtendedRightBar() {
  //   if (!this.state.hideRightBar) {
  //     return (
  //       <div
  //         className="editor-extended-right-bar"
  //         onScroll={this.attachERBScrollListener}
  //       >
  //         {this.state.erbSelectedItem == this.ERB_Summary &&
  //           this.renderERBSummary()}
  //         {this.state.erbSelectedItem == this.ERB_Clause &&
  //           this.renderERBClause()}
  //         {this.state.erbSelectedItem == this.ERB_Details &&
  //           this.renderERBDetails()}
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  // attachERBScrollListener = e => {
  //   console.log("ok");
  //   if (this.state.erbSelectedItem == this.ERB_Clause) {
  //     const obj = e.target;
  //     const height = obj.scrollHeight - obj.offsetHeight;
  //     const scrollPos = obj.scrollTop;
  //     const factor = 0.7;
  //     if (scrollPos >= factor * height) {
  //       if (!this.state.erbClauseLoading) {
  //         this.onGetMoreClauseList();
  //       }
  //     }
  //   }
  // };

  // renderERBSummary() {
  //   if (this.state.erbLoading)
  //     return (
  //       <div
  //         style={{
  //           display: "inline-block",
  //           alignSelf: "center",
  //           margin: "auto"
  //         }}
  //       >
  //         <Spinner style={{ color: "#473080" }} />
  //       </div>
  //     );
  //   return (
  //     <div
  //       className={"summary-wrapper"}
  //       style={{
  //         display: "flex",

  //         flexDirection: "column"
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           alignContent: "center",
  //           marginBottom: "1vw",
  //           alignItems: "center"
  //         }}
  //       >
  //         <h3>Contract Summary</h3>
  //       </div>
  //       {this.renderSummaryItem(
  //         false,
  //         "Title of Contract",
  //         this.state.summary.title,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               title: value
  //             }
  //           }));
  //         },
  //         "textarea"
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "Value",
  //         this.state.summary.value,
  //         e => {
  //           let value = e.target.value;
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               value: value
  //             }
  //           }));
  //         },
  //         "input",
  //         null,
  //         this.state.summary.alerts.value
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "Version",
  //         this.state.summary.version,
  //         e => {
  //           let value = e.target.value;
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               version: value
  //             }
  //           }));
  //         },
  //         "input",
  //         null,
  //         this.state.summary.alerts.version
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "Type",
  //         this.state.summary.type,
  //         e => {
  //           let value = e.target.value;
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               type: value
  //             }
  //           }));
  //         },
  //         "input"
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "Sector",
  //         this.state.summary.sector,
  //         value =>
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               sector: value
  //             }
  //           })),
  //         "dropdown",
  //         ["Public", "Private"],
  //         this.state.summary.alerts.sector
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "Entity",
  //         this.state.summary.entity == "NG" ? "Non Government" : "Government",
  //         value =>
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               entity: value == "Non Government" ? "NG" : "G"
  //             }
  //           })),
  //         "dropdown",
  //         ["Government", "Non Government"],
  //         this.state.summary.alerts.entity
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "PO Start Date",
  //         this.state.summary.start_date,
  //         e => {
  //           let value = e.target.value;
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               start_date: value
  //                 .split("-")
  //                 .reverse()
  //                 .join("/")
  //             }
  //           }));
  //         },
  //         "date",
  //         [],
  //         this.state.summary.alerts.start_date
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "PO End Date",
  //         this.state.summary.end_date,
  //         e => {
  //           let value = e.target.value;
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               end_date: value
  //                 .split("-")
  //                 .reverse()
  //                 .join("/")
  //             }
  //           }));
  //         },
  //         "date",
  //         []
  //       )}

  //       {this.renderSummaryItem(
  //         false,
  //         "Risk",
  //         this.state.summary.risk + "%",
  //         e => {
  //           let value = e.target.value;
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               risk: value
  //             }
  //           }));
  //         },
  //         "input",
  //         [],
  //         this.state.summary.alerts.risk
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Contract Purpose",
  //         this.state.summary.contract_purpose,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               contract_purpose: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.contract_purpose
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Which Template",
  //         this.state.summary.which_template,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               which_template: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.which_template
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Key Business Risk",
  //         this.state.summary.key_business_risk,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               key_business_risk: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.key_business_risk
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Key Buisness Opportunities",
  //         this.state.summary.key_business_opportunities,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               key_business_opportunities: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.key_business_opportunities
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Non-Standard Terms",
  //         this.state.summary.nonstandard_terms,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               nonstandard_terms: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.nonstandard_terms
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Termination Options",
  //         this.state.summary.termination_options,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               termination_options: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.termination_options
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "IP Protected",
  //         this.state.summary.ip_protected,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               ip_protected: value
  //             }
  //           }));
  //         },
  //         "input",
  //         null,
  //         this.state.summary.alerts.ip_protected
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Misc Problems",
  //         this.state.summary.misc_problems,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               misc_problems: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.misc_problems
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Related Documents",
  //         this.state.summary.related_documents,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               related_documents: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.related_documents
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Counter Party Country Name",
  //         this.state.summary.country_name,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               country_name: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.country_name
  //       )}

  //       {this.renderSummaryItem(
  //         true,
  //         "Counter Party Name",
  //         this.state.summary.second_party_name,
  //         e => {
  //           let value = e.target.value.replace(/\n|\r/g, "");
  //           this.setState(prevState => ({
  //             summary: {
  //               ...prevState.summary,
  //               second_party_name: value
  //             }
  //           }));
  //         },
  //         "textarea",
  //         null,
  //         this.state.summary.alerts.second_party_name
  //       )}

  //       {this.state.summary.other_parties_to_contract &&
  //         this.renderSummaryItem(
  //           true,
  //           "Other Parties to Contract",
  //           this.state.summary.other_parties_to_contract.length > 0
  //             ? this.state.summary.other_parties_to_contract.reduce(
  //                 (string, item) => string + ", " + item
  //               )
  //             : "",
  //           tags => {
  //             this.setState(prevState => ({
  //               summary: {
  //                 ...prevState.summary,
  //                 other_parties_to_contract: tags
  //               }
  //             }));
  //           },
  //           "tags",
  //           null,
  //           this.state.summary.alerts.other_parties_to_contract
  //         )}
  //     </div>
  //   );
  // }

  // renderSummaryInput(type, value, onChangeHandler, items) {
  //   switch (type) {
  //     case "textarea":
  //       return (
  //         <TextArea type="text" value={value} onChange={onChangeHandler} />
  //       );
  //     case "input":
  //       return <input type="text" value={value} onChange={onChangeHandler} />;
  //     case "dropdown":
  //       return (
  //         <Dropdown items={items} value={value} onChange={onChangeHandler} />
  //       );
  //     case "date":
  //       return (
  //         <input
  //           type="date"
  //           required={true}
  //           value={value
  //             .split("/")
  //             .reverse()
  //             .join("-")}
  //           onChange={onChangeHandler}
  //         />
  //       );
  //     case "tags":
  //       return (
  //         <ReactTagInput
  //           tags={value.length > 0 ? value.split(", ") : []}
  //           onChange={onChangeHandler}
  //         />
  //       );
  //     default:
  //       return <span>ops</span>;
  //   }
  // }

  // renderSummaryItem(
  //   editable,
  //   title,
  //   value,
  //   onChangeHandler,
  //   type,
  //   items = null,
  //   warningMessage
  // ) {
  //   return (
  //     <p>
  //       <div className={"summary-item-label"}>
  //         <strong>{title}</strong>

  //         {warningMessage && (
  //           <ToolTip title={warningMessage} placement="left">
  //             <div>
  //               <WarningOutlineIcon color={"red"}></WarningOutlineIcon>
  //             </div>
  //           </ToolTip>
  //         )}
  //       </div>
  //       {this.state.editSummaryMode && editable
  //         ? this.renderSummaryInput(type, value, onChangeHandler, items)
  //         : value
  //         ? value
  //         : "N/A"}
  //     </p>
  //   );
  // }

  // renderEnitityDropdown() {
  //   return (
  //     <Dropdown
  //       items={["Non Government", "Government"]}
  //       value={
  //         this.state.summary.entity == "NG" ? "Non Government" : "Government"
  //       }
  //       onChange={value =>
  //         this.setState(prevState => ({
  //           summary: {
  //             ...prevState.summary,
  //             entity: value == "Non Government" ? "NG" : "G"
  //           }
  //         }))
  //       }
  //     />
  //   );
  // }

  // onErbClauseSearch = e => {
  //   console.log(e.target.value);
  //   this.setState(
  //     {
  //       clauses: [],
  //       clauseCurrentPage: 1,
  //       clauseLastPage: Infinity,
  //       erbClauseSearchKeyword: e.target.value
  //     },
  //     this.onGetClauseList
  //   );
  // };

  // renderSectorDropdown() {
  //   return (
  //     <Dropdown
  //       items={["Public", "Private"]}
  //       value={this.state.summary.sector}
  //       onChange={value =>
  //         this.setState(prevState => ({
  //           summary: {
  //             ...prevState.summary,
  //             sector: value
  //           }
  //         }))
  //       }
  //     />
  //   );
  // }
  // renderERBClause() {
  //   if (this.state.erbLoading)
  //     return (
  //       <div
  //         style={{
  //           display: "inline-block",
  //           alignSelf: "center",
  //           margin: "auto"
  //         }}
  //       >
  //         <Spinner style={{ color: "#473080" }} />
  //       </div>
  //     );
  //   return (
  //     <div className={"clause-wrapper"}>
  //       <h3>Clause Library</h3>
  //       <div className={"search-filter-wrapper"} style={{ display: "flex" }}>
  //         <input
  //           value={this.state.erbClauseSearchKeyword}
  //           onChange={this.onErbClauseSearch}
  //           placeholder={"Search"}
  //         ></input>
  //         <img src={require("../../assets/icons/equalizer.svg")} />
  //       </div>
  //       <Tabs items={["Fallback", "All"]} onChange={null} />

  //       {this.state.erbClauseLoading ? (
  //         <div
  //           style={{
  //             display: "flex",
  //             alignSelf: "center",
  //             margin: "auto"
  //           }}
  //         >
  //           <Spinner style={{ color: "#473080", margin: "16px auto" }} />
  //         </div>
  //       ) : null}
  //       {this.state.erbClauseSearchNoResults ? (
  //         <div
  //           style={{
  //             display: "flow-root",
  //             alignSelf: "center",
  //             textAlign: "center",
  //             margin: "auto"
  //           }}
  //         >
  //           No Results
  //         </div>
  //       ) : null}
  //     </div>
  //   );
  // }
  // renderERBDetails() {
  //   if (this.state.erbLoading)
  //     return (
  //       <div
  //         style={{
  //           display: "inline-block",
  //           alignSelf: "center",
  //           margin: "auto"
  //         }}
  //       >
  //         <Spinner style={{ color: "#473080" }} />
  //       </div>
  //     );
  //   return (
  //     <div
  //       className={"details-wrapper"}
  //       style={{
  //         display: "flex",

  //         flexDirection: "column"
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           alignContent: "center",
  //           marginBottom: ".5vw",
  //           alignItems: "center"
  //         }}
  //       >
  //         <h3>Details</h3>
  //       </div>
  //       <p>
  //         ID
  //         <br />
  //         XXXXX
  //       </p>
  //       <p>
  //         Version
  //         <br />
  //         XXXXX
  //       </p>
  //       <p>
  //         Round
  //         <br />
  //         XXXXX
  //       </p>
  //       <p>
  //         Last Modified
  //         <br />
  //         XXXX
  //       </p>
  //       <p>
  //         Duration
  //         <br />
  //         XXXX
  //       </p>
  //       <span className={"details-break"}></span>
  //       <div className={"details-section"}>
  //         <div className={"details-section-title"}>
  //           <h3>Authority</h3>
  //           <PlusIcon />
  //         </div>
  //         <div className={"details-item"}>
  //           <img
  //             src={"http://localhost:3000/static/media/avatar.28908e67.png"}
  //           />
  //           <span>hahaha</span>
  //         </div>
  //         <div className={"details-item"}>
  //           <img
  //             src={"http://localhost:3000/static/media/avatar.28908e67.png"}
  //           />
  //           <span>hahaha</span>
  //         </div>
  //       </div>
  //       <span className={"details-break"}></span>
  //       <div className={"details-section"}>
  //         <div className={"details-section-title"}>
  //           <h3>Secondary Party</h3>
  //         </div>
  //         <div className={"details-item"}>
  //           <img
  //             src={"http://localhost:3000/static/media/avatar.28908e67.png"}
  //           />
  //           <span>General Motors</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // getAbsPosition = function(el) {
  //   var el2 = el;
  //   var curtop = 0;
  //   var curleft = 0;
  //   if (document.getElementById || document.all) {
  //     do {
  //       curleft += el.offsetLeft - el.scrollLeft;
  //       curtop += el.offsetTop - el.scrollTop;
  //       el = el.offsetParent;
  //       el2 = el2.parentNode;
  //       while (el2 != el) {
  //         curleft -= el2.scrollLeft;
  //         curtop -= el2.scrollTop;
  //         el2 = el2.parentNode;
  //       }
  //     } while (el.offsetParent);
  //   } else if (document.layers) {
  //     curtop += el.y;
  //     curleft += el.x;
  //   }
  //   return [curtop, curleft];
  // };

  // lastKey = "";
  // renderComment = (comments, spanId, index) => {
  //   //console.log("COMMENT RENDERED ="+index);

  //   let editorBlock = $(`.inline-comment-span[data-span-id="${spanId}"]`);
  //   console.log("meez check rc eb", editorBlock);

  //   if (!editorBlock.offset()) return;

  //   // let newblock = $(`[data-offset-key="${comment.key}"][data-block="true"]`);
  //   // if (!newblock.offset()) return;

  //   // window.block = newblock;

  //   // console.log("last", $(`.comment-box ${this.lastKey}`).outerHeight());

  //   // let lastCommentBlock = $(".comment-box").last();
  //   // let lastBlockHeight = lastCommentBlock.outerHeight()
  //   //   ? lastCommentBlock.outerHeight()
  //   //   : 144;
  //   // console.log("last bottom", this.lastBottom);
  //   // console.log("lastBlockHeight", lastBlockHeight);
  //   // console.log("top", newblock.offset().top + 174);
  //   // console.log("bottom", newblock.offset().top + 174 + lastBlockHeight);
  //   // let offset =
  //   //   newblock.offset().top + 174 > this.lastBottom
  //   //     ? newblock.offset().top + 174
  //   //     : this.lastBottom;
  //   // this.lastBottom = offset + lastBlockHeight + 20;
  //   // this.lastKey = "." + comment.key;
  //   // console.log(offset);

  //   let top = 0;
  //   let prev = $(".comment-box " + this.lastKey);
  //   if (prev.offset()) {
  //     let prevBottom = this.getOffsetSpan(prev[0]);
  //     prev.outerHeight();
  //     top = top > prevBottom + 20 ? top : prevBottom + 20;
  //   }
  //   // console.log()

  //   this.lastKey = "." + spanId;
  //   let oldfocusSpanId = this.state.focusSpanId;
  //   return (
  //     <div
  //       key={spanId}
  //       style={{ top: top, zIndex: this.state.focusSpanId2 == spanId ? 2 : 1 }}
  //       fake-top={top}
  //       onClick={() => this.handleCommentBoxClick(spanId, oldfocusSpanId)}
  //       className={`comment-box ${spanId}`}
  //       data={spanId}
  //     >
  //       {console.log(spanId, this.state.focusSpanId)}
  //       {/* <CommentItem
  //         blockKey={spanId}
  //         focussed={this.state.focusSpanId2 == spanId}
  //         comments={comments}
  //         onStartEditing={this.handleStartEditing}
  //         onStartDelete={this.handleStartDelete}
  //         onDelete={this.handleCommentDelete}
  //         onCreateNewComment={this.handleCreateNewComment}
  //         onAddNewReply={this.handleNewReply}
  //       /> */}
  //     </div>
  //   );
  // };

  // handleCommentBoxClick = (key, oldfocusSpanId) => {
  //   if (key == this.state.focusSpanId) return;

  //   this.setState({ focusSpanId2: key, hideBubble: true });

  //   setTimeout(() => {
  //     this.focusComment(key);
  //     this.moveToSpanId(key);
  //     this.setState({ focusSpanId: key });
  //   }, 200);
  // };

  // handleCommentDelete = spanId => {
  //   let comments = this.state.comments;
  //   delete comments[spanId];

  //   console.log("deleted", spanId);
  //   this.setState({
  //     editorState: removeCommentBySpanId(this.state.editorState, spanId),
  //     comments: comments
  //   });
  // };

  // handleStartEditing = (spanId, index, id) => {
  //   alert(spanId + "-" + index + "-" + id);
  // };
  // handleStartDelete = (spanId, index, id) => {
  //   alert(spanId + "-" + index + "-" + id);
  // };

  // handleCreateNewComment = (key, value) => {
  //   let comments = this.state.comments;
  //   let commentDetails = {
  //     comment: value,
  //     contract_id: this.state.contract_id,
  //     block_key: key
  //   };
  //   Comment.add_new_comment(commentDetails)
  //     .then(body => {
  //       comments[key].data.push(body);
  //       this.setState({ comments: comments }, () => {
  //         this.onSave();
  //       });
  //     })
  //     .catch(e => {
  //       ToastsStore.error(
  //         e.error ? e.error.message : "Error in creating comment."
  //       );
  //     });
  // };

  // handleNewReply = (key, value) => {
  //   let comments = this.state.comments;
  //   let commentDetails = {
  //     comment: value,
  //     contract_id: this.state.contract_id,
  //     block_key: key,
  //     parent_id: comments[key].data[0].id
  //   };
  //   Comment.add_new_comment(commentDetails)
  //     .then(body => {
  //       comments[key].data.push(body);
  //       this.setState({ comments: comments });
  //     })
  //     .catch(e => {
  //       ToastsStore.error(e.error.message);
  //     });
  // };

  // lastBottom = 0;

  // getSortedComments = () => {
  //   let comments = this.state.comments;
  //   let sortedKeys = Object.keys(comments).sort(function(a, b) {
  //     return comments[a].index > comments[b].index; // Organize the category array
  //   });
  //   return sortedKeys;
  // };

  // renderAllComments = () => {
  //   return (
  //     <div className={"comments-wrapper"}>
  //       {this.getSortedComments().map((key, index) => {
  //         console.log(
  //           "meez comment render",
  //           key,
  //           this.state.comments[key].data
  //         );
  //         return this.renderComment(this.state.comments[key].data, key, index);
  //       })}
  //       {/* {this.state.comments.map((comment,index) => {
  //         return this.renderComment(comment,index);
  //       })} */}
  //     </div>
  //   );
  // };

  // renderCTAs() {
  //   return (
  //     <div className="pulse-Editor-CTA-container">
  //       <div onClick={() => this.onRemodel()}>
  //         {/* <img src={require("../../assets/icons/share.svg")} /> */}
  //         Remodel
  //       </div>
  //       <div onClick={() => this.onAnalyze()}>
  //         <img src={require("../../assets/icons/chroma_logo_white.svg")} />
  //         {this.state.action != "modelling" ? "Analyze" : "Simulate"}
  //       </div>
  //     </div>
  //   );
  // }

  // renderIndex() {
  //   let index_numberings = Array(7).fill(0);
  //   if (!this.state.hideIndex)
  //     return (
  //       <div
  //         className={`editor-index-wrapper${
  //           !this.state.showIndex ? " editor-index-wrapper-collapsed" : ""
  //         }`}
  //       >
  //         <div
  //           onClick={() =>
  //             this.setState({ showIndex: false }, () =>
  //               setTimeout(
  //                 () => this.setState({ hideIndex: true, index: [] }),
  //                 50
  //               )
  //             )
  //           }
  //         >
  //           Index <ChevronLeft size="1.4vw" color="#4d4d4d" />
  //         </div>
  //         <div>
  //           {this.state.index.map(index => (
  //             <p
  //               style={{
  //                 fontWeight: index.weight,
  //                 paddingLeft: this.index_paddings[index.levelIndex],
  //                 display: "flex"
  //               }}
  //               key={index.key}
  //               onClick={e => this.moveToIndex(index.key)}
  //             >
  //               <span className="index_text">
  //                 {`${this.getIndexNumber(
  //                   this.index_paddings[index.levelIndex] / 12 - 1,
  //                   index_numberings
  //                 )} ${index.text}`}
  //               </span>
  //               <span
  //                 className={`index_dot ${
  //                   this.state.slimulateAlerts
  //                     .map(i => i.blockId)
  //                     .includes(index.key)
  //                     ? "index_dot_red"
  //                     : ""
  //                 }`}
  //               ></span>
  //             </p>
  //           ))}
  //         </div>
  //       </div>
  //     );
  //   return (
  //     <div
  //       onClick={() => {
  //         this.hideERB();

  //         this.setState({
  //           showIndex: true,
  //           index: this.generateIndex(),
  //           hideIndex: false
  //         });
  //       }}
  //       className="editor-index-CTA"
  //     >
  //       <FileDocumentOutline size="1.4vw" color="#4d4d4d" />
  //     </div>
  //   );
  // }

  // renderTestCTA(show = true) {
  //   if (show)
  //     return (
  //       <React.Fragment>
  //         <button
  //           onClick={this.onTest}
  //           style={{
  //             zIndex: "300",
  //             position: "fixed",
  //             top: "70px",
  //             right: "101px"
  //           }}
  //         >
  //           Test
  //         </button>
  //         <button
  //           onClick={this.onTest2}
  //           style={{
  //             zIndex: "300",
  //             position: "fixed",
  //             top: "70px",
  //             right: "151px"
  //           }}
  //         >
  //           Test2
  //         </button>
  //       </React.Fragment>
  //     );
  //   return null;
  // }

  // renderCutomToolBar() {
  //   return (
  //     <div className="custom-toolbar">
  //       {this.renderSave()}
  //       {this.renderComments()}

  //       {this.state.action != "modelling" && this.renderFwd2Modelling()}
  //       {/*this.state.action != "modelling" &&*/
  //       !this.state.showRightBar && this.renderCTAs()}
  //     </div>
  //   );
  // }

  // renderHeaderBg() {
  //   return <div className="editor-header-bg"></div>;
  // }

  // showNotification = (title, message, type, alert) => {
  //   notificationRU.notice({
  //     content: (
  //       <AnalyzeNotification
  //         type={type}
  //         title={title}
  //         message={message}
  //         alert={alert}
  //       />
  //     ),
  //     duration: 2,
  //     closable: true,
  //     style: { top: 0, left: "calc(100vw - 100%)", transition: "all 1s ease" },
  //     className: `right-up`
  //   });
  // };

  // showAlertsNotification = alerts => {
  //   const sn = this.showNotification;
  //   alerts.forEach(function(alert, index) {
  //     setTimeout(function() {
  //       sn(
  //         alert.metadata.category == "pusle_risk" ? "Pulse Risk" : "Pulse Risk",
  //         alert.alert_message,
  //         alert.metadata.category,
  //         alert
  //       );
  //     }, 700 * index);
  //   });
  // };

  render() {
    return <MyPulseContract {...this.props} />;
    // return (
    //   <React.Fragment>
    //     <div className="_my-editor-container">
    //       <div className="avoid-scroll-wrapper">
    //         <div
    //           ref={c => (this.scroll_component = c)}
    //           className="editor-main-container"
    //         >
    //           {/* {this.renderHeaderBg()} */}
    //           {this.renderHeader()}
    //           {this.renderEditor()}
    //           {this.renderFab()}
    //         </div>
    //         {this.renderIndex()}
    //         {this.renderTestCTA(false)}
    //         {this.renderAnalyzingLoader()}
    //       </div>
    //       {this.renderExtendedRightBar()}
    //       {this.renderRightBar()}
    //       {this.renderRedirect()}
    //       {this.renderAnalyzeBar()}
    //       {this.renderRulingsModal()}
    //     </div>
    //   </React.Fragment>
    // );
  }
}
