import React from "react";
import "./common.scss";

const primary = props => (
  <div className={"__btn-primary " + props.className} onClick={props.onClick}>
    {props.children}
  </div>
);

const Button = {
  primary
};

export default Button;
