import Auth from "../auth/Auth";

const get_http_error_message = status => {
  switch (status) {
    case 200:
      return "Success";
    case 500:
      return "Server Error";
    case 400:
      return "Client Error";
    case 422:
      return "Unprocessible Entity";
    case 406:
      return "Non Acceptable Content";
    case 401:
      return "Unauthorized Request";
    default:
      return "Unknown Error";
  }
};

const create_api_error = (res, resJson) => {
  if (
    res.status === 401 ||
    resJson.message === "Access denied due to expired/invalid token"
  )
    Auth.sign_out();
  return {
    api_error: true,
    error: {
      http_status: res.status,
      http_message: get_http_error_message(res.status),
      status: resJson.status,
      message:
        resJson.body != undefined ? resJson.body.message : "Unknown Error"
    }
  };
};

const create_general_error = e => {
  return {
    api_error: false,
    error: e
  };
};

const error = {
  create_api_error,
  create_general_error
};

export default error;
