import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Layout from "../layout/Layout";
import Auth from "../../api/auth/Auth";
import Profile from "../profile/Profile";
import LogIn from "../auth/LogIn";
import ModellingContracts from "../modelling/ModellingContracts";
import NotFound from "../not-found/NotFound";
import MyWorkspace from "../insight/MyWorkspace";
import MyContract from "../editor/MyContract";
import MissionControl from "../mission-control/MissionControl";
import MyPlaybook from "../playbook/MyPlaybook";
import MyPlaybookDetail from "../playbook/MyPlaybookDetail";
import PulseContacts from "../pulse/PulseContracts";
import PulseContract from "../pulse/PulseContract";
import InsightContract from "../insight/InsightContract";
import ModellingContract from "../modelling/ModelingContract";
import SecondPartyContract from "../second-party/SecondPartyContract";
import TestScreen from "../test/TestScreen";
import PulseDashboard from "../pulse-dashboard/PulseDashboard";
import GifMissionControl from '../gif-mission-control/GifMissionControl';


function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function SecondPartyRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={props => <Component {...props} />} />;
}

function NonPrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/mission-control" }} />
        )
      }
    />
  );
}

export default class Router extends Component {
  state = {
    redirect: false
  };

  wrappedRoutes = () => (
    <Layout>
      <Switch>
        <Route exact path="/pulse/dashboard" component={PulseDashboard} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/test" component={TestScreen} />
        <Route
          exact
          path="/modelling/contracts"
          component={ModellingContracts}
        />
        <Route
          exact
          path="/modelling/contract/:contract_id(\d+)"
          component={ModellingContract}
        />
        <Route exact path="/insights/workspace" component={MyWorkspace} />
        <Route
          exact
          path="/insights/contract/new-contract"
          component={InsightContract}
        />
        <Route
          exact
          path="/insights/contract/:contract_id(\d+)"
          component={InsightContract}
        />
        <Route
          exact
          path="/pulse/contract/:contract_id(\d+)"
          component={PulseContract}
        />
        <Route exact path="/pulse/contracts" component={PulseContacts} />
        <Route
          exact
          path="/modelling/contract/:contract_id(\d+)"
          component={MyContract}
        />
        <Route exact path="/mission-control" component={MissionControl} />
        <Route exact path="/playbook" component={MyPlaybook} />
        <Route path="/playbook" component={MyPlaybookDetail} />
        <Route path='/gif-contract' component={GifMissionControl}/>
        <Route path="/" component={NotFound} />
      </Switch>
    </Layout>
  );

  componentDidMount() {
    setTimeout(
      () =>
        Auth.on_change_state(user => {
          if (user && window.location.pathname !== "/mission-control") {
            this.setState({
              redirect: "/mission-control"
            });
          } else if (!user && window.location.pathname !== "/login")
            this.setState({
              redirect: "/login"
            });
        }),
      200
    );
  }

  render() {
    return (
      <Switch>
        <NonPrivateRoute
          authed={Auth.is_logged_in()}
          exact
          path="/login"
          component={LogIn}
        />
        <NonPrivateRoute
          authed={Auth.is_logged_in()}
          exact
          path="/"
          component={LogIn}
        />
        <SecondPartyRoute
          exact
          path="/contract/counter_party/:contract_hash(\w+)"
          component={SecondPartyContract}
        />
        <PrivateRoute
          authed={Auth.is_logged_in()}
          path="/"
          component={this.wrappedRoutes}
        />
      </Switch>
    );
  }
}
