import React, { Component } from "react";
import "./common.scss";

export default class Header extends Component {
  render() {
    return (
      <h1 style={this.props.headingStyle} className="_page-header">
        {this.props.heading}
      </h1>
    );
  }
}
