import React, { Component } from "react";

import Editor from "../../api/editor/Editor";
import { ToastsStore } from "react-toasts";
import Contract from "../../api/contract/Contract";
import throttle from "lodash.throttle";
import { Spinner, Collapse, UncontrolledCollapse } from "reactstrap";
import PencilOffIcon from "mdi-react/PencilOffIcon";

import { Link } from "react-router-dom";

export default class MyWorkspace extends Component {
  state = {
    collapse:false,
    loading: false,
    contractLoading: false,
    page: 1,
    listOpen: false,
    newListOpen: false,
    folders: [
      {
        sharedWith: [
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png"),
          require("../../assets/images/avatar.png")
        ],
        folderName: "Chroma"
      }
    ],
    quickAccess: [],
    sharedWithMe: [],
    filter: "all", // al/shared
    files: []
  };

  load_contracts() {
    if (this.state.page <= 0 || this.state.loading) return;
    this.setState({ loading: true });
    return Contract.get_contracts_list(
      "INS",
      this.state.page,
      this.state.filter == "shared" ? true : false
    )
      .then(res => {
        const data = this.state.files;
        res.data.forEach(item => {
          const contract = item;
          contract.quickImg = require("../../assets/icons/quickaccess.png");
          data.push(contract);
        });
        this.setState({
          files: this.state.files,
          loading: false,
          page: res.data.length === res.per_page ? res.current_page + 1 : -1,
          quickAccess:
            res.current_page == 1
              ? this.state.files.slice()
              : this.state.quickAccess
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        console.log(e);
        if (e.error && e.error.message) {
          ToastsStore.error(e.error.message);
          return;
        }
        ToastsStore.error("Failed to Load Contracts List!");
      });
  }

  load_more = throttle(this.load_contracts, 800, {
    trailing: false
  });

  componentDidMount() {
    this.load_contracts().then(() => {
      this.load_contracts(2);
      this.load_contracts(3);
    });
    this.page_wrapper.onscroll = e => {
      const obj = e.target;
      const height = obj.scrollHeight - obj.offsetHeight;
      const scrollPos = obj.scrollTop;
      const factor = 0.9;
      if (scrollPos >= factor * height) {
        this.load_more();
      }
    };
  }

  getLocaleTime = d => {
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  getElapsedTime = d1 => {
    const d2 = new Date();
    const delta = d2.getTime() - d1.getTime();
    if (delta < 0) return d1.toDateString();
    const today = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
    const yesterday = new Date(today - 24 * 60 * 60 * 1000);
    const month = new Date(today - 24 * 30 * 60 * 60 * 1000);
    const year = new Date(today - 24 * 30 * 12 * 60 * 60 * 1000);
    if (d1 < year) return d1.toDateString();
    else if (d1 < month)
      return `${Math.floor(delta / 1000 / 60 / 60 / 24 / 30)} months ago`;
    else if (d1 < yesterday)
      return `${Math.floor(delta / 1000 / 60 / 60 / 24)} days ago`;
    else if (d1 < today) return "yesterday at " + this.getLocaleTime(d1);
    else {
      const secs = Math.floor((d2 - d1) / 1000);
      const mins = Math.floor(secs / 60);
      const hours = Math.floor(mins / 60);
      if (hours > 5) return "today at " + this.getLocaleTime(d1);
      else if (mins > 60) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      else if (secs > 60) return `${mins} min${mins > 1 ? "s" : ""} ago`;
      return "just now";
    }
  };

  handleClickToggle() {
    if (this.state.listOpen) {
      this.setState({ listOpen: false });
    } else {
      this.setState({ listOpen: true });
    }
  }

  handleFilter = filter => {
    this.setState(
      { filter: filter, page: 1, quickAccess: [], files: [] },
      () => {
        this.load_contracts();
      }
    );
  };

  handleNewClickToggle() {
    if (this.state.newListOpen) {
      this.setState({ newListOpen: false,collapse:false });
    } else {
      this.setState({ newListOpen: true,collapse:true });
    }
  }

  navigateToEditor = contractId => {
    let path = "/insights/contract";
    if (contractId) path += `/${contractId}`;
    else path += "/new-contract";
    this.props.history.push(path);
  };

  empty_content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: { key: "637gr" }
      }
    ]
  };

  createNewFile = (content = null) => {
    if (content) {
      Contract.upload_contract("Untitled Document", content)
        .then(res => {
          this.navigateToEditor(res.contract_id);
        })
        .catch(e => {
          ToastsStore.error("An error occurred while saving contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    } else {
      Contract.upload_contract("Untitled Document", this.empty_content)
        .then(res => {
          this.navigateToEditor(res.contract_id);
        })
        .catch(e => {
          ToastsStore.error("An error occurred while saving contract!");
          console.log(e);
          this.setState({ saving: false });
        });
    }
  };

  onFileSelect = e => {
    this.setState({ contractLoading: true });
    const file = e.target.files[0];
    Editor.get_raw_content_from_file(file)
      .then(res => {
        this.setState({ contractLoading: false });
        console.log("nf", res);
        this.createNewFile(res.raw_content);
      })
      .catch(e => {
        this.setState({ contractLoading: false });
        ToastsStore.error("Error opening file!");
      });
  };

  naviGate = id => {
    this.setState({ contractLoading: true });
    Contract.get_contract(id)
      .then(contract => {
        console.log(contract);
        let content =
          contract.content.content !== undefined
            ? contract.content.content
            : contract.content;
        //first replace all 'null' values with ''
        content = JSON.stringify(content, null, 4);
        content = content.replace(/null/g, '""');
        //then if 'atomic' blocks contain empty text they should be changed to ' '
        content = JSON.parse(content);
        content.blocks = content.blocks.map(block => {
          if (block.type === "atomic" && block.text === "") block.text = " ";
          return block;
        });
        //then convert entityMap array into object
        content.entityMap = { ...content.entityMap };
        this.setState({ contractLoading: false }, () =>
          this.navigateToEditor(id)
        );
      })
      .catch(e => {
        this.setState({ contractLoading: false });
        ToastsStore.error("Error opening contract!");
        console.log(e);
      });
  };

  renderLoadingModal() {
    if (this.state.contractLoading)
      return (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0, 0.4)",
            zIndex: 50
          }}
        >
          <Spinner style={{ color: "#473080" }} />
        </div>
      );
    return null;
  }

  renderHeader() {
    return (
      <div className="_search_container">
        <h1>My Workspace</h1>
        <div className="search">
          <div className="search-icon">
            <img src={require("../../assets/icons/search.png")} alt="" />
          </div>
          <form action="">
            {" "}
            <input
              type="text"
              className="search-input"
              placeholder="Search Drive"
            />{" "}
          </form>
          <div className="drop-icon">
            <img
              src={require("../../assets/icons/drop-down-arrow.png")}
              alt=""
            />
          </div>
        </div>
        <div className="workfolder-header-icons">
          <div>
            <img
              src={require("../../assets/icons/workfoldericon1.png")}
              alt=""
            />
          </div>
          <div>
            <img
              src={require("../../assets/icons/workfoldericon2.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }

  renderSideBar() {
    return (
      <div className="sidebarcontainer">
        <div className="newbtn" onClick={() => this.handleNewClickToggle()}  >
          <p  >+ New</p>
        </div>

          <Collapse isOpen={this.state.collapse} className='new_work_list'>
            

        <div
          className={`dropdownlistitems ${
            this.state.newListOpen ? "show" : "none"
          }`}
          >
          <div
            className="newbtndropdownlist"
            onClick={() => this.createNewFile(null)}
            >
            <p>Create New</p>
          </div>
          <div
            onClick={e => this.refs.file_input.click()}
            className="newbtndropdownlist"
            >
            <p>Import File</p>
          </div>
        </div>
            {/* </UncontrolledCollapse> */}
           </Collapse>


        <div className="menu">
          <div
            className={`menu-item ${
              this.state.filter == "all" ? "active" : ""
            }`}
            onClick={() => this.handleFilter("all")}
          >
            <span className="dd"></span>

            <img src={require("../../assets/icons/allContract.svg")} alt="" />

            <p>All  Contracts</p>
          </div>
          <div
            className={`menu-item ${
              this.state.filter == "shared" ? "active" : ""
            }`}
            onClick={() => this.handleFilter("shared")}
          >
            <span className="dd"></span>

            <img src={require("../../assets/icons/sharedwith.svg")} alt="" />

            <p>Shared with me</p>
          </div>
          <div
            className={`dropdownlistitems ${
              this.state.listOpen ? "show" : "none"
            }`}
          >
            <div className="droplist1">
              <img src={require("../../assets/icons/dropright.png")} alt="" />
              <img src={require("../../assets/icons/folder.png")} alt="" />
              <p>Chroma</p>
            </div>
            <div className="droplist1">
              <img src={require("../../assets/icons/dropright.png")} alt="" />
              <img src={require("../../assets/icons/folder.png")} alt="" />
              <p>commercial Docs</p>
            </div>
            <div className="droplist1">
              <img src={require("../../assets/icons/dropright.png")} alt="" />
              <img src={require("../../assets/icons/folder.png")} alt="" />
              <p>Chroma</p>
            </div>
            <div className="droplist1">
              <img src={require("../../assets/icons/dropright.png")} alt="" />
              <img src={require("../../assets/icons/folder.png")} alt="" />
              <p>commercial Docs</p>
            </div>
          </div>

          <div className="menu-item">
            <span className="dd">
              <img
                // src={require("../../assets/icons/drop-down-arrow.png")}
                alt=""
              />
            </span>

            <img src={require("../../assets/icons/recent.png")} alt="" />

            <p>Recents</p>
          </div>
          <div className="recent">
            <div>
              {" "}
              <img src={require("../../assets/icons/stared.png")} alt="" />
            </div>
            <p>Starred</p>
          </div>
          <div className="recent">
            <div>
              {" "}
              <img src={require("../../assets/icons/bin.png")} alt="" />
            </div>
            <p>Bin</p>
          </div>
          {/* <div className="recent">
            <div>
              {" "}
              <img src={require("../../assets/icons/sidebar2.svg")} alt="" />
            </div>
            <Link to={"/playbook"}>
              <p>View Playbook</p>
            </Link>
          </div> */}
        </div>
      </div>
    );
  }

  renderQuickAccess(quickAccess, i) {
    const date = `You edited this ${this.getElapsedTime(
      new Date(
        new Date(quickAccess.updated_at).getTime() -
          new Date().getTimezoneOffset() * 60 * 1000
      )
    )}`;
    return (
      <div
        onClick={e => this.navigateToEditor(quickAccess.id)}
        className="quick_access"
        key={i.toString()}
      >
        <div className="quickAccessImg">
          <img src={quickAccess.quickImg} alt="" />{" "}
        </div>
        <div className="commercial">
          <div className="commercial_wrapper" title={quickAccess.title}>
            <div className="wicon">
              <p>W</p>
            </div>
            <p className="com">{quickAccess.title}</p>
            {!quickAccess.can_update == 1 && false && (
              <div className="eicon">
                <PencilOffIcon />
              </div>
            )}
          </div>
          <div className="date" title={date}>
            <p className="date">{date}</p>
          </div>
        </div>
      </div>
    );
  }

  renderfolder(folder, i) {
    return (
      <div
        className={`chroma_folder ${i == 0 ? "first" : " "}`}
        key={i.toString()}
      >
        <div className="sharedwith">
          <p>Shared With</p>
        </div>
        <div className="avatars">
          {folder.sharedWith.map((avatar, index) => (
            <img src={avatar} key={index.toString()} />
          ))}
        </div>
        <div className="spacer"></div>

        <div className="folder">
          <p>Folder</p>
        </div>
        <div className="folderName">
          <p>{folder.folderName}</p>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="workfoldercontainer">
        {this.renderSideBar()}

        <div
          className="renderfoldercontainer"
          ref={c => (this.page_wrapper = c)}
        >
          {this.state.files.length < 1 && this.state.loading ? (
            <div
              style={{
                display: "flex",
                alignSelf: "center",

                zIndex: 50
              }}
            >
              <Spinner style={{ color: "#473080" }} />
            </div>
          ) : (
            <React.Fragment>
              <p>Folders</p>
              <div className="renderfoldercontainerwrapper">
                {this.state.folders.map((folder, index) =>
                  this.renderfolder(folder, index)
                )}
              </div>

              <p className="quick_access_p">Quick Access</p>

              {this.state.quickAccess.length < 1 ? (
                <div className="renderQuickAccessEmptyContainer">
                  <span>No Files Available</span>
                </div>
              ) : (
                <div className="renderQuickAccessContainer">
                  {this.state.quickAccess.map((quick, index) =>
                    this.renderQuickAccess(quick, index)
                  )}
                </div>
              )}

              <p style={{ marginTop: `${(48 * 100) / 1920}vw` }}>Files</p>

              {this.state.quickAccess.length < 1 ? (
                <div className="renderQuickAccessEmptyContainer">
                  <span>No Files Available</span>
                </div>
              ) : (
                <div className="renderQuickAccessContainer">
                  {this.state.files.map((quick, index) =>
                    this.renderQuickAccess(quick, index)
                  )}
                </div>
              )}
            </React.Fragment>
          )}
          {this.state.files.length > 0 && this.state.loading ? (
            <div
              style={{
                display: "inline-block",
                alignSelf: "center",
                position: "absolute",
                bottom: "20px",
                zIndex: 50
              }}
            >
              <Spinner style={{ color: "#473080" }} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="_page-container _alter_page-container">
        {this.renderLoadingModal()}
        {this.renderHeader()}
        {this.renderContent()}
        <input
          type="file"
          style={{ display: "none" }}
          ref="file_input"
          onChange={this.onFileSelect}
        />
      </div>
    );
  }
}
