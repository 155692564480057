import React, { Component } from "react";
// import "./profile.scss";
import Btn from "../common/Button";
import Store from "../../store";
import { ToastsStore } from "react-toasts";
import utils from "../../api/common/utils";
import Modal from "../common/Modal";
import ProfileApi from "../../api/profile/Profile";
import Auth from "../../api/auth/Auth";
import { Col, Row, ModalHeader, ModalBody, Input, Button } from "reactstrap";
import HeadingContainer from "../layout/HeadingContainer";

export default class Profile extends Component {
  state = {
    loading: true
  };
  languages = [
    {
      url: require("../../assets/icons/usflag.png"),
      name: "English - US",
      nameShort: "English - US",
      code: 0
    },
    {
      url: require("../../assets/icons/usflag.png"),
      name: "English - UK",
      nameShort: "English - UK",
      code: 1
    },
    {
      url: require("../../assets/icons/ksaflag.png"),
      name: "Arabic - AR",
      nameShort: "Arabic",
      code: 2
    },
    {
      url: require("../../assets/icons/gerflag.png"),
      name: "German - Gr",
      nameShort: "German",
      code: 3
    },
    {
      url: require("../../assets/icons/spainflag.png"),
      name: "Spanish - SP",
      nameShort: "Spanish",
      code: 4
    }
  ];

  componentDidMount() {
    Store.profileRef = this;
    ProfileApi.get_profile_details()
      .then(res => {
        Store.user = res;
        this.setState({ loading: false });
        if (Store.topBarRef) Store.topBarRef.update_user();
      })
      .catch(e => {
        ToastsStore.error("Failed to Load Profile Details!");
        this.setState({ loading: false });
        console.log(e);
      });
  }

  componentWillUnmount() {
    Store.profileRef = null;
  }

  handleEditProfile = e => {
    e.preventDefault();
    this.editProfile.toggle();
    const elems = e.target.elements;
    const data = {
      first_name: elems.first_name.value,
      last_name: elems.last_name.value,
      designation: elems.designation.value,
      email: elems.email.value,
      contact_details: {
        country_code: elems.country_code.value,
        contact_number: elems.contact_number.value
      }
    };
    ProfileApi.update_profile_details(data)
      .then(res => {
        data.organization_name = Store.user.profile_details.organization_name;
        Store.user.profile_details = data;
        this.forceUpdate();
        Store.topBarRef.update_user();
        ToastsStore.success(res.message);
      })
      .catch(e => {
        console.log(e);
        if (e.api_error) ToastsStore.error(e.error.message);
        else ToastsStore.error("An Unknown Error Occurred!");
      });
  };

  //put rrequest update password
  handleUpdatePassword(e) {
    e.preventDefault();
    this.updatePassword.toggle();
    const elems = e.target.elements;
    Auth.reset_password(
      elems["old_password"].value,
      elems["new_password"].value
    )
      .then(res => {
        this.forceUpdate();
        ToastsStore.success(res.message);
      })
      .catch(e => {
        console.log(e);
        if (e.api_error) ToastsStore.error(e.error.message);
        else ToastsStore.error("An Unknown Error Occurred!");
      });
  }

  updateLanguage(lang) {
    this.changeLanguage.toggle();
    ProfileApi.update_language(lang.code)
      .then(res => {
        Store.user.language_details.selected_language = lang.code;
        this.forceUpdate();
        ToastsStore.success(res.message);
      })
      .catch(e => {
        console.log(e);
        if (e.api_error) ToastsStore.error(e.error.message);
        else ToastsStore.error("An Unknown Error Occurred!");
      });
  }

  renderUpdateProfile() {
    const profile = Store.user.profile_details;
    const lang = this.languages.find(
      item => item.code === Store.user.language_details.selected_language
    );
    return (
      <Modal ref={c => (this.editProfile = c)}>
        <ModalHeader
          className="modal-header"
          toggle={() => this.editProfile.toggle()}
        >
          Edit Profile
          {/* {`${
            lang.name == "German - Gr" ? "Profil bearbeiten" : "Edit Profile"
          }`} */}
        </ModalHeader>
        <ModalBody>
          <form className="form" onSubmit={e => this.handleEditProfile(e)}>
            <div className="row container">
              <div className="form__form-group col-lg-6">
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <Input
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    defaultValue={profile.first_name}
                  />
                </div>
              </div>
              <div className="form__form-group col-lg-6">
                <span className="form__form-group-label">Last Name</span>

                <div className="form__form-group-field">
                  <Input
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    defaultValue={profile.last_name}
                  />
                </div>
              </div>
            </div>
            <div className="row container">
              <div className="form__form-group col-lg-12">
                <span className="form__form-group-label">Designation</span>
                <div className="form__form-group-field">
                  <Input
                    name="designation"
                    placeholder="Designation"
                    type="text"
                    defaultValue={profile.designation}
                  />
                </div>
              </div>
            </div>
            <div className="row container">
              <div className="form__form-group col-md-5">
                <span className="form__form-group-label">Email</span>
                <div className="form__form-group-field">
                  <Input
                    name="email"
                    type="email"
                    placeholder="John@Doe.com"
                    defaultValue={profile.email}
                  />
                </div>
              </div>
              <div className="form__form-group col-md-2">
                <span className="form__form-group-label">Code</span>
                <div className="form__form-group-field">
                  <Input
                    name="country_code"
                    type="text"
                    placeholder="+92"
                    defaultValue={profile.contact_details.country_code}
                  />
                </div>
              </div>
              <div className="form__form-group col-md-5">
                <span className="form__form-group-label">Contact Number</span>
                <div className="form__form-group-field">
                  <Input
                    name="contact_number"
                    type="number"
                    placeholder="3331234567"
                    defaultValue={profile.contact_details.contact_number}
                  />
                </div>
              </div>
            </div>
            <div className="row container footer-buttons">
              <Button
                className="profile__button"
                onClick={() => this.editProfile.toggle()}
              >
                Cancel
              </Button>
              <Button className="profile__button-active" type="submit">
                Update
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  renderChangeLanguage() {
    return (
      <Modal ref={c => (this.changeLanguage = c)}>
        <ModalHeader
          className="modal-header"
          toggle={() => this.changeLanguage.toggle()}
        >
          Change Language
        </ModalHeader>
        <ModalBody>
          {this.languages.map(item => (
            <div key={item.code}>
              <button
                className="modal-language-item"
                onClick={() => this.updateLanguage(item)}
              >
                <Row>
                  <Col xs="4" md="4" lg="1">
                    <img className="language-flag" src={item.url} />
                  </Col>
                  <Col xs="6" md="6" lg="4">
                    <p className="language-name">{item.nameShort}</p>
                  </Col>
                  {Store.user.language_details.selected_language ===
                  item.code ? (
                    <Col xs="4" lg="9">
                      <p className="check-language">✓</p>
                    </Col>
                  ) : null}
                </Row>
              </button>
              <hr />
            </div>
          ))}
        </ModalBody>
      </Modal>
    );
  }

  renderUpdatePassword() {
    return (
      <Modal ref={p => (this.updatePassword = p)}>
        <ModalHeader
          className="modal-header"
          toggle={() => this.updatePassword.toggle()}
        >
          Update Password
        </ModalHeader>
        <ModalBody>
          <form className="form" onSubmit={e => this.handleUpdatePassword(e)}>
            <div className="row container">
              <div className="form__form-group col-lg-12">
                <span className="form__form-group-label">
                  Enter Current Password
                </span>
                <div className="form__form-group-field">
                  <Input
                    name="old_password"
                    type="password"
                    placeholder="Please write your current password"
                  />
                </div>
              </div>
              <div className="form__form-group col-lg-12">
                <span className="form__form-group-label">
                  Enter New Password
                </span>

                <div className="form__form-group-field">
                  <Input
                    name="new_password"
                    type="password"
                    placeholder="Please write your new password"
                  />
                </div>
              </div>
            </div>
            <div className="row container">
              <div className="form__form-group col-lg-12">
                <span className="form__form-group-label">Confirm Password</span>
                <div className="form__form-group-field">
                  <Input
                    name="confirm_password"
                    placeholder="Please write your new password again"
                    type="password"
                  />
                </div>
              </div>
            </div>
            <div className="row container footer-buttons">
              <Button
                onClick={() => this.updatePassword.toggle()}
                className="profile__button"
                type="button"
              >
                Cancel
              </Button>
              <Button className="profile__button-active" type="submit">
                Confirm
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const profile = Store.user.profile_details;
    const lang = this.languages.find(
      item => item.code === Store.user.language_details.selected_language
    );
    return (
      <HeadingContainer
        headingStyle={{
          fontWeight: "bold",
          marginLeft: `${(28 * 100) / 1920}vw`,
          marginTop: `${(28 * 100) / 1920}vw`
        }}
        heading={`${
          lang.name == "German - Gr"
            ? "Account Einstellungen"
            : "Account Settings"
        }`}
      >
        <div className="_page-container">
          <div className="profile-content-section">
            <div className="user-info col-section">
              <div className="user-detail">
                <div className="avatar-box">
                  <img
                    className="avatar-img"
                    src={Store.userAvatar}
                    alt="Profile Picture"
                  />
                  <img
                    className="camera-icon"
                    src={require("../../assets/icons/camera.svg")}
                    alt="upload photo"
                  />
                </div>
                <div className="user-info-box">
                  <div>
                    {!this.state.loading
                      ? `${profile.first_name} ${profile.last_name}`
                      : "Loading..."}
                  </div>
                  <div>
                    {this.state.loading
                      ? "Loading..."
                      : `${profile.designation} at ${profile.organization_name}`}
                  </div>
                  <div>
                    <img
                      src={require("../../assets/icons/phone.svg")}
                      alt="contact number"
                    />
                    {this.state.loading
                      ? "Loading..."
                      : `${profile.contact_details.country_code} ${profile.contact_details.contact_number}`}
                  </div>
                  <div>
                    <img
                      src={require("../../assets/icons/email.svg")}
                      alt="email"
                    />
                    {this.state.loading ? "Loading..." : profile.email}
                  </div>
                </div>
              </div>
              <Btn.primary onClick={() => this.editProfile.toggle()}>
                {`${
                  lang.name == "German - Gr"
                    ? "Profil bearbeiten"
                    : "Edit Profile"
                }`}
              </Btn.primary>
            </div>
            <div className="user-language col-section">
              <div className="language-detail">
                {`${lang.name == "German - Gr" ? "Sprache" : "Language"}`}

                <div>
                  <img src={lang.url} alt={lang.name} />
                  {this.state.loading ? "Loading..." : lang.name}
                </div>
              </div>
              <Btn.primary onClick={() => this.changeLanguage.toggle()}>
                {`${
                  lang.name == "German - Gr"
                    ? "Sprache ändern"
                    : "Change Language"
                }`}
              </Btn.primary>
            </div>
            <div className="user-password col-section">
              <div className="pass-detail">
                {`${lang.name == "German - Gr" ? "Passwort" : "Password"}`}

                <div>
                  <img
                    src={require("../../assets/icons/lock.svg")}
                    alt="password"
                  />
                  {"*".repeat(utils.get_user_data().password.length)}
                </div>
              </div>
              <Btn.primary onClick={() => this.updatePassword.toggle()}>
                {`${
                  lang.name == "German - Gr"
                    ? "Kennwort aktualisieren"
                    : "Update Password"
                }`}
              </Btn.primary>
            </div>
          </div>
          <div
            className="profile-logout-section"
            onClick={() => Auth.sign_out()}
          >
            <div>
              <img
                src={require("../../assets/icons/power button.svg")}
                alt="Log out"
              />
              {`${lang.name == "German - Gr" ? "Ausloggen" : "Logout"}`}
            </div>
            <img
              src={require("../../assets/icons/r-arrow.svg")}
              alt="Log out"
            />
          </div>
          {this.renderChangeLanguage()}
          {this.renderUpdateProfile()}
          {this.renderUpdatePassword()}
        </div>
      </HeadingContainer>
    );
  }
}
