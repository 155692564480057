import React, { Component, useState } from "react";

import ArrowDown from "../../../assets/icons/arrow-down.svg";
import FormatBoldIcon from "mdi-react/FormatBoldIcon";
import FormatItalicIcon from "mdi-react/FormatItalicIcon";
import FormatUnderlineIcon from "mdi-react/FormatUnderlineIcon";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon";
import FormatListNumberedIcon from "mdi-react/FormatListNumberedIcon";
import FormatAlignLeftIcon from "mdi-react/FormatAlignLeftIcon";
import FormatAlignRightIcon from "mdi-react/FormatAlignRightIcon";
import FormatAlignJustifyIcon from "mdi-react/FormatAlignJustifyIcon";
import FormatColorHighlightIcon from "mdi-react/FormatColorHighlightIcon";
import FormatColorTextIcon from "mdi-react/FormatColorTextIcon";
import CodeBracesIcon from "mdi-react/CodeBracesIcon";

import { RichUtils, Modifier, EditorState, ContentBlock } from "draft-js";
import { Map } from "immutable";
import {
  Dropdown as Dropdown2,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { textAlign } from "@material-ui/system";

export default class EditorToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFonts: false,
      displayBlockType: false,
      displayFontSize: false,
      displayText: false,
      displayTextHighlight: false,
      editorState: props.editorState,
      options: [
        {
          label: "Normal",
          style: "normal"
        },
        {
          label: "H1",
          style: "header-one"
        },
        {
          label: "H2",
          style: "header-two"
        },
        {
          label: "H3",
          style: "header-three"
        },
        {
          label: "H4",
          style: "header-four"
        },
        {
          label: "H5",
          style: "header-five"
        },
        {
          label: "H6",
          style: "header-six"
        },
        {
          label: "Blockquote",
          style: "blockquote"
        },
        {
          label: "Code Block",
          style: "code-block"
        }
      ],
      listOptions: [
        {
          label: "UL",
          style: "unordered-list-item",
          image: FormatListBulletedIcon
        },
        {
          label: "OL",
          style: "ordered-list-item",
          image: FormatListNumberedIcon
        }
      ],
      blockStyles: [
        {
          label: "leftAlign",
          style: "left",
          image: FormatAlignLeftIcon
        },
        {
          label: "JusifyAlign",
          style: "justify",
          image: FormatAlignJustifyIcon
        },
        {
          label: "rightAlign",
          style: "right",
          image: FormatAlignRightIcon
        }
      ],
      fontSizes: [
        {
          label: "8",
          fontName: "px8"
        },
        {
          label: "9",
          fontName: "px9"
        },
        {
          label: "10",
          fontName: "px10"
        },
        {
          label: "11",
          fontName: "px11"
        },
        {
          label: "12",
          fontName: "px12"
        },
        {
          label: "14",
          fontName: "px14"
        },
        {
          label: "16",
          fontName: "px16"
        },
        {
          label: "18",
          fontName: "px18"
        },
        {
          label: "24",
          fontName: "px24"
        },
        {
          label: "30",
          fontName: "px30"
        },
        {
          label: "36",
          fontName: "px36"
        },
        {
          label: "48",
          fontName: "px48"
        },
        {
          label: "60",
          fontName: "px60"
        },
        {
          label: "72",
          fontName: "px72"
        },
        {
          label: "96",
          fontName: "px96"
        }
      ],
      fontStyle: [
        {
          label: "Arial",
          fontStyle: "Arial"
        },
        {
          label: "Georgia",
          fontStyle: "Georgia"
        },
        {
          label: "Impact",
          fontStyle: "Impact"
        },
        {
          label: "Tahoma",
          fontStyle: "Tahoma"
        },
        {
          label: "Times New Roman",
          fontStyle: "TimesNewRoman"
        },
        {
          label: "Verdana",
          fontStyle: "Verdana"
        }
      ],
      colorPicker: [
        {
          label: "color1",
          labelHighlight: "hcolor1",
          color: "rgb(97,189,109)"
        },
        {
          label: "color2",
          labelHighlight: "hcolor2",
          color: "rgb(26,188,156)"
        },
        {
          label: "color3",
          labelHighlight: "hcolor3",
          color: "rgb(84,172,210)"
        },
        {
          label: "color4",
          labelHighlight: "hcolor4",
          color: "rgb(44,130,201)"
        },
        {
          label: "color5",
          labelHighlight: "hcolor5",
          color: "rgb(147,101,184)"
        },
        {
          label: "color6",
          labelHighlight: "hcolor6",
          color: "rgb(71,85,119)"
        },
        {
          label: "color7",
          labelHighlight: "hcolor7",
          color: "rgb(204,204,204)"
        },
        {
          label: "color8",
          labelHighlight: "hcolor8",
          color: "rgb(65,168,95)"
        },
        {
          label: "color9",
          labelHighlight: "hcolor9",
          color: "rgb(0,168,133)"
        },
        {
          label: "color10",
          labelHighlight: "hcolor10",
          color: "rgb(61,142,185)"
        },
        {
          label: "color11",
          labelHighlight: "hcolor11",
          color: "rgb(41,105,176)"
        },
        {
          label: "color12",
          labelHighlight: "hcolor12",
          color: "rgb(85,57,130)"
        },
        {
          label: "color13",
          labelHighlight: "hcolor3",
          color: "rgb(40,50,78)"
        },
        {
          label: "color14",
          labelHighlight: "hcolor14",
          color: "rgb(0,0,0)"
        },
        {
          label: "color15",
          labelHighlight: "hcolor15",
          color: "rgb(247,218,100)"
        },
        {
          label: "color16",
          labelHighlight: "hcolor16",
          color: "rgb(251,160,38)"
        },
        {
          label: "color17",
          labelHighlight: "hcolor17",
          color: "rgb(235,107,86)"
        },
        {
          label: "color18",
          labelHighlight: "hcolor18",
          color: "rgb(226,80,65)"
        },
        {
          label: "color19",
          labelHighlight: "hcolor19",
          color: "rgb(163,143,132)"
        },
        {
          label: "color20",
          labelHighlight: "hcolor20",
          color: "rgb(239,239,239)"
        },
        {
          label: "color21",
          labelHighlight: "hcolor21",
          color: "rgb(255,255,255)"
        },
        {
          label: "color22",
          labelHighlight: "hcolor22",
          color: "rgb(250,197,28)"
        },
        {
          label: "color23",
          labelHighlight: "hcolor23",
          color: "rgb(243,121,52)"
        },
        {
          label: "color24",
          labelHighlight: "hcolor24",
          color: "rgb(209,72,65)"
        },
        {
          label: "color25",
          labelHighlight: "hcolor25",
          color: "rgb(184,49,47)"
        },
        {
          label: "color26",
          labelHighlight: "hcolor26",
          color: "rgb(124,112,107)"
        },
        {
          label: "color27",
          labelHighlight: "hcolor27",
          color: "rgb(209,213,216)"
        }
      ],
      defaultBlockType: "Normal",
      defaultFontSize: "16",
      defaultFontStyle: "Font",
      selectInlinebtnBold: "",
      selectInlinebtnUnderline: "",
      selectInlinebtnItalic: "",
      selectInlinebtnCode: "",
      selectTextAlignRight: null,
      selectTextAlignLeft: null,
      selectTextAlignJustify: null
    };
  }

  componentDidMount = () => {
    const currentBlockType = RichUtils.getCurrentBlockType(
      this.state.editorState
    );
    let currBlock = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(this.state.editorState.getSelection().getStartKey());
    const alignType = currBlock.getData().has("text-align")
      ? currBlock.getData().get("text-align")
      : "left";
    console.log("ALIGN", alignType);
    const currentInlineStyleType = this.state.editorState.getCurrentInlineStyle();
    this.activateStyleButton(
      currentBlockType,
      currentInlineStyleType,
      alignType
    );
    console.log("CONTENT SF", currentBlockType);
  };

  handleOutSideClick = () => {
    // alert("Click");
    this.setState({
      displayFonts: false,
      displayBlockType: false,
      displayFontSize: false,
      displayText: false,
      displayTextHighlight: false
    });
    document.removeEventListener("click", this.handleOutSideClick);
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.editorState !== this.props.editorState) {
      console.log("PROPS", nextProps);

      this.setState(
        {
          editorState: nextProps.editorState
        },
        () => {
          const currentBlockType = RichUtils.getCurrentBlockType(
            this.state.editorState
          );
          let currBlock = this.state.editorState
            .getCurrentContent()
            .getBlockForKey(
              this.state.editorState.getSelection().getStartKey()
            );
          const alignType = currBlock.getData().has("text-align")
            ? currBlock.getData().get("text-align")
            : "left";
          console.log("ALIGN", alignType);
          const currentInlineStyleType = this.state.editorState.getCurrentInlineStyle();
          this.activateStyleButton(
            currentBlockType,
            currentInlineStyleType,
            alignType
          );
          console.log("CONTENT SF", currentBlockType);
        }
      );
    }
  };

  activateStyleButton = (
    currentBlockType,
    currentInlineStyleType,
    alignType
  ) => {
    console.log("CONTENT BLOCK SF", currentBlockType);
    console.log("CONTENT TYPE SF", currentInlineStyleType.has("BOLD"));

    // if(alignType === "left")

    // if (
    //   currentInlineStyleType.has("BOLD") ||
    //   currentInlineStyleType.has("ITALIC") ||
    //   currentInlineStyleType.has("UNDERLINE") ||
    //   currentInlineStyleType.has("CODE")
    // ) {
    let mystate = {
      selectInlinebtnBold: null,
      selectInlinebtnItalic: null,
      selectInlinebtnUnderline: null,
      selectInlinebtnCode: null,
      selectTextAlignRight: null,
      selectTextAlignLeft: null,
      selectTextAlignJustify: null
    };
    if (alignType === "left") {
      console.log("LEFT", alignType);
      mystate.selectTextAlignLeft = "left";
    }
    if (alignType === "right") {
      mystate.selectTextAlignRight = "right";
    }
    if (alignType === "justify") {
      mystate.selectTextAlignJustify = "justify";
    }
    if (currentInlineStyleType.has("BOLD")) {
      console.log("BOLD");
      mystate.selectInlinebtnBold = "BOLD";
    }
    if (currentInlineStyleType.has("ITALIC")) {
      console.log("ITALIC");
      mystate.selectInlinebtnItalic = "ITALIC";
    }
    if (currentInlineStyleType.has("UNDERLINE")) {
      console.log("UNDERLINE");
      mystate.selectInlinebtnUnderline = "UNDERLINE";
    }
    if (currentInlineStyleType.has("CODE")) {
      console.log("CODE");
      mystate.selectInlinebtnCode = "CODE";
    }
    this.setState(Object.assign(this.state, mystate));

    switch (currentBlockType) {
      case "unordered-list-item":
        console.log("HELLO");
        this.setState({ selectedbtn: currentBlockType });
        break;
      case "ordered-list-item":
        console.log("HELLO");
        this.setState({ selectedbtn: currentBlockType });
        break;
      case "align-right":
        console.log("RIGHT", currentBlockType);
        this.setState({ selectedbtn: currentBlockType });
        break;
      case "left":
        this.setState({ selectedbtn: currentBlockType });
        break;
      case "justify":
        this.setState({ selectedbtn: currentBlockType });
        break;
      case "header-one":
        this.setState({ defaultBlockType: "H1" });
        break;
      case "header-two":
        console.log("HEADER T2");
        this.setState({ defaultBlockType: "H2" });
        break;
      case "header-three":
        this.setState({ defaultBlockType: "H3" });
        break;
      case "header-four":
        this.setState({ defaultBlockType: "H4" });
        break;
      case "header-five":
        this.setState({ defaultBlockType: "H5" });
        break;
      case "header-six":
        this.setState({ defaultBlockType: "H6" });
        break;
      case "blockquote":
        this.setState({ defaultBlockType: "Blockquote" });
        break;
      case "code-block":
        this.setState({ defaultBlockType: "Code" });
        break;
      default:
        this.setState({ selectedbtn: null, defaultBlockType: "Normal" });
    }
  };

  _onClick = (e, name) => {
    e.preventDefault();
    console.log("SELECTED STATE", this.state.editorState);
    this.onEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, name)
    );
  };

  handleBlockType = (e, name) => {
    console.log("BLOCK", e.target.id);
    e.preventDefault();
    if (
      name === "ordered-list-item" ||
      name === "unordered-list-item" ||
      name === "align-left" ||
      name === "align-right" ||
      name === "align-justify"
    ) {
      this.onEditorChange(
        RichUtils.toggleBlockType(this.state.editorState, name)
      );
    } else {
      this.setState({
        defaultBlockType: name,
        displayBlockType: false
      });
      this.onEditorChange(
        RichUtils.toggleBlockType(this.state.editorState, name)
      );
    }
  };

  handleBlockStyle = (e, name) => {
    e.preventDefault();
    let editorState = EditorState.push(
      this.state.editorState,
      Modifier.setBlockData(
        this.state.editorState.getCurrentContent(),
        this.state.editorState.getSelection(),
        Map({ "text-align": name })
      ),
      "apply-entity"
    );
    this.onEditorChange(editorState);
  };

  onHandleFontSize = e => {
    e.preventDefault();
    console.log("FONT SIZE", e.target.id);
    this.setState({
      defaultFontSize: e.target.id,
      displayFontSize: false
    });
    this.onEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.target.name)
    );
  };
  onHandleFontStyle = e => {
    e.preventDefault();
    console.log("FONT STYLE", e.target.name);
    this.setState({
      defaultFontStyle: e.target.name,
      displayFonts: false
    });
    this.onEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.target.name)
    );
  };
  onHandleTextColor = e => {
    e.preventDefault();
    console.log("FONT Color", e.target.id);
    this.setState({
      displayText: false
    });
    this.onEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.target.id)
    );
  };
  onEditorChange = editorState => {
    // this.setState(
    //   {
    //     editorState
    //   },
    //   this.props.onChange(editorState)
    // );

    this.props.onChange(editorState);
  };

  handle = e => {
    e.preventDefault();
  };

  render() {
    const styles = [
      { style: "BOLD", name: "B", image: FormatBoldIcon },
      {
        style: "ITALIC",
        name: "I",
        image: FormatItalicIcon
      },
      {
        style: "UNDERLINE",
        name: "U",
        image: FormatUnderlineIcon
      },
      { style: "CODE", name: "{}", image: CodeBracesIcon }
    ];
    const buttons = styles.map((style, index) => {
      return (
        <button
          key={index}
          className={`test ${
            style.style === this.state.selectInlinebtnBold
              ? "activebtn"
              : style.style === this.state.selectInlinebtnCode
              ? "activebtn"
              : style.style === this.state.selectInlinebtnItalic
              ? "activebtn"
              : style.style === this.state.selectInlinebtnUnderline
              ? "activebtn"
              : ""
          }`}
          name={style.style}
          id={style.style}
          //   onMouseDown={this.props.onChange(this.state.editorState)}
          onMouseDown={e => this._onClick(e, style.style)}
        >
          <style.image />
        </button>
      );
    });
    const listStylesButtons = this.state.listOptions.map((style, index) => {
      return (
        <button
          key={index}
          name={style.label}
          className={`test ${
            style.style === this.state.selectedbtn ? "activebtn" : ""
          }`}
          name={style.style}
          id={style.style}
          //   onMouseDown={this.props.onChange(this.state.editorState)}
          onMouseDown={e => this.handleBlockType(e, style.style)}
        >
          <style.image />
        </button>
      );
    });

    const blockStylesButtons = this.state.blockStyles.map((style, index) => {
      return (
        <button
          key={index}
          name={style.label}
          className={`test ${
            style.style === this.state.selectTextAlignLeft
              ? "activebtn"
              : style.style === this.state.selectTextAlignRight
              ? "activebtn"
              : style.style === this.state.selectTextAlignJustify
              ? "activebtn"
              : ""
          }`}
          name={style.style}
          id={style.style}
          //   onMouseDown={this.props.onChange(this.state.editorState)}
          onMouseDown={e => this.handleBlockStyle(e, style.style)}
        >
          <style.image />
        </button>
      );
    });

    return (
      <>
        <div className="toolbar">
          <div
            className="custom-dropdown"
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <div
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                // setTimeout(() => {
                this.setState({
                  displayBlockType: !this.state.displayBlockType,
                  displayFontSize: false,
                  displayFonts: false
                });
                document.addEventListener("click", this.handleOutSideClick);

                // }, 1000);
              }}
              className="title"
            >
              <span className="label">{this.state.defaultBlockType}</span>
              <span>
                <img src={ArrowDown} />
              </span>
            </div>

            {this.state.displayBlockType ? (
              <div onClick={e => e.preventDefault()} className="dropdown-menu">
                {this.state.options.map((optionItem, index) => (
                  <a
                    key={index}
                    name={optionItem.style}
                    id={optionItem.style}
                    value={optionItem.style}
                    onClick={e => this.handleBlockType(e, optionItem.style)}
                    // onMouseDown={e => this.onHandleFontStyle(e)}
                    className="dropdownitems"
                  >
                    {optionItem.label}
                  </a>
                ))}
              </div>
            ) : null}
          </div>

          <span className="seperator"></span>
          <div
            className="custom-dropdown"
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <div
              onClick={e => {
                e.preventDefault();
                this.setState({
                  displayFonts: !this.state.displayFonts,
                  displayFontSize: false,
                  displayBlockType: false
                });
                document.addEventListener("click", this.handleOutSideClick);
              }}
              className="title"
            >
              <span className="label">{this.state.defaultFontStyle}</span>
              <span>
                <img src={ArrowDown} />
              </span>
            </div>

            {this.state.displayFonts ? (
              <div onClick={e => e.preventDefault()} className="dropdown-menu">
                {this.state.displayFonts
                  ? this.state.fontStyle.map((optionItem, index) => (
                      <a
                        key={index}
                        name={optionItem.fontStyle}
                        value={optionItem.fontStyle}
                        onClick={e => this.onHandleFontStyle(e)}
                        // onMouseDown={e => this.onHandleFontStyle(e)}
                        className="dropdownitems"
                      >
                        {optionItem.label}
                      </a>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
          <span className="seperator"></span>
          <div
            className="custom-dropdown custom-dropdown-font-size"
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <div
              className="title"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  displayFontSize: !this.state.displayFontSize,
                  displayFonts: false,
                  displayBlockType: false
                });
                document.addEventListener("click", this.handleOutSideClick);
              }}
            >
              <span className="label">{this.state.defaultFontSize}</span>
              <span>
                <img src={ArrowDown} />
              </span>
            </div>

            {this.state.displayFontSize ? (
              <div onClick={e => e.preventDefault()} className="dropdown-menu">
                {this.state.fontSizes.map((optionItem, index) => (
                  <a
                    key={index}
                    name={optionItem.fontName}
                    id={optionItem.label}
                    value={optionItem.fontName}
                    onClick={e => this.onHandleFontSize(e)}
                    // onMouseDown={e => this.onHandleFontStyle(e)}
                    className="dropdownitems"
                  >
                    {optionItem.label}
                  </a>
                ))}
              </div>
            ) : null}
          </div>

          <span className="seperator"></span>
          {buttons}
          <span className="seperator"></span>
          {listStylesButtons}
          <span className="seperator"></span>
          {blockStylesButtons}
          <span className="seperator"></span>
          <div
            className="custom-dropdown-text-color"
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <button
              onClick={e => {
                e.preventDefault();
                this.setState({
                  displayText: !this.state.displayText,
                  displayFonts: false,
                  displayBlockType: false,
                  displayFontSize: false
                });
                document.addEventListener("click", this.handleOutSideClick);
              }}
            >
              <FormatColorTextIcon />

              {/* <img src={IconTextColor}></img> */}
            </button>

            {this.state.displayText ? (
              <div onClick={e => e.preventDefault()} className="colorContainer">
                <div className="subColorContainer">
                  {this.state.colorPicker.map((optionItem, index) => (
                    <a
                      key={index}
                      name={optionItem.color}
                      id={optionItem.label}
                      // value={optionItem.fontName}
                      onClick={e => this.onHandleTextColor(e)}
                      // onMouseDown={e => this.onHandleFontStyle(e)}
                      className="itemColor"
                      style={{ backgroundColor: `${optionItem.color}` }}
                    ></a>
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          <div
            className="custom-dropdown-text-color"
            onMouseDown={e => {
              e.preventDefault();
            }}
          >
            <button
              onClick={e => {
                e.preventDefault();

                this.setState({
                  displayTextHighlight: !this.state.displayTextHighlight,
                  displayFonts: false,
                  displayBlockType: false,
                  displayFontSize: false,
                  displayText: false
                });
                document.addEventListener("click", this.handleOutSideClick);
              }}
              onBlur={e => {
                alert("K");
              }}
            >
              <FormatColorHighlightIcon />
              {/* <img src={IconTextHighlight}></img> */}
            </button>

            {this.state.displayTextHighlight ? (
              <div onClick={e => e.preventDefault()} className="colorContainer">
                <div className="subColorContainer">
                  {this.state.colorPicker.map((optionItem, index) => (
                    <a
                      key={index}
                      name={optionItem.color}
                      id={optionItem.labelHighlight}
                      // value={optionItem.fontName}
                      onClick={e => this.onHandleTextColor(e)}
                      // onMouseDown={e => this.onHandleFontStyle(e)}
                      className="itemColor"
                      style={{ backgroundColor: `${optionItem.color}` }}
                    ></a>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <span className="seperator"></span>
        </div>

        <div>{this.props.toolbarCustomComponent}</div>
      </>
    );
  }
}
