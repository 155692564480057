import React, { PureComponent } from "react";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { ToastsStore } from "react-toasts";
import Auth from "../../api/auth/Auth";
import "./login.scss";
import Particles from "react-particles-js";

class LogIn extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      loading: false,
      check: false
    };
  }

  showPassword = e => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  onSignIn = e => {
    e.preventDefault();
    this.setState({ loading: true });
    const elems = e.target.elements;
    Auth.sign_in(elems["email"].value, elems["password"].value)
      .then(res => {
        ToastsStore.success(res.message);
      })
      .catch(e => {
        this.setState({ loading: false });
        console.log(e);
        if (e.api_error) ToastsStore.error(e.error.message);
        else ToastsStore.error("An Unknown Error Occurred!");
      });
  };
  particleOpt = {
    particles: {
      number: {
        value: 50,
        density: {
          enable: true,
          value_area: 800
        }
      }
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: { enable: true, mode: "grab" },
        onclick: { enable: true, mode: "push" }
      }
    }
  };
  render() {
    return (
      <div className="login-container">
        <Particles
          params={this.particleOpt}
          style={{
            width: "50%",
            height: "100%",
            position: "absolute"
          }}
        />
        <div className="container-left">
          <div className="chroma-name">
            <h2>Chroma</h2>
          </div>
          <div className="anim-container">
            <img
              className="center"
              src={require("../../assets/images/login-center.png")}
              alt=""
            />
            <img
              className="login-left"
              src={require("../../assets/images/login-left.png")}
              alt=""
            />
            <img
              className="login-right"
              src={require("../../assets/images/login-right.png")}
              alt=""
            />
            <div className="top-cross-white"></div>
            <div className="bottom-cross-white"></div>
            <div className="top-strait-white"></div>
            <div className="bottom-strait-white"></div>
            <div className="top-strait-purple"></div>
            <div className="bottom-strait-purple"></div>
          </div>
        </div>
        <div className="container-right">
          <form className="content-container" onSubmit={e => this.onSignIn(e)}>
            <div className="content-container">
              <div className="input-field-container">
                Email
                <div className="input-container">
                  {/* <div className="login-icon-container">
                    <AccountOutlineIcon className="login-icon" size={18} />
                  </div> */}
                  <input
                    name="email"
                    type="email"
                    required={true}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="input-field-container">
                Password
                <div className="input-container">
                  {/* <div className="login-icon-container">
                    <KeyVariantIcon className="login-icon" size={18} />
                  </div> */}
                  <input
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    placeholder="Password"
                    required={true}
                  />
                  {/* <button
                    type="button"
                    onClick={e => this.showPassword(e)}
                    type="button"
                  >
                    <div className="login-icon-container">
                      <EyeIcon className="login-icon" size={18} />
                    </div>
                  </button> */}
                </div>
              </div>
              <div className="forget-password-area">
                <div className="remember-me">
                  <input
                    type="checkbox"
                    checked={this.state.check}
                    onChange={() => this.setState({ check: !this.state.check })}
                  />
                  <span
                    onClick={() => this.setState({ check: !this.state.check })}
                  >
                    Remember me
                  </span>
                </div>
                <span onClick={() => alert("Forgot Password?")}>
                  Forgot Password?
                </span>
              </div>
              <button
                disabled={this.state.loading}
                type="submit"
                className="sign-in login-cta"
              >
                {this.state.loading ? "Login..." : "Login"}
              </button>
              <div className="signup-text">
                <span>
                  Don't have an account?<a>Signup</a>
                </span>
                {/* <button
                  onClick={() => alert("Create Account!")}
                  type="button"
                  className="create-account login-cta"
                >
                  Signup
                </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default LogIn;

{
  /* <form className="content-container" onSubmit={e => this.onSignIn(e)}>
          <div className="content-container">
            <div className="input-field-container">
              Email
              <div className="input-container">
                <div className="login-icon-container">
                  <AccountOutlineIcon className="login-icon" size={18} />
                </div>
                <input
                  name="email"
                  type="email"
                  required={true}
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="input-field-container">
              Password
              <div className="input-container">
                <div className="login-icon-container">
                  <KeyVariantIcon className="login-icon" size={18} />
                </div>
                <input
                  name="password"
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder="Password"
                  required={true}
                />
                <button
                  type="button"
                  onClick={e => this.showPassword(e)}
                  type="button"
                >
                  <div className="login-icon-container">
                    <EyeIcon className="login-icon" size={18} />
                  </div>
                </button>
              </div>
            </div>
            <span onClick={() => alert("Forgot Password?")}>
              Forgot Password?
            </span>
            <button
              disabled={this.state.loading}
              type="submit"
              className="sign-in login-cta"
            >
              {this.state.loading ? "Signing in..." : "Sign In"}
            </button>
            <button
              onClick={() => alert("Create Account!")}
              type="button"
              className="create-account login-cta"
            >
              Create Account
            </button>
          </div>
        </form> */
}
