import React, { Component, useState } from "react";
// import "./commentitem.scss";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import MoreVertIcon from "mdi-react/MoreVertIcon";
import TextArea from "../TextArea";
import Store from "../../../store";
class CommentItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewFull: false,
      focussed: props.focussed || false,
      input: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.focussed !== this.state.focussed) {
      this.setState({ focussed: this.state.focussed }, () => {
        this.checkDelete(prevProps);
        this.hideReplies(prevProps);
      });
    }

    if (prevState.value !== this.state.value) {
      this.setState({ value: this.state.value });
    }
  }

  checkDelete = prevProps => {
    if (
      this.state.focussed != this.props.blockKey &&
      this.props.comments.length < 1
    ) {
      this.props.onDelete(this.props.blockKey, this.props.entityKey);
      console.log("lost focus and no data");
    }
  };

  hideReplies = prevProps => {
    this.setState({ viewFull: false });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.focussed !== prevState.focussed) {
      return { focussed: nextProps.focussed };
    } else return null;
  }

  getTime(updatedAt) {
    return this.getElapsedTime(
      new Date(
        new Date(updatedAt).getTime() -
          new Date().getTimezoneOffset() * 60 * 1000
      )
    );
  }

  getLocaleTime = d => {
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  getLocaleDate = d => {
    let day = d.getDate();
    let mlist = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let month = mlist[d.getMonth()];
    let year = d.getFullYear();
    var strTime = day + " " + month + " " + year;
    return strTime;
  };

  getElapsedTime = d1 => {
    const d2 = new Date();
    const delta = d2.getTime() - d1.getTime();
    if (delta < 0) return d1.toDateString();
    const today = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
    const yesterday = new Date(today - 24 * 60 * 60 * 1000);
    const month = new Date(today - 24 * 30 * 60 * 60 * 1000);
    const year = new Date(today - 24 * 30 * 12 * 60 * 60 * 1000);

    if (d1 < yesterday)
      return this.getLocaleTime(d1) + ", " + this.getLocaleDate(d1);
    else if (d1 < today) return "Yesterday at " + this.getLocaleTime(d1);
    else {
      const secs = Math.floor((d2 - d1) / 1000);
      const mins = Math.floor(secs / 60);
      const hours = Math.floor(mins / 60);
      if (hours > 5) return "Today at " + this.getLocaleTime(d1);
      else if (mins > 60) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
      else if (secs > 60) return `${mins} min${mins > 1 ? "s" : ""} ago`;
      return "Just Now";
    }
  };

  renderCommentItem = (comment, index) => {
    if (!comment) return;

    return (
      <div
        key={comment.comment}
        className={`comment-item ${index > 0 ? "reply-view" : ""}`}
      >
        <div className={"comment-item-user-wrapper"}>
          <img src="/static/media/avatar.28908e67.png" />
          <div className={"user-area"}>
            <span className={"name"}>{comment.comment_by.first_name}</span>
            <span className={"time"}>{this.getTime(comment.updated_at)}</span>
          </div>
          <div className={"comment-menu"}>
            {index == 0 && (
              <span
                disabled={this.state.input.length == 0}
                onClick={e =>
                  this.state.input.length != 0 &&
                  (this.props.comments.length == 0
                    ? this.createNewComment(e)
                    : this.addNewReply(e))
                }
              >
                {"Resolve"}
              </span>
            )}
            <CommentMenu
              onEdit={() => {
                this.onStartEditing(index, comment.id);
              }}
              onDelete={() => {
                this.onStartDelete(index, comment.id);
              }}
            />
            {/* <MoreVertIcon /> */}
          </div>
        </div>
        <span className={"comment-text"}>{comment.comment}</span>
      </div>
    );
  };

  renderUnCollapsedComment = () => {
    return (
      <React.Fragment>
        {this.renderCommentItem(this.props.comments[0], 0)}
        {this.renderCommentItem(this.props.comments[1], 1)}
        <div
          className="comments-show-reply-button"
          onClick={() => {
            this.setState({ viewFull: true });
          }}
        >
          Show all {this.props.comments.length - 1} replies.
        </div>
        {this.renderCommentItem(
          this.props.comments[this.props.comments.length - 1],
          this.props.comments.length - 1
        )}
      </React.Fragment>
    );
  };

  render() {
    let focussed = this.state.focussed;
    let Collapsed = this.state.viewFull || this.props.comments.length < 5; //||this.state.viewFull;

    return (
      <div>
        <div className={"comment-item-wrapper"}>
          {Collapsed &&
            this.props.comments.map((comment, index) => {
              return this.renderCommentItem(comment, index);
            })}

          {!Collapsed && this.renderUnCollapsedComment()}

          {focussed && this.props.comments.length == 0 && (
            <div className={"comment-item"}>
              <div className={"comment-item-user-wrapper"}>
                <img src={Store.userAvatar} alt="Profile Picture" />
                <div className={"user-area"}>
                  <span className={"name"}>
                    {Store.user.profile_details.first_name}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {(focussed || true) && (
          <div
            className={`comment-reply ${
              !focussed ? "comment-reply-hidden" : ""
            }`}
          >
            <TextArea
              ref={e => {
                this.input = e;
              }}
              value={this.state.input}
              onChange={e => this.setState({ input: e.target.value })}
            />
            <div>
              <span
                disabled={this.state.input.length == 0}
                onClick={e =>
                  this.state.input.length != 0 &&
                  (this.props.comments.length == 0
                    ? this.createNewComment(e)
                    : this.addNewReply(e))
                }
              >
                {this.props.comments.length > 0 ? "Reply" : "Comment"}
              </span>{" "}
            </div>
          </div>
        )}
      </div>
    );
  }

  createNewComment = e => {
    console.log(this.state.input);
    this.props.onCreateNewComment(this.props.blockKey, this.state.input);
    this.setState({ input: "" });
  };

  onStartEditing = (index, id) => {
    this.props.onStartEditing(this.props.blockKey, index, id);

    // console.log(this.state.input);
    // this.props.onCreateNewComment(this.props.blockKey, this.state.input);
    // this.setState({ input: "" });
  };

  onStartDelete = (index, id) => {
    this.props.onStartDelete(this.props.blockKey, index, id);
  };

  addNewReply = e => {
    console.log(this.state.input);
    this.props.onAddNewReply(this.props.blockKey, this.state.input);
    this.setState({ input: "" });
  };

  toogleViewFull = () => {
    this.setState({ viewFull: !this.state.viewFull });
  };
}

const CommentMenu = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="action">
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          tag="div"
          data-toggle="dropdown"
          aria-expanded={dropdownOpen}
        >
          <MoreVertIcon />
        </DropdownToggle>
        <DropdownMenu className="actions_box">
          <DropdownItem onClick={props.onEdit}>Edit</DropdownItem>
          <DropdownItem onClick={props.onDelete}>Delete</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

// const ReplyItem = props => {
//   {
//   }

//   if (!comment) return;

//   return (
//     <div
//       key={comment.comment}
//       className={`comment-item ${index > 0 ? "reply-view" : ""}`}
//     >
//       <div className={"comment-item-user-wrapper"}>
//         <img src="/static/media/avatar.28908e67.png" />
//         <div>
//           <span className={"name"}>{comment.comment_by.first_name}</span>
//           <span className={"time"}>{this.getTime(comment.updated_at)}</span>
//         </div>
//         <div className={"comment-menu"}>
//           {index == 0 && (
//             <span
//               disabled={this.state.input.length == 0}
//               onClick={e =>
//                 this.state.input.length != 0 &&
//                 (this.props.comments.length == 0
//                   ? this.createNewComment(e)
//                   : this.addNewReply(e))
//               }
//             >
//               {"Resolve"}
//             </span>
//           )}
//           <MoreVertIcon />
//         </div>
//       </div>
//       <span className={"comment-text"}>{comment.comment}</span>
//     </div>
//   );
// };

export default CommentItem;
