import Store from "../../store";
import utils from "../common/utils";
import error from "../common/Error";
const BASE_URL = Store.BASE_URL;

const get_playbook_details = (page, status) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(
          `${BASE_URL}/v1/playbooks?playbook_status=${status}&page=${page}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          }
        )
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const inactive_playbook_rule = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const inactive_specific_variable = id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook/variable/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const edit_specific_variable = (id, data) => {
  console.log("edit variable api", data);
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook/variable/${parseInt(id)}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const edit_playbook_rule = (id, data) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook/${parseInt(id)}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};
const get_variable_list_for_playbook = (id, page,status) => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook/${id}/variables?variable_status=${status}&page=${page}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const add_new_variable_for_playbook = (playbook_id, data) => {
  console.log("add variable api", data);
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook/${playbook_id}/variable`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const add_new_playbook = data => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/playbook`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(data)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

function objToQueryString(obj) {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(obj).length; i += 1) {
    keyValuePairs.push(
      `${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(
        Object.values(obj)[i]
      )}`
    );
  }
  return keyValuePairs.join("&");
}

const Playbook = {
  add_new_variable_for_playbook,
  get_playbook_details,
  add_new_playbook,
  get_variable_list_for_playbook,
  inactive_playbook_rule,
  edit_playbook_rule,
  edit_specific_variable,
  inactive_specific_variable
};

export default Playbook;
