import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styles from "./common.scss";
import { borderTop } from "@material-ui/system";

const MyTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "#292929",
    border: 0 * styles.ratio + "vw" + " solid #dadde9",
    width: 232 * styles.ratio + "vw",
    borderRadius: 10 * styles.ratio + "vw",
    boxShadow: "0px 0px 16px 7px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#ffffff",
    fontSize: 14 * styles.ratio + "vw",
    lineHeight: 18 * styles.ratio + "vw",
    padding: "0px",
    overflow: "hidden"
  }
}))(Tooltip);

const getTitle = props => {
  let alert = props.alert;
  let stage =
    (props.alert && props.alert.metadata && props.alert.metadata.stage) || "";
  let type =
    (props.alert && props.alert.metadata && props.alert.metadata.type) || "";
  let subType =
    props.alert && props.alert.metadata && props.alert.metadata.sub_type;
  let heading = `${stage} ${type.replace("_", " ").replace("_", " ")}` || "";
  let bdColorClass = props.bdColorClass;

  return (
    <div
      className={`tooltip-content ${
        stage == "pulse" && type == "sanction" && subType == "risk"
          ? "bd-red"
          : bdColorClass
      }`}
    >
      {heading && <h3>{heading}</h3>}
      {props.title}
    </div>
  );
};

export default function ToolTip(props) {
  return <MyTooltip interactive {...props} title={getTitle(props)}></MyTooltip>;
}

export function SimpleToolTip(props) {
  return (
    <ToolTip interactive {...props}>
      props.children
    </ToolTip>
  );
}
