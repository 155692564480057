import Store from "../../store";
import utils from "../common/utils";
import error from "../common/Error";

const BASE_URL = Store.BASE_URL;

const add_new_comment = commentDetails => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/comment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(commentDetails)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const get_comments_for_contract = contract_id => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/contract/${contract_id}/comments`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const Comment = {
  add_new_comment,
  get_comments_for_contract
};

export default Comment;
