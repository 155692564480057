import React, { Component } from "react";
import ReactMeter from "../common/ReactMeter";
import utils from "../../api/common/utils";
import Store from "../../store";
const BASE_URL = Store.BASE_URL;

export default class TestScreen extends Component {
  socket = null;

  componentDidMount() {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/clauses?page=`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {})
              .catch(e => {
                console.log(e.message);
                // return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            console.log(e.message);
            // return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        // return rej(error.create_general_error(e));
      });

    console.log("fetch sy bahar");
  }

  render() {
    return (
      <div>
        <h1 onClick={e => this.sendMessage()}>Hello World</h1>
        <ReactMeter className={"risk-meter"} />
      </div>
    );
  }
}
