import Auth from "../auth/Auth";

const CURR_USER = "__curr_user__";

const get_token = () => {
  return new Promise((resolve, rej) => {
    let user = localStorage[CURR_USER];
    if (user) {
      const userData = JSON.parse(user);
      //if token has expired then request a new one
      if (new Date(userData.expires_at) <= new Date()) {
        Auth.sign_in_wtih_credentials(userData.email, userData.password)
          .then(res => {
            //if new token is received successfylly then replace if with old one
            res.email = userData.email;
            res.password = userData.password;
            localStorage[CURR_USER] = JSON.stringify(res);
            //return the new token
            return resolve(res.token);
          })
          .catch(e => {
            //if unable to receive the new token then logout
            Auth.sign_out().then(() => {
              return rej("session-expired");
            });
          });
      }
      //if valid token is already present then return it
      else return resolve(userData.token);
    } else {
      //if token is not there then logout
      Auth.sign_out().then(() => {
        return rej("session-expired");
      });
    }
  });
};

const get_user_data = () => {
  let user = localStorage[CURR_USER];
  if (user) return JSON.parse(user);
  return {};
};

const isUserAuthority = () => {
  let user = localStorage[CURR_USER];
  if (user) {
    let roles = JSON.parse(user).user_roles.map(i => i.role_name);
    return roles.includes("authority");
  }

  return false;
};

const str_to_unicode = str => {
  let unicode = "";
  for (let i = 0; i < str.length; i++) {
    let theUnicode = str
      .charCodeAt(i)
      .toString(16)
      .toUpperCase();
    while (theUnicode.length < 4) {
      theUnicode = "0" + theUnicode;
    }
    theUnicode = "\\u" + theUnicode;
    unicode += theUnicode;
  }
  return unicode;
};

const utils = {
  get_token,
  get_user_data,
  str_to_unicode,
  isUserAuthority
};

export default utils;
