import { ContentState, convertToRaw } from "draft-js";
import mammoth from "mammoth";
import htmlToDraft from "html-to-draftjs";

const get_raw_content_from_file = file => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = loadEvent => {
      const buffer = loadEvent.target.result;
      try {
        mammoth
          .convertToHtml({ arrayBuffer: buffer })
          .then(result => {
            const blocksFromHtml = htmlToDraft(result.value);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            return res({
              raw_content: convertToRaw(contentState),
              messages: result.messages
            });
          })
          .catch(e => {
            return rej(e);
          })
          .done();
      } catch (e) {
        return rej(e);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

const Editor = {
  get_raw_content_from_file
};

export default Editor;
