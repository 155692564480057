import React, { Component } from "react";

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.textarea2 = null;
  }

  textAreaAdjust = o => {
    window.setTimeout(function() {
      o.style.height = "1px";
      o.style.height = 2 + o.scrollHeight + "px";
    }, 0);
  };
  render() {
    return (
      <textarea
      style={this.style}

        ref={e => (this.textarea = e)}
        {...this.props}
        onChange={e => {
          this.textAreaAdjust(e.target);
          this.props.onChange(e);
        }}
      />
    );
  }

  componentDidUpdate(){
    this.textAreaAdjust(this.textarea);
  }

  componentDidMount() {
    this.textarea.style.height = "1px";
    this.textarea.style.height = 2 + this.textarea.scrollHeight + "px";

  }

  style={
    resize: 'none',
  display: 'flex',
  alignItems:' center',
  width: '100%',
  }
}



export default TextArea;
