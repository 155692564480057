import Store from "../../store";
import error from "../common/Error";
import utils from "../common/utils";

const BASE_URL = Store.BASE_URL;
const CURR_USER = "__curr_user__";
const NOTIFICATIONS = "notifications";
const auth_event = new CustomEvent("auth_event");

const sign_in_wtih_credentials = (email, pass) => {
  return new Promise((resolve, rej) => {
    if (email === "")
      return rej(error.create_general_error("no-email-provided"));
    else if (pass === "")
      return rej(error.create_general_error("no-password-provided"));
    else {
      fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          password: pass
        })
      })
        .then(res => {
          res
            .json()
            .then(resJson => {
              //if http error occurred
              if (!res.ok) return rej(error.create_api_error(res, resJson));
              //if login request was rejected
              else if (resJson.status !== 0)
                return rej(error.create_api_error(res, resJson));
              else return resolve(resJson.body);
            })
            .catch(e => {
              return rej(error.create_general_error(e));
            });
        })
        .catch(e => {
          return rej(error.create_general_error(e));
        });
    }
  });
};

const on_change_state = callback => {
  //when listening starts, provide the current auth state
  const _user = localStorage[CURR_USER];
  if (_user) {
    const userData = JSON.parse(_user);
    if (new Date(userData.expires_at) > new Date()) callback(userData);
    //if token has been expired then request for a new one
    else {
      sign_in_wtih_credentials(userData.email, userData.password)
        .then(res => {
          //if the token is retreived
          res.email = userData.email;
          res.password = userData.password;
          localStorage[CURR_USER] = JSON.stringify(res);
          callback(res);
        })
        .catch(e => {
          //if unable to retreive the token then signout the current user
          localStorage[CURR_USER] = "";
          callback(null);
        });
    }
  } else callback(null);

  //triggers each time auth state has changed from current tab
  window.addEventListener("auth_event", e => {
    const user = localStorage[CURR_USER];
    if (user) callback(JSON.parse(user));
    else callback(null);
  });

  //triggers each time auth state has changed from a different tab
  window.addEventListener("storage", e => {
    if (e.key === CURR_USER) {
      //user has been signed in
      if (!e.oldValue && e.newValue) callback(JSON.parse(e.newValue));
      //user has been signed out
      else if (!e.newValue) callback(null);
    }
    //to handle reset case
    else if (!e.key) {
      //to signout from other tabs
      localStorage[CURR_USER] = "";
      //to signout from current tab
      callback(null);
    }
  });
};

const sign_out = () => {
  return new Promise(res => {
    localStorage[NOTIFICATIONS] = "[]";
    localStorage[CURR_USER] = "";
    window.dispatchEvent(auth_event);
    setTimeout(() => res(), 50);
  });
};

const sign_in = (email, pass) => {
  return new Promise((resolve, rej) => {
    sign_in_wtih_credentials(email, pass)
      .then(res => {
        //if login request is successful get body of response
        let body = res;
        body.email = email;
        body.password = pass;
        //then save user data along with credentials in the storage/cache
        localStorage[CURR_USER] = JSON.stringify(body);
        //trigger the auth_event
        window.dispatchEvent(auth_event);
        //return the body of the response on promise resolution
        return resolve(res);
      })
      .catch(e => {
        return rej(e);
      });
  });
};

const reset_password = (oldPass, newPass) => {
  return new Promise((resolve, rej) => {
    if (oldPass !== newPass || newPass === "")
      return rej(error.create_general_error("passwords-not-match"));
    //first get the updated token
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/profile/change-password`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            old_password: oldPass,
            new_password: newPass
          })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if reset pass request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else {
                  const data = JSON.parse(localStorage[CURR_USER]);
                  data.password = newPass;
                  localStorage[CURR_USER] = JSON.stringify(data);
                  return resolve(resJson.body);
                }
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const is_logged_in = () => {
  const user = localStorage[CURR_USER];
  return user ? true : false;
};

const get_current_user = () => {
  try {
    const user = JSON.parse(localStorage[CURR_USER]);
    return user;
  } catch (e) {
    console.error(e);
  }
  return null;
};

const Auth = {
  on_change_state,
  sign_in,
  sign_out,
  sign_in_wtih_credentials,
  reset_password,
  is_logged_in,
  get_current_user
};

export default Auth;
