import Store from "../../store";
import utils from "../common/utils";
import error from "../common/Error";

const BASE_URL = Store.BASE_URL;

const get_profile_details = () => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if get profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const update_profile_details = details => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/profile`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(details)
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update profile request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const update_language = langCode => {
  return new Promise((resolve, rej) => {
    utils
      .get_token()
      .then(token => {
        fetch(`${BASE_URL}/v1/profile/change-language`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ selected_language: langCode })
        })
          .then(res => {
            res
              .json()
              .then(resJson => {
                //if http error occurred
                if (!res.ok) return rej(error.create_api_error(res, resJson));
                //if update language request was rejected
                else if (resJson.status !== 0)
                  return rej(error.create_api_error(res, resJson));
                else return resolve(resJson.body);
              })
              .catch(e => {
                return rej(error.create_general_error(e));
              });
          })
          .catch(e => {
            return rej(error.create_general_error(e));
          });
      })
      .catch(e => {
        return rej(error.create_general_error(e));
      });
  });
};

const ProfileApi = {
  get_profile_details,
  update_profile_details,
  update_language
};

export default ProfileApi;
