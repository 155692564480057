import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import IconAtom from "../../assets/editor-images/AtomIcon.png";
import IconAtom2 from "../../assets/editor-images/PurpleCircle.png";
import IconAtom3 from "../../assets/editor-images/RiskCircle.svg";
import Editor from "../../api/editor/Editor";
import { ToastsStore } from "react-toasts";
import Contract from "../../api/contract/Contract";
import Store from "../../store";
import throttle from "lodash.throttle";
import { Spinner } from "reactstrap";
import GifMissionControl from '../gif-mission-control/GifMissionControl';

export default class MissionControl extends Component {
  state = {
    Todo: false,
    summary: true,
    templates: false,
    fadein: "fadein",
    fadeout: "fadeout",
    loading: false,
    content: null,
    page: 1,
    title: "Untitled Document",
    listOpen: false,
    newListOpen: false,
    folders: [
      {
        sharedWith: [
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png")
        ],
        folderName: "Chroma"
      },
      {
        sharedWith: [
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png"),
          require("../../assets/icons/avatar.png")
        ],
        folderName: "Chroma"
      }
    ],
    quickAccess: [],

    files: []
  };

  componentDidMount() {}

  handleToggleSummary = () => {
    if (this.state.summary) {
    } else {
      this.setState({ summary: true, templates: false, Todo: false });
    }
  };

  handleToggleTemplates = () => {
    if (this.state.templates) {
    } else {
      this.setState({ templates: true, summary: false, Todo: false });
    }
  };
  handleToggleTodo = () => {
    if (this.state.Todo) {
    } else {
      this.setState({ Todo: true, summary: false, templates: false });
    }
  };

  renderfolder(folder, i) {
    return (
      <div
        className={`chroma_folder ${i == 0 ? "first" : " "}`}
        key={i.toString()}
      >
        <div className="sharedwith">
          <p>Shared With</p>
        </div>
        <div className="avatars">
          {folder.sharedWith.map((avatar, index) => (
            <img src={avatar} key={index.toString()} />
          ))}
        </div>
        <div className="spacer"></div>

        <div className="folder">
          <p>Folder</p>
        </div>
        <div className="folderName">
          <p>{folder.folderName}</p>
        </div>
      </div>
    );
  }

  renderContracts() {
    return (
      <div className="renderContracts">
        <div className="contracts_heading_dropdown">
          <p>My Contracts</p>
          <div className="spacer"></div>
          <div className="Top-dropdown">
            <p>Top Five</p>
            <img
              src={require("../../assets/MissionControlImages/downarrowpurple.png")}
              alt=""
            />
          </div>
        </div>

        <table>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Entity</th>
            <th>Status</th>
          </tr>
          <tr className="bold">
            <td>American Airlines NDA</td>
            <td>NDA</td>
            <td>Government</td>
            <td className="Draft">
              <div className="Draftdiv">
                <p>Draft</p>
              </div>
            </td>
          </tr>
          <tr className="bold">
            <td>American Airlines MSA</td>
            <td>MSA</td>
            <td>Non-Government</td>
            <td className="Draft">
              <div className="Draftdiv negotiation">
                <p>Negotiation</p>
              </div>
            </td>
          </tr>
          <tr className="bold">
            <td>American Airlines NDA</td>
            <td>NDA</td>
            <td>Government</td>
            <td className="Draft">
              <div className="Draftdiv live">
                <p>Live</p>
              </div>
            </td>
          </tr>
          <tr className="bold">
            <td>American Airlines MSA</td>
            <td>MSA</td>
            <td>Non-Government</td>
            <td className="Draft">
              <div className="Draftdiv negotiation">
                <p>Negotiation</p>
              </div>
            </td>
          </tr>
          {/* <tr className="bold">
            <td>American Airlines NDA</td>
            <td>NDA</td>
            <td>Government</td>
            <td className="Draft">
              <div className="Draftdiv">
                <p>Draft</p>
              </div>
            </td>
          </tr>
          <tr className="bold">
            <td>American Airlines NDA</td>
            <td>NDA</td>
            <td>Government</td>
            <td className="Draft">
              <div className="Draftdiv live">
                <p>Live</p>
              </div>
            </td>
          </tr> */}
        </table>
      </div>
    );
  }
  renderWorkspace() {
    return (
      <div className="renderWorkspace">
        <div className="workspace_heading_dropdown">
          <Link to="/insights/workspace" style={{ textDecoration: "none" }}>
            <p>My Workspace</p>
          </Link>
          <div className="spacer"></div>
          <div className="latest-dropdown">
            <p>Latest</p>
            <img
              src={require("../../assets/MissionControlImages/downarrowpurple.png")}
              alt=""
            />
          </div>
        </div>
        <div className="renderfoldercontainerwrapper">
          {this.state.folders.map((folder, index) =>
            this.renderfolder(folder, index)
          )}
        </div>
      </div>
    );
  }
  renderRespository() {
    return (
      <div className="renderRespository">
        <div className="repository_container">
          <p>Contracts Repository</p>
          <div className="repository_icons_container">
            <div className="icons">
              <div className="blue-icon">
                <img
                  src={require("../../assets/MissionControlImages/non-disclusure-blue.svg")}
                  alt=""
                />
              </div>
              <p>Non-Disclosure Agreement</p>
            </div>
            <div className="icons">
              <div className="red-icon">
                <img
                  src={require("../../assets/MissionControlImages/master-service.svg")}
                  alt=""
                />
              </div>
              <p>Master Service Agreement</p>
            </div>
            <div className="icons">
              <div className="green-icon">
                <img
                  src={require("../../assets/MissionControlImages/non-disclusore-green.svg")}
                  alt=""
                />
              </div>
              <p>Non-Disclosure Agreement</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrganization() {
    return (
      <div className="renderOrganization">
        <div className="organization_clauses_heading">
          <p>Organization Clauses</p>

          <div className="organization_clauses_right_content">
            <img
              src={require("../../assets/MissionControlImages/equalizer.svg")}
              alt=""
            />
            <div className="liability">
              <p>Liability</p>
              <div className="cross_icon">
                <p>x</p>
              </div>
            </div>
            <div className="liability payment">
              <p>Payment</p>
              <div className="cross_icon">
                <p>x</p>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown">
          <div className="dropdown_left_content">
            <img
              src={require("../../assets/MissionControlImages/handshake.svg")}
              alt=""
            />
            <p>Payment Clauses (300)</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/down_arrow.svg")}
            alt=""
          />
        </div>
        <div className="dropdown">
          <div className="dropdown_left_content">
            <img
              src={require("../../assets/MissionControlImages/handshake.svg")}
              alt=""
            />
            <p>Termination Clauses (400)</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/down_arrow.svg")}
            alt=""
          />
        </div>
        <div className="dropdown">
          <div className="dropdown_left_content">
            <img
              src={require("../../assets/MissionControlImages/handshake.svg")}
              alt=""
            />
            <p>Liability Clauses (200)</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/down_arrow.svg")}
            alt=""
          />
        </div>
        <div className="dropdown">
          <div className="dropdown_left_content">
            <img
              src={require("../../assets/MissionControlImages/handshake.svg")}
              alt=""
            />
            <p>Introductory Clauses (350)</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/down_arrow.svg")}
            alt=""
          />
        </div>
        <div className="dropdown">
          <div className="dropdown_left_content">
            <img
              src={require("../../assets/MissionControlImages/handshake.svg")}
              alt=""
            />
            <p>Termination Clauses (100)</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/down_arrow.svg")}
            alt=""
          />
        </div>
      </div>
    );
  }

  renderWorkflow() {
    return (
      <div className="renderWorkflow">
        <p>Workflow Process</p>
        <div className="workflow_boxes_container">
          <div className="workflow_box insight">
            <p>Insight</p>
            <div className="workflow_box_wrapper">
              <div className="workflow_box_wrapper_content">
                <div className="hori-lines">
                  <div className="yellow"></div>
                  <div className="green"></div>
                </div>
                <p className="main_heading">Contract Writing</p>
                <p className="sub_heading">32 contracts</p>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="workflow_avatar">
                  <img
                    src={require("../../assets/MissionControlImages/workflow_avatar.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/MissionControlImages/workflow_avatar.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/MissionControlImages/workflow_avatar.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="workflow_box modeling">
            <p>Modeling</p>
            <div className="workflow_box_wrapper">
              <div className="workflow_box_wrapper_content">
                <div className="hori-lines">
                  <div className="yellow blue"></div>
                  <div className="green yellow_dark"></div>
                </div>
                <p className="main_heading">Negotiation</p>
                <p className="sub_heading">32 contracts</p>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="workflow_avatar">
                  <img
                    src={require("../../assets/MissionControlImages/workflow_avatar.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/MissionControlImages/workflow_avatar.png")}
                    alt=""
                  />
                  <img
                    src={require("../../assets/MissionControlImages/workflow_avatar.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="workflow_box Pulse">
            <p>Pulse</p>
            <div className="workflow_box_wrapper">
              <div className="workflow_box_wrapper_content">
                <div className="hori-lines">
                  <div className="yellow red"></div>
                </div>
                <p className="main_heading">Live Contracts</p>
                <p className="sub_heading">32 contracts</p>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="agreement">
                  <img
                    src={require("../../assets/MissionControlImages/doc.svg")}
                    alt=""
                  />
                  <p>General Agreement.doc</p>
                </div>
                <div className="workflow_avatar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRightSideBarSummary() {
    return (
      <div className="renderRightSideBarSummary fadein">
        <p>Summary</p>
        <div className="Drafts">
          <img
            src={require("../../assets/MissionControlImages/drafts.png")}
            alt=""
          />
        </div>
        <div className="Drafts">
          <img
            src={require("../../assets/MissionControlImages/nagotiation.png")}
            alt=""
          />
        </div>
        <div className="Drafts">
          <img
            src={require("../../assets/MissionControlImages/waitingforreview.png")}
            alt=""
          />
        </div>
        <div className="Drafts">
          <img
            src={require("../../assets/MissionControlImages/livecontracts.png")}
            alt=""
          />
        </div>
      </div>
    );
  }

  renderRightSideBarTemplates() {
    return (
      <div className="renderRightSideBarTemplates fadein">
        <p>My Templates</p>
        <div className="search">
          <input type="text" placeholder="Search" />
        </div>
        <div className="doc">
          <img
            src={require("../../assets/MissionControlImages/docfile.png")}
            alt=""
          />
          <p>Introduction.docs</p>
        </div>

        <div className="doc">
          <img
            src={require("../../assets/MissionControlImages/docfile.png")}
            alt=""
          />
          <p>Introduction.docs</p>
        </div>
      </div>
    );
  }

  renderRightSideBarTodo() {
    return (
      <div className="renderRightSideBarTodo fadein">
        <div className="top">
          <p>My To-do List</p>
          <img
            src={require("../../assets/MissionControlImages/plus.svg")}
            alt=""
          />
        </div>

        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox white"></div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox white"></div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox white"></div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
        <div className="TodoItem">
          <div className="TodoItemContent">
            <div className="checkbox">
              <img
                src={require("../../assets/MissionControlImages/check.png")}
                alt=""
              />
            </div>
            <p>List Item One</p>
          </div>
          <img
            src={require("../../assets/MissionControlImages/star.png")}
            alt=""
          />
        </div>
      </div>
    );
  }

  renderSideBarIcon() {
    return (
      <div className="renderSideBarIcon">
        <div className="renderSideBarIconWrapper">
          <div className={`todo ${this.state.Todo ? "active" : ""} `}>
            <div>
              <img
                src={require("../../assets/MissionControlImages/todo.svg")}
                alt=""
                onClick={this.handleToggleTodo}
              />
              <p>
                To do
                <br />
                List
              </p>
            </div>
          </div>
          <div className={`todo ${this.state.templates ? "active" : ""} `}>
            <div>
              <img
                src={require("../../assets/MissionControlImages/todo.svg")}
                alt=""
                onClick={this.handleToggleTemplates}
              />
              <p>
                My
                <br />
                Templates
              </p>
            </div>
          </div>
          <div className={`todo ${this.state.summary ? "active" : ""} `}>
            <div>
              <img
                src={require("../../assets/MissionControlImages/summary.svg")}
                alt=""
                onClick={this.handleToggleSummary}
              />
              <p>Summary</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleGif=()=>(
    // alert("helo")
    this.props.history.push('/gif-contract')
    // return(
    //   <GifMissionControl/>
    // )
  )

  renderChromaStatus() {
    return (
      <div className="chromaStatusContainer"  onClick={this.handleGif} style={{cursor:'pointer'}}>
        <div className="chromaStatsHeading">
          <p>Chroma Stats</p>
        </div>
        <div className="chromaVisualContainer">
          <div className="anim-container">
            <img className="icon-atom" src={IconAtom}></img>
            <img
              className="lightOpCircle"
              src={require("../../assets/editor-images/6.svg")}
            ></img>
            <img className="purple-circle" src={IconAtom2}></img>
            <img
              className="boxCircle"
              src={require("../../assets/editor-images/boxCircle.svg")}
            ></img>
            <img
              className="LineCircle"
              src={require("../../assets/editor-images/LineCircle.svg")}
            ></img>
            <img
              className="innerCircle"
              src={require("../../assets/editor-images/3.svg")}
            ></img>
            <img
              className="forthCircle"
              src={require("../../assets/editor-images/4.svg")}
            ></img>
            <img
              className="centerCircle"
              src={require("../../assets/editor-images/5.svg")}
            ></img>
          </div>
          <div className="riskCircle1">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">75%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Business Operations</p>
            </div>
          </div>
          <div className="dotCircle">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          {/* <div className="RiskCircleContainer"> */}
          <div className="riskCircle2">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">5%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Regulatory&nbsp;& Compliance</p>
            </div>
            {/* </div> */}
          </div>
          <div className="dotCircle1">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle3">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">75%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Asset Protection</p>
            </div>
          </div>
          <div className="dotCircle2">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle4">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">5%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Financial&nbsp;& Payments</p>
            </div>
          </div>
          <div className="dotCircle3">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle5">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">75%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Reputation</p>
            </div>
          </div>
          <div className="dotCircle4">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="riskCircle6">
            <img className="riskCircle" src={IconAtom3}></img>
            <div className="neuroTextContainer">
              <p className="neuropolText">5%</p>
              <span className="neuropolRiskText">Risk</span>
            </div>
            <div className="neuropolTextHeading">
              <p>Other</p>
            </div>
          </div>
          <div className="dotCircle5">
            <img
              className="dotCircleImg"
              src={require("../../assets/editor-images/8.svg")}
            ></img>
          </div>
          <div className="rightCross1"></div>
          <div className="rightStraight1"></div>
          <div className="rightCross2"></div>
          <div className="rightStraight2"></div>
          <div className="rightCross3"></div>
          <div className="rightStraight3"></div>
          <div className="leftCross1"></div>
          <div className="leftStraight1"></div>
          <div className="leftCross2"></div>
          <div className="leftStraight2"></div>
          <div className="leftCross3"></div>
          <div className="leftStraight3"></div>
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <div className="MainContainer_with_sidebar">
        <div className="Maincontainer">
          <div className="container-first-row">
            {/* {this.renderContracts()} */}
            {this.renderChromaStatus()}
            {this.renderContracts()}

            {/* <div className="workspaceRepositoy">
              {this.renderWorkspace()}
              {this.renderRespository()}
            </div> */}
          </div>
          <div className="container-second-row">
            {/* {this.renderOrganization()} */}
            {/* {this.renderContracts()} */}
            {this.renderWorkspace()}
            {this.renderRespository()}
            {this.renderWorkflow()}
          </div>
        </div>

        <div className="sideBarContainer">
          {this.state.summary ? this.renderRightSideBarSummary() : null}
          {this.state.templates ? this.renderRightSideBarTemplates() : null}
          {this.state.Todo ? this.renderRightSideBarTodo() : null}
        </div>

        <div className="sideBarIconContainer">{this.renderSideBarIcon()}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="_page-container _alter_page-container">
        <div className="main-heading">
          <h1>Mission Control</h1>
        </div>
        {this.renderContent()}
        <input
          type="file"
          style={{ display: "none" }}
          ref="file_input"
          onChange={this.onFileSelect}
        />
        {this.state.content ? (
          <Redirect
            to={{
              pathname: "/my-editor",
              state: {
                rawContent: this.state.content,
                title: this.state.title
              }
            }}
          />
        ) : null}
      </div>
    );
    return (
      <div className="_work-space-container _page-container">
        {this.state.loading ? (
          <h1>Loading...</h1>
        ) : (
          <div>
            <Link
              to={{
                pathname: "/my-editor",
                state: { title: this.state.title }
              }}
            >
              <button>Create New </button>
            </Link>
            <button onClick={e => this.refs.file_input.click()}>
              Import File
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              ref="file_input"
              onChange={this.onFileSelect}
            />
            {this.state.content ? (
              <Redirect
                to={{
                  pathname: "/my-editor",
                  state: {
                    rawContent: this.state.content,
                    title: this.state.title
                  }
                }}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}
