import React, { Component } from "react";
import EditorToolbar from "./EditorToolbar";

import {
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  convertToRaw
} from "draft-js";

import { styleMap, myBlockStyleFn } from "./config";

class MyEditor extends Component {
  onEditorStateChange = editorState => {
    this.props.onEditorStateChange(editorState);
    // this.props.onContentStateChange(editorState.getCurrentContent());
  };
  onEditorStateChangeByToolbar = editorState => {
    this.props.onChangeByToolbar(editorState);
    // this.props.onContentStateChange(editorState.getCurrentContent());
  };

  render() {
    console.log("props", this.props);
    return (
      <div className={this.props.wrapperClassName || ""}>
        {!this.props.hideToolbar ? (
          <div className={this.props.toolbarClassName || ""}>
            <EditorToolbar
              editorState={this.props.editorState}
              onChange={this.onEditorStateChangeByToolbar}
              toolbarCustomComponent={this.props.toolbarCustomComponent}
            />

            {/* {this.renderCutomToolBar()} */}
          </div>
        ) : null}
        <div className={this.props.editorClassName || ""}>
          <Editor
            handleBeforeInput={(text, editorState, timeStamp) => {
              this.timeDiff = Date.now();
              // console.log("TIME",Date.now())
              return "not-handled";
            }}
            customStyleMap={this.props.customStyleMap || styleMap}
            blockStyleFn={this.props.blockStyleFn || myBlockStyleFn}
            readOnly={this.props.readOnly}
            customDecorators={this.props.customDecorators}
            ref={this.props.editorRef}
            plugins={this.props.plugins}
            editorState={this.props.editorState}
            handleReturn={this.props.handleReturn}
            onChange={this.onEditorStateChange}
            onContentStateChange={this.onContentStateChange} //TO DO
          />
        </div>
      </div>
    );
  }
}

export default MyEditor;
