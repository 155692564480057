import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import Signature from "./Signature";

const ApproveModal = props => (
  <Modal isOpen={props.show} className={"approve-modal "} centered>
    <ModalHeader toggle={props.toggle}>Approve Contract</ModalHeader>
    <ModalBody>
      <Signature onApproveContract={props.onApproveContract} />
    </ModalBody>
  </Modal>
);

export default ApproveModal;
