import React from "react";
import {
  EditorState,
  Modifier,
  Entity,
  RichUtils,
  isSoftNewlineEvent,
  CompositeDecorator
} from "draft-js";
import {
  getSelectionText,
  getEntityRange,
  getSelectionEntity,
  getSelectedBlocksMap,
  handleNewLine
} from "draftjs-utils";
import generate from "@babel/generator";
import ToolTip from "../ToolTip";
import { EntityComponent } from "./EntityComponent";
import { ToastsStore } from "react-toasts";

const commentEntityType = "COMMENT";

export function setDecorator(editorState) {
  const commentDecorator = new CompositeDecorator([getCommentDecorator()]);
  return EditorState.set(editorState, { decorator: commentDecorator });
}

export function getEntityRanges(editorState, entityKey) {
  let entityRanges = [];
  let content = editorState.getCurrentContent();
  content.getBlocksAsArray().forEach(block => {
    block.findEntityRanges(
      value => value.get("entity") === entityKey,
      (start, end) => {
        entityRanges.push({
          start,
          end,
          blockKey: block.getKey()
        });
      }
    );
  });
  return entityRanges;
}

export function getEntityRangesBySpanId(editorState, spanId) {
  let entityRanges = [];
  let content = editorState.getCurrentContent();
  let currentEntity = null;
  content.getBlocksAsArray().forEach(block => {
    block.findEntityRanges(
      value => {
        if (value.getEntity() !== null) {
          currentEntity = {
            entity: content.getEntity(value.getEntity()),
            entityKey: value.getEntity()
          };
          return (
            value.getEntity() &&
            content.getEntity(value.getEntity()).getData() &&
            content.getEntity(value.getEntity()).getData().spanId === spanId
          );
        }
        return false;
      },
      (start, end) => {
        entityRanges.push({
          start,
          end,
          blockKey: block.getKey(),
          entity: currentEntity.entity,
          entityKey: currentEntity.entityKey
        });
      }
    );
  });
  return entityRanges;
}

export const getSelectionEntitySpanId = editorState => {
  if (!isSelectionACommentEntity(editorState)) return "";
  let contentState = editorState.getCurrentContent();
  let entityKey = getSelectionEntity(editorState);
  let data = contentState.getEntity(entityKey).getData();
  return data.spanId;
};

export const addLineBreakBetweenComments = (editorState, event) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  let nextEditorState = EditorState.createEmpty();
  const fragment = contentState.getSelectionAfter();
  const block = contentState.getBlockForKey(fragment.getStartKey());
  let entityKey = getSelectionEntity(editorState);
  let text = "\n";

  if (selection.isCollapsed()) {
    const nextContentState = Modifier.insertText(
      contentState,
      selection,
      text,
      null,
      entityKey
    );
    nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "insert-characters"
    );
  } else {
    const nextContentState = Modifier.replaceText(
      contentState,
      selection,
      text,
      null,
      entityKey
    );
    nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "insert-characters"
    );
  }
  return nextEditorState;
};

export const selectionHasEntity = (editorState, entityType) => {
  // let selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  if (selection.getIsBackward()) {
    let anchorKey = selection.focusKey;
    let focusKey = selection.anchorKey;
    let anchorOffset = selection.focusOffset;
    let focusOffset = selection.anchorOffset;
    selection.merge({
      anchorKey: anchorKey,
      focusKey: focusKey,
      anchorOffset: anchorOffset,
      focusOffset: focusOffset
    });
  }

  const entities = [];
  getSelectedBlocksMap(editorState).forEach(block => {
    let selectedEntity = null;
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (entity.getType() == entityType && entity.getData().spanId) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity())
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        // console.log(
        //   "meez checking cbk=",
        //   selectedEntity.blockKey,
        //   "sbk=",
        //   selection.focusKey,
        //   "fos",
        //   selection.focusOffset,
        //   "start",
        //   start
        // );
        if (
          (selectedEntity.blockKey == selection.focusKey &&
            selection.focusOffset < start) ||
          (selectedEntity.blockKey == selection.anchorKey &&
            selection.anchorOffset > end)
        ) {
          // console.log("meez check retuened");
          return;
        }

        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  // console.table("meez check ", entities);
  return entities.length > 0;
};

export const isSelectionACommentEntity = editorState => {
  let entityKey = getSelectionEntity(editorState);

  if (!entityKey) return false;

  // console.log("meez", editorState.getCurrentContent().getEntity(entityKey));
  if (
    entityKey &&
    editorState
      .getCurrentContent()
      .getEntity(entityKey)
      .getType() == "COMMENT"
  ) {
    // console.log(
    //   "meez",
    //   editorState
    //     .getCurrentContent()
    //     .getEntity(entityKey)
    //     .getType()
    // );
    return true;
  }
  return false;
};

export const getCommentDecorator = () => {
  const commentEntityType = "COMMENT";

  return {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }
        return (
          contentState.getEntity(entityKey).getType() === commentEntityType
        );
      }, callback);
    },

    component: EntityComponent
  };
};

export const generateSpanId = () => {
  var chars = "0123456789abcdef".split("");

  var uuid = [],
    rnd = Math.random,
    r;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
  uuid[14] = "4"; // version 4

  for (var i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (rnd() * 16);

      uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuid.join("");
};

export const getSelection = editorState => {
  const {
    anchorKey,
    anchorOffset,
    focusKey,
    focusOffset,
    isBackward
  } = editorState.getSelection().toJS();
  const isSelected = anchorOffset !== focusOffset || anchorKey !== focusKey;
  if (!isSelected) return null;
  let selectionBounds = {};
  if (isBackward) {
    selectionBounds.offset = focusKey === anchorKey ? focusOffset : 0;
    selectionBounds.length = anchorOffset - selectionBounds.offset;
  } else {
    selectionBounds.offset = anchorOffset;
    selectionBounds.length =
      focusKey === anchorKey ? focusOffset - selectionBounds.offset : 10000;
  }
  return selectionBounds;
};

//add comment by adding entity
export const addComment = (editorState, spanId) => {
  let entity = {
    type: commentEntityType,
    mutability: "MUTABLE",
    data: {
      spanId: spanId
    }
  };

  let selection = editorState.getSelection();
  const entityKey = Entity.create(entity.type, entity.mutability, entity.data);
  return [
    EditorState.push(
      editorState,
      Modifier.applyEntity(
        editorState.getCurrentContent(),
        selection,
        entityKey
      ),
      "apply-entity"
    ),
    entityKey
  ];

  // return addEntity(
  //   {
  //     type: commentEntityType,
  //     mutability: "MUTABLE",
  //     data: {
  //       spanId: "123456"
  //     }
  //   },
  //   editorState
  // );
};

export const removeComment = editorState => {
  let selection = editorState.getSelection();
  return EditorState.push(
    editorState,
    Modifier.applyEntity(editorState.getCurrentContent(), selection, null),
    "apply-entity"
  );
};

export const removeCommentBySpanId = (editorState, SpanId) => {
  let selection = editorState.getSelection();
  editorState = EditorState.set(editorState, { allowUndo: false });
  if (SpanId) {
    const entities = getEntityRangesBySpanId(editorState, SpanId);
    // console.table(entities);

    entities.forEach(entity => {
      selection = selection.merge({
        anchorOffset: entity.start,
        focusOffset: entity.end,
        anchorKey: entity.blockKey,
        focusKey: entity.blockKey,
        isBackward: false
      });
      // console.log("EK=" + entity.entityKey);

      const entityKey = Entity.create("COMMENT", "MUTABLE", {
        spanId: null,
        highlight: entity.entity.getData()
          ? entity.entity.getData().highlight
          : null,
        alert: entity.entity.getData() ? entity.entity.getData().alert : null
      });
      editorState = EditorState.push(
        editorState,
        Modifier.applyEntity(
          editorState.getCurrentContent(),
          selection,
          entityKey
        ),
        "apply-entity"
      );
    });
    editorState = EditorState.set(editorState, { allowUndo: true });
    return editorState;
  }
};

export const addCommentIntelligence = (editorState, selection, data) => {
  let foundFirstBlock = false;
  let foundLastBlock = false;
  let firstBlockOffset = selection.getStartOffset();
  let lastBlockOffset = selection.getEndOffset();
  let firstBlockKey = selection.getStartKey();
  let lastBlockKey = selection.getEndKey();
  let starting = firstBlockOffset;

  let entities = [];
  let content = editorState.getCurrentContent();
  content.getBlockMap().forEach((block, index) => {
    if (foundLastBlock) {
      return;
    } else if (foundFirstBlock) {
    } else if (!foundLastBlock && block.getKey() == lastBlockKey) {
      foundLastBlock = true;
    } else if (!foundFirstBlock && block.getKey() == firstBlockKey) {
      foundFirstBlock = true;
    } else {
      return;
    }
    // console.log("intel");
    if (index > 0) starting = 0;

    let selectedEntity = null;
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (entity.getType() === "COMMENT") {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: entity
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });

  const entityKey = Entity.create("COMMENT", "MUTABLE", {
    ...data,
    highlight: true
  });

  try {
    editorState = EditorState.push(
      editorState,
      Modifier.applyEntity(
        editorState.getCurrentContent(),
        selection,
        entityKey
      ),
      "apply-entity"
    );
    editorState = EditorState.set(editorState, { allowUndo: false });
    entities.forEach(entity => {
      const entityKey = Entity.create("COMMENT", "MUTABLE", {
        ...data,
        spanId: entity.entity.getData().spanId,
        highlight: true
      });

      if (entity.blockKey == firstBlockKey) {
        if (lastBlockOffset < entity.start) return;

        entity.start =
          entity.start < firstBlockOffset ? firstBlockOffset : entity.start;
      }

      if (entity.blockKey == lastBlockKey) {
        //if (firstBlockOffset > entity.end) return;

        //entity.start =
        //  entity.start < lastBlockOffset ? lastBlockOffset : entity.start;

        entity.end =
          entity.end > lastBlockOffset ? lastBlockOffset : entity.end;
      }

      selection = selection.merge({
        anchorOffset: entity.start,
        focusOffset: entity.end,
        anchorKey: entity.blockKey,
        focusKey: entity.blockKey,
        isBackward: false
      });

      editorState = EditorState.push(
        editorState,
        Modifier.applyEntity(
          editorState.getCurrentContent(),
          selection,
          entityKey
        ),
        "apply-entity"
      );
    });
  } catch (e) {
    ToastsStore.success("Stopped Crashing");
  }
  // console.log("intel returend");
  editorState = EditorState.set(editorState, { allowUndo: true });
  return editorState;
};

export const addCommentNew = (editorState, spanId) => {
  let selection = editorState.getSelection();
  let foundFirstBlock = false;
  let foundLastBlock = false;
  let firstBlockOffset = selection.getStartOffset();
  let lastBlockOffset = selection.getEndOffset();
  let firstBlockKey = selection.getStartKey();
  let lastBlockKey = selection.getEndKey();
  let starting = firstBlockOffset;

  let entities = [];
  let content = editorState.getCurrentContent();
  content.getBlockMap().forEach((block, index) => {
    if (foundLastBlock) {
      return;
    } else if (foundFirstBlock) {
    } else if (!foundLastBlock && block.getKey() == lastBlockKey) {
      foundLastBlock = true;
    } else if (!foundFirstBlock && block.getKey() == firstBlockKey) {
      foundFirstBlock = true;
    } else {
      return;
    }
    // console.log("intel");
    if (index > 0) starting = 0;

    let selectedEntity = null;
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (entity.getType() === "COMMENT") {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: entity
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });

  const entityKey = Entity.create("COMMENT", "MUTABLE", { spanId: spanId });

  editorState = EditorState.push(
    editorState,
    Modifier.applyEntity(editorState.getCurrentContent(), selection, entityKey),
    "apply-entity"
  );

  editorState = EditorState.set(editorState, { allowUndo: false });

  entities.forEach(entity => {
    const entityKey = Entity.create("COMMENT", "MUTABLE", {
      spanId: spanId,
      highlight: entity.entity.getData().highlight,
      alert: entity.entity.getData().alert
    });

    if (entity.blockKey == firstBlockKey) {
      if (lastBlockOffset < entity.start) return;

      entity.start =
        entity.start < firstBlockOffset ? firstBlockOffset : entity.start;
    }

    if (entity.blockKey == lastBlockKey) {
      //if (firstBlockOffset > entity.end) return;

      //entity.start =
      //  entity.start < lastBlockOffset ? lastBlockOffset : entity.start;

      entity.end = entity.end > lastBlockOffset ? lastBlockOffset : entity.end;
    }

    selection = selection.merge({
      anchorOffset: entity.start,
      focusOffset: entity.end,
      anchorKey: entity.blockKey,
      focusKey: entity.blockKey,
      isBackward: false
    });

    editorState = EditorState.push(
      editorState,
      Modifier.applyEntity(
        editorState.getCurrentContent(),
        selection,
        entityKey
      ),
      "apply-entity"
    );
  });

  // console.log("intel returend");
  editorState = EditorState.set(editorState, { allowUndo: true });
  return editorState;
};

export const deleteCommentNew = (editorState, spanId) => {
  let selection = editorState.getSelection();
  let foundFirstBlock = false;
  let foundLastBlock = false;
  let firstBlockOffset = selection.getStartOffset();
  let lastBlockOffset = selection.getEndOffset();
  let firstBlockKey = selection.getStartKey();
  let lastBlockKey = selection.getEndKey();
  let starting = firstBlockOffset;

  let entities = [];
  let content = editorState.getCurrentContent();
  content.getBlockMap().forEach((block, index) => {
    if (foundLastBlock) {
      return;
    } else if (foundFirstBlock) {
    } else if (!foundLastBlock && block.getKey() == lastBlockKey) {
      foundLastBlock = true;
    } else if (!foundFirstBlock && block.getKey() == firstBlockKey) {
      foundFirstBlock = true;
    } else {
      return;
    }
    // console.log("intel");
    if (index > 0) starting = 0;

    let selectedEntity = null;
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (entity.getType() === "COMMENT") {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: entity
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });

  const entityKey = Entity.create("COMMENT", "MUTABLE", { spanId: spanId });

  editorState = EditorState.push(
    editorState,
    Modifier.applyEntity(editorState.getCurrentContent(), selection, entityKey),
    "apply-entity"
  );

  editorState = EditorState.set(editorState, { allowUndo: false });

  entities.forEach(entity => {
    const entityKey = Entity.create("COMMENT", "MUTABLE", {
      spanId: spanId,
      highlight: entity.entity.getData().highlight
    });

    if (entity.blockKey == firstBlockKey) {
      if (lastBlockOffset < entity.start) return;

      entity.start =
        entity.start < firstBlockOffset ? firstBlockOffset : entity.start;
    }

    if (entity.blockKey == lastBlockKey) {
      //if (firstBlockOffset > entity.end) return;

      //entity.start =
      //  entity.start < lastBlockOffset ? lastBlockOffset : entity.start;

      entity.end = entity.end > lastBlockOffset ? lastBlockOffset : entity.end;
    }

    selection = selection.merge({
      anchorOffset: entity.start,
      focusOffset: entity.end,
      anchorKey: entity.blockKey,
      focusKey: entity.blockKey,
      isBackward: false
    });

    editorState = EditorState.push(
      editorState,
      Modifier.applyEntity(
        editorState.getCurrentContent(),
        selection,
        entityKey
      ),
      "apply-entity"
    );
  });

  // console.log("intel returend");
  editorState = EditorState.set(editorState, { allowUndo: true });
  return editorState;
};

export const removeHighlights = contentState => {
  let deletedEntityKeys = [];
  Object.keys(contentState.entityMap).forEach(function(key) {
    if (
      contentState.entityMap[key].data &&
      contentState.entityMap[key].data.highlight
    ) {
      delete contentState.entityMap[key].data.highlight;
      delete contentState.entityMap[key].data.alert;
      if (
        !contentState.entityMap[key].data.spanId &&
        contentState.entityMap[key].type == "COMMENT"
      ) {
        deletedEntityKeys.push(key);
        delete contentState.entityMap[key];
      }
    }
  });

  // // if (deletedEntityKeys.length > 0)
  // contentState.blocks.forEach(function(block, i) {
  //   block.entityRanges.forEach(function(entityRange, j) {
  //     // if (deletedEntityKeys.includes(entityRange.key + ""))
  //     contentState.blocks[i].entityRanges.splice(j, j + 1);
  //   });

  //   // block.inlineStyleRanges.forEach(function(inlineStyleRange, j) {
  //   //   // if (deletedEntityKeys.includes(entityRanges.key + ""))
  //   //   contentState.blocks[i].inlineStyleRanges.splice(j, j + 1);
  //   // });
  // });

  // console.log("de:", deletedEntityKeys);

  return contentState;
};

export const getHighlightColorClass = (alert, classPrefix) => {
  let stage = alert && alert.metadata.stage;
  let type = alert && alert.metadata.type;
  let subType = alert && alert.metadata.sub_type;

  let colorClass = ` ${
    stage == "pulse" && type == "sanction" && subType == "risk"
      ? "red"
      : stage == "modeling" && type == "risk_analyzer_engine"
      ? alert.data.intensity <= 10
        ? "dark-green"
        : alert.data.intensity <= 25
        ? "green"
        : alert.data.intensity <= 49
        ? "yellow"
        : alert.data.intensity <= 69
        ? "orange"
        : alert.data.intensity <= 89
        ? "red"
        : "dark-red"
      : stage == "pulse" && type == "legislation"
      ? alert.data.risk <= 10
        ? "dark-green"
        : alert.data.risk <= 25
        ? "green"
        : alert.data.risk <= 49
        ? "yellow"
        : alert.data.risk <= 69
        ? "orange"
        : alert.data.risk <= 89
        ? "red"
        : "dark-red"
      : stage == "playbook" && type == "variable"
      ? "dark-red"
      : "gray"
  }`;

  return classPrefix + "-" + colorClass.replace(" ", "");
};

export const renderHighlightsInEditor = (editorState, alerts) => {
  if (alerts && alerts.length == 0) {
    return editorState;
  }
  let preSelection = editorState.getSelection();

  alerts.forEach(alert => {
    // console.log(alert);
    if (
      alert.blockId &&
      alert.length != undefined &&
      alert.offset != undefined &&
      alert.offset >= 0 &&
      alert.offset + alert.length > 0
    ) {
      let selection = editorState.getSelection().merge({
        anchorKey: alert.blockId,
        focusKey: alert.blockId,
        anchorOffset: alert.offset,
        focusOffset: alert.length + alert.offset,
        isBackward: false
      });
      try {
        editorState = addCommentIntelligence(editorState, selection, {
          alert: alert
        });
      } catch (e) {
        console.log(e);
      }
    }
  });

  return EditorState.forceSelection(editorState, preSelection);
};
