import React, { Component } from "react";
import { Modal } from "reactstrap";

class ModalLang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    return (
      <Modal
        toggle={this.toggle}
        {...this.props}
        isOpen={this.state.modal}
        className={!this.props.cname ? "modal-lg" : this.props.cname}
      >
        <div>{this.props.children}</div>
      </Modal>
    );
  }
}

export default ModalLang;
