const Store = {
  BASE_URL: "https://chroma-api.psmorfia.com/api",
  topBarRef: null,
  profileRef: null,
  Toast: null,
  user: {
    profile_details: {
      first_name: "N/A",
      last_name: "",
      designation: "N/A",
      organization_name: "N/A",
      contact_details: {
        country_code: "N/A",
        contact_number: ""
      },
      user_picture: require("./assets/images/avatar.png"), //used in comments
      email: "N/A"
    },
    language_details: {
      selected_language: 0,
      available_languages: [
        {
          code: 0,
          name: "English - US"
        },
        {
          code: 1,
          name: "English - UK"
        }
      ]
    },
    user_roles: [],
    user_id: 0
  },
  userAvatar: require("./assets/images/avatar.png"),
  notifications: []
};

export default Store;
